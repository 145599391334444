import { Component } from '@angular/core';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { AuthService } from '@auth0/auth0-angular';
import { GTMComponentComponent } from 'src/app/shared/components/gtmcomponent/gtmcomponent.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-for-students',
  templateUrl: './for-students.component.html',
  styleUrls: ['./for-students.component.scss'],
})
export class ForStudentsComponent extends GTMComponentComponent {
  constructor(
    public cdn: CDNService,
    private auth0: AuthService,
    gtm: GoogleTagManagerService
  ) {
      super(gtm);
  }
  
  signup() {
    this.handle('signup', 'home', 'click', 'Sign Up');

    this.auth0.loginWithRedirect({
      appState: { target: '/dashboard' },
      authorizationParams: {
        screen_hint: 'signUp',
      }
    });
 }
}
