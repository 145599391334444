import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class LocationApiConfig {
    static path: string = environment.api.location.path;
    static version: string | number = environment.api.location.version;

    public static setApiVersion(version: string | undefined = 'api'): void {
        LocationApiConfig.version = version;
    }

    public static getApiVersion(): string | number {
        return LocationApiConfig.version;
    }

    public static getPath(): string {
        return LocationApiConfig.path;
    }

    public static getFullUrl(path): string {
        if (LocationApiConfig.getApiVersion()) {
            return [LocationApiConfig.getPath(), LocationApiConfig.getApiVersion(), path].join('/');
        } else {
            return [LocationApiConfig.getPath(), path].join('/');
        }
    }
}
