<div class="standard-padding">
    <div class="rounded-corners">
        <div class="text mb-large">
            Messages
        </div>
        <div class="text mb-large">
            Coming Soon...
        </div>
    </div>
</div>


<!--

<div fxLayout="column">
    <h2>Message Center</h2>
    <div class="disucssion-board px-2">
        <app-progress-bar value="50" color="green" *ngIf="loading"></app-progress-bar>
        <div *ngFor="let d of discussion | sort:'created'" fxLayout="column">
            <div [innerHTML]="d.comment" [ngClass]="{ 'font-italic ml-auto': d.userId === user.id, 'private': d.privacy }">
            </div>
            <div class="mr-auto" *ngIf="d.userId !== user.id">
                - {{ d.name }}
            </div>
            <div class="font-italic ml-auto" *ngIf="d.userId === user.id">
                - {{ d.name }}
            </div>
            <mat-divider></mat-divider>
        </div>
        <div *ngIf="!discussion || !discussion.length" class="mx-auto font-italic">
            There are no messages.
        </div>
    </div>

    <form [formGroup]="formGroup" (ngSubmit)="onSubmit($event)" fxLayout="column" fxLayoutAlign="stretch stretch" fxLayoutGap="20px">
        <div fxLayout="column">
            <mat-form-field appearance="outline">
                <mat-label>Message</mat-label>
                <textarea matInput placeholder="message" rows="15" autocomplete="false" formControlName="Message">
                </textarea>
                <mat-hint [ngClass]="{ 'alert-error':  message.value.length > 100 }">
                    ({{ message.value.length }}/100)
                </mat-hint>
                <mat-error *ngIf="message.errors?.required">
                    Message is Required.
                </mat-error>
                <mat-error *ngIf="message.errors?.maxlength">
                    Must be at most <strong>100 character</strong>.
                </mat-error>
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <mat-checkbox class="my-auto" color="accent" [checked]="isPrivate" (change)="isPrivate = !isPrivate">
                    This is a private note for myself
                </mat-checkbox>
                <button color="primary" mat-icon-button *ngIf="!isPrivate" class="my-auto" type="submit"><mat-icon>send</mat-icon></button>
                <button color="accent" mat-icon-button *ngIf="isPrivate" class="my-auto" type="submit"><mat-icon>save</mat-icon></button>
            </div>
        </div>
    </form>
</div> -->