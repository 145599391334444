import { Component } from '@angular/core';
import { TableOfContents } from 'src/app/shared/models';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {

  public links: TableOfContents[] = [{
    heading: 'Welcome',
    href: 'welcome',
    contents: `Welcome to Unext and Unext’s website, operated by Unext. By using or
    accessing any of the services offered through our web portals https://www.unext.online
    ("Service Offering(s)"), You agree to be bound by these terms of service between
    You and Unext (the "Agreement").`,
    scroll: true,
    external: false,
  }, {
    heading: 'Your content',
    href: 'your-content',
    contents: `If You do not agree to this Agreement, You must not use or access the
    Service Offering. If You are accepting this Agreement on behalf of an Organization
    (and not for You as individual), You represent and warrant that You have legal
    authority to bind that Organization, and You hereby enter into this Agreement on
    behalf of that Organization. "You" or "Your" means the entity accepting this
    Agreement and the individual accepting this Agreement on behalf of the entity.
    "Unext," "we," "us," or "our" means (i) Unext, Capitalized terms used in the
    Agreement are defined throughout the Agreement and in this Section below.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Acceptable use',
    href: 'acceptable-use',
    contents: `"Account" means an approved user of the website. An account shall have one
    Account Owner, and, in the case of student users, one user, and, in the case of
    Organization users, such employees of the Organization  as the Organization
    determines appropriate.`,
    scroll: true,
    external: false,
  }, {
    heading: 'IP ownership of services offering and rights to feedback',
    href: 'ip-ownership',
    contents: `"Account Owner" means the individual or Organization representative who is
    responsible for using a given account, and, in the case of a Premium  User, is
    responsible for payment and billing of a given Account.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Payment and taxes',
    href: 'payment-and-taxes',
    contents: `"Confidential Information" means information, software and materials
    provided by Unext to You, including software, information and materials of third
    parties, which are in tangible form and labeled "confidential" or the like, or,
    information which a reasonable person knew or should have known to be confidential.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Temporary suspension',
    href: 'temporary-suspension',
    contents: `The following information shall be considered our Confidential Information
    whether marked or identified as such: Login Credentials, software, technical
    information and documentation relating to the Service Offerings that is not
    otherwise made generally available to the public by Unext.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Term and termination',
    href: 'term-and-termination',
    contents: `"Content" means any and all applications, files, information, data,
    software, or other content uploaded to, published or displayed through the
    Service Offerings.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Warrenty exclusion',
    href: 'warrenty',
    contents: `"Intellectual Property Rights" means all worldwide intellectual property
    rights, including, without limitation, copyrights, trademarks, service marks, trade
    secrets, patents, patent applications, and moral rights, whether registered or
    unregistered.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Indemnification',
    href: 'indemnification',
    contents: `"Organization" means the entity that is accepting this Agreement.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Temporary suspension',
    href: 'temporary-suspension',
    contents: `"Login Credentials" means any passwords, API tokens or other security
    credentials that enable Your access to the Service Offering.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Limitation of liability',
    href: 'limitation-of-liability',
    contents: `"Paid User" means an Organization or individual employer that subscribes
    to a for-pay Service Offering. Only private, non-governmental entities can be Paid
    Users. Paid Users have the right to publish internship and job opportunities on the
    Unext website.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Temporary suspension',
    href: 'temporary-suspension',
    contents: `“Premium User” means a Paid User.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Confidential information',
    href: 'confidentital-information',
    contents: `"Privacy Policy" means the then-current version of the privacy policy
    document accessible on the Unext website. We may modify that policy from time to time.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Miscellaneous',
    href: 'miscellaneous',
    contents: `"Relationship Data" means personal information we collect during the
    registration, activation and maintenance of your user profile and/or account. It
    may include, without limitation, names and contact details of Your personnel
    involved in maintaining or using the Service Offering. Relationship Data does not
    include information collected through the publicly accessible portions of our
    webpage, which is subject to our Privacy Policy.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Services',
    href: 'services',
    contents: `"Services Description(s)" means any description of or information about
    the Service Offerings made available to You through any usage or access instructions
    that we provide to You and which we may modify from time to time.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Subscription Fees',
    href: 'subscription-fees',
    contents: `"Subscription Fees" means the amount paid by Paid Users to Unext for the
    Service Offering for the Subscription Term.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Subscription Term',
    href: 'subscription-term',
    contents: `"Subscription Term" means the time period during which You are provided
    access to the Service Offerings, as specified when you register for the Service Offerings.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Third Parties',
    href: 'third-parties',
    contents: `"Third Party Content" means third party data, Content, services, or
    applications, including open source software.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Third Party Terms',
    href: 'third-party-terms',
    contents: `"Third Party Terms" means the then-current version of the third-party terms
    applicable to any Third Party Content.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Usage Data',
    href: 'usage-data',
    contents: `"Usage Data" means information regarding Your use of the Service Offerings,
    such as information on the amount of storage resources consumed.`,
    scroll: true,
    external: false,
  }, {
    heading: 'User',
    href: 'user',
    contents: `"User" means an Organization or individual that subscribes to the
    Service Offering.`,
    scroll: true,
    external: false,
  }, {
    heading: 'User ID',
    href: 'user-id',
    contents: `"UserID" means the email and username by which You identify your User Profile.`,
    scroll: true,
    external: false,
  }, {
    heading: 'User Profile',
    href: 'user-profile',
    contents: `"User Profile" means Your profile information including username, full name,
    email and other Login Credentials, provided to us by You and updated by you periodically.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Your content',
    href: 'your-content',
    contents: `"Your Content" means any Content published or displayed through the Service
    Offerings by You, or your Your authorized users (or otherwise through Your account).
    Your Content includes Relationship Data, but does not include Usage Data.`,
    scroll: true,
    external: false,
  }, {
    heading: 'The service offering',
    href: 'service-offering',
    contents: `<ol>
    <li>
        The Service Offering.
        <ol>
            <li>
                Generally. All access to, and use of, any Service Offerings are subject to the terms and
                conditions of this Agreement. We may deliver the Service Offering to You with the assistance of
                our affiliates, licensors and service providers. You will comply with all laws, rules and
                regulations applicable to Your use of the Service Offering, and with the Third Party Terms and
                the Service Descriptions.
            </li>
            <li>
                Access to the Service Offering. You may access and use the Service Offering for solely Your own
                benefit (or the benefit of Your Organization). To access the Service Offering, You must register
                for the Service Offering and be approved by us, and we will set up an authorized User Profile.
                You shall keep your Login Credentials confidential. User Profile accurate, complete and current
                as long as You continue to use the Service Offering. You are responsible for any use that occurs
                under your Login Credentials. If You believe an unauthorized user has gained access to Your
                Login Credentials, You shall notify us immediately. Neither we nor our affiliates are
                responsible for any unauthorized access to or use of Your account. Unext does not guaranty
                uninterrupted or continuous access to the Service Offerings, as Unext relies on third party
                service providers to maintain the Service Offering.
            </li>
            <li>
                Third Party Content. As part of Your use of the Service Offering, You may be provided with
                access to Third Party Content under separate terms and/or with separate fees. We make this Third
                Party Content available on an "AS-IS" basis without indemnification or support and disclaim all
                warranties of any kind (including warranties of merchantability, fitness for a particular
                purposes, and non-infringement), express or implied. You are solely responsible for reviewing,
                accepting, and complying with any Third Party Terms and other restrictions applicable to the
                Third Party Content.
            </li>
        </ol>
    </li>
</ol>
`,
    scroll: true,
    external: false,
  }];

}
