import { Component, Input } from '@angular/core';
import { SubscriptionSummary } from '../../models';
import { CDNService } from '../../services/cdn.service';

@Component({
  selector: 'app-pricing-summary',
  templateUrl: './pricing-summary.component.html',
  styleUrls: ['./pricing-summary.component.scss']
})
export class PricingSummaryComponent {

  @Input()
  monthly: boolean | undefined;

  @Input()
  public pricing: SubscriptionSummary;

  constructor(
    public cdn: CDNService
  ) { }

}
