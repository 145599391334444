import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import { MessageService } from '../../services/message.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-message-center',
  templateUrl: './message-center.component.html',
  styleUrls: ['./message-center.component.scss']
})
export class MessageCenterComponent implements OnInit {

  @Input()
  user: User;

  // @Input()
  // studentOpportunity: StudentOpportunity;

  // discussion: DiscussionMessage[] = [];
  // newId: Guid;
  // formGroup: FormGroup;
  // isPrivate = false;
  // loading = false;

  constructor(
    private messages: MessageService,
    private snackbar: ToastService,
  ) {
    // this.formGroup = new FormGroup({
    //   Message: new FormControl('', [Validators.required, Validators.maxLength(100)])
    // });
  }

  ngOnInit(): void {
    this.loadMessages();
  }

  // createForm(): void {
  //   this.formGroup = new FormGroup({
  //     Message: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)])
  //   });
  // }

  private loadMessages(): void {
    // if (this.studentOpportunity) {
    //   this.loading = true;
    //   this.messages.getOpportunityDiscussion(this.studentOpportunity.id)
    //         .toPromise()
    //         .then((value: DiscussionMessage[]) => {
    //     this.discussion = value;
    //     this.newId = Guid.create();
    //     this.loading = false;
    //   }).catch(err => {
    //     this.snackbar.open('There was a problem loading the messages for this opportunity', 'dismiss', { duration: 5000 });
    //     this.loading = false;
    //   });
    // }
  }

  // public refesh(): void {
  //   this.loadMessages();
  // }

  // onSubmit(e: any) {
  //   if (this.formGroup.valid) {
  //     const mess = new DiscussionMessage();
  //     mess.id = this.newId.toString();
  //     mess.comment = this.message.value;
  //     mess.privacy = this.isPrivate;
  //     mess.studentOpportunityId = this.studentOpportunity.id;
  //     mess.userId = this.user.id;
  //     this.loading = true;
  //     this.messages.addDiscussion(mess).toPromise().then((value: DiscussionMessage) => {
  //       this.discussion.push(value);
  //       this.newId = Guid.create();
  //       this.message.setValue('');
  //       this.loading = false;
  //     }).catch(err => {
  //       this.snackbar.open('There was a problem sending your message', 'dimsiss', { duration: 5000 });
  //       this.loading = false;
  //     });
  //   }
  // }

  // get message(): FormControl {
  //   return this.formGroup.get('Message') as FormControl;
  // }
}
