import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class IndustryApiConfig {
    static path: string = environment.api.industry.path;
    static version: string | number = environment.api.industry.version;

    public static setApiVersion(version: string | undefined = 'api'): void {
        IndustryApiConfig.version = version;
    }

    public static getApiVersion(): string | number {
        return IndustryApiConfig.version;
    }

    public static getPath(): string {
        return IndustryApiConfig.path;
    }

    public static getFullUrl(path): string {
        if (IndustryApiConfig.getApiVersion()) {
            return [IndustryApiConfig.getPath(), IndustryApiConfig.getApiVersion(), path].join('/');
        } else {
            return [IndustryApiConfig.getPath(), path].join('/');
        }
    }
}
