import { StudentOpportunity } from "../models";

export class CalendarService {
    private editMode = false;

    daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  
    getAvailabilityString(availabilityString: string): string {
        const openings: [number, number][] = []; // Array to store start and end indices of openings
        let startTime: number | null = null;
    
        for (let i = 0; i < availabilityString.length; i++) {
          if (availabilityString[i] === '1') {
            if (startTime === null) {
              startTime = i;
            }
          } else { // char === '0'
            if (startTime !== null) {
              openings.push([startTime, i - 1]);
              startTime = null;
            }
          }
        }
    
        // Handle the case where the last character is '1'
        if (startTime !== null) {
          openings.push([startTime, availabilityString.length - 1]);
        }
    
        if (openings.length === 0) {
          return '(Time Not Available)';
        }
    
        const formattedOpenings = openings.map(([start, end]) => {
          const formattedStartTime = this.getFormattedTime(start);
          const formattedEndTime = this.getFormattedTime(end + 1);
          return `${formattedStartTime} - ${formattedEndTime}`;
        });
    
        return formattedOpenings.join(' & ');
      }
    
      getFormattedTime(hour: number): string {
        const period = hour < 12 ? 'am' : 'pm';
        const displayHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
        return `${displayHour}${period}`;
      }

      getBestStudetnOpportunityTimes(prospects: StudentOpportunity[]) {
        if (prospects.length) {
          const dayHourCounts: { [day: string]: { [hour: number]: number } } = {
            sunday: { },
            monday: {},
            tuesday: {},
            wednesday: {},
            thursday: {},
            friday: {},
            saturday: {},
          };
    
          // Iterate through each prospect
          prospects.forEach(p => {
            const student = p.student;
    
            // Iterate through each day of the week and count the available hours
            this.daysOfWeek.forEach(day => {
              switch (day.toLowerCase()) {
                case 'sunday':
                  for (let hour = 0; hour < student.sunday.length; hour++) {
                    if (dayHourCounts.sunday[hour] === undefined) {
                      dayHourCounts.sunday[hour] = 0;
                    }
                    dayHourCounts.sunday[hour] += (student.sunday[hour] === '1' ? 1 : 0);
                  }
                  break;
                case 'monday':
                  for (let hour = 0; hour < student.monday.length; hour++) {
                    //console.log(student.monday)
                    if (dayHourCounts.monday[hour] === undefined) {
                      dayHourCounts.monday[hour] = 0;
                    }
                    dayHourCounts.monday[hour] += (student.monday[hour] === '1' ? 1 : 0);
                  }
                  break;
                case 'tuesday':
                  for (let hour = 0; hour < student.tuesday.length; hour++) {
                    if (dayHourCounts.tuesday[hour] === undefined) {
                      dayHourCounts.tuesday[hour] = 0;
                    }
                    dayHourCounts.tuesday[hour] += (student.tuesday[hour] === '1' ? 1 : 0);
                  }
                  break;
                case 'wednesday':
                  for (let hour = 0; hour < student.wednesday.length; hour++) {
                    if (dayHourCounts.wednesday[hour] === undefined) {
                      dayHourCounts.wednesday[hour] = 0;
                    }
                    dayHourCounts.wednesday[hour] += (student.wednesday[hour] === '1' ? 1 : 0);
                  }
                  break;
                case 'thursday':
                  for (let hour = 0; hour < student.thursday.length; hour++) {
                    if (dayHourCounts.thursday[hour] === undefined) {
                      dayHourCounts.thursday[hour] = 0;
                    }
                    dayHourCounts.thursday[hour] += (student.thursday[hour] === '1' ? 1 : 0);
                  }
                  break;
                case 'friday':
                  for (let hour = 0; hour < student.friday.length; hour++) {
                    if (dayHourCounts.friday[hour] === undefined) {
                      dayHourCounts.friday[hour] = 0;
                    }
                    dayHourCounts.friday[hour] += (student.friday[hour] === '1' ? 1 : 0);
                  }
                  break;
                case 'saturday':
                  for (let hour = 0; hour < student.saturday.length; hour++) {
                    if (dayHourCounts.saturday[hour] === undefined) {
                      dayHourCounts.saturday[hour] = 0;
                    }
                    dayHourCounts.saturday[hour] += (student.saturday[hour] === '1' ? 1 : 0);
                  }
                  break;
              }
            });
          });
    
          const minRequiredCount = Math.ceil(prospects.length / 2);
    
          // Create new strings for each day based on availability
          const bestTimesStrings: { [day: string]: string } = {};
    
          console.log(bestTimesStrings);
    
          for (const day in dayHourCounts) {
            bestTimesStrings[day] = Object.entries(dayHourCounts[day])
              .map(([/*hour*/, count]) => {
                return (count >= minRequiredCount ? '1' : '0');
              })
              .join('');
          }
    
          console.log(bestTimesStrings);
          // Sort the best times strings by the number of 1s
          const sortedBestTimes = Object.entries(bestTimesStrings)
            .sort((a, b) => b[1].split('').filter(char => char === '1').length - a[1].split('').filter(char => char === '1').length)
            .map(([day, times]) => ({ day, times }));
    
    
          const bestDays = sortedBestTimes.slice(0, 3);
          bestDays.forEach(d => {
            d.times = this.getAvailabilityString(d.times);
          });
    
          return bestDays;
        }
    
        return [];
      }
}