import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Affiliate } from '../models/affiliate.model';

@Injectable()
export class AffiliateService {

    private http: HttpClient;

    constructor(handler: HttpBackend) {
        this.http = new HttpClient(handler);
    }

    public get(): Observable<Affiliate[]> {
        return this.http.get<Affiliate[]>('/assets/json/affiliates.json');
    }
}
