import { Component } from '@angular/core';

@Component({
  selector: 'app-confident-talent-pool',
  templateUrl: './confident-talent-pool.component.html',
  styleUrl: './confident-talent-pool.component.scss'
})
export class ConfidentTalentPoolComponent {

}
