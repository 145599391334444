export const environment = {
    production: false,
    api: {
        auth: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/auth',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true
        },
        user: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/user',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        audience: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/audience',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        certification: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/resume/certification',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        culture: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/culture',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        employer: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/employer',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        experience: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/experience',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        fieldOfInterest: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/fieldofinterest',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        industry: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/industry',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        location: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/location',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        jobdescription : {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/jobdescription',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        student: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/student',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        message: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/message',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        opportunity: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/opportunity',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        school: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/school',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        size: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/size',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        skill: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/skill',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        study: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/student/study',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        subscription: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/subscription',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        upload: {
            path: 'https://dev-upload-api-unext.azurewebsites.net/api/upload',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        resume: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/resume',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        admin: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/admin',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        reporting: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api/reporting',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true,
        },
        unext: {
            path: 'https://dev-core-api-unext.azurewebsites.net/api',
            version: null,
            debug: false,
            authPrefix: '',
            secure: true
        },
        stripe: {
            publicApiKey: 'pk_test_e9DWY5WxWA31EQGhHZkcgtiQ00CwFmfRr3',
        },
    },
    cdn: {
        root: 'https://dev.cdn.unext.online',
        startPath: ['https://unextdev.blob.core.windows.net/assets/'],
        replacePath: 'https://dev.cdn.unext.online/'
    },
    google: {
        tagId: 'GTM-KL67WS6'
    },
    auth0: {
        domain: 'login.unext.online',
        clientId: 'H9jtG7Sl5DNKnf5xtqqLvrIKIFDOUicK',
        audience: 'https://dev--bem2te4.us.auth0.com/api/v2/',
        useRefreshTokens: true,
        scope: 'openid email profile',
        version: 'api/v2'
    },
};

import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
