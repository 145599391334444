import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { Employer, User } from 'src/app/shared/models';
import { SubscriptionOptions } from 'src/app/shared/models/subscriptionOptions.model';
import { UserService, ModalService, EmployerService } from 'src/app/shared/services';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-employer-subscription',
  templateUrl: './employer-subscription.component.html',
  styleUrl: './employer-subscription.component.scss'
})
export class EmployerSubscriptionComponent {


  
  oppTypes: DropDownOptions[];
  employer: Employer;
  urlName: string;
  stripeUrl: string;
  working: number;
  stripePlans: SubscriptionOptions[] = [];
  subscriptions:[] = [];
  user: User;


  constructor(
    private route: ActivatedRoute,
    private users: UserService,
    private modals: ModalService,
    private snackbar: ToastService,
    private employerService: EmployerService,
  
    private subscriptionService: SubscriptionService,
  ) {
     this.user = this.users.authenticatedUser;

    this.route.params.subscribe(params => {
      this.urlName = params['urlName'];
      this.loadStripeInfo();
    });
  }

async loadStripeInfo(){
  if(this.urlName != null){
    this.employer = await this.employerService.getEmployer(this.urlName);
    this.stripePlans =  await this.subscriptionService.getSubscriptionDetails();
    this.subscriptions = await this.employerService.getSubscription(this.urlName);
  }
}

updatedSubscription(subscribed: boolean): void {
  this.employer.subscribed = subscribed;
  this.close('subscribe');
}

close(s: string){
  this.modals.close(s);
}
open(s: string){
  this.modals.open(s);
}

subscribeNow(subscribed: boolean, id: string): void {
  this.close(id);
  this.user.employee.employer.subscribed = subscribed;

  if (subscribed) {
    this.snackbar.success({
      title: 'Thank you for subscribing!',
      message: 'You now have access to create opportunities and connnect with students.'
    });
  } else {
    this.snackbar.warning({
      title: 'No Subscription',
      message: 'You will not be able to use all the features Unext has to offer until you subscribe.'
    });
  }
}
  async navigateToStripe(planId: number): Promise<void> {

    try {
      this.working = 50;
      if (this.employer.subscribed) {
        this.stripeUrl = (await this.employerService.getManageSubscriptionLink(window.location.href))?.link;
      } else {
        this.stripeUrl = (await this.employerService.getCreateSubscriptionLink(planId, window.location.href))?.link;
      }
    } catch (error) {
      this.snackbar.error({
        message: error.message,
      });
    } finally {
      this.working = 0;
    }

    if (this.stripeUrl?.length) {
      window.location.href = this.stripeUrl;
    }
  }
}
