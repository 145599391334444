import { Component } from '@angular/core';
import { BaseSectionComponent } from '../base-section/base-section.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ModalService, ResumeService, SkillService } from 'src/app/shared/services';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrl: './languages.component.scss'
})
export class LanguagesComponent extends BaseSectionComponent {
  constructor(
    route: ActivatedRoute,
    fb: FormBuilder,
    modals: ModalService,
    resumes: ResumeService,
    snackbar: ToastService,
    skills: SkillService,
    router: Router) {

      super(route, fb, modals, resumes, skills, snackbar, router);

    this.forcePresentTense = undefined;
    this.validateStartDate = true;
    this.validateEndDate = false;
    this.showLanguages = true;
    this.setupForm();
  }

  override async updateForm(): Promise<void> {
    super.updateForm();
  }

}
