<app-progress-bar [value]="working" [splashScreen]="true"></app-progress-bar>

<div class="contact-wrapper" *ngIf="section">
    <form [formGroup]="sectionForm">
        <!-- Contact Info Section -->
        <div class="form edit-section">
            <div class="four-col row">
                <label for="email" class="form-label text text-black">
                    Email
                </label>
                <input name="email" type="text" formControlName="email"
                    class="form-input border border-teal text form-input-100"/>
            </div>

            <div class="four-col row">
                <label for="phoneNumber" class="form-label text text-black">
                    Phone Number
                </label>
                <input appPhonemask name="phoneNumber" type="text" formControlName="phoneNumber"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="four-col row">
                <label for="address" class="form-label text text-black">
                    Location
                </label>
                <input name="address" type="text" formControlName="address"
                    class="form-input border border-teal text form-input-100" />
            </div>
        </div>
        <div class="flex-columns flex-wrap mt-medium">
            <div class="three-col">
                <label class="form-error" *ngIf="sectionForm.touched && email?.errors?.required ?? false">
                    Email is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && email?.errors?.email ?? false">
                    Email is not valid
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && address?.errors?.required ?? false">
                    <Address></Address> is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && address?.errors?.required ?? false">
                    <Address></Address> is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && phoneNumber?.errors?.required ?? false">
                    Phone is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.required?? false">
                    Description is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.minlength ?? false">
                    Description is not long enough ({{ description?.errors?.minlength?.actualLength }} of {{
                    description?.errors?.minlength?.requiredLength }})
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.maxlength ?? false">
                    Description is too long ({{ description?.errors?.maxlength?.actualLength }} of {{
                    description?.errors?.maxlength?.requiredLength }})
                </label>
            </div>

            <div class="three-col row mb-small mr-small ml-auto flex-columns">
                <button type="submit" class="btn mr-small" (click)="cancel()" [disabled]="!hasUnsavedChanges">
                    Cancel
                </button>
                <button type="submit" class="lime-green" (click)="save()" [disabled]="!sectionForm?.valid">
                    Save Section
                </button>
            </div>
        </div>

    </form>
</div>