<footer class="bg-dark-green">
    <div class="background tilt flip bg-dark-green"></div>
    <div class="row">
        <div class="col newsletter">
            <app-logo class="white" [loggedIn]="user"></app-logo>
            <p class="serif">
                <strong>Learn about new opportunities & more<br/>by signing up for our newsletter!</strong>
            </p>
            <app-signup></app-signup>
        </div>
        <div class="col">
            <div class="row justify-end" *ngIf="!user">
                <button class="btn lime-green" [routerLink]="['/request-demo']">Request Demo</button>
                <button class="btn lime-green" (click)="this.login()">Login/Register</button>
            </div>
            <div class="links row">
                <div class="col">
                    <a rel="noopener" [routerLink]="['/for-students']">For Students</a>
                    <a rel="noopener" *ngIf="!user || user.isEmployer" [routerLink]="['/pricing']">Pricing</a>
                    <a rel="noopener" [routerLink]="['/faqs']">FAQs</a>
                    <a rel="noopener" href="https://cdn.unext.online/Web%20Services%20Agreement.pdf" target="_blank">Terms & Conditions</a>
                    </div>
                <div class="col">
                    <a rel="noopener" [routerLink]="['/for-employers']">For Employers</a>
                    <a rel="noopener" [routerLink]="['/contact-us']">Contact Us</a>
                    <a rel="noopener" href="https://cdn.unext.online/Privacy%20Policy.pdf" target="_blank">Privacy Policy</a>
                    <a rel="noopener" [routerLink]="['/about-us']">About Unext</a>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="copyright text-center">
    <div>©{{ year() }} Unext. All rights reserved. | v {{ version }}</div>
</div>