import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/services';
import { InfoModalService } from 'src/app/shared/services/info-modal.service';
import { InfoModalData } from '../../models/infoModalData.model';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {
  faInfoCircle = faInfoCircle;
  
  @Input()
  audience: string;

  @Input()
  section: string;

  @Input()
  set item(val: string) {
    this.d = val;
    if (this.d) {
      this.findData();
    }
  }

  get item(): string {
    return this.d;
  }

  private d: string;

  private data: InfoModalData[] = [];
  public dataToShow: string;
  public modalId: string;

  constructor(
    private modals: ModalService,
    private infoModalService: InfoModalService,
  ) { 
    this.modalId = 'information-modal';
  }

  async ngOnInit(): Promise<void> {
    // TODO: use @input values to filter data from an api call
    this.data = await this.infoModalService.get()
    this.findData();
  }

  private findData() {
    if (this.data?.length) {
      const audienceData = this.data.find(x => x.audienceName === this.audience);
      const sectionData = audienceData?.sections.find(x => x.sectionName === this.section);
      const item = sectionData?.items.find(x => x.name === this.item);
      
      if (item) {
        this.dataToShow = item.info;
        this.modalId = 'information-modal-' + item.name;
      }
    }
    
  }

  public openModal(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.modals.open(this.modalId);
  }
  public closeModal(): void {
    this.modals.close(this.modalId);
  }

}
