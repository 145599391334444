import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { StepperComponent } from 'src/app/shared/components/stepper/stepper.component';
import { WeekSchedulerComponent } from 'src/app/shared/components/week-scheduler/week-scheduler.component';
import { Employer, EntityType, InviteMessage, Opportunity, OpportunityRequest, OpportunityRequestApplication, Student, User } from 'src/app/shared/models';
import { Week } from 'src/app/shared/models/week.model';
import { EmployerService, ModalService, StudentService, UserService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { ToastService } from 'src/app/shared/services/toast.service';
type stepId = 'info' | 'documentation' | 'overview';

@Component({
  selector: 'app-request-opportunity',
  templateUrl: './request-opportunity.component.html',
  styleUrl: './request-opportunity.component.scss'
})
export class RequestOpportunityComponent implements OnInit {

  @ViewChild('stepper') stepper: StepperComponent;

  steps: { id: stepId, title: string, split: boolean, valid: () => boolean, next: () => void }[] = [{
    id: 'info',
    title: 'Information',
    split: true,
    valid: () => {
      return this.opportunityType.value && this.location.value && this.industry.value
    },
    next: () => {
      this.next()
    }
  }, {
    id: 'documentation',
    title: 'Documentation',
    split: false,
    valid: () => {
      return this.resume.value
    },
    next: () => this.next()
  }, {
    id: 'overview',
    title: 'Overview',
    split: true,
    valid: () => {
      return true
    },
    next: () => {
      this.router.navigate(['/employer', this.urlName, 'profile'])
    }
  },]

  activeStep = 0;

  daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  fullDayHours = '0'.repeat(24); // A string of 24 zeros
  hoursOfDay = Array.from({ length: 9 }, (_, i) => i + 9); // 9 to 17 (9am to 5pm)
  availabilityOptions = [{ value: 1, label: 'Available All Day' }, { value: 2, label: 'Unavailable All Day' }, { value: 3, label: 'Set Custom Availability' }]


  requestedOpportunities: Opportunity[] = [];
  isLoading = 0;
  opportunityTypeList: DropDownOptions[];
  urlName: string;
  employerOpportunities: Opportunity[] = [];
  employer: Employer;
  selectedOpportunityType: any = null;

  opportunityTypes = [{ value: 5, label: 'Volunteering' }, { value: 2, label: 'Shadow' }];

  requestForm: FormGroup;
  currentUser: User;
  displaySchedule: boolean = false;
  displayInvite: boolean = false;

  request: OpportunityRequest;
  requestApplication: OpportunityRequestApplication;

  inviteForm: FormGroup;
  interests: DropDownOptions[] = [];
  locations: DropDownOptions[] = [];
  industries: DropDownOptions[] = [];
  student: Student;
  resumes: DropDownOptions[] = [];
  resumeId: number;
  entityType = EntityType;

  coverLetterName: string;
  portfolioName: string
  requestSubmitted: boolean = false;
  defaultDayValue = '000000000000000000000000';

  week: Week = {
    sunday: this.defaultDayValue,
    monday: this.defaultDayValue,
    tuesday: this.defaultDayValue,
    wednesday: this.defaultDayValue,
    thursday: this.defaultDayValue,
    friday: this.defaultDayValue,
    saturday: this.defaultDayValue,
  };

  @ViewChild(WeekSchedulerComponent) schedule!: WeekSchedulerComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private employers: EmployerService,
    public cdn: CDNService,
    private snackbar: ToastService,
    private fb: FormBuilder,
    private users: UserService,
    public modalService: ModalService,
    private studentService: StudentService,
  ) {
    this.urlName = this.route.snapshot.params.urlName;
    this.currentUser = this.users.authenticatedUser;
  }

  async ngOnInit() {

    try {
      this.isLoading = 50;
      this.createRequestForm();
      this.employer = await this.employers.getEmployer(this.urlName);
      this.student = await this.studentService.getStudentResume(this.currentUser.student.urlName);
      if (this.student) {
        this.week.sunday = this.student?.sunday;
        this.week.monday = this.student?.monday;
        this.week.tuesday = this.student?.tuesday;
        this.week.wednesday = this.student?.wednesday;
        this.week.thursday = this.student?.thursday;
        this.week.friday = this.student?.friday;
        this.week.saturday = this.student?.saturday;
      }
      this.interests = this.employer.employerFieldOfInterests.map(x => { return { label: x.name, value: x.id } });
      this.locations = this.employer.employerLocations.map(x => { return { label: x.value, value: x.id } });
      this.industries = this.employer.employerIndustries.map(x => { return { label: x.value, value: x.id } });

      this.currentUser.unextResumes.map(value => {
        this.resumes.push({ value: value.id, label: value.name });
      });


      this.request = {
        id: 0,
        studentId: this.currentUser.student.id,
        employerId: this.employer.id,
        opportunityTypeId: 0,
        employerIndustryId: 0,
        employerLocationId: 0
      };

      this.requestApplication = {
        id: 0,
        studentId: this.student?.id,
        opportunityRequestId: 0,
        resumeURL: '',
        coverLetterURL: '',
        portfolioURL: '',
        sundayAvailability: '',
        mondayAvailability: '',
        tuesdayAvailability: '',
        wednesdayAvailability: '',
        thursdayAvailability: '',
        fridayAvailability: '',
        saturdayAvailability: '',
      }

   
    } catch {
      this.snackbar.error({
        message: 'Error loading information.'
      });
    } finally {
      
      this.isLoading = 0;
    }
  }

 

  setWeek(){
    if (this.displaySchedule && this.schedule) {
      const week = this.schedule.getSchedule();
      this.requestApplication.sundayAvailability = week.sunday;
      this.requestApplication.mondayAvailability = week.monday;
      this.requestApplication.tuesdayAvailability = week.tuesday;
      this.requestApplication.wednesdayAvailability = week.wednesday;
      this.requestApplication.thursdayAvailability = week.thursday;
      this.requestApplication.fridayAvailability = week.friday;
      this.requestApplication.saturdayAvailability = week.saturday;
    }
  }

  createRequestForm() {
    this.requestForm = this.fb.group({
      Id: new FormControl(),
      OpportunityType: new FormControl(),
      Location: new FormControl(),
      Industry: new FormControl(),
      Interest: new FormControl(),
      Resume: new FormControl(),
      Name: new FormControl('', [Validators.required]),
      Email: new FormControl( '', [Validators.required, Validators.email]),

    });

  }

  getAvailabilityCheckboxName(day: string, hour: number) {
    return `${day}_${hour}`; // Unique control name
  }

  getAvailabilityDropdownName(day: string) {
    return `${day}_availability`; // Unique control name
  }

  getFormControl(name: string): FormControl {
    return this.requestForm?.get(name) as FormControl;
  }

  get opportunityType(): FormControl {
    return this.getFormControl('OpportunityType');
  }

  get location(): FormControl {
    return this.getFormControl('Location');
  }

  get industry(): FormControl {
    return this.getFormControl('Industry');
  }

  get interest(): FormControl {
    return this.getFormControl('Interest');
  }

  get resume(): FormControl {
    return this.getFormControl('Resume');
  }

  get name(): FormControl {
    return this.getFormControl('Name') as FormControl;
  }

  get email(): FormControl {
    return this.getFormControl('Email') as FormControl;
  }


  async submitRequest() {
    try {
      this.isLoading = 50
      this.request.application = this.requestApplication;
      this.request.opportunityTypeId = this.opportunityType.value?.value;
      this.request.employerIndustryId = this.industry.value?.value;
      this.request.employerLocationId = this.location.value?.value;
      this.requestApplication.employerInterestId = this.interest.value?.value;
      this.requestApplication.resumeURL = this.resume.value ? `${this.getOrigin()}/${this.student.urlName}/resume-preview/${encodeURIComponent(this.resume.value)}/base` : ''
      this.request = await this.employers.saveOpportunityRequest(this.employer.urlName, this.request);
      this.snackbar.success({
        message: 'Your request has been submitted.'
      });

      this.requestSubmitted = true;

      return true;
    } catch (error) {

      this.snackbar.error({
        message: 'There was a problem, please try again.'
      });
      return false;
    } finally {
      this.isLoading = 0;
    }

  }

  async next() {
    this.stepper.next()
  }

  currentStepValid() {
    return this.steps[this.activeStep].valid();
  }

  selectOpportunityType(e: any) {
    if (e?.label != 'Shadow' || e == undefined) {
      this.displaySchedule = false;

    } else {
      this.displaySchedule = true;
      this.setWeek();
    }
  }

  async sendInvite() {
    this.name.markAsTouched();
    this.email.markAsTouched();
  
    // Check if the form is valid
    if ( this.email.invalid) {
      this.snackbar.error({
        message: `Failed to send invite. Invalid email.`
      });
      return; // Stop execution if the form is invalid
    }
  
    const message: InviteMessage = {
      name: this.name.value,
      email: this.email.value,
      link: ''
    };
  
    if (!this.requestApplication.invites) {
      this.requestApplication.invites = [];
    }
  
    this.requestApplication.invites.push(message);

    this.snackbar.success({
      message: `We will send an invite email to ${this.name.value}.`
    });

    this.displayInvite = false;
    this.email.setValue('');
    this.name.setValue('');
  }
  cancelInvite() {
    this.displayInvite = false;
    this.email.setValue('');
    this.name.setValue('');
  }


  displayInviteForm() {
    this.displayInvite = true;

  }

  back(){
    this.displayInvite = false;
    this.stepper.previous()
  }

  // attacth resumne
  coverletterUploaded(e: string[], modal: string): void {
    this.requestApplication.coverLetterURL = e?.length ? e[0] : undefined;
    this.modalService.close(modal);
  }

  resumeUploaded(e: string[], modal: string): void {
    this.requestApplication.resumeURL = e?.length ? e[0] : undefined;
    this.modalService.close(modal);
  }


  portfolioUploaded(e: string[], modal: string): void {
    this.requestApplication.portfolioURL = e?.length ? e[0] : undefined;
    this.modalService.close(modal);
  }

  getOrigin(): string {
    return window.location.origin.toLowerCase().indexOf('localhost') > 0 ? 'https://dev.unext.online' : window.location.origin;
  }

  getAvailabilityString(availabilityString: string): string {
    const openings: [number, number][] = []; // Array to store start and end indices of openings
    let startTime: number | null = null;

    for (let i = 0; i < availabilityString.length; i++) {
      if (availabilityString[i] === '1') {
        if (startTime === null) {
          startTime = i;
        }
      } else { // char === '0'
        if (startTime !== null) {
          openings.push([startTime, i - 1]);
          startTime = null;
        }
      }
    }

    // Handle the case where the last character is '1'
    if (startTime !== null) {
      openings.push([startTime, availabilityString.length - 1]);
    }

    if (openings.length === 0) {
      return 'Not Available';
    }

    const formattedOpenings = openings.map(([start, end]) => {
      const formattedStartTime = this.getFormattedTime(start);
      const formattedEndTime = this.getFormattedTime(end + 1);
      return `${formattedStartTime} - ${formattedEndTime}`;
    });

    return formattedOpenings.join(', ');
  }

  getFormattedTime(hour: number): string {
    const period = hour < 12 ? 'am' : 'pm';
    const displayHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
    return `${displayHour}${period}`;
  }


}
