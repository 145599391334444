<div class="drop-down drop-down-{{ color }}" [ngClass]="{ 'dropdown-fill': fill, 'active': open }">
    <div class="drop-down-buttons">
        <button type="button" (click)="open = !open" class="drop-down-button drop-down-button-{{ color }}"
            [disabled]="disabled" [ngClass]="{ 'active': open, 'required': required }">
            {{ displayLabel }}
        </button>
        <button type="button" (click)="open=!open" class="drop-down-alt-button drop-down-alt-button-{{ color }}"
            [ngClass]="{ 'active': open }">
            <svg width="23" height="10" viewBox="0 0 23 10" xmlns="http://www.w3.org/2000/svg">
                <path d="M23 0.3125H0.5H0L11.5 9.29688L23 0.3125Z" />
            </svg>
        </button>
    </div>
    <div class="drop-down-panel drop-down-panel-{{ color }}" [ngClass]="{ 'active': open }" *ngIf="!disabled">
        <button type="button" *ngFor="let a of actions" type="button" class="drop-down-item drop-down-item-{{ color }}"
            (click)="selectOption(a)">{{ a.label }}</button>
    </div>
</div>