<div class="list-view">
    <div class="bg-teal pl-medium pr-medium">
        <div class="bg-lime pl-medium pr-medium py-large">
            <app-filter-menu [vertical]="true" (outputListEvent)="updateData($event)" [filterDropDownOptions]="dataDropDownOptions">
            </app-filter-menu>
        </div>
    </div>
    <div class="bg-cream pl-medium pr-medium py-large">
        <div id="title" class="flex-rows mb-medium">
            <div class="text text-title mb-small mr-small">
                Student & Resume Metrics
            </div>
            <div class="text text-900">
                Here, you can easily explore student metrics, resume data, and engagement info. Check out enrollment numbers, graduation rates, and average GPA.
                Dive into resume details to see students' skills and experiences. Discover how students are participating in activities and internships.
                Use these insights to support and understand student success better!
            </div>
        </div>
        <div id="subtitle" class="flex-columns grid-thirds mb-medium">
            <div class="flex-rows">
                <label class="text text-label text-teal xlarge text-900">{{school?.name}}</label>
                <label class="text text-label text-black accent xlarge text-900">Student Stats</label>
            </div>
            <div class="bg-white py-medium px-medium flex-columns justify-between">
                <h1 class="text-green">{{basicData?.currentMonth?.newStudents}}</h1>
                <div class="text-label text-800 text-teal large">Student <br> Profiles</div>
            </div>
            <div class="bg-white py-medium px-medium flex-columns justify-between">
                <h1  class="text-green">{{basicData?.currentMonth?.resumesCreated}}</h1>
                <div  class="text-label text-800 text-teal large">Completed <br> Resumes</div>
            </div>
        </div>
        <div class="flex-rows mb-medium">
            <div>
                <label class="text text-black accent large text-700">Documentation</label>
            </div>
            <div class="flex-columns grid-halves">
                <div class="flex-rows bg-white py-medium px-medium ">
                    <div class="text text-black large text-900">
                        Total Resumes
                    </div>
                    <div>
                        <app-line-graph></app-line-graph>
                    </div>
                </div>
                <div class="flex-rows bg-white py-medium px-medium ">
                    <div class="text text-black large text-900">
                        Additional Stats
                    </div>
                    <div>
                        <ul class="text-label text normal">
                            <li>Total new resume created</li>
                            <li>Total Resumes</li>
                            <li>Total Cover Letters</li>
                            <li>Total new cover letter</li>
                            <li>Resumes per students</li>
                            <li>Cover letter per students</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-rows">
            <div>
                <label class="text text-black accent large text-700">Student Prefernces</label>
            </div>
            <div class="flex-columns grid-halves">
                <div class="flex-rows bg-white py-medium px-medium">
                    <div class="text text-black large text-900">
                        Total Internships
                    </div>
                    <div>
                        <app-line-graph></app-line-graph>
                    </div>
                </div>
                <div class="flex-rows bg-white py-medium px-medium ">
                    <div class="text text-black large text-900">
                        Additional Stats
                    </div>
                    <div>
                        <ul>
                            <li>Top Skills (Top 10)</li>
                            <li>Top Locations (Top 10)</li>
                            <li>Top Fields of interest (Top 10)</li>
                            <li>Top Certifications (Top 10)</li>
                            <li>Top Employers (Top 10)</li>
                            <li>Top Degrees/Majors</li>
                            <li>Top Minors</li>
                            <li>Average resume score</li>
                            <li>Top amopunt of internships pursued</li>
                            <li>Internship of students</li>
                                                       
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>


