<div class="tncwrapper standard-padding">
    <div class="text mb-small">
        Terms and Conditions
    </div>
    <div class="background">
        <section id="table-of-contents">
            <app-table-of-contents [links]="links"></app-table-of-contents>
        </section>

        <section id="terms-and-conditions">
            <div class="content">
                <div class="text text-black mb-medium heading-3">
                    Unext Web Services Agreement
                </div>
                <p class="text text-400 text-black mb-medium">
                    Last Updated on January 9, 2020
                </p>

                <ol>
                    <ng-template ngFor let-l [ngForOf]="links">
                        <li id="{{ l.href }}"
                            class="text text-black">
                            {{ l.heading }}
                        </li>
                        <p class="text text-400 text-black mb-medium"
                            [innerHTML]="l.contents"></p>
                    </ng-template>
                </ol>
            </div>

        </section>
    </div>
</div>