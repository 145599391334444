<section id="library" class="standard-padding">
    <div id="user-actions" class="mt-large flex-columns justify-between grid-thirds">
        <ng-template ngFor let-l [ngForOf]="lists | sort:'name':'asc'">
            <div class="rounded-corners">
                <h3 class="text xlarge">{{l.name}}</h3>
                <div class="flex-columns justify-between">
                    <p class="text">Total {{l.name}}</p>
                    <p class="text">{{l.list.length}}</p>
                </div>
                <a class="edit-profile button dark-green outline mt-small mb-small" (click)="open(l.name)">View List</a>
            </div>
        </ng-template>
    </div>
</section>

<app-modal id="library-list" size="large" heading="{{ activeListName }}">
    <div class="flex-rows">
        <div>
            <div class="flex-columns justify-between grid-quarters">
                <ng-template  ngFor let-i [ngForOf]="activeList | paginate: { itemsPerPage: 48, currentPage: pageNumber }">
                    <div class="flex-columns justify-between">
                        <p  [title]="i" class="text ellipsis tooltip">{{i}}</p>
                    </div>
                </ng-template>
            </div>
            <div class="mt-small flex-columns justify-around">
                <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
            </div>
        </div>
    
    </div>
</app-modal>