import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class AdminApiConfig {
    static path: string = environment.api.admin.path;
    static version: string | number = environment.api.admin.version;

    public static setApiVersion(version: string | undefined = 'api'): void {
        AdminApiConfig.version = version;
    }

    public static getApiVersion(): string | number {
        return AdminApiConfig.version;
    }

    public static getPath(): string {
        return AdminApiConfig.path;
    }

    public static getFullUrl(path): string {
        if (AdminApiConfig.getApiVersion()) {
            return [AdminApiConfig.getPath(), AdminApiConfig.getApiVersion(), path].join('/');
        } else {
            return [AdminApiConfig.getPath(), path].join('/');
        }
    }
}
