import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CertificationApiConfig } from '../api';
import { IdName } from '../models';
import { skipAuthHeaders } from '../auth/auth-interceptor';

@Injectable()
export class CertificationService {

    constructor(private http: HttpClient) { }

    public get(): Promise<IdName[]> {
        return lastValueFrom(this.http.get<IdName[]>(CertificationApiConfig.getFullUrl(''), { headers: skipAuthHeaders }));
    }
}
