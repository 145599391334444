<!-- Slider main container -->
<h2 class="sans-serif-bold">{{title}}</h2>

<div class="swiper">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <ng-content select="[slides]"></ng-content>
    </div>
    <!-- If we need pagination -->
    <div class="swiper-pagination"></div>
  
    <!-- If we need navigation buttons -->
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  
    <!-- If we need scrollbar -->
    <div class="swiper-scrollbar"></div>
 </div>
<div class="carousel-sticker">
  <app-sticker [class]="stickerClass" [stickerText]="stickerText"></app-sticker>
</div>
<div class="description">
  <ng-content select="[description]"></ng-content>
</div>