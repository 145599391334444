import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { Staff, School, EntityType } from 'src/app/shared/models';
import { UserService, ModalService, SchoolService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-school-settings',
  templateUrl: './school-settings.component.html',
  styleUrl: './school-settings.component.scss'
})
export class SchoolSettingsComponent implements OnInit {

  working = 0;
  user: User;
  staff: Staff;
  school: School;
  urlName: string;
  entityType: EntityType;


  constructor(
    private users: UserService,
    private modals: ModalService,
    private snackbar: ToastService,
    private schoolService: SchoolService,
    public cdn: CDNService,
    private route: ActivatedRoute,
  ) {
    this.urlName = this.route.snapshot.params.urlName;
    this.user = this.users.authenticatedUser;
    this.entityType = EntityType.SchoolAttachment;
  }

  async ngOnInit(): Promise<void> {
    this.working = 0;
    try {
      this.school = await this.schoolService.get(this.urlName);
     
    } catch (error) {
      console.log(error);
      this.snackbar.error({
        message: 'There was a problem loading your school information',
      });
    } finally {
      this.working = 0;
    }
  }

  openModal(id: string): void {
    this.modals.open(id);
  }

  close(id: string): void {
    this.modals.close(id);
  }

  imageUploaded(e: string[]): void {
    if (e?.length) {
      this.modals.close('uploadImage');
    }
  }
  async imageSelected(e: File[]): Promise<void> {
    this.working = e?.length ?? 10;
    try {
      this.working = 50;
      this.modals.close('uploadImage');
    } catch (error) {
      this.snackbar.error({
        message: 'There was a problem loading your school information',
      });
    } finally {
      this.working = 0;
    }
  }

}


