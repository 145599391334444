import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FAQGroup } from '../models/faq.model';


@Injectable()
export class FAQService {

    private http: HttpClient;

    constructor(handler: HttpBackend) {
        this.http = new HttpClient(handler);
    }

    public get(): Observable<FAQGroup[]> {
        return this.http.get<FAQGroup[]>('/assets/json/faqs.json');
    }
}
