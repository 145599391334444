import { Injectable } from "@angular/core";
import { OpportunityService } from "./opportunity.service";

@Injectable()
export class CacheService {

    private initialized = false;
    constructor(
        private opportunitities: OpportunityService,
    ) {

    }

    public async initialize(): Promise<void> {
        if (!this.initialized) {

            const otypes = [1, 2, 3, 4, 5, 6];
            for (let i = 0; i < otypes.length; ++i) {
                const o = otypes[i];
                this.opportunitities.purgeOpportunityLengths(o);
                await this.opportunitities.getOpportunityLengths(o);
            }
            this.initialized = true;
        }
    }

}
