import { Component, OnInit } from '@angular/core';
import { SubscriptionSummary } from 'src/app/shared/models';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  public showMonthly = true;

  public tiers: SubscriptionSummary[] = [];

  constructor(
    private subscriptions: SubscriptionService,
    public cdn: CDNService
  ) {
  }

  async ngOnInit() {
    this.tiers = await this.subscriptions.getSubscriptionOptions();
  }

  toggleMonthly(on: boolean): void {
    this.showMonthly = !on;
  }
}
