<div id="filter-menu">
    <form [formGroup]="formGroup" class="form mb-large">
        <label *ngIf="displayHeading" class="text text-title mb-small">Filter Search</label>
        <div *ngIf="searchOptions?.length">
            <input class="form-input teal border border-teal text search text box mb-small" type="text"
                formControlName="Search" placeholder="Search...">
        </div>
        <div *ngIf="sortOptions?.length" class="mb-small">
            <ng-select class="drop-down drop-down-button-blue" [items]="sortOptions" [closeOnSelect]="true"
                [placeholder]="'Sort...'" (change)="selectSort($event)">
            </ng-select>
        </div>

        <div *ngIf="filterDropDownOptions?.length" [ngClass]="vertical ? 'flex-rows' : 'flex-columns' ">
            <ng-template ngFor let-f [ngForOf]="filterDropDownOptions">
                <div class="mb-small">
                    <ng-select class="drop-down drop-down-buttons drop-down-button drop-down-button-lime"
                        [items]="f.options" [closeOnSelect]="true" [placeholder]="f.label"
                        (change)="selectDropDownFilter(f,$event)">
                    </ng-select>
                </div>
            </ng-template>
        </div>

        <div *ngIf="filterCheckBoxOptions?.length" class="mb-small">
            <ng-template ngFor let-f [ngForOf]="filterCheckBoxOptions">
                <div>
                    <input #filterCheckbox type="checkbox" type="checkbox" style="min-height: auto !important;"
                        (change)="selectCheckBoxFilter(f)">
                    <label class="form-label">{{f.label}}</label>
                </div>
            </ng-template>
        </div>
        <div *ngIf="displaySearch" class="flex-columns justify-between">
            <button class="btn btn-green" (click)="triggerSearch()">Search</button>
            <button class="btn btn-green" (click)="resetFilters()">Clear</button>
        </div>
    </form>
</div>