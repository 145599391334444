import { Component, Input } from '@angular/core';
import { CDNService } from 'src/app/shared/services/cdn.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {

  @Input() loggedIn: boolean;
  @Input() size: 'small' | 'normal';
  @Input()
  asLink = true;

  constructor(
    public cdn: CDNService,
    ) {

    }

  redirect(): string[] {
    return [this.loggedIn ? '/dashboard' : '/home'];
  }
}
