<app-banner>
    <h1 heading>
        Request a demo
    </h1>
    <p paragraph>
        Students often pick a major without knowing
        what it entails, they struggle to get connected
        to employers, they overlook the importance of
        culture and how it affects their fulfillment of jobs.
        <br/><br/>
        Connect with us to schedule a demo and find out how Unext can help you, your prospects, and your students.
    </p>
    <img image alt="students hanging out in hallway" src="/assets/images/students.jpg" />
</app-banner>
<section>
    <div class="outer-wrapper">
        <div class="wrapper" id="hubspotForm"></div>
        <div>&nbsp;<!-- placeholder --></div>
    </div>
</section>