import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class CDNService {
    toCDN(path: string, hasRoot: true | false = false): string {
        if (!path) {
            return undefined;
        }

        if (path.startsWith(environment.cdn.replacePath)) {
            return path;
        }

        for (let i = 0; i < environment.cdn.startPath.length; ++i) {
            const p = environment.cdn.startPath[i];

            if (path.startsWith(p)) {
                return path.replace(p, environment.cdn.replacePath);
            }
        }

        if (hasRoot) {
            return path;
        } else {
            return `${environment.cdn.root}/${path}`;
        }
    }
}
