import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'localtime'
})
export class LocalTimePipe implements PipeTransform {
    transform(value: Date): Date {
        const timeoffset = new Date().getTimezoneOffset();
        if (typeof value === typeof '') {
            return new Date(new Date(value).getTime() + timeoffset);
        } else {
            return new Date(value.getTime() + timeoffset);
        }
    }
}

@Pipe({
    name: 'dayTimeHour'
  })
  export class DayTimeHourPipe implements PipeTransform {
    transform(hour: number): string {
      const period = hour >= 12 ? 'PM' : 'AM';
      const twelveHour = (hour % 12) || 12; // 0 becomes 12
      return `${twelveHour}:00 ${period}`;
    }
  }
