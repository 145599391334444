<section class="bg-dark-green">
    <h2>Connecting You To What's Next</h2>
    <p>
        Our mission is to help college students learn about careers that relate to their major. We connect them to first-hand experiences such as job shadows, internships, jobs, and other opportunities.
    </p>
    <p class="learn-more serif large">Learn More</p>
    <div class="arrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path fill="currentColor" d="M390.624 150.625L256 16L121.376 150.625l22.628 22.627l95.997-95.998v417.982h32V77.257l95.995 95.995z"/></svg>
    </div>
    <div class="row">
        <button type="button" class="lime-green">For Students</button>
        <button type="button" class="lime-green">For Employers</button>
    </div>
</section>