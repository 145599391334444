import { Component, Input, OnInit } from '@angular/core';
import { Employee, Employer, Student, Staff, User } from 'src/app/shared/models';
import { UserService } from 'src/app/shared/services';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  @Input()
  user: User;

  @Input()
  student: Student;

  @Input()
  employee: Employee;

  @Input()
  staff: Staff;

  @Input()
  direction: 'horizontal' | 'vertical' = 'vertical';

  @Input()
  showDetails = true;

  @Input()
  get employer(): Employer | undefined {
    return this._employer;
  }
  set employer(value: Employer | undefined) {
    this._employer = value;
    this.primaryAddress = this.employer?.primaryAddress;
    if (!this.primaryAddress) {
      this.primaryAddress = this.employer?.addresses.length > 0 ? this.employer?.addresses[0] : null;
    }
  }

  currentUser: User;
  _employer: Employer | undefined;
  isMe = false;
  primaryAddress: string;

  constructor(
    private users: UserService,
  ) {
    this.currentUser = this.users.authenticatedUser;
  }

  async ngOnInit() {
    this.isMe = this.user?.id === this.users.authenticatedUser.id;
    if (this.user?.isEmployer) {
      if (!this.employer) {
        this.primaryAddress = this.employer?.primaryAddress;
        if (!this.primaryAddress) {
          this.primaryAddress = this.employer?.addresses.length > 0 ? this.employer?.addresses[0] : null;
        }
       }
    }
  }

  getStudentUrl(): string {
    return `${window.location.origin}/students/${this.user.student.urlName}`;
  }
}
