<section>
    <div class="row">
        <div class="col">
            <div class="left-clip">
                <img src="https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64" alt="college students" />
            </div>
        </div>
        <app-sticker class="blue shadow tilt-left" stickerText="Career Launchpad"></app-sticker>
        <div class="col p-4">
            <h3>So you’ve selected your major. What’s next?</h3>
            <p>
                Understand your major of interest and build confidence in your life after graduation. Use our resume builder and connect to our job shadowing, volunteering, internships, and employment opportunities.
            </p>
            <button type="button" class="text-button">
                <p class="learn-more large serif">Learn More</p>
                <div class="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M390.624 150.625L256 16L121.376 150.625l22.628 22.627l95.997-95.998v417.982h32V77.257l95.995 95.995z" />
                    </svg>
                </div>
            </button>
        </div>
    </div>
</section>