import { Injectable } from "@angular/core";
import { ArrayMatchSortPipe } from "../pipes";

@Injectable()
export class SortDataService {
    onSearch(value: any, array: any): any {
        // Create a set from this.allMajors to ensure uniqueness
        const orgSet = new Set(array);
        // Transform value.items and convert it to a set to ensure uniqueness
        const diffSet = new Set(new ArrayMatchSortPipe().transform(value.items, value.term));
        // Combine sets without duplicates
        const combinedSet = new Set([...diffSet, ...orgSet ]);
        // Convert the set back to an array
        return Array.from(combinedSet);
      }
    
}