import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPhonemask]'
})
export class PhoneMasksDirective {

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const trimmed = input.value.replace(/\D/g, '').slice(0, 10);

    let formatted = '';
    if (trimmed.length > 0) {
      formatted += `(${trimmed.substring(0, 3)}`;
    }
    if (trimmed.length > 3) {
      formatted += `) ${trimmed.substring(3, 6)}`;
    }
    if (trimmed.length > 6) {
      formatted += `-${trimmed.substring(6, 10)}`;
    }

    input.value = formatted;
  }

}