import { Component } from '@angular/core';
import { CDNService } from 'src/app/shared/services/cdn.service';

@Component({
  selector: 'app-for-employers',
  templateUrl: './for-employers.component.html',
  styleUrls: ['./for-employers.component.scss']
})
export class ForEmployersComponent  {

  constructor(
    public cdn: CDNService
  ) {

  }
}
