import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
import { User } from '../shared/models';
import { UserService } from '../shared/services';
import { ToastService } from '../shared/services/toast.service';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnDestroy {

  working = false;
  subscriptions: Subscription[] = [];
  email: string;
  private provider: string;
  private providerId: string;

  user: import("@auth0/auth0-spa-js").User | null | undefined;
  
  constructor(
    private auth0: AuthService,
    private users: UserService,
    private router: Router,
    private snackbar: ToastService
  ) {

    this.subscriptions.push(this.auth0.user$.subscribe(async (user: User) => {
      this.user = user;      
      if (this.user.email_verified) {
        this.router.navigate([ '/dashboard' ]);
      } else {
        this.email = this.user?.email;
        this.provider = this.user.sub.split('|')[0];
        this.providerId = this.user.sub;  
      }
    }));  
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  signout(): void {
    this.users.logout();
    this.auth0.logout();
  }

  async resend(): Promise<void> {
    try {
      this.working = true;
      await this.users.resendVerification(this.email , this.provider, this.providerId);
      this.snackbar.success({
        title: 'Success',
        message: 'You should receive a verification email shortly.'
      });
      setTimeout(() => {    
        this.snackbar.warning({
          title: 'Logged Out',
          message: 'You were logged out due to resend (you\'ll need to log out after you verify anyway).',
          closeDuration: 60000
        });    
        this.users.logout();
        this.router.navigate(['/']);
      }, 30000);
    } catch (error) {
      this.snackbar.error({
        title: 'Something\'s not right',
        message: 'There was a problem requesting your verification email, please try again.'
      });
    } finally {
      this.working = false;
    }

  }

}
