import { Injectable } from "@angular/core";
import { UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ModalService, UserService } from "../services";

@Injectable()
export class SubscribeGuard  {

    constructor(
        private users: UserService,
        private modals: ModalService) {

    }

    canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

        if (this.users.authenticatedUser?.employee && !this.users.authenticatedUser.employee.employer.verified) {
            this.modals.open('employer-verification-progress');
            return false;
        } else if (this.users.authenticatedUser?.employee && !this.users.authenticatedUser.employee.employer.subscribed) {
            this.modals.open('employer-profile-subscription');
            return false;
        } else {
            return true;
        }
    }

}