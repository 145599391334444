import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'phone',
  pure: true
})
export class PhonePipe implements PipeTransform {
  transform(value: string) {
    const trimmed = value?.replace(/\D/g, '')?.slice(0, 10) ?? '';

    let formatted = '';
    if (trimmed.length > 0) {
      formatted += `(${trimmed.substring(0, 3)})`;
    }
    if (trimmed.length > 3) {
      formatted += ` ${trimmed.substring(3, 6)}`;
    }
    if (trimmed.length > 6) {
      formatted += `-${trimmed.substring(6, 10)}`;
    }
    return formatted;
  }
}