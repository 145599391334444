import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';

import { FilterOption, SortOption } from '../../models';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DropDownOptions } from '../dropdown/dropdown.component';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrl: './filter-menu.component.scss'
})
export class FilterMenuComponent {

  @Input()
  filterCheckBoxOptions: FilterOption[] = [];

  @Input()
  filterDropDownOptions: FilterOption[] = [];

  @Input()
  searchOptions: any[] = [];

  @Input()
  sortOptions: SortOption[] = [];

  @Input()
  inputList: any[] = [];

  @Output()
  outputListEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  vertical: boolean = true;

  @Input()
  displaySearch: boolean = true;

  @Input()
  displayHeading: boolean = true

  outputList: any[] = [];
  selectedSortOption: SortOption = null;
  searchValue: string = '';
  formGroup!: FormGroup;
  reset: boolean = false;

  @ViewChildren(NgSelectComponent) ngSelects: QueryList<NgSelectComponent>;
  @ViewChildren('filterCheckbox') filterCheckboxes: QueryList<ElementRef>;

  constructor(
    private fb: FormBuilder,
  ) {

    this.formGroup = this.createForm();
  }


  createForm(): FormGroup {
    const fg = this.fb.group({
      Search: new FormControl(),
    });

    return fg;
  }

  get search(): FormControl {
    return this.formGroup.get('Search') as FormControl;
  }


  selectCheckBoxFilter(e: any) {
    this.outputList = [];
    const option = this.filterCheckBoxOptions.find(a => a.name === e.name);

    if (option) {
      option.active = !option.active;
    }
    this.filterAndEmit();
  }

  selectDropDownFilter(c: any, e: any) {
    this.outputList = [];
    const option = this.filterDropDownOptions.find(a => a.name === c.name);
    if (option && e) {
      option.active = true;
      option.selected = e
    }
    if (e == undefined) {
      option.active = false;
      option.selected = '';
    }
    this.filterAndEmit();
  }

  selectSort(e: any) {
    this.selectedSortOption = e ?? this.sortOptions[0];
    this.filterAndEmit();
  }

  triggerSearch() {
    this.searchValue = this.search.value;
    this.filterAndEmit();

  }

  filterAndEmit() {

    let list = []
    list = this.inputList

    // check for active sort options
    if (this.selectedSortOption == null) {
      this.selectedSortOption = this.sortOptions[0];
    }

    // check for active filters
    const activeFilterCheckBoxOptions = this.filterCheckBoxOptions.filter(a => a.active);
    const activeFilterDropDownOptions = this.filterDropDownOptions.filter(a => a.active);

    if (activeFilterCheckBoxOptions.length || activeFilterDropDownOptions.length) {

      if (activeFilterCheckBoxOptions.length) {
        list = list.filter(item => {
          return activeFilterCheckBoxOptions.every(filter => {
            // Check if the item has a property with the filter's name and if it's true
            return item[filter.name] === true;
          });
        });
      }

      if (activeFilterDropDownOptions.length) {
        list = list.filter(item => {
          return activeFilterDropDownOptions.every(filter => {
            if (Array.isArray(item[filter.name])) {
              if (filter?.selected instanceof DropDownOptions) {
                return item[filter.name].some(option =>
                  option.label === filter.selected?.label && option.value === true
                );
              } else {
                return item[filter.name].some(a => a == filter.selected);
              }
            } else {
              return item[filter.name] == filter.selected;
            }
          });
        });
      }

      this.outputList = list;
    } else {
      this.outputList = this.inputList; // No filters, return original list
    }

    // emit active filters settings to parent
    this.outputListEvent.emit({
      list: this.outputList,
      sort: { value: this.selectedSortOption?.value, asc: this.selectedSortOption?.asc },
      search: this.searchValue,
      reset: this.reset
    });
    this.reset = false;
  }

  resetFilters() {
    this.filterDropDownOptions.forEach(a => { a.active = false });
    this.filterCheckBoxOptions.forEach(a => { a.active = false });
    this.selectedSortOption = this.sortOptions[0];
    this.searchValue = '';

    this.ngSelects.forEach(select => {
      select.handleClearClick();
    });

    this.filterCheckboxes.forEach(checkboxRef => {
      const checkbox = checkboxRef.nativeElement as HTMLInputElement;
      if (checkbox) {
        checkbox.checked = false;
      }
    });
    this.reset = true;
    this.filterAndEmit();
  }


}
