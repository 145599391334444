<div class="rounded-corners mr-medium mb-small subscription">
    <div class="text heading-4 mb-small">
        {{ !employer?.subscribed ? 'Setup' : '' }} Subscription
    </div>
    
    <div class="plan-wrapper" *ngIf="employer?.subscribed">
        <app-pricing-summary [pricing]="employer?.subscription" [monthly]="employer?.subscription?.isMonthly"
            (click)="navigateToStripe(employer?.subscription?.id ?? 0)"></app-pricing-summary>
    </div>
    <div class="plan-wrapper flex-rows" *ngIf="!employer?.subscribed" style="overflow-y:hidden; overflow-x: scroll;">
        <ng-template *ngIf="!employer?.subscribed" ngFor [ngForOf]="stripePlans | sort:'price':'asc'" let-p>
            <!-- <app-pricing-summary [pricing]="p" [monthly]="p.isMonthly"
                (click)="navigateToStripe(p.id)"></app-pricing-summary> -->
            <button type="button" class="button dark-green text text-white mb-medium"
                (click)="navigateToStripe(p.id)">
                Create {{ p.price | currency }} / {{ p.interval }} Subscription
            </button>
        </ng-template>
    </div>
   

    <div *ngIf="!working && subscriptions.length">
        
        <div class="text heading-4 mb-small">
            Subscription History
        </div>
        <div class="subscription-history" >
            <div class="text">
                Start Date
            </div>
            <div class="text">
                End Date
            </div>
            <div class="text">
                Payment Date
            </div>
            <div class="text">
                Status
            </div>
            <ng-template ngFor let-h [ngForOf]="subscriptions | sort:'startDate':false">
                <div class="text">
                    {{ h.startDate | date }}
                </div>
                <div class="text">
                    {{ h.endDate | date }}
                </div>
                <div class="text">
                    {{ h.paymentDate | date }}
                </div>
                <div class="text">
                    {{ h.active ? 'Active' : 'Cancelled' }}
                </div>
            </ng-template>
        </div>
    </div>
</div>
