import { ViewportScroller } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TableOfContents } from '../../models';

@Component({
  selector: 'app-table-of-contents',
  templateUrl: './table-of-contents.component.html',
  styleUrls: ['./table-of-contents.component.scss']
})
export class TableOfContentsComponent {

  @Input()
  public links: TableOfContents[] = [];

  @Input()
  public hasHeader = true;

  constructor(private viewportScroller: ViewportScroller) { }

  onClick(element: string): void {
    this.viewportScroller.scrollToAnchor(element);
    const here = this.viewportScroller.getScrollPosition();
    here[1] -= 220;
    this.viewportScroller.scrollToPosition(here);
  }
}
