<div class="faq">
    <div class="header" [ngClass]="{ 'expanded': isExpanded }"
        (click)="isExpanded = !isExpanded">
        <div class="text text-black">
            {{ faq.Title }}
        </div>
        <div class="expander">
            <div class="expander-bar expander-bar-1" [ngClass]="{ 'active': isExpanded }"></div>
            <div class="expander-bar expander-bar-2" [ngClass]="{ 'active': isExpanded }"></div>
        </div>
    </div>
    <div class="content text text-400 text-black"
       [ngClass]="{ 'expanded': isExpanded }"  [innerHTML]="faq.Content">
    </div>
</div>
