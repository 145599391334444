import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sticker',
  templateUrl: './sticker.component.html',
  styleUrl: './sticker.component.scss'
})
export class StickerComponent {
@Input() stickerText: string;

constructor() { 
  this.stickerText = 'Sticker Text';
}

}
