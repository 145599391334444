import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'remove',
  pure: true
})
export class RemovePipe implements PipeTransform {
  transform(value: string, remove: string) {
    let result = value;
    if (!!value && !!remove) {
        result = result.replace(remove, '');
    }
    return result;
  }
}