<a *ngIf="asLink" class="logo-link" title="link to homepage" [routerLink]="redirect()">
    <svg width="5074" height="1295" viewBox="0 0 5074 1295" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="u-top" d="M477.008 200.859H258.588C238.018 250.879 219.708 301.038 197.038 349.148C166.218 414.568 68.258 490.598 3.47803 477.748V5.44849H1408.61V614.708C1305.65 628.818 1212.97 670.658 1123.26 739.688V201.558H901.578C898.238 273.948 894.348 344.598 891.858 415.308C887.578 536.768 851.788 648.798 782.898 747.798C731.378 821.848 663.608 873.569 564.278 863.339C512.818 858.039 476.038 829.688 471.718 778.458C466.598 717.728 463.908 656.519 465.028 595.609C467.258 474.569 472.838 353.599 476.968 232.599C477.258 223.959 477.008 215.299 477.008 200.869V200.859Z" fill="#221F20"/>
        <path class="u-bottom" d="M3520.25 727.058H2901.6C2897 794.438 2952.56 874.558 3015.14 896.038C3101.62 925.728 3179.44 895.558 3235.24 809.568H3509.81C3483.48 914.048 3429.84 995.688 3341.04 1049.16C3199.63 1134.3 3049.52 1135.26 2894.54 1088.59C2802.15 1060.77 2733.46 1005.38 2685.58 924.568C2643.6 853.728 2628.46 773.579 2621.86 692.369C2612.9 582.039 2628.99 475.838 2684.5 378.498C2722.54 311.788 2778.84 263.879 2847.17 229.119C2954.66 174.439 3069.58 172.269 3183.53 190.589C3313.03 211.409 3407 284.518 3462.86 407.208C3503.19 495.788 3514.8 588.479 3520.16 683.339C3520.85 695.499 3520.25 707.738 3520.25 727.058ZM2907.35 541.498H3238.14C3217.81 443.708 3151.98 390.569 3061.69 395.589C2979.42 400.169 2917.07 458.268 2907.35 541.498Z" fill="black"/>
        <path class="n" d="M2493.86 1091.56H2223.8C2222.89 1075.55 2221.26 1059.98 2221.25 1044.4C2221.13 885.859 2222.43 727.298 2220.48 568.778C2220.03 532.178 2212.08 494.658 2201.24 459.528C2192.43 430.988 2168.63 414.308 2136.19 411.528C2078.89 406.608 2018.92 395.978 1971.87 437.338C1947.53 458.738 1930.83 491.838 1918.41 522.768C1909.58 544.768 1912.29 571.958 1912.21 596.838C1911.76 746.638 1912 896.448 1912.01 1046.25C1912.01 1059.82 1912.01 1073.39 1912.01 1090.62H1640.05V204.708H1898.82C1900.43 230.678 1902 255.998 1903.82 285.448C2011.06 173.578 2144.02 165.058 2279.45 193.838C2384.48 216.158 2458.96 283.348 2480.09 395.288C2487.43 434.168 2492.97 474.018 2493.28 513.478C2494.67 688.238 2493.86 863.018 2493.85 1037.79C2493.85 1053.91 2493.85 1070.04 2493.85 1091.54L2493.86 1091.56Z" fill="black"/>
        <path class="e" d="M4124.56 1091.07C4077.64 1015.64 4029.91 938.898 3977.9 855.278C3927.16 937.558 3879.55 1014.76 3832.2 1091.54H3505.53C3611.69 938.128 3713.62 790.828 3817.74 640.368C3717.5 497.118 3617.62 354.388 3513.45 205.528H3842.82C3888.14 277.868 3936.01 354.278 3987.09 435.818C4036.2 356.918 4083.07 281.618 4131.34 204.058H4450.73C4344.7 353.208 4242.83 496.488 4140.36 640.638C4245.54 789.898 4349.63 937.618 4457.76 1091.07H4124.55H4124.56Z" fill="black"/>
        <path class="x" d="M1408.76 1291.2C1393.91 1292.06 1380.58 1293.5 1367.24 1293.51C1059.13 1293.65 751.018 1292.58 442.918 1294.23C341.938 1294.77 249.268 1269.26 170.298 1209.08C65.6183 1129.31 6.15826 1021.23 1.08826 887.438C-1.12174 829.088 0.718265 770.588 0.718265 709.038C85.2783 689.848 169.628 677.528 237.248 620.448C240.078 621.778 242.908 623.108 245.738 624.438C245.738 664.948 242.488 705.768 246.418 745.888C254.378 826.998 270.968 905.528 326.708 970.538C387.438 1041.36 465.798 1071.04 556.238 1078.07C676.778 1087.44 782.648 1055.53 871.678 971.968C877.738 966.278 885.968 962.898 899.178 954.748V1081.8H1139.38C1196.88 970.188 1269.61 870.538 1408.74 838.738V1291.2H1408.76Z" fill="#221F20"/>
        <path class="t" d="M4899.46 849.168C4954.23 891.258 5014.83 869.658 5073.66 880.018V1113.08C5004.68 1113.08 4936.22 1118.18 4868.87 1111.76C4789.49 1104.19 4715.78 1075.95 4666.45 1007.78C4629.89 957.248 4611.93 898.768 4611.29 836.318C4610.13 723.958 4610.65 611.588 4610.48 499.218C4610.46 484.448 4610.48 469.668 4610.48 450.418C4573.75 448.388 4539.74 446.518 4503.23 444.508V217.458H4610.08V0.618408H4894.23V215.948H5072.11V446.948H4899.46V849.168Z" fill="black"/>
    </svg>
</a>

<ng-container *ngIf="!asLink">
    <svg width="5074" height="1295" viewBox="0 0 5074 1295" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="u-top" d="M477.008 200.859H258.588C238.018 250.879 219.708 301.038 197.038 349.148C166.218 414.568 68.258 490.598 3.47803 477.748V5.44849H1408.61V614.708C1305.65 628.818 1212.97 670.658 1123.26 739.688V201.558H901.578C898.238 273.948 894.348 344.598 891.858 415.308C887.578 536.768 851.788 648.798 782.898 747.798C731.378 821.848 663.608 873.569 564.278 863.339C512.818 858.039 476.038 829.688 471.718 778.458C466.598 717.728 463.908 656.519 465.028 595.609C467.258 474.569 472.838 353.599 476.968 232.599C477.258 223.959 477.008 215.299 477.008 200.869V200.859Z" fill="#221F20"/>
        <path class="u-bottom" d="M3520.25 727.058H2901.6C2897 794.438 2952.56 874.558 3015.14 896.038C3101.62 925.728 3179.44 895.558 3235.24 809.568H3509.81C3483.48 914.048 3429.84 995.688 3341.04 1049.16C3199.63 1134.3 3049.52 1135.26 2894.54 1088.59C2802.15 1060.77 2733.46 1005.38 2685.58 924.568C2643.6 853.728 2628.46 773.579 2621.86 692.369C2612.9 582.039 2628.99 475.838 2684.5 378.498C2722.54 311.788 2778.84 263.879 2847.17 229.119C2954.66 174.439 3069.58 172.269 3183.53 190.589C3313.03 211.409 3407 284.518 3462.86 407.208C3503.19 495.788 3514.8 588.479 3520.16 683.339C3520.85 695.499 3520.25 707.738 3520.25 727.058ZM2907.35 541.498H3238.14C3217.81 443.708 3151.98 390.569 3061.69 395.589C2979.42 400.169 2917.07 458.268 2907.35 541.498Z" fill="black"/>
        <path class="n" d="M2493.86 1091.56H2223.8C2222.89 1075.55 2221.26 1059.98 2221.25 1044.4C2221.13 885.859 2222.43 727.298 2220.48 568.778C2220.03 532.178 2212.08 494.658 2201.24 459.528C2192.43 430.988 2168.63 414.308 2136.19 411.528C2078.89 406.608 2018.92 395.978 1971.87 437.338C1947.53 458.738 1930.83 491.838 1918.41 522.768C1909.58 544.768 1912.29 571.958 1912.21 596.838C1911.76 746.638 1912 896.448 1912.01 1046.25C1912.01 1059.82 1912.01 1073.39 1912.01 1090.62H1640.05V204.708H1898.82C1900.43 230.678 1902 255.998 1903.82 285.448C2011.06 173.578 2144.02 165.058 2279.45 193.838C2384.48 216.158 2458.96 283.348 2480.09 395.288C2487.43 434.168 2492.97 474.018 2493.28 513.478C2494.67 688.238 2493.86 863.018 2493.85 1037.79C2493.85 1053.91 2493.85 1070.04 2493.85 1091.54L2493.86 1091.56Z" fill="black"/>
        <path class="e" d="M4124.56 1091.07C4077.64 1015.64 4029.91 938.898 3977.9 855.278C3927.16 937.558 3879.55 1014.76 3832.2 1091.54H3505.53C3611.69 938.128 3713.62 790.828 3817.74 640.368C3717.5 497.118 3617.62 354.388 3513.45 205.528H3842.82C3888.14 277.868 3936.01 354.278 3987.09 435.818C4036.2 356.918 4083.07 281.618 4131.34 204.058H4450.73C4344.7 353.208 4242.83 496.488 4140.36 640.638C4245.54 789.898 4349.63 937.618 4457.76 1091.07H4124.55H4124.56Z" fill="black"/>
        <path class="x" d="M1408.76 1291.2C1393.91 1292.06 1380.58 1293.5 1367.24 1293.51C1059.13 1293.65 751.018 1292.58 442.918 1294.23C341.938 1294.77 249.268 1269.26 170.298 1209.08C65.6183 1129.31 6.15826 1021.23 1.08826 887.438C-1.12174 829.088 0.718265 770.588 0.718265 709.038C85.2783 689.848 169.628 677.528 237.248 620.448C240.078 621.778 242.908 623.108 245.738 624.438C245.738 664.948 242.488 705.768 246.418 745.888C254.378 826.998 270.968 905.528 326.708 970.538C387.438 1041.36 465.798 1071.04 556.238 1078.07C676.778 1087.44 782.648 1055.53 871.678 971.968C877.738 966.278 885.968 962.898 899.178 954.748V1081.8H1139.38C1196.88 970.188 1269.61 870.538 1408.74 838.738V1291.2H1408.76Z" fill="#221F20"/>
        <path class="t" d="M4899.46 849.168C4954.23 891.258 5014.83 869.658 5073.66 880.018V1113.08C5004.68 1113.08 4936.22 1118.18 4868.87 1111.76C4789.49 1104.19 4715.78 1075.95 4666.45 1007.78C4629.89 957.248 4611.93 898.768 4611.29 836.318C4610.13 723.958 4610.65 611.588 4610.48 499.218C4610.46 484.448 4610.48 469.668 4610.48 450.418C4573.75 448.388 4539.74 446.518 4503.23 444.508V217.458H4610.08V0.618408H4894.23V215.948H5072.11V446.948H4899.46V849.168Z" fill="black"/>
    </svg>
</ng-container>