import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EntityAction, EntityFilter, EntitySearch } from 'src/app/shared/components/entity-list/entity-list.component';
import { Employer } from 'src/app/shared/models/employer.model';
import { EmployerService } from 'src/app/shared/services';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-admin-employers',
  templateUrl: './admin-employers.component.html',
  styleUrls: ['./admin-employers.component.scss']
})
export class AdminEmployersComponent implements OnInit {

  displayedColumns = [{
    label: 'Name', value: 'name', displayType: 'text', width: '360px', display: true, sortable: true
  }, {
    label: 'Size', value: 'size', displayType: 'text', width: '200px', display: true, sortable: true
  }, {
    label: 'Domain', value: 'domain', displayType: 'text', width: '360px', display: true, sortable: true
  }, {
    label: 'Private', value: 'isPrivate', displayType: 'toggle', width: 'auto', display: true, sortable: true
  }, {
    label: 'Verified', value: 'verified', displayType: 'toggle', width: 'auto', display: true, sortable: true
  }, {
    label: 'Subscribed', value: 'subscribed', displayType: 'toggle', width: 'auto', display: true, sortable: true
  }, {
    label: 'Featured', value: 'featured', displayType: 'toggle', width: 'auto', display: true, sortable: true
  }, {
    label: 'Active', value: 'active', displayType: 'toggle', width: 'auto', display: true, sortable: true
  }];


  actions: EntityAction[] = [{
    label: 'View',
    action: (emp: Employer) => { this.router.navigate(['/employers', emp.urlName]); }
  }, {
    label: 'Edit',
    action: (emp: Employer) => { this.router.navigate(['/employers', emp.urlName, 'edit']); }
  }];

  filters: EntityFilter[] = [{
    displayType: 'toggle',
    label: 'Only Active',
    value: 'active'
  }, {
    displayType: 'toggle',
    label: 'Only Verified',
    value: 'verified'
  }, {
    displayType: 'toggle',
    label: 'Only Subscribed',
    value: 'subscribed'
  }, {
    displayType: 'toggle',
    label: 'Only Featured',
    value: 'featured'
  }, {
    displayType: 'text',
    label: 'Name',
    value: 'name'
  }];

  allemployers: Employer[] = [];
  employers: Employer[] = [];
  isLoading = false;
  showVerified = true;
  showUnverified = true;
  showActive = true;
  showInactive = true;
  showSubscribed = true;
  showUnsubscribed = true;

  constructor(
    private employerService: EmployerService,
    private snackbar: ToastService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getEmployers();
  }

  async getEmployers(): Promise<void> {
    this.isLoading = true;
    try {
      this.allemployers = await this.employerService.getEmployers(true, true, true, true);
      this.employers = this.allemployers;
    } catch (error) {
      this.snackbar.error({
        title: 'Error getting employers',
        message: error?.error ? error.error : error.message
      });
    } finally {
      this.isLoading = false;
    }
  }

  // toggleVerified(): void {
  //   this.showVerified = !this.showVerified;
  //   this.filterEmployers();
  // }
  // toggleUnverified(): void {
  //   this.showUnverified = !this.showUnverified;
  //   this.filterEmployers();
  // }
  // toggleActive(): void {
  //   this.showActive = !this.showActive;
  //   this.filterEmployers();
  // }
  // toggleInactive(): void {
  //   this.showInactive = !this.showInactive;
  //   this.filterEmployers();
  // }
  // toggleSubscribed(): void {
  //   this.showSubscribed = !this.showSubscribed;
  //   this.filterEmployers();
  // }
  // toggleUnsubscribed(): void {
  //   this.showUnsubscribed = !this.showUnsubscribed;
  //   this.filterEmployers();
  // }

  filterEmployers(filter: EntitySearch): void {
    console.log(filter);
    this.employers = [];
    this.allemployers.forEach(e => {
      if ((this.showVerified && e.verified) ||
        (this.showUnverified && !e.verified) ||
        (this.showActive && e.active) ||
        (this.showInactive && !e.active) ||
        (this.showSubscribed && e.subscribed) ||
        (this.showUnsubscribed && !e.subscribed)) {
        this.employers.push(e);
      }
    });
  }

  navigate(value: Employer): void {
    this.router.navigate(['/employers', value.urlName, 'edit']);
  }


  getProperty(e: any, property: string): any {
    const path: string[] = property.split('.');
    return this.getSubProperty(e, path);
  }

  getSubProperty(e: any, path: string[]): any {
    if (!path.length) {
      return e;
    } else if (path.length === 1) {
      return e[path[0]];
    } else {
      const root = path.splice(0, 1);
      return this.getSubProperty(e[root[0]], path);
    }
  }

  // edit(employer: Employer): void {
  //   // const dialogRef = this.dialog.open(EmployerVerificationComponent, {
  //   //   autoFocus: true,
  //   //   minWidth: '70vw',
  //   //   minHeight: '70vh',
  //   //   maxHeight: '90vh',
  //   //   maxWidth: '80vw',
  //   //   data: {
  //   //     employer
  //   //   }
  //   // });

  //   // dialogRef.afterClosed().toPromise().then(result => {
  //   //   console.log('employer dialog closed...');
  //   //   this.getEmployers();
  //   // });

  // }
}
