<app-progress-bar *ngIf="isLoading" [value]="60"></app-progress-bar>
<div #logo *ngIf="school?.bannerUrl" class="banner">
    <div class="banner-inner" [ngStyle]="{ 'background-image': 'url(' + cdn.toCDN(school?.bannerUrl, true) + ')' }">
        <div hidden>
            {{ school?.name }}
        </div>
    </div>
</div>
<div class="flex-columns no-gap" style="width: -webkit-fill-available;">
    <div class="bg-cream pl-medium pr-medium py-large">
        <div class="mb-medium info">
            <div class="flex-rows ml-large" style="position: relative;">
                <div class="logo-wrapper">
                    <div class="bg-white border-teal mb-small border border-round logo" *ngIf="school?.logoUrl">
                        <div class="logo-inner"
                            [ngStyle]="{ 'background-image': 'url(' + cdn.toCDN(school?.logoUrl, true) + ')' }">
                            <span class="sr-only">{{ school?.name + ' logo' }}</span>
                        </div>
                    </div>
                    <a class="btn lime-green mb-small" href="{{school?.meetingUrl ?? school?.domain}}">Schedule a Meeting</a>
                    <div *ngIf="user.isStaff">
                        <a [routerLink]="[ '/school', school?.urlName, 'edit' ]" class="btn white">
                            Edit School Profile
                        </a>
                    </div>
                </div>

            </div>
            <div class="flex-rows" style="min-height: 352px;">
                <div class="text text-title text-right mb-medium">
                    {{ school?.name}}
                </div>
                <div class="text text-right text-800 mb-small">
                    {{school?.primaryAddress}}
                </div>
                <div class="text text-right text-800 mb-small">
                    <a href="{{school?.domain}}">{{school?.domain}}</a>
                </div>
                <div class="text text-right text-800 mb-small" *ngIf="school?.phone?.length">
                    <a href="tel:{{school?.phone}}">{{school?.phone}}</a>
                </div>
                <div class="text text-right text-800 mb-small" *ngIf="school?.contactEmail?.length">
                    <a href="mail:{{school?.contactEmail}}">{{school?.contactEmail}}</a>
                </div>
                <!-- <div class="text text-right text-800">
                    {{school?.youtubeUrl}}
                </div> -->
                <div class="text text-right text-800">{{school?.size}} Students</div>

            </div>
        </div>

        <div class="mb-medium">
            <div class="text text-title">Description</div>
            <div class="text mb-medium" style="grid-column: 2; grid-row: 1 / span 3;" [innerHTML]="school?.description"></div>

            <div class="text text-title" *ngIf="school?.benefits?.length">Benefits</div>
            <div class="text mb-medium" style="grid-column: 2; grid-row: 1 / span 3;" *ngIf="school?.benefits?.length" [innerHTML]="school?.benefits"></div>

            <div class="text text-title" *ngIf="school?.culture?.length">Culture</div>
            <div class="text" style="grid-column: 2; grid-row: 1 / span 3;" *ngIf="school?.culture?.length" [innerHTML]="school?.culture"></div>

        </div>
        <div class="spacer spacer-teal"></div>
        <div *ngIf="user?.isStudent || user?.isStaff" class="student-content px-large pt-large">
            <div class="rounded-corners" disabled-flag>
                <div class="text text-title mb-medium">
                    Resume Templates
                </div>
                <div>
                    Browse ATS-friendly templates from Unext and your university to kickstart your resume. Choose your preferred template and dive into building with our resume builder!
                </div>
            </div>
            <div class="rounded-corners" disabled-flag>
                <div class="text text-title mb-medium">
                    Upcoming Events
                </div>
                <div>
                    View all upcoming events tailored for you, including university-hosted sessions, local employer meetups, volunteering opportunities, and shadowing requests. Stay on top of what will make your resume shine! 
                </div>
            </div>
            <div class="rounded-corners">
                <div class="text text-title mb-medium">
                    My Advisor
                </div>
                <div>
                    Easily view all advisors from your career services office and beyond. Use this feature to schedule meetings and explore who's available to guide you on your career journey.
                </div>
                <a class="btn" [routerLink]="['/school', school?.urlName, 'staff']">View on Site</a>
            </div>

        </div>
    </div>
    <div class="bg-teal pl-medium pr-medium py-large">
        <a class="flex-rows" [routerLink]="[ '/dashboard']">
            <img src="/assets/images/icons/dashboard.svg" style="height: 76px;" class="mx-auto" />
            <div class="text text-white mx-auto text-center mb-medium">
                Dashboard
            </div>
        </a>
        <div class="mb-medium">
            <div class="spacer spacer-white"></div>
        </div>
        <a class="flex-rows" [routerLink]="[ '/school', school?.urlName]">
            <img src="/assets/images/icons/shake.svg" style="height: 43px;" class="mx-auto" />
            <div class="text text-white mx-auto text-center mb-medium mt-medium">
                Meetings & Feedback
            </div>
        </a>
        <div class="mb-medium" *ngIf="school?.attachments?.length">
            <div class="spacer spacer-white"></div>
        </div>

    </div>
</div>