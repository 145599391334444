<div class="resume-container flex-rows">
    <div class="text text-center heading-1">
        {{ resume?.fullName }}
    </div>
    <div class="text text-center heading-4">
        {{ subheading.join(' - ') }}
    </div>
    <div class="separator thick">
        &nbsp;
    </div>

    <ng-template ngFor let-s [ngForOf]="resume.sections | sort:'displayOrder':true">
        <ng-container *ngIf="s.sectionType === sectionType.Language; else skills">
            <div class="text heading-2" *ngIf="s.resumeSections?.length && s.resumeSections[0]?.languages?.length">
                {{ s?.overrideSectionName ?? s.name }}
            </div>
            <div class="separator" *ngIf="s.resumeSections?.length && s.resumeSections[0]?.languages?.length">
                &nbsp;
            </div>
        </ng-container>
        <ng-template #skills>
            <ng-container *ngIf="s.sectionType === sectionType.Skills; else generic">
                <div class="text heading-2" *ngIf="s.resumeSections?.length && s.resumeSections[0]?.skills?.length">
                    {{ s?.overrideSectionName ?? s.name }}
                </div>
                <div class="separator" *ngIf="s.resumeSections?.length && s.resumeSections[0]?.skills?.length">
                    &nbsp;
                </div>
            </ng-container>
            <ng-template #generic>
                <div class="text heading-2"
                    *ngIf="s.resumeSections?.length && !(summary === '' && s.name === 'Summary')">
                    {{ s?.overrideSectionName ?? s.name }}
                </div>
                <div class="separator" *ngIf="s.resumeSections.length && !(summary === '' && s.name === 'Summary')">
                    &nbsp;
                </div>
            </ng-template>
            <!-- TODO ADD EMAIL, LOCATION, REMOVE DUPLICATE NAME, ADD LINKEDIN PROFILE   -->
        </ng-template>

        <div *ngIf="s.sectionType === sectionType.General" class="text mb-small" [innerHTML]="summary">
        </div>

        <div *ngIf="s.sectionType === sectionType.Education" class="mb-small">
            <ng-template ngFor let-edu
                [ngForOf]="s.resumeSections | doublesort:'startDate':false:'resumeSectionId':false">
                <div class="text heading-4">
                    {{ [edu.schoolName].join(' - ') }}
                </div>
                <div class="text text-800 mb-small">
                    <span *ngIf="edu.location?.length">
                        {{ edu.location }}&nbsp;|
                    </span>
                    {{edu.startDate | date:'MMM y' }}
                    <span *ngIf="edu.endDate"> - {{ edu.endDate | date:'MMM y' }}</span>
                    <span *ngIf="!edu.endDate"> - Present</span>
                </div>
                <div class="mb-small">
                    <div class="mr-small flex-columns text text-800">
                        <div class="text mr-small">Major{{ edu.major.length > 1 ? '(s)' : '' }}:</div>
                        <div class="flex-columns">
                            <ng-template ngFor let-m [ngForOf]="edu.major" let-last="last">
                                <div class="text mr-small">{{ m }}{{ last ? '' : ',' }} </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="mr-small flex-columns text text-800">
                        <div *ngIf="edu.minor?.length && edu.minor !== 'None'" class="text mr-small">Minor{{ edu.minor?.length > 1 ? '(s)' : '' }}:</div>
                        <div class="flex-columns" *ngIf="edu.minor?.length">
                            <div *ngFor="let m of edu.minor" class="text mr-small">{{ m }}</div>
                        </div>
                    </div>
                </div>
                <div class="text text-400 mb-small" [innerHTML]="edu.description">
                </div>
            </ng-template>
        </div>

        <div *ngIf="s.sectionType === sectionType.Experience" class="mb-small">
            <ng-template ngFor let-ex
                [ngForOf]="s.resumeSections | doublesort:'startDate':false:'resumeSectionId':false" let-isLast="last">
                <div class="text heading-4">
                    {{ [ex.title, ex.employerName].join(' - ') }}
                </div>

                <div class="text text-500">
                    <span *ngIf="ex.location?.length">
                        {{ ex.location }}&nbsp;|
                    </span>
                    {{ex.startDate | date:'MMMM y' }} <span *ngIf="ex.endDate"> - {{ ex.endDate | date:'MMM y' }}</span>
                    <span *ngIf="!ex.endDate"> - Present</span>
                </div>
                <div class="text text-400" [innerHTML]="ex.description" [ngClass]="{ 'mb-small': !isLast }"></div>
            </ng-template>
        </div>

        <div *ngIf="s.sectionType === sectionType.Skills" class="mb-small">
            <ng-template ngFor let-sk [ngForOf]="s.resumeSections" let-isFirst="first" let-isLast="last">
                <ul *ngIf="isFirst" class="skills">
                    <li class="text text-500" *ngFor="let skill of sk.skills | sort:'':true"
                        style="text-transform: capitalize;">
                        {{skill}}
                    </li>
                </ul>
            </ng-template>
        </div>

        <div *ngIf="s.sectionType === sectionType.Award" class="mb-small">
            <ng-template ngFor let-aw
                [ngForOf]="s.resumeSections | doublesort:'startDate':false:'resumeSectionId':false" let-isLast="last">
                <div class="text" *ngIf="aw.awarderName?.length || aw.awardName?.length">
                    {{ [aw.awardName, aw.awarderName].join(' - ') }}
                </div>
                <!-- <div class="text" *ngIf="!(aw.awarderName?.length || aw.awardName?.length)">
                    No Award Name
                </div> -->
                <div class="text text-400">
                    {{aw.startDate | date:'MMMM y' }}
                </div>
                <div class="text text-400" [innerHTML]="aw.description" [ngClass]="{ 'mb-small': !isLast }">
                </div>
            </ng-template>
        </div>

        <div *ngIf="s.sectionType === sectionType.Organization" class="mb-small">
            <ng-template ngFor let-org
                [ngForOf]="s.resumeSections | doublesort:'startDate':false:'resumeSectionId':false" let-isLast="last">
                <div class="text heading-4">
                    {{ [org.organizationName, org.schoolName].join(' - ') }}
                </div>
                <div class="text">
                    {{org.startDate | date }} <span *ngIf="org.endDate"> - {{ org.endDate | date }}</span>
                </div>
                <div class="text text-400" [innerHTML]="org.description" [ngClass]="{ 'mb-small': !isLast }">
                </div>
            </ng-template>
        </div>

        <div *ngIf="s.sectionType === sectionType.Language" class="mb-small">
            <ng-template ngFor let-sk [ngForOf]="s.resumeSections | sort:'id':true" let-isFirst="first">
                <ul *ngIf="isFirst" class="languages">
                    <li class="text text-500" *ngFor="let lang of sk.languages">
                        {{lang}}
                    </li>
                </ul>
            </ng-template>
        </div>

    </ng-template>
</div>