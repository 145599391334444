import { Component, Input } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ToastService } from '../../services/toast.service';
import { GTMComponentComponent } from '../gtmcomponent/gtmcomponent.component';

@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss']
})
export class ShareButtonComponent extends GTMComponentComponent {

  @Input()
  buttonText: string;

  @Input()
  buttonColor: string;

  @Input()
  url: string | undefined;

  constructor(
    private snackBar: ToastService,
    gtm: GoogleTagManagerService
  ) { 
    super(gtm);
  }

  urlToClipboard(): void {
    this.handle('share', 'user', 'click', 'share button');
    navigator.clipboard.writeText(this.url || window.location.href);
    this.snackBar.success({
      message: 'Copied to Clipboard!',
      title: 'Copied'
    });
  }
}
