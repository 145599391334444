import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-resume-process',
  standalone: false,
  templateUrl: './resume-process.component.html',
  styleUrl: './resume-process.component.scss'
})
export class ResumeProcessComponent {

  @Input()
  skipFirstStepNumbering = false;
  @Input()
  steps: string[] = [];

  @Input()
  currentStep: number | undefined = undefined;
  @Output()
  public changed: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  getIndex(value: number): number | undefined {
    if (this.skipFirstStepNumbering && value === 0) {
      return undefined;
    } else {
      return value + (this.skipFirstStepNumbering ? 0 : 1);
    }
  }
}
