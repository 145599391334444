<div class="user-profile mb-medium">
    <app-user-card class="mb-medium" *ngIf="student?.user" [user]="student.user" [student]="student" [isEdit]="false" [direction]="direction"></app-user-card>
    <app-user-card class="mb-medium" *ngIf="employee?.user" [user]="employee.user" [isEdit]="false"></app-user-card>
    <app-user-card class="mb-medium" *ngIf="staff?.user" [user]="staff.user" [isEdit]="false"></app-user-card>
    <div *ngIf="showDetails" class="profile">

        <ng-template [ngIf]="user?.isStudent">
            <div>
                <div class="text text-green mt-small">
                    University/School
                </div>
                <div class="text text-black text-400">
                    {{ student.schoolName }}
                </div>
            </div>

            <!-- <div>
                <div class="text text-green mt-small">
                    Projected Graduation Year
                </div>
                <div class="text text-black text-400">
                    {{ student.graduationYear }}
                </div>
            </div> -->

            <div>
                <div *ngIf="student?.majors?.length" class="text text-green mt-small">
                    Majors
                </div>
                <div *ngFor="let m of student.majors"
                        class="text text-black text-400">
                    {{ m }}
                </div>
            </div>

            <div>
                <div *ngIf="student?.minors?.length" class="text text-green mt-small">
                    Minors
                </div>
                <div *ngFor="let m of student.minors"
                        class="text text-black text-400">
                    {{ m }}
                </div>
            </div>

            <div>
                <div class="text text-green mt-small">
                    Location
                </div>
                <div class="text text-black text-400">
                    {{ student.user.location.address.city.name }}, {{ student.user.location.address.city.state.name }} {{ student.user.location.address.zipCode }}
                </div>
            </div>

            <div class="flex-rows">
                <a *ngIf="isMe" class="edit-profile button btn-blue mt-small mb-small" [routerLink]="['/my-profile']">Edit Profile</a>
                <a *ngIf="!isMe && currentUser.isAdmin" class="view-profile button btn-blue mt-small mb-small" [routerLink]="['/students', student.urlName]">View Profile</a>
                <app-share-button *ngIf="isMe || currentUser.isAdmin" [url]="getStudentUrl()" class="share mt-auto mb-auto" buttonColor="green" buttonText="Share Profile"></app-share-button>
            </div>
        </ng-template>

        <ng-template [ngIf]="user?.isEmployer && employer">
            <div class="col-2">
                <div class="avatar">
                    <app-avatar-image [size]="'auto'" [circle]="false" [image]="employer.smallLogoUrl || employer.logoUrl"
                        [title]="employer.name"></app-avatar-image>
                </div>
                <div class="employer text heading-4 mt-small">
                    {{ employer.name }}
                </div>
                <div *ngIf="primaryAddress" class="location text mb-small">
                    {{ primaryAddress }}
                </div>
            </div>

            <div class="span-2" *ngIf="employer.description?.length">
                <div class="text text-green mt-small">
                    Who We Are
                </div>
                <div class="text text-black text-400" [innerHTML]="employer.description">

                </div>
            </div>

            <div class="span-2" *ngIf="primaryAddress">
                <div class="text text-green mt-small">
                    Contact Information
                </div>
                <div class="text text-black text-400">
                    {{ primaryAddress }}
                </div>
            </div>

            <a *ngIf="!isMe" class="edit-profile button btn-blue mt-small mb-small" [routerLink]="['/employers', this.user?.employee?.employer?.urlName ,'edit']">Edit Profile</a>
            <a *ngIf="isMe" class="edit-profile button btn-blue mt-small mb-small" [routerLink]="['/my-profile']">Edit Profile</a>
            <a *ngIf="isMe" class="edit-profile button btn-blue mt-small mb-small" [routerLink]="['/employers', this.user?.employee?.employer?.urlName ,'edit']">Edit Company</a>
            <a *ngIf="isMe" class="edit-profile button btn-blue mt-small mb-small" [routerLink]="['/school', user?.student?.school?.urlName, 'edit']">Edit School</a>
        </ng-template>

        <!-- <a *ngIf="isMe && !user.isEmployer && user.student" class="button orange outline mt-small mb-small" [routerLink]="['/students', user.student.urlName]">View Profile</a> -->

        <ng-template [ngIf]="user?.isStaff && staff">
            <div class="col-2">
                <div class="avatar">
                    <app-avatar-image [size]="'auto'" [circle]="false" [image]="staff.schoolLogoUrl"
                        [title]="staff.school.name"></app-avatar-image>
                </div>
                <div class="staff text heading-4 mt-small">
                    {{ staff.school.name }}
                </div>
                <div *ngIf="primaryAddress" class="location text mb-small">
                    {{ primaryAddress }}
                </div>
            </div>

            <div class="span-2" *ngIf="staff.school.description?.length">
                <div class="text text-green mt-small">
                    Who We Are
                </div>
                <div class="text text-black text-400" [innerHTML]="staff.school.description">

                </div>
            </div>

            <div class="span-2" *ngIf="primaryAddress">
                <div class="text text-green mt-small">
                    Contact Information
                </div>
                <div class="text text-black text-400">
                    {{ primaryAddress }}
                </div>
            </div>

            <a *ngIf="!isMe" class="edit-profile button btn-blue mt-small mb-small" [routerLink]="['/employers', this.user?.employee?.employer?.urlName ,'edit']">Edit Profile</a>
            <a *ngIf="isMe" class="edit-profile button btn-blue mt-small mb-small" [routerLink]="['/my-profile']">Edit Profile</a>
            <a *ngIf="isMe" class="edit-profile button btn-blue mt-small mb-small" [routerLink]="['/employers', this.user?.employee?.employer?.urlName ,'edit']">Edit Company</a>
            <a *ngIf="isMe" class="edit-profile button btn-blue mt-small mb-small" [routerLink]="['/school', user?.student?.school?.urlName, 'edit']">Edit School</a>
        </ng-template>
    </div>
</div>
