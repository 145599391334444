import { Component } from '@angular/core';
import { TableOfContents } from 'src/app/shared/models';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {

  public links: TableOfContents[] = [{
    heading: 'General Information',
    href: 'general-information',
    contents: `This Privacy Policy explains what types of information Unext
    (“Unext” “we,” “us,”) or our service providers may collect from or about visitors
    to our websites or applications (“you”), and describes our practices for collecting,
    using, retaining, protecting, disclosing, and transferring such information.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Scope',
    href: 'scope',
    contents: `This Privacy Policy applies to personal information and other information
    collected by Unext or its service providers from or about visitors to our websites
    or applications (collectively, “websites”) that display or link to this Privacy Policy.
    Unless otherwise specified below, this Privacy Policy does not apply to any other
    products or services or to information collected in any other way (whether online or
      offline) or for any other purpose. Information we may process about clients of
      our customers is addressed separately in the section below entitled "Personal
      Information of our Customers’ Clients."`,
    scroll: true,
    external: false,
  }, {
    heading: 'What personal and other information does Unext collect?',
    href: 'personal-information',
    contents: `Unext or our service providers may collect personal information and other
    information from or about you when you:use our websites to receive or provide
    information regarding employment and employment opportunities request information
    download software register to a secure extranet site to obtain access to product
    information, support, services, and training. For purposes of this Privacy Policy,
    “Personal Data” means information that can be used to personally identify you, such
    as your name, address, telephone number, e-mail address, user IDs and passwords,
    billing and transaction information, credit card or other financial information,
    contact preferences, and similar information. We also may collect information that is
    related to you but that does not personally identify you, such as your computer’s
    operating system, browser, and your use of and activities on our websites. See
    also the discussion below of cookies and other electronic tools used for the
    collection of such information.`,
    scroll: true,
    external: false,
  }, {
    heading: 'How does Unext use your information?',
    href: 'information-use',
    contents: `Unext or our service providers may use information that we or our service
    providers collect from or about you, including any Personal Data:
    <ul>
    <li>
    to welcome you
    </li>
    <li>
    to help you find employment opportunities or employ individuals
    </li>
    <li>
    to bill you for services we provide
    </li>
    <li>
    to provide ongoing service and support
    </li>
    <li>
    to carry out our obligations and enforce our rights arising from any contracts entered
    into between you and us
    </li>
    <li>
    to notify you about changes to our website or any services we offer or provide through it
    </li>
    <li>
    to improve our websites, services, and products
    </li>
    <li>
    to contact you to complete surveys that we use for marketing or quality assurance purposes
    </li>
    <li>
    to e-mail or otherwise send you marketing or other promotional information about Unext
    services that may be of interest to you
    </li>
    <li>
    to fulfill any other purpose for which you provide it in any other way we may describe
    when you provide the information
    </li>
    <li>
    to combat fraud or any other criminal activity as otherwise required or permitted by
    law, and/or for any other purpose with your consent
    </li>
    <li>
    To assist us with the uses described in this Privacy policy, information we or our
    service providers have collected from or about you through our websites, including
    about your use of such sites, may be combined with or enhanced by other information
    from or about you that we have obtained from other online or offline sources, including
    from our service providers.
    </li>
    </ul>
    Our website may provide online newsgroups, chat forums, and blogs for our users. Any information that you disclose in these forums becomes public information that can be read, collected, or used by other users to send you unsolicited messages. You should exercise caution when deciding to disclose your Personal Data. Our websites may also provide for "Live Chat" functionality, wherein Unext personnel can monitor the user's areas of interest in the website and engage in instant messaging with the user to provide additional information or answer any questions. By posting in our newsgroups and using "Live Chat," you explicitly consent to these uses of your information. We are not responsible for the Personal Data you choose to submit in these forums. For testimonials, we provide a choice as to whether a user posts his or her Personal Data along with the testimonial. See also the next section for an explanation of how Unext uses cookies, web beacons, and other electronic tools to collect other information about you for advertising and other purposes.
    `,
    scroll: true,
    external: false,
  }, {
    heading: 'Collection of infromation using cookies and other tools for online advertising',
    href: 'information-collection',
    contents: `Unext may collect and/or log your Internet Protocol address,
    Internet domain name, the web browser and operating system used to access our websites,
    the files visited, the time spent in each file, and the time and date of each visit.
    Unext may collect this information automatically as you browse our websites through the
    use of log files and web beacons. Unext may also use cookies to automatically collect
    information about your use of our websites. Cookies are text files that are sent
    from a website or application via your browser and placed on your computer's hard
    drive or your mobile device. Thereafter, when you visit that website or application,
    a message is sent back to the web server by the browser accessing the website or
    application, and this information about your activities is stored in the cookie.
    The information collected in cookies (e.g., the web pages you visit on the website
    and navigation patterns, the date and time of your visit, the number of links you
    click within the site, the functions you use on the site, the databases you view
    and the searches you request on the site, the data you save on, or download from,
    the site, and the third-party websites you visited immediately before and after
    visiting the websites, and when you open our emails or click on any of their links)
    may be used and analyzed to improve our service to you and to personalize your
    web-browsing experience by providing Unext with a better understanding of your
    interests and requirements regarding our websites. Cookies may be linked to your
    Personal Data, such as your e-mail address or password.`,
    scroll: true,
    external: false,
  }, {
    heading: 'With whom does Unext share your information?',
    href: 'information-sharing',
    contents: `We may disclose information that does not identify any individual
    (e.g., anonymous, aggregated data) without restriction. Unext contracts with
    third-party service providers, partners and suppliers to deliver certain services
    and customer solutions. Examples of such services provided on our behalf are mail
    delivery, website hosting, and transaction processing. Unext may share your
    Personal Data with its service providers and suppliers to the extent necessary to
    deliver the service you requested, respond to your requests for information on
    services or otherwise support your business needs. Third-party service providers
    and suppliers receiving Personal Data are authorized to use your Personal Data
    only for the purpose it was originally intended or as required or permitted by law.
    Unless otherwise dictated by law or agreement, however, Unext is not responsible
    for any improper use by such parties.
    We may also disclose your Personal Data and other information:
    <ul>
    <li>
    to comply with the law or in response to a subpoena, court order, law enforcement
    or government request, or other legal process
    </li>
    <li>
    to produce relevant documents or information in connection with litigation,
    arbitration, mediation, adjudication, government or internal investigations,
    or other legal or administrative proceedings
    </li>
    <li>
    if Unext determines in its good faith judgment that such disclosure is necessary
    to provide its services or to protect the rights, interests, safety, or property
    of its business, employees, suppliers, customers, or others
    </li>
    <li>
    in connection with any proposed or actual sale or other transfer of some or all
    assets of Unext, and/or any proposed or actual sale or merger of Unext or any
    division of Unext
    </li>
    <li>
    to enforce the terms of any agreement with Unext
    </li>
    <li>
    to combat fraud or other criminal activity
    </li>
    <li>
    for any other purpose disclosed by us when you provide the information
    </li>
    <li>
    as otherwise required or permitted by law, and/or with your consent
    </li>
    </ul>
    Unless permitted by relevant laws, Unext will not share, sell, trade, or lease
    your Personal Data to or with others except as provided for in this Privacy policy.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Links to non-Unext websites and third parties',
    href: 'third-parties',
    contents: `Unext’s websites may provide links to third-party websites for your
    convenience and information. If you access those links, you will leave the Unext
    website. Unext does not control these third-party websites or their privacy practices,
    which may differ from Unext's privacy practices. We do not endorse or make any
    representations about third-party websites. The Personal Data you choose to provide
    to unrelated third parties is not covered by the Unext Privacy Statement. We encourage
    you to review the privacy policy of any entity before submitting your Personal Data.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Security of your personal data',
    href: 'personal-data',
    contents: `Unext has implemented administrative, technical, and physical measures
    designed to protect your Personal Data from accidental loss and from unauthorized
    access, disclosure, use, alteration, or destruction. These measures include computer
    safeguards and secured files and buildings, Internet firewalls, intrusion detection,
    anti-virus protection, network monitoring, and, where appropriate and required by
    applicable law, Transport Layer Security (“TLS”) or similarly encrypted browsers.
    No electronic data transmission or storage of information can be guaranteed to be
    100% private and secure, however, and you understand Unext does not ensure or
    warrant the privacy or security of any information we collect from or about you,
    and that you use the Unext websites and other Unext services and provide us with
    information at your own risk. If a password is used to help protect your accounts
    and Personal Data, it is your responsibility to keep your password confidential.
    Please also be careful to avoid “phishing” scams, where someone may send you an
    email that looks like it is from Unext asking for your Personal Data. Unext will
    never request your Personal Data through email.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Personal data of our customers\' clients',
    href: 'customers-clients',
    contents: `When we provide services to our customers, in some instances we process
    Personal Data about their clients ("Client Information") on their behalf. In these
    situations, it is our customers rather than we who decide the reasons for which the
    Client Information will be processed. For details of how the Client Information will
    be used and protected, and details of how to access or correct the information, please
    refer to the privacy policy of the Unext customer to which you submitted your
    Personal Data.`,
    scroll: true,
    external: false,
  }, {
    heading: 'International data transfers involving the European Economic Area ("EEA")',
    href: 'eea',
    contents: `If you are in the EEA, the data Unext collects from you as described in
    this Privacy Policy may be transferred to and stored at a destination outside the
    EEA in order to facilitate our business. Countries outside the EEA may not have laws
    that provide the same level of protection to your Personal Data as laws for EEA-member
    countries. Where this is the case we will attempt to implement appropriate safeguards
    to ensure that such transfers comply with EU Privacy Law by using standard contractual
    clauses approved by the European Commission as ensuring an adequate protection or by
    ensuring that the transfer is done to an organization that complies with Privacy Shield
    in case data transfer is made to the United States.
    In this respect, please note that Unext adheres to the E.U.-U.S. and Swiss-U.S.
    Privacy Shield Framework. In compliance with the E.U.-U.S. and Swiss-U.S. Privacy
    Shield Principles, we commit to resolve complaints about your privacy and our
    collection or use of your Personal Data. Unext has further committed to refer
    unresolved privacy complaints under the E.U.-U.S. and Swiss-U.S. Privacy Shields
    to the American Arbitration Association, http://go.adr.org/privacyshield.html.
    In certain limited cases, it may be possible for individuals to invoke binding
    arbitration before the Privacy Shield Panel to be created by the U.S. Department
    of Commerce and the European Commission. Please note that if you are not a European
    Union or Switzerland resident, then Privacy Shield requirements regarding the
    handling of complaints may not apply to you and Privacy Shield enforcement
    mechanisms may not be available to you. In case you wish to obtain more details
    about Unext’s data transfer safeguards and the mechanisms in place, you may contact
    us at opportunity@unext.online.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Your rights - non-EU users',
    href: 'non-eu-user-rights',
    contents: `Depending on where you are located, you will have different rights in
    respect of your Personal Data and we will make every effort to comply with the
    relevant requirements of applicable laws and this Privacy Policy.`,
    scroll: true,
    external: false,
  }, {
    heading: 'Your rights - EU users',
    href: 'eu-user-rights',
    contents: `If you are in the EU, you should know that the EU Privacy Law grants
    specific rights, summarized below, you can in principle exercise free of charge,
    subject to statutory exceptions. These rights may be limited, for example if
    fulfilling your request would reveal Personal Data about another person, or if
    you ask Unext to delete information we are required by law to keep or have
    compelling legitimate interests in keeping. To exercise any of your rights,
    you can file a request via email at opportunity@unext.online.
    Should you have unresolved concerns, you have the right to lodge a complaint
    where you live or where you believe a breach may have occurred. Unext encourages
    you to come to us in the first instance but, to the extent that this right applies
    to you, you are entitled to complain directly to the relevant supervisory authority.
    <ul>
    <li>
    Right to Withdraw Consent. Wherever we rely on your consent, you will be able to
    withdraw that consent at any time you choose and at your own initiative by logging
    in to your account on our website (if you have one) or by contacting us at
    opportunity@unext.online. The withdrawal of your consent will not affect the
    lawfulness of the collection and processing of your data based on your consent
    up until the moment where you withdraw your consent. Please note that Unext may
    have other legal grounds for processing your data for other purposes, such as those
    set out in this Privacy Policy.
    </li>
    <li>
    Right to Access and Rectify Your Data. You have the right to access, review, and
    rectify your Personal Data. You may be entitled to ask us for a copy of your
    information, to review or correct it if you wish to rectify any information like
    your name, email address, passwords and/or any other preferences, you can easily do
    so by logging in to your account on our website (if you have one) or by contacting
    us at opportunity@unext.online. You may also request a copy of the Personal Data
    processed as described in this Privacy Policy.
    </li>
    <li>
    Right to Erasure. In accordance with EU Privacy Law, you have the right to erasure
    of your Personal Data processed by us as described in this Privacy Policy in case
    it is no longer needed for the purposes for which the Personal Data was initially
    collected or processed or in the event you have withdrawn your consent or objected
    to processing as described in this Privacy Policy and no other legal ground for
    processing applies. Should you wish to have your Personal Data erased, please file
    a request via email at opportunity@unext.online.
    </li>
    <li>
    Right to Restriction of Processing. Under certain circumstances described in EU
    Privacy Law, you may ask us to restrict the processing of your Personal Data.
    This is for example the case when you contest the accuracy of your Personal Data.
    In such an event, we will restrict the processing until we can verify the accuracy
    of your data.
    </li>
    <li>
    Right to Object to Processing. Under certain circumstances described in EU Privacy
    Law, you may object to the processing of your Personal Data, including where your
    Personal Data is processed for direct marketing purposes.
    </li>
    <li>
    Right to Data Portability. Where you have provided your data directly to us and where
    the processing is carried out by automated means and based on your consent or the
    performance of a contract between you and us, you have the right to receive the
    Personal Data processed about you in a structured, commonly used and machine-readable
    format, and to transmit this data to another service provider.
    </li>
    </ul>`,
    scroll: true,
    external: false,
  }, {
    heading: 'Changes to this privacy policy',
    href: 'policy-changes',
    contents: `Unext will review and update this Privacy Policy periodically, and will
    note the date of its most recent revision above. If we make material changes to
    this Privacy Policy, we will notify you either by prominently posting a notice of
    such changes prior to implementing the changes or by directly sending you a
    notification. We encourage you to review this Privacy Policy frequently to be
    informed of how Unext is collecting, using, retaining, protecting, disclosing, and
    transferring your information.`,
    scroll: true,
    external: false,
  },
  ];

}
