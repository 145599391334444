import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class Auth0ApiConfig {
    static path = `${environment.auth0.domain}`;
    static version: string | number = environment.auth0.version;

    public static setApiVersion(version: string | undefined = 'api'): void {
        Auth0ApiConfig.version = version;
    }

    public static getApiVersion(): string | number {
        return Auth0ApiConfig.version;
    }

    public static getPath(): string {
        if (Auth0ApiConfig.path[Auth0ApiConfig.path.length - 1] === '/') {
            Auth0ApiConfig.path = Auth0ApiConfig.path.substring(0, Auth0ApiConfig.path.length - 1);
        }
        return Auth0ApiConfig.path;
    }

    public static getFullUrl(path: string): string {
        if (Auth0ApiConfig.getApiVersion()) {
            return [Auth0ApiConfig.getPath(), Auth0ApiConfig.getApiVersion(), path].join('/');
        } else {
            return [Auth0ApiConfig.getPath(), path].join('/');
        }
    }
}
