import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AudienceApiConfig } from '../api';
import { Audience, AudienceUpdate } from '../models';

@Injectable()
export class AudienceService {

    constructor(private http: HttpClient) { }

    public get(urlName: string): Promise<Audience[]> {
        return lastValueFrom(this.http.get<Audience[]>(AudienceApiConfig.getFullUrl(urlName)));
    }

    public updateAudience(model: AudienceUpdate, id?: number, ): Promise<Audience> {
        if (id) {
            return lastValueFrom(this.http.put<Audience>(
                AudienceApiConfig.getFullUrl(`${id}`), model));
        } else {
            return lastValueFrom(this.http.post<Audience>(
                AudienceApiConfig.getFullUrl(''), model));
        }
    }

}
