import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent {
  @Input() steps: [{title: string}]

  private _activeStep = 0

  @Input()
  get activeStep() {
    return this._activeStep
  }

  set activeStep(val: number) {
    this._activeStep = val
    this.activeStepChange.emit(this._activeStep)
  }

  @Output()
  activeStepChange: EventEmitter<number> = new EventEmitter()

  next() {
    this.activeStep++
  }

  previous() {
    this.activeStep--
  }
}