<div class="form mb-small">
    <div class="append-button">
        <input *ngIf="!useSelect" class="form-input form-input-rounded form-input-100 form-input-teal text"
                [placeholder]="placeholder"
                [ngModel]="search" (keyup)="updateSearch($event)"
                (focus)="onFocus()" />
        <!-- <button type="button" class="button text text-{{ buttonColor }} mt-small" (click)="updateOpportunity(search)">
            +
        </button> -->
        <ng-select *ngIf="useSelect" class="drop-down drop-down-button-blue" [items]="values | sort:'':'asc'"
        [closeOnSelect]="true" (search)="onSearch($event, values)" (change)="setSelection($event)" [placeholder]="placeholder" >
    </ng-select>
    </div>
    <div class="value-wrapper">
        <div class="value" *ngIf="filtered && filtered.length && !isSelected()">
            <div class="text"
                (click)="updateOpportunity(foi)"
                    *ngFor="let foi of filtered">
                {{ foi }}
            </div>
        </div>
    </div>
</div>
<app-progress-bar *ngIf="working" [value]="40"></app-progress-bar>
