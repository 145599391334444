import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FilterOption, School, Staff, User } from 'src/app/shared/models';
import { SchoolService, UserService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { ReportingService } from 'src/app/shared/services/reporting.service';

@Component({
  selector: 'app-school-data-dashboard',
  templateUrl: './school-data-dashboard.component.html',
  styleUrl: './school-data-dashboard.component.scss'
})
export class SchoolDataDashboardComponent implements OnInit {

  user: User;
  school: School;
  urlName: string;
  working = 0;
  staffPageNumber: number = 1;

  allStaffList?: Staff[];
  modifiedStaffList?: Staff[];


  formGroup: FormGroup;

  staffFilterDropDownOptions: FilterOption[] = [];
  staff: any[];
  staffSearchOptions: any[] = [];
  dataDropDownOptions: FilterOption[] = [];

  basicData: any;
  engagementData: any;

  constructor(
    public cdn: CDNService,
    private route: ActivatedRoute,
    private schoolService: SchoolService,
    userService: UserService,
    private reports: ReportingService
  ) {

    this.user = userService.authenticatedUser;
    this.route.params.subscribe(params => {
      // Retrieve the ID from the route parameters
      this.urlName = params['urlName'];
    });
  }

  async ngOnInit(): Promise<void> {
    this.school = await this.schoolService.get(this.urlName);
    this.allStaffList = await this.schoolService.getFacultyList(this.urlName);
    this.modifiedStaffList = this.allStaffList;

    this.basicData = await this.reports.getBasicInfo();

    this.engagementData = await this.reports.getEngagementInfo();

    this.createFilterOptions();
  }

  createFilterOptions() {
    const departments = this.school.departments
      .filter(a => a.departmentName)
      .map(a => a.departmentName);

    const positions = this.school.positions
      .filter(a => a.name)
      .map(a => a.name);
    if (departments.length) {
      this.dataDropDownOptions.push({ id: 2, label: 'Department', active: false, name: 'department', options: departments });
    }
    if (this.school.locations.length) {
      this.dataDropDownOptions.push({ id: 3, label: 'Campus', active: false, name: 'campus', options: this.school.locations });
    }
    if (positions.length) {
      this.dataDropDownOptions.push({ id: 4, label: 'Position', active: false, name: 'position', options: positions });
    }


  }

  updateData(e: any) {
console.log(e);
  }
}
