<div class="cancel mb-medium" *ngIf="employer?.subscribed">
  <h1 class="text">
    Cancel Your Subscription
  </h1>
  <div class="actions mb-small">
    <button class="button orange outline" type="button" (click)="open('confirm-cancel')">
      Cancel Subscription
    </button>
    <button class="button btn-teal mr-2" type="button" (click)="close('confirm-cancel')">
      Close
    </button>
  </div>
  <app-progress-bar *ngIf="working" value="60"></app-progress-bar>
</div>

<app-modal id="confirm-cancel" class="confirm">
  <div class="text-center">
    <h1 class="text">
      Are you sure you want to cancel your subscription to Unext?
    </h1>
    <h2 class="text">
      Deleting the subscription will stop your company from: 
    </h2>
    <ul class="text text-400">
      <li>Posting opportunities</li>
      <li>Connecting with students</li>
      <li>Organizing Events, Volunteering, shadowing, and tours on Unext</li>
      <li>Remove students from seeing your company page</li>
      <li>Remove targeted candidates</li>
      <li>And any additional features Unext is working on to build a closer community</li>
    </ul>
    <div class="actions mb-small">
      <button type="button" (click)="submitCancellation()" class="button orange outline">I'm Sure</button>
      <button type="button" (click)="close('confirm-cancel')" class="button btn-teal">Let's Reconsider</button>
    </div>
  </div>
  <app-progress-bar *ngIf="working" value="60"></app-progress-bar>
</app-modal>

<div class="subscribe mb-medium" *ngIf="!employer?.subscribed && formGroup">
  <form [formGroup]="formGroup" class="form" (ngSubmit)="submit()">
    <div class="text heading-4 mb-medium">
      Payment Information
    </div>
    <div>
      <label for="firstName" class="form-label">
        First Name
      </label>
      <input [required]="formGroup.get('firstName').errors?.required" type="text" name="firstName"
        formControlName="firstName" class="form-input form-input-rounded form-input-green" />
      <label class="form-error" *ngIf="formGroup.get('firstName').errors?.required">
        First name is required
      </label>
    </div>
    <div>
      <label for="lastName" class="form-label">
        Last Name
      </label>
      <input [required]="formGroup.get('lastName').errors?.required" type="text" name="lastName"
        formControlName="lastName" class="form-input form-input-rounded form-input-green" />
      <label class="form-error" *ngIf="formGroup.get('lastName').errors?.required">
        Last name is required
      </label>
    </div>
    <div>
      <label for="Email" class="form-label">Email</label>
      <input [required]="formGroup.get('email').errors?.required" type="email" inputmode="email" name="Email"
        formControlName="email" class="form-input form-input-rounded form-input-green" />
      <label class="form-error" *ngIf="formGroup.get('email').errors?.required">
        Email is required
      </label>
      <label class="form-error" *ngIf="formGroup.get('email').errors?.email">
        Email is not valid
      </label>
    </div>
    <div>
      <label for="Phone" class="form-label">Phone</label>
      <input appPhonemask [required]="formGroup.get('phone').errors?.required" formControlName="phone" type="tel"
        name="Phone" class="form-input form-input-rounded form-input-green" />
      <label class="form-error" *ngIf="formGroup.get('phone').errors?.required">
        Phone is required
      </label>
      <label class="form-error" *ngIf="formGroup.get('phone').errors?.pattern">
        Phone is not valid
      </label>
      <label class="form-error" *ngIf="formGroup.get('phone').errors?.minlength">
        Phone is too short
      </label>
      <label class="form-error" *ngIf="formGroup.get('phone').errors?.maxlength">
        Phone is too long
      </label>
    </div>
    <div>
      <label for="Address1" class="form-label">Address Line 1</label>
      <input [required]="formGroup.get('addressLineOne').errors?.required" formControlName="addressLineOne" type="text"
        name="Address1" class="form-input form-input-rounded form-input-green" />
      <label class="form-error" *ngIf="formGroup.get('addressLineOne').errors?.required">
        Address is required
      </label>
    </div>
    <div>
      <label for="Address2" class="form-label">Address Line 2</label>
      <input name="Address2" formControlName="addressLineTwo" type="text"
        class="form-input form-input-rounded form-input-green" />
    </div>
    <div>
      <label for="City" class="form-label">City</label>
      <input [required]="formGroup.get('city').errors?.required" name="City" formControlName="city" type="text"
        class="form-input form-input-rounded form-input-green" />
      <label class="form-error" *ngIf="formGroup.get('city').errors?.required">
        City is required
      </label>
    </div>
    <div>
      <label for="State" class="form-label">State</label>
      <input [required]="formGroup.get('state').errors?.required" name="State" formControlName="state" type="text"
        class="form-input form-input-rounded form-input-green" />
      <label class="form-error" *ngIf="formGroup.get('state').errors?.required">
        State is required
      </label>
    </div>
    <div>
      <label for="Zip" class="form-label">Zip</label>
      <input [required]="formGroup.get('zip').errors?.required" name="Zip" formControlName="zip" type="text"
        class="form-input form-input-rounded form-input-green" />
      <label class="form-error" *ngIf="formGroup.get('zip').errors?.required">
        Zip is required
      </label>
    </div>

    <div class="mb-small">
      <div class="plans">
        <label *ngFor="let plan of stripePlans" for="PlanName" class="form-label mr-medium">
          <input type="radio" formControlName="plan" [value]="plan.id"
            class="form-input form-input-rounded form-input-green mr-small" />
          {{ plan.price | currency }} {{ plan.name }}
        </label>
      </div>
      <label class="form-error" *ngIf="formGroup.get('plan').errors?.required">
        Plan is required
      </label>
    </div>

    <div class="mt-small mb-small strip">
      <!-- <ngx-stripe-card class="m-2" [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card> -->
    </div>

    <div class="actions mb-small">
      <button type="submit" [disabled]="formGroup.invalid" class="button dark-green mr-small"
        [ngClass]="{ 'btn-green': !formGroup.invalid, 'btn-disabled': formGroup.invalid }">
        Save
      </button>
      <button type="button" class="button orange outline" (click)="closed.next(true)">Cancel</button>
    </div>
    <app-progress-bar *ngIf="working" value="60"></app-progress-bar>
  </form>
</div>
