import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Guid } from 'guid-typescript';
import { EntityType } from '../../models';
import { ResumeService, UploadService } from '../../services';
import { CDNService } from '../../services/cdn.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent {

  @Input()
  entityType: EntityType;
  @Input()
  entityId: string;
  @Input()
  maxFiles = 1;
  @Input()
  acceptType: 'image' | 'video' | 'resume' = 'image';
  @Output()
  filesUploaded = new EventEmitter<string[]>();
  @Output()
  filesSelected = new EventEmitter<File[]>();
  @Input()
  isResume = false;

  uniqueId = Guid.create();
  fileUpload: any;

  files: File[] = []; // Variable to store file
  working = false;
  metaTypes = {
    image: 'image/*',
    video: 'video/*',
    resume: '.pdf,.doc,.docx,.txt',
  };

  constructor(
    private upload: UploadService,
    private resumes: ResumeService,
    private snackBar: ToastService,
    public cdn: CDNService,
  ) { }

  onChange(event) {
    this.files.push(event.target.files[0]);
  }

  remove(i: number): void {
    if (i < this.files.length) {
      this.files.splice(i, 1);
    }
  }

  async onUpload(): Promise<void> {

    if (this.entityId) {
      this.working = true;
      try {
        let files: string[] = [];
        if (this.isResume) {
          files = await this.resumes.parse(this.files);
        } else {
          files = await this.upload.push(this.files, this.entityId, this.entityType);
        }
        this.snackBar.success({ message: 'Your file has been uploaded.' });
        this.filesUploaded.emit(files);
        this.files = [];
        this.uniqueId = Guid.create();
        this.fileUpload = undefined;
      } catch (err) {
        if (err.error?.filetype) {
          this.snackBar.error({ message: err.error.filetype });
        } else if (err.error?.message?.length) {
          this.snackBar.error({ message: err.error.message });
        } else {
          this.snackBar.error({ message: 'There was a problem uploading your file.' });
        }
        this.filesUploaded.emit([]);
      } finally {
        this.working = false;
      }
    }
  }

  getMetaType(): string {
    switch (this.acceptType) {
      case 'image':
        return this.metaTypes.image;
        break;
      case 'video':
        return this.metaTypes.video;
        break;
      case 'resume':
      default:
        return this.metaTypes.resume;
        break;
    }
  }
}
