import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class AudienceApiConfig {
    static path: string = environment.api.audience.path;
    static version: string | number = environment.api.audience.version;

    public static setApiVersion(version: string | undefined = 'api'): void {
        AudienceApiConfig.version = version;
    }

    public static getApiVersion(): string | number {
        return AudienceApiConfig.version;
    }

    public static getPath(): string {
        return AudienceApiConfig.path;
    }

    public static getFullUrl(path): string {
        if (AudienceApiConfig.getApiVersion()) {
            return [AudienceApiConfig.getPath(), AudienceApiConfig.getApiVersion(), path].join('/');
        } else {
            return [AudienceApiConfig.getPath(), path].join('/');
        }
    }
}
