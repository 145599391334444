import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { DropdownComponent, DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { Opportunity, OpportunityType, Student, StudentOpportunity, User } from 'src/app/shared/models';
import { IndustryService, LocationService, OpportunityService, StudentService, UserService } from 'src/app/shared/services';

@Component({
  selector: 'app-my-opportunities',
  templateUrl: './my-opportunities.component.html',
  styleUrls: ['./my-opportunities.component.scss']
})
export class MyOpportunitiesComponent implements OnInit {

  @ViewChild('ddIndustry', { static: false })
  ddIndustry: DropdownComponent;
  @ViewChild('ddEmployment', { static: false })
  ddEmployment: DropdownComponent;
  @ViewChild('ddLocation', { static: false })
  ddLocation: DropdownComponent;

  formGroup = new FormGroup({
    filterText: new FormControl('')
  });

  user: User;
  student: Student;

  searchResults: Opportunity[] = undefined;
  filter = 'none';
  sort = 'none';
  max: number;
  selectedIndustry: string;
  selectedEmployment: number;
  selectedLocation: string;
  searching = false;

  interested: Opportunity[] = [];
  all: Opportunity[] = [];
  recommended: Opportunity[] = [];
  near: Opportunity[] = [];
  newest: Opportunity[] = [];
  fulltime: Opportunity[] = [];
  internships: Opportunity[] = [];
  volunteers: Opportunity[] = [];
  others: Opportunity[] = [];
  coOps: Opportunity[] = [];
  shadows: Opportunity[] = [];
  
  industryFocus = [];
  industryFocusName: string[] = [];
  favorites: Opportunity[] = [];
  applied: Opportunity[] = [];
  rejected: Opportunity[] = [];

  opportunities: StudentOpportunity[] = [];

  industries: DropDownOptions[] = [];
  employmentTypes: DropDownOptions[] = [];
  locations: DropDownOptions[] = [];

  HeadingText: string;
  isFavorites = false;
  showFilters = false;
  loading = 0;

  constructor(
    private users: UserService,
    private students: StudentService,
    private industryService: IndustryService,
    private locationService: LocationService,
    private opportunityService: OpportunityService,
    private router: Router
  ) {
    if (!this.user) {
      this.user = this.users.authenticatedUser as User;
    }


    this.router.events.forEach(async item => {
      window.scrollTo({
        top: 0
      });
      if (item instanceof NavigationEnd) {
        this.HeadingText = 'Favorites';
        if (this.router.url.endsWith('my-favorites')) {
          this.isFavorites = true;
          this.showFilters = false;
        } else {
          this.HeadingText = 'Find the Right Opportunity';
          this.isFavorites = false;
          this.showFilters = true;
        }
        await this.loadOpportunities();
      }
    });
  }

  async ngOnInit() {
    await this.loadIndustries();
    await this.loadLocations();
    await this.loadEmploymentTypes();
  }

  async loadEmploymentTypes() {
    this.employmentTypes = [];
    (await this.opportunityService.getEmploymentTypes().toPromise())
      .map(e => {
        this.employmentTypes.push({
          label: e.value,
          value: e.id
        });
      });
  }

  async loadIndustries() {
    this.industries = [];
    (await this.industryService.get())
      .sort()
      .map(i => this.industries.push({
        label: i,
        value: i
      }));
  }
  async loadLocations() {
    this.locations = [];
    (await this.locationService.getCities())
      .map(l => this.locations.push({
        label: l, 
        value: l,
      }));
  }

  async loadOpportunities() {
    if (this.user) {
      try {
        this.loading = 10;
        this.student = await this.students.getStudent(this.user.student.urlName);
        this.loading = 20;
        if (this.isFavorites) {
          this.opportunities = await this.students.getFavorites(
            this.user.student.urlName,
            this.max,
            this.sort).toPromise();
          this.all = this.opportunities.map(x => x.opportunity);
          this.loading = 30;
        } else {
          this.opportunities = await this.students.getOpportunities(
            this.user.student.urlName,
            null,
            null,
            null,
            null,
            this.sort);
          this.loading = 30;
          this.all = this.opportunities.map(x => x.opportunity);
          this.loading = 40;
          this.favorites = this.opportunities.filter(o => o.studentInterested).map(x => x.opportunity);
          const recommendationthreshold = 0;
          this.recommended = this.opportunities.filter(o => o.score > recommendationthreshold).map(x => x.opportunity);
          this.near = this.opportunities
                        .filter(o => o.opportunity
                                      .locations
                                      .filter(l => l === `${this.student.address.city.name}, ${this.student.address.city.state.name}`)
                                      .length > 0)
                        .map(x => x.opportunity);
          const nw: Date = new Date();
          const mod = nw.getDate() - 7;
          nw.setDate(mod);
          this.newest = this.opportunities.filter(o => o.opportunity.publishStartDate > nw).map(x => x.opportunity);
          this.fulltime = this.opportunities.filter(o => o.opportunity.opportunityTypeId === OpportunityType.Employment).map(x => x.opportunity);
          this.internships = this.opportunities.filter(o => o.opportunity.opportunityTypeId === OpportunityType.Internship).map(x => x.opportunity);
          this.volunteers = this.opportunities.filter(o => o.opportunity.opportunityTypeId === OpportunityType.Volunteering).map(x => x.opportunity);
          this.others = this.opportunities.filter(o => o.opportunity.opportunityTypeId === OpportunityType.Other).map(x => x.opportunity);
          this.coOps = this.opportunities.filter(o => o.opportunity.opportunityTypeId === OpportunityType.CoOp).map(x => x.opportunity);
          this.shadows = this.opportunities.filter(o => o.opportunity.opportunityTypeId === OpportunityType.JobShadowing).map(x => x.opportunity);

          this.industryFocus = [];
          this.industryFocusName = [];
          this.student.industries.map(i => {
            const opps = this.opportunities
                              .filter(o => o.opportunity
                                            .industries
                                            .filter(ii => ii === i).length > 0)
                              .map(x => x.opportunity);
            if (opps.length) {
              this.industryFocusName.push(i);
              this.industryFocus.push(opps);
            }
          });
          this.loading = 70;
          this.interested = this.opportunities.filter(o => o.studentInterested &&
                                                          !o.applied).map(x => x.opportunity);
          this.loading = 80;
          this.applied = this.opportunities.filter(o => o.applied).map(x => x.opportunity);
          this.loading = 90;
          this.rejected = this.opportunities.filter(o => o.applied &&
                                                         o.rejected).map(x => x.opportunity);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = 0;
      }
    }
  }

  selectIndustry(val: DropDownOptions): void {
    this.searching = true;
    this.selectedIndustry = val.value;
  }
  selectEmployment(val: DropDownOptions): void {
    this.searching = true;
    this.selectedEmployment = val.value;
  }
  selectLocation(val: DropDownOptions): void {
    this.searching = true;
    this.selectedLocation = val.value;
  }
  updateFilter(): void {
    this.searching = true;
  }

  clearSearch(): void {
    this.searchResults = null;
    this.selectIndustry = null;
    this.ddIndustry.value = null;
    this.selectEmployment = null;
    this.ddEmployment.value = null;
    this.selectLocation = null;
    this.ddLocation.value = null;
    this.formGroup.get('filterText').setValue('');
    this.searching = false;
  }
  async search(): Promise<void> {
    this.loading = 25;
    try {

      this.searchResults = (await this.students.filterOpportunities(
        this.student.urlName,
        null,
        null,
        null,
        this.formGroup.get('filterText').value,
        null,
        this.selectedIndustry,
        this.selectedLocation,
        this.selectedEmployment)).map(o => o.opportunity);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = 0;
    }
  }
}
