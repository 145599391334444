<app-progress-bar [splashScreen]="true" [value]="working"></app-progress-bar>
<div class="bg-cream stepper-wrapper">
    <div class="bg-teal flex-rows pl-medium pr-small py-large">
        <div class="text text-title text-lime mb-large">
            Edit Audience
        </div>
        <app-stepper #stepper [steps]="steps" [(activeStep)]="activeStep"></app-stepper>
    </div>


    <div class="my-school flex-rows">
        <form *ngIf="formGroup" class="form pt-large px-medium flex-rows" [ngClass]="{ 'mb-medium': working }" (ngSubmit)="onSave()"
            [formGroup]="formGroup" >
            <div class="flex-columns justify-between">
                <div class="text text-title mb-large mr-auto">
                    Creating Audience/Opportunity
                </div>
                <a *ngIf="opportunity && steps[activeStep]?.id !== 'overview'"
                    [routerLink]="['/employers', employer?.urlName ]" class="btn border lime-green">View
                    Profile
                </a>
            </div>
            <div class="stepper-content mb-auto" [ngClass]="{ 'stepper-content-split': steps[activeStep].split }">
                <ng-container *ngIf="steps[activeStep].id === 'info'">
                    <div class="upload-image mb-large flex-columns">
                        <div class="logo mr-large" (click)="openModal('uploadLogo')" style="cursor: pointer;">
                            <div class="text text-subtitle text-800">
                                Profile Picture
                            </div>
                            <app-avatar-image title="logo"
                                [image]="employer?.smallLogoUrl?.length > 0 ? opportunity?.bannerUrl: cdn.toCDN('images/icons/camera.png')"
                                size="auto" fit="contain">
                            </app-avatar-image>
                        </div>
                        <div class="logo mr-large" (click)="openModal('uploadBanner')" style="cursor: pointer;">
                            <div class="text text-subtitle text-800">
                                Cover Picture
                            </div>
                            <app-avatar-image title="banner"
                                [image]="employer?.logoUrl?.length > 0 ? opportunity?.bannerUrl : cdn.toCDN('images/icons/camera.png')"
                                size="auto" fit="contain">
                            </app-avatar-image>
                        </div>
                        <div class="logo mr-large" (click)="openModal('uploadBanner')" style="cursor: pointer;">
                            <div class="text text-subtitle text-800">
                                Upload Image
                            </div>
                            <app-avatar-image title="banner"
                                [image]="employer?.logoUrl?.length > 0 ? opportunity?.bannerUrl : cdn.toCDN('images/icons/camera.png')"
                                size="auto" fit="contain">
                            </app-avatar-image>
                        </div>
                    </div>
                    <div></div>
                    <div class="flex-rows mb-small">
                        <label for="SchoolName" class="form-label text text-black text-rounded-mplus text-800">
                            Audience/Opportunity Name
                        </label>
                        <input name="Name" type="text" formControlName="Name" required
                            class="form-input border border-teal text" />
                    </div>
                    <div></div>
                    <div class="flex-rows mb-small">
                        <label for="Domain" class="form-label">
                            External Application Link
                        </label>
                        <input type="text" formControlName="ApplicationLink"
                            class="form-input border border-teal text" />
                    </div>
                    <div></div>
                    <div class="flex-rows mb-small">
                        <label for="Type" class="form-label">
                            Opportunity Type
                        </label>
                        <ng-select formControlName="OpportunityType" class="drop-down drop-down-button-blue"
                            placeholder="Select Opportunity Type" [items]="opportunityTypeList">
                    </ng-select>
                    </div>
                    <div></div>
                    <div class="flex-rows mb-small">
                        <label for="Contact" class="form-label">
                            Contact
                        </label>
                        <input type="text" formControlName="Contact"
                            class="form-input border border-teal text" />
                    </div>
                    <div></div>
                    <div class="flex-rows mb-small">
                        <label for="ManagerId" class="form-label">
                            Opportunity Supervisor
                        </label>
                        <ng-select formControlName="ManagerId" class="drop-down drop-down-button-blue"
                            placeholder="Select a supervisor" [items]="employees | sort:'label':'asc'">
                        </ng-select>
                    </div>
                    <div></div>
                    <div class="flex-rows">
                        <label for="Description" class="form-label">
                            Description
                        </label>
                        <quill-editor name="Description" formControlName="Description" [modules]="quillConfiguration"
                        [sanitize]="true"></quill-editor>
                    </div>
                </ng-container>
                <ng-container *ngIf="steps[activeStep].id === 'demography'">
                    <div>
                        <div class="flex-rows">
                            <label for="School" class="form-label">
                                Schools
                            </label>
                            <div>
                                <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                    [availableValues]="availableSchools" attribute="school" [showOnFocus]="true"
                                    placeholder="Search for Schools" buttonColor="green" (added)="selectSchool($event)">
                                </app-opportunity-add-attribute>
                            </div>
                            <div class="flex-columns flex-wrap" *ngIf="schools?.value?.length">
                                <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                    *ngFor="let s of schools?.value" (click)="removeSelection(s, schools, 'school')">
                                    {{ s }}
                                </span>
                            </div>
                        </div>
                        <div class="flex-rows">
                            <label for="Major" class="form-label">
                                Major
                            </label>
                            <!-- <ng-select formControlName="Major" [items]="opportunityTypeList"
                            class="drop-down drop-down-button-blue"></ng-select> -->
                            <div>
                                <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                    [availableValues]="availableMajors" attribute="major"
                                    placeholder="Search for majors" buttonColor="green" (added)="selectMajor($event)">
                                </app-opportunity-add-attribute>
                            </div>
                            <div class="flex-columns flex-wrap" *ngIf="majors?.value?.length">
                                <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                    *ngFor="let m of majors?.value" (click)="removeSelection(m, majors, 'major')">
                                    {{ m }}
                                </span>
                            </div>
                        </div>
                        <div class="flex-rows">
                            <label for="Minor" class="form-label">
                                Minor
                            </label>
                            <!-- <ng-select formControlName="Minor" [items]="opportunityTypeList"
                            class="drop-down drop-down-button-blue"></ng-select> -->
                            <div>
                                <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                    [availableValues]="availableMinors" attribute="minor"
                                    placeholder="Search for minors" buttonColor="green" (added)="selectMinor($event)">
                                </app-opportunity-add-attribute>
                            </div>
                            <div class="flex-columns flex-wrap" *ngIf="minors?.value?.length">
                                <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                    *ngFor="let m of minors?.value" (click)="removeSelection(m, minors, 'minor')">
                                    {{ m }}
                                </span>
                            </div>
                        </div>
                        <div class="flex-rows">
                            <label for="FieldOfInterest" class="form-label">
                                Field Of Interest
                            </label>
                                <!-- <ng-select formControlName="FieldOfInterest" [items]="opportunityTypeList"
                                class="drop-down drop-down-button-blue"></ng-select> -->
                                <div>
                                    <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                        [availableValues]="availableSkills" attribute="skill"
                                        placeholder="Search for skills" buttonColor="green" (added)="selectSkill($event)">
                                    </app-opportunity-add-attribute>
                                </div>
    
                                <div class="flex-columns flex-wrap" *ngIf="skills.value?.length">
                                    <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                        *ngFor="let s of skills.value" (click)="removeSelection(s, skills, 'skill')">
                                        {{ s }}
                                    </span>
                                </div>
                        </div>
                        <div class="flex-rows">
                            <label for="GraduationYear" class="form-label" >
                                Graduation Year
                            </label>
                            <input type="text" formControlName="GraduationYear"
                                class="form-input border border-teal text" />
                                
                        </div>

                        <div class="flex-rows">
                            <label for="Skills" class="form-label">
                                Skills
                            </label>
                            <!-- <ng-select formControlName="Skills" [items]="opportunityTypeList"
                            class="drop-down drop-down-button-blue"></ng-select> -->

                            <div>
                                <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                    [availableValues]="availableSkills" attribute="skill"
                                    placeholder="Search for skills" buttonColor="green" (added)="selectSkill($event)">
                                </app-opportunity-add-attribute>
                            </div>

                            <div class="flex-columns flex-wrap" *ngIf="skills.value?.length">
                                <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                    *ngFor="let s of skills.value" (click)="removeSelection(s, skills, 'skill')">
                                    {{ s }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="flex-columns justify-between">
                        
                        <div style="width: 50%" class="preferred-opportunity checkbox-section gray mb-small">
                            <div class="text checkbox-section-title">
                                Opportunity Type
                            </div>
                            <div class="flex-columns mb-xsmall">
                                <input hidden type="checkbox" formControlName="Shadowing"
                                    class="form-input" />
                                <div (click)="toggleProp('Shadowing')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('Shadowing').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="Shadowing" class="form-label" (click)="toggleProp('Shadowing')">
                                    Shadowing
                                </label>
                            </div>
                            <div class="flex-columns mb-xsmall">
                                <input hidden type="checkbox" formControlName="Volunteering"
                                    class="form-input" />
                                <div (click)="toggleProp('Volunteering')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('Volunteering').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="Volunteering" class="form-label" (click)="toggleProp('Volunteering')">
                                    Volunteering
                                </label>
                            </div>
                            <div class="flex-columns mb-xsmall">
                                <input hidden type="checkbox" formControlName="Internship"
                                    class="form-input" />
                                <div (click)="toggleProp('Internship')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('Internship').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="Internship" class="form-label" (click)="toggleProp('Internship')">
                                    Internship
                                </label>
                            </div>
                            <div class="flex-columns mb-xsmall">
                                <input hidden type="checkbox" formControlName="Employment"
                                    class="form-input" />
                                <div (click)="toggleProp('Employment')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('Employment').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="Employment" class="form-label" (click)="toggleProp('Employment')">
                                    Employment
                                </label>
                            </div>
                            <div class="flex-columns mb-xsmall">
                                <input hidden type="checkbox" formControlName="Tour"
                                    class="form-input" />
                                <div (click)="toggleProp('Tour')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('Tour').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="Tour" class="form-label" (click)="toggleProp('Tour')">
                                    Tour
                                </label>
                            </div>
                            <div class="flex-columns mb-xsmall">
                                <input hidden type="checkbox" formControlName="CoOp"
                                    class="form-input" />
                                <div (click)="toggleProp('CoOp')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('CoOp').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="CoOp" class="form-label" (click)="toggleProp('CoOp')">
                                    Co-Op
                                </label>
                            </div>
                        </div>
            
                        <div style="width: 50%" class="preferred-opportunity checkbox-section gray mb-small">
                            <div class="text checkbox-section-title">
                                Candidate Type
                            </div>
                            <div class="flex-columns mb-xsmall">
                                <input hidden type="checkbox" formControlName="UnderGraduate" [value]="true"
                                    class="form-input" />
                                <div (click)="toggleProp('UnderGraduate')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('UnderGraduate').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="UnderGraduate" class="form-label" (click)="toggleProp('UnderGraduate')">
                                    Under Graduate
                                </label>
                            </div>
                            <div class="flex-columns mb-xsmall">
                                <input hidden type="checkbox" formControlName="Graduate" [value]="true"
                                    class="form-input" />
                                <div (click)="toggleProp('Graduate')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('Graduate').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="Graduate" class="form-label" (click)="toggleProp('Graduate')">
                                    Graduate
                                </label>
                            </div>
                            <div class="flex-columns mb-xsmall">
                                <input hidden type="checkbox" formControlName="Doctorate" [value]="true"
                                    class="form-input" />
                                <div (click)="toggleProp('Doctorate')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('Doctorate').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="Doctorate" class="form-label" (click)="toggleProp('Doctorate')">
                                    Doctorate
                                </label>
                            </div>
                            <div class="flex-columns mb-xsmall">
                                <input hidden type="checkbox" formControlName="TechnicalDiploma" [value]="true"
                                    class="form-input" />
                                <div (click)="toggleProp('TechnicalDiploma')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('TechnicalDiploma').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="TechnicalDiploma" class="form-label" (click)="toggleProp('TechnicalDiploma')">
                                    Technical Diploma
                                </label>
                            </div>
                            <div class="flex-columns mb-xsmall">
                                <input hidden type="checkbox" formControlName="HighSchool" [value]="true"
                                    class="form-input" />
                                <div (click)="toggleProp('HighSchool')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('HighSchool').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="HighSchool" class="form-label" (click)="toggleProp('HighSchool')">
                                    High School
                                </label>
                            </div>
                            <div class="flex-columns mb-xsmall">
                                <input hidden type="checkbox" formControlName="PostGraduate" [value]="true"
                                    class="form-input" />
                                <div (click)="toggleProp('PostGraduate')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('PostGraduate').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="PostGraduate" class="form-label" (click)="toggleProp('PostGraduate')">
                                    Post Graduate
                                </label>
                            </div>
                            <div class="flex-columns mb-xsmall">
                                <input hidden type="checkbox" formControlName="JobCenters" [value]="true"
                                    class="form-input" />
                                <div (click)="toggleProp('JobCenters')" class="checkbox-display mr-small"
                                    [ngClass]="{ 'checked': formGroup.get('JobCenters').value }">
                                    <div class="checkbox-fill"></div>
                                </div>
                                <label for="JobCenters" class="form-label" (click)="toggleProp('JobCenters')">
                                    Job Centers
                                </label>
                            </div>
                        </div>
                    </div>
                  

                </ng-container>
            </div>
        </form>

        <ng-container *ngIf="steps[activeStep].id === 'overview'">
        </ng-container>

        <div class="pt-large pb-medium px-medium stepper-actions-split">
            <div class="actions flex-columns justify-between">
                <button type="button" class="text text-label btn btn-gray border border-black text"
                    [disabled]="activeStep === 0" (click)="stepper.previous()">
                    Back
                </button>
                <button type="button" *ngIf="activeStep < steps.length - 1"
                    class="text text-label btn lime-green border border-teal" [disabled]="!currentStepValid()"
                    (click)="steps[activeStep].next()">
                    Next
                </button>
                <button type="button" *ngIf="activeStep === steps.length - 1"
                    class="text text-label btn lime-green border border-teal" [disabled]="!currentStepValid()"
                    (click)="steps[activeStep].next()">
                    Finish
                </button>
            </div>
        </div>
        <div class="clear"></div>

       
    </div>
</div>


<app-modal id="uploadImage">
    <app-upload acceptType="image" [entityType]="entityType.EmployerMiniImage" [entityId]="opportunity?.id"
        (filesUploaded)="imageUploaded($event)" (filesSelected)="imageSelected($event)">
    </app-upload>
</app-modal>
<app-modal id="uploadBanner">
    <app-upload acceptType="image" [entityType]="entityType.SchoolBanner" [entityId]="opportunity?.id"
        (filesUploaded)="bannerUploaded($event)" (filesSelected)="bannerSelected($event)">>
    </app-upload>
</app-modal>
<app-modal id="uploadLogo">
    <app-upload acceptType="image" [entityType]="entityType.SchoolLogo" [entityId]="opportunity?.id"
        (filesUploaded)="logoUploaded($event)" (filesSelected)="logoSelected($event)">>
    </app-upload>
</app-modal>
