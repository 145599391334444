import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { School, Staff, Student } from 'src/app/shared/models';
import { UserService, EmployerService, SchoolService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-school-profile',
  templateUrl: './school-profile.component.html',
  styleUrl: './school-profile.component.scss'
})
export class SchoolProfileComponent implements OnInit {
  @Input()
  id: number;
  @Input()
  school: School;
  @Input()
  user: User;
  

  urlName: string;
  isLoading = 0;

  schoolId: string;
  staffList: Staff[];
  studentList: Student[];
  staffPageNumber: number = 1;
  studentPageNumber: number = 1;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private users: UserService,
    private employers: EmployerService,
    public cdn: CDNService,
    private snackbar: ToastService,
    private schoolService: SchoolService
  ) {
    this.urlName = this.route.snapshot.params.urlName;

    this.schoolId = this.route.snapshot.params.schoolId
    if (!this.user) {
      this.user = this.users.authenticatedUser;
    }
  }

  async ngOnInit(): Promise<void> {
    await this.loadSchool();
  }

  async loadSchool() {
    try {
      this.isLoading = 25;
      this.school =  await this.schoolService.get(this.urlName ?? this.schoolId);
      this.staffList = await this.schoolService.getFacultyList(this.school.urlName);
      this.studentList = await this.schoolService.getStudentList(this.school.urlName);
      this.isLoading += 25;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = 0;
    }

    if (!this.school) {
      this.router.navigate(['/404']);
    }
  }
  
}
