import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactForm } from '../models/contactForm.model';
import { ApiConfig } from '../api/api.config';

@Injectable()
export class ContactUsService {

    constructor(public http: HttpClient) { }

    public post(formData: ContactForm): Observable<ContactForm> {
        return this.http.post<ContactForm>(ApiConfig.getFullUrl('contactUs'), {
            name: formData.name,
            email: formData.email,
            message: formData.message,
        });
    }
}
