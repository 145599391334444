import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class JobDescriptionApiConfig {
    static path: string = environment.api.jobdescription.path;
    static version: string | number = environment.api.jobdescription.version;

    public static setApiVersion(version: string | undefined = 'api'): void {
        JobDescriptionApiConfig.version = version;
    }

    public static getApiVersion(): string | number {
        return JobDescriptionApiConfig.version;
    }

    public static getPath(): string {
        return JobDescriptionApiConfig.path;
    }

    public static getFullUrl(path): string {
        if (JobDescriptionApiConfig.getApiVersion()) {
            return [JobDescriptionApiConfig.getPath(), JobDescriptionApiConfig.getApiVersion(), path].join('/');
        } else {
            return [JobDescriptionApiConfig.getPath(), path].join('/');
        }
    }
}
