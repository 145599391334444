import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { SubscriptionOptions } from '../shared/models/subscriptionOptions.model';
import { Employer } from '../shared/models/employer.model';
import { ToastService } from '../shared/services/toast.service';
import { ModalService } from '../shared/services';

@Component({
  selector: 'app-stripe',
  styleUrls: ['./stripe.component.scss'],
  templateUrl: './stripe.component.html',
})
export class StripeComponent {
  @Input()
  get employer(): Employer {
    return this.emp;
  }
  set employer(value: Employer) {
    this.emp = value;
    if (this.emp) {
      this.loadPlans();
    }
  }

  @Output()
  public subscribed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public canceled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  emp: Employer;
  stripePlans: SubscriptionOptions[] = [];

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  // elementsOptions: StripeElementsOptions = {
  //   locale: 'en'
  // };

  stripeTest: FormGroup;
  formGroup: FormGroup;
  working = false;

  constructor(
    // private stripeService: StripeService,
    private subscriptionService: SubscriptionService,
    private snackbar: ToastService,
    private modals: ModalService,
    ) {}

  async loadPlans(): Promise<void> {
    const plans = await this.subscriptionService.getSubscriptionDetails();
    this.stripePlans = plans.filter(p => p.name.startsWith(this.employer?.size));
    this.createFormGroup();
  }

  private createFormGroup(): void {
    this.formGroup = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      addressLineOne: new FormControl('', [Validators.required, Validators.min(5)]),
      addressLineTwo: new FormControl('', ),
      city: new FormControl('', [Validators.required, Validators.min(5)]),
      state: new FormControl('', [Validators.required, Validators.min(2)]),
      zip: new FormControl('', [Validators.required, Validators.min(5)]),
      plan: new FormControl('', [Validators.required]),
    });
  }

  async submit(): Promise<void> {
    this.working = true;
    // const name = `${this.formGroup.get('firstName').value} ${this.formGroup.get('lastName').value}`;
    // const addressLineOne = this.formGroup.get('addressLineOne').value;
    // const addressLineTwo = this.formGroup.get('addressLineTwo').value;
    // const city = this.formGroup.get('city').value;
    // const state = this.formGroup.get('state').value;
    // const zip = this.formGroup.get('zip').value;
    // const planId = +this.formGroup.get('plan').value;

    // const dataForToken = {
    //   name,
    //   address_line1: addressLineOne,
    //   address_line2: addressLineTwo,
    //   address_city: city,
    //   address_state: state,
    //   address_zip: zip
    // };

    // const result = await this.stripeService.createToken(this.card.element, dataForToken).toPromise();
    // try {
    //   if (result.token) {
    //       // Use the token
    //       // console.log('STRIPE TOKEN', result.token.id);
    //       const subscribeData = {
    //         name,
    //         addressLineOne,
    //         addressLineTwo,
    //         city,
    //         state,
    //         zip,
    //         planId,
    //         stripeToken: result.token.id,
    //         phone: this.formGroup.get('phone').value,
    //         email: this.formGroup.get('email').value,
    //         employerId: this.employer.id,
    //       };
    //       await this.subscriptionService.postCustomerDetails(subscribeData).toPromise();
    //       this.snackbar.success({
    //         message: 'You have subscribed!'
    //       });
    //       this.subscribed.next(true);
    //   } else if (result.error) {
    //       // Error creating the token
    //       this.snackbar.error({
    //         message: result.error.message
    //       });
    //   }
    // } catch (error) {
    //   this.snackbar.error({
    //     message: error.message
    //   });
    // } finally {
    //   this.working = false;
    // }
      this.working = false;
  }

  open(id: string): void {
    this.modals.closeAll();
    this.modals.open(id);
  }
  close(id: string): void {
    this.modals.close(id);
    this.closed.next(true);
  }

  async submitCancellation(): Promise<void> {
    try {
      this.working = true;
      await this.subscriptionService.cancelSubscription(this.employer.id);
      this.canceled.emit(true);
    } catch (error) {
      this.snackbar.error({
        message: error.message,
      });
    } finally {
      this.working = false;
    }
  }
}
