import { Component, Input } from "@angular/core";

@Component({
  selector: "app-left-image",
  templateUrl: "./left-image.component.html",
  styleUrl: "./left-image.component.scss",
})
export class LeftImageComponent {
  @Input()
  image: string;
  @Input()
  imageAlt: string;
  @Input()
  stickerText: string = null;
  @Input()
  stickerColor: string = "white";
}
