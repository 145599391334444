<app-banner>
    <h1 heading>Unext <span class="accent">connects</span> students to first-hand <span class="accent">experiences</span></h1>
    <p paragraph>
        Our mission is to help college students learn about careers that relate to their major.
        We connect them to first-hand experiences such as job shadows, internships, jobs, and other opportunities.
    </p>
    <img image alt="student at job interview" src="/assets/images/student-2.jpeg" />
</app-banner>

<section class="bg-dark-green text-center">
    <h2 class="sans-serif-bold">UNCOVERING THE NEXT GENERATION OF INDUSTRY LEADERS</h2>
    <p>
        We help college students get connected to short-term and long-term opportunities in the professional world. 
        Our mission is to help college students learn about careers that relate to their major.
    </p>

        <h3>Sign up for an account to get started.</h3>

        <div class="arrow down">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512">
                <path fill="currentColor" d="M390.624 150.625L256 16L121.376 150.625l22.628 22.627l95.997-95.998v417.982h32V77.257l95.995 95.995z" />
            </svg>
        </div>

    <button class="button light-blue outline" [routerLink]="['/dashboard']">Get Started</button>
</section>

<section class="benefits bg-cream text-center">
    <h2 class="sans-serif-bold">Unext Improves Graduate Placement and Turnover Rates</h2>
    <div class="row">
        <div class="col text-left">
            <span class="metric">1+</span>
            <span class="label">
                College students change their major at least once
            </span>
        </div>
        <div class="col text-left">
            <span class="metric">50%+</span>
            <span class="label">
                Americans think their job is unrewarding.
            </span>
        </div>
        <div class="col text-left">
            <span class="metric">
                43%+
            </span>
            <span class="label">
                Graduates are underemployed for their first job
            </span>
        </div>
    </div>
</section>

<app-left-image image="../../assets/images/connections.jpg" imageAlt="connections and networking" stickerColor="purple" stickerText="Connections">
    <h3>
        Discover Career Paths Through Unext Connections
    </h3>
    <p>
        Unext helps students explore their field of interest, boosting confidence in career choices by connecting them with both small and large employers. 
        These experiences help match students with companies that align with their personality and goals.
    </p>
</app-left-image>

<app-contact-section class="bg-blue">
    <h2 class="sans-serif-bold" title>HAVE ANY QUESTIONS? CONTACT US OR REQUEST A DEMO.</h2>
    <p paragraph>Unext is dedicated to providing a platform for career exploration and preparation by providing students with comprehensive insights, practical experiences.</p>
    <h3 subheading>Sign up for an account</h3>
    <button class="button light-blue outline" [routerLink]="['/contact-us']" buttons>Contact Us</button>
    <button class="button light-blue outline" [routerLink]="['/request-demo']" buttons>Request Demo</button>
</app-contact-section>

<!-- 
<div class="container standard-padding">
    <section class="heading single-row">
        <div class="text text-black xl">
            About
        </div>
        <img alt="Unext" title="Unext" [src]="cdn.toCDN('images/logos/unext-overlap.png')"
            [srcset]="cdn.toCDN('images/logos/unext-overlap@2x.png 2x') + ', ' + cdn.toCDN('images/logos/unext-overlap@3x.png 3x')" />
    </section>
    <section class="single-row">
        <div class="headline">
            <p class="text">
                
            </p>
            <p class="text" style="font-size: 18px">
                We connect them to first-hand experiences such as job shadows, internships, jobs, and other opportunities.
            </p>
        </div>
        <img class="rocket" [src]="cdn.toCDN('images/svg/rocket.svg')" />
    </section>
    <section class="single-row">
        <div class="stat-box">
            <div class="text text-green-muted">75%</div>
            <div>
                <img [src]="cdn.toCDN('images/svg/graduation-cap.svg')">
                <img [src]="cdn.toCDN('images/svg/graduation-cap.svg')">
                <img [src]="cdn.toCDN('images/svg/graduation-cap.svg')">
                <img [src]="cdn.toCDN('images/svg/graduation-cap-grey.svg')">
            </div>
            <div class="text">
                of college students change their major at least <span class="text text-green text-upper">once.</span>
            </div>
        </div>
        <div class="stat-box">
            <div class="text text-green-muted">50-80%</div>
            <div>
                <img [src]="cdn.toCDN('images/svg/frowny-face.svg')">
            </div>
            <div class="text">
                of Americans think their job is <span class="text text-green text-upper">unrewarding.</span>
            </div>
        </div>
        <div class="stat-box">
            <div class="text">out of all college graduates</div>
            <div class="text text-green-muted">43%</div>
            <div class="text">
                are <span class="text text-green text-upper">underemployed</span> for their first job out of school.
            </div>
            <div class="text" style="font-size: 18px">
                This means they are overqualified for the position they hold.
            </div>
        </div>
    </section>
    <section>
        <p class="text">
            
        </p>
        <p class="text">
            We offer every student an equal opportunity to learn about careers and majors through these first-hand experiences.
        </p>
        <p class="text">
            This helps students gain a better understanding about their major/field of interest before committing to an
            internship or job. Ultimately, these connections and experiences students have will boost their confidence
            when pursuing a major, increasing the probability that students will be satisfied with their careers after
            college. Unext aims to increase the amount of connections between students and employers and help employers
            recruit/identify talent that will best fit their company. Not every student wants to work for a major
            corporation, so students will be able to connect with small and large employers on our platform. Students
            will learn about different company cultures to help decide which businesses will match their personality.
            Explore as many environments as possible through Unext!
        </p>
    </section>

</div> -->
