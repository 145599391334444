import { Component, Input } from '@angular/core';
import { CDNService } from '../../services/cdn.service';

@Component({
  selector: 'app-separator',
  templateUrl: './separator.component.html',
  styleUrls: ['./separator.component.scss']
})
export class SeparatorComponent {

  @Input()
  public showLogo = true;
  @Input()
  public showBar = true;
  @Input()
  public showOr = false;

  constructor(public cdn: CDNService) {

  }
}
