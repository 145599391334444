import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class OpportunityApiConfig {
    static path: string = environment.api.opportunity.path;
    static version: string | number = environment.api.opportunity.version;

    public static setApiVersion(version: string | undefined = 'api'): void {
        OpportunityApiConfig.version = version;
    }

    public static getApiVersion(): string | number {
        return OpportunityApiConfig.version;
    }

    public static getPath(): string {
        return OpportunityApiConfig.path;
    }

    public static getFullUrl(path): string {
        if (OpportunityApiConfig.getApiVersion()) {
            return [OpportunityApiConfig.getPath(), OpportunityApiConfig.getApiVersion(), path].join('/');
        } else {
            return [OpportunityApiConfig.getPath(), path].join('/');
        }
    }
}
