import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { JobDescriptionApiConfig } from '../api';
import { JobDescription } from '../models';

@Injectable()
export class JobDescriptionService {
    constructor(private http: HttpClient) { }

    public get(id: number): Promise<JobDescription> {
        return lastValueFrom(this.http.get<JobDescription>(JobDescriptionApiConfig.getFullUrl(`${id}`)));
    }
    public create(model: JobDescription): Promise<JobDescription> {
        return lastValueFrom(this.http.put<JobDescription>(JobDescriptionApiConfig.getFullUrl(''), model));
    }

    public update(model: JobDescription): Promise<JobDescription> {
        return lastValueFrom(this.http.post<JobDescription>(JobDescriptionApiConfig.getFullUrl(`${model.id}`), model));
    }
}
