import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../models';


@Pipe({
    name: 'addressCityName'
})
export class AddressCityNamePipe implements PipeTransform {
    transform(value: Address): string {
        if (value != null) {
            return `${value.city.name}, ${value.city.state.name}`;
        }
    }
}

@Pipe({
    name: 'addressFullName'
})
export class AddressFullNamePipe implements PipeTransform {
    transform(value: Address): string {
        if (value != null) {
            return `${value.address1} ${value.city.name} ${value.city.state.name}, ${value.zipCode}`;
        }
    }
}
