<div class="wrapper desktop">
  <ol class="stepper">
    <li class="stepper-item mb-medium" [ngClass]="{'active': activeStep === i}" *ngFor="let step of steps; let i = index" 
        (click)="activeStep = i">
      <div class="stepper-bubble bg-lime mr-small">
        <div class="mx-auto my-auto text text-title text-teal">{{i + 1}}</div>
      </div>
      <h3 class="stepper-title text-subtitle text-no-wrap text mt-auto mb-auto" [ngClass]="{ 'text-white': activeStep !== i, 'text-lime': activeStep === i }">
        {{step.title}} 
      </h3>    
    </li>
  </ol>
</div>


<div class="wrapper text mobile">
  <h3>{{activeStep + 1}} / {{steps.length}} {{steps[activeStep].title}}</h3>
</div>
