import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { FilterOption, Opportunity, OpportunityType } from 'src/app/shared/models';
import { Employer } from 'src/app/shared/models/employer.model';
import { User } from 'src/app/shared/models/user.model';
import { ArraySortPipe } from 'src/app/shared/pipes';
import { ModalService, OpportunityService, UserService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { EmployerService } from 'src/app/shared/services/employer.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-employer-profile',
  templateUrl: './employer-profile.component.html',
  styleUrls: ['./employer-profile.component.scss']
})
export class EmployerProfileComponent implements OnInit {

  @Input()
  id: number;
  @Input()
  employer: Employer;
  @Input()
  user: User;

  urlName: string;
  isLoading = 0;
  opportunityTypeList: DropDownOptions[];
  requestedOpportunities: Opportunity[] = [];
  employerOpportunities: Opportunity[] = [];
  selectedOpportunityType: any = null;

  opportunityFilterDropDownOptions: FilterOption[] = [];
  opportunityList: any[] = [];
  modifiedOpportunitiesList: Opportunity[] = [];
  filterActive: boolean = false;
  opportunityPageNumber: number = 1;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private users: UserService,
    private employers: EmployerService,
    public cdn: CDNService,
    private snackbar: ToastService,
    private modals: ModalService,
    private oppService: OpportunityService,
    private messageService: MessageService
  ) {
    this.urlName = this.route.snapshot.params.urlName;

    if (!this.user) {
      this.user = this.users.authenticatedUser;
    }
    if (!this.user) {
      this.user = this.users.authenticatedUser;
    }
  }

  async ngOnInit() {
    await this.loadEmployer();
    this.opportunityTypeList = this.oppService.getOpportunityTypesDropdownValues();
    console.log(this.opportunityTypeList);
    this.employerOpportunities = await this.oppService.getEmployerOpportunities(this.employer?.urlName,
      true, true, 'name', null, null, null, null, null);

    this.sortOpportunities(this.employerOpportunities);
    this.createFilterOptions();
    this.createFilterObjects();
  }

  sortOpportunities(oppList: Opportunity[]) {
    this.modifiedOpportunitiesList = oppList.sort((a, b) => {
      if (a.opportunityType === 'Shadow' && b.opportunityType !== 'Shadow') {
        return -1; // a comes before b
      } else if (a.opportunityType !== 'Shadow' && b.opportunityType === 'Shadow') {
        return 1; // b comes before a
      } else {
        return 0; // maintain original order
      }
    });
  }

  createFilterOptions() {
    const locations = [...new Set(this.employerOpportunities
      .flatMap(s => s.locations))]
      .sort();

    const interests = [...new Set(this.employerOpportunities
      .filter(a => a.interests.name).flatMap(s => s.interests.name))]
      .sort();


    const types = this.oppService.getOpportunityTypes().map(a => a.name);

    if (locations.length) {
      this.opportunityFilterDropDownOptions.push({ id: 1, label: 'Location', active: false, name: 'location', options: locations });
    }
    if (interests.length) {
      this.opportunityFilterDropDownOptions.push({ id: 3, label: 'Field Offered', active: false, name: 'interest', options: interests });
    }

    this.opportunityFilterDropDownOptions.push({ id: 5, label: 'Opportunity Type', active: false, name: 'type', options: types });
  }

  createFilterObjects() {
    this.employerOpportunities.forEach(o => {
      const opp = {
        id: o.id,
        location: o.locations,
        interest: [...new Set(o.interests.flatMap(s => s.name))].sort(),
        type: o.opportunityType
      }

      this.opportunityList.push(opp);
    });
  }

  updateOpportunityList(data: { list: any[], sort: { value: string, asc: boolean }, search: string, reset: boolean }) {

    if (data.list.length) {
      this.modifiedOpportunitiesList = [];

      this.modifiedOpportunitiesList = new ArraySortPipe().transform(
        this.employerOpportunities.filter(a => data.list.some(i => i.id === a.id)),
        data.sort.value,
        data.sort.asc
      );
    }

    this.sortOpportunities(this.modifiedOpportunitiesList);


  }


  chipColor(opportunity: Opportunity): string {
    let color: string;

    switch (opportunity?.opportunityTypeId) {
      case OpportunityType.Internship:
        color = 'orange';
        break;
      case OpportunityType.Employment:
        color = 'purple';
        break;
      case OpportunityType.Volunteering:
        color = 'green';
        break;
      case OpportunityType.Other:
        color = 'gray';
        break;
      case OpportunityType.CoOp:
        color = 'green-muted';
        break;
      case OpportunityType.JobShadowing:
      default:
        color = 'blue';
        break;
    }

    return color;
  }

  async loadEmployer() {
    try {
      this.isLoading = 25;
      this.employer = await this.employers.getEmployer(this.urlName);
      this.isLoading += 25;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = 0;
    }

    if (!this.employer) {
      this.router.navigate(['/404']);
    }
  }

  async requestShadowing(): Promise<void> {
    try {
      this.isLoading = 35;
      await this.employers.requestShadow(this.employer);
      this.snackbar.success({
        message: `We've let ${this.employer.name} know you're interested in job shadowing.`
      });
    } catch (error) {
      this.snackbar.error({
        message: 'There was a problem setting up shadowing, please try again.'
      });
      console.error(error);
    } finally {
      this.isLoading = 0;
    }
  }

  openModal(e: string) {
    this.modals.open(e);
  }

  findOpportunityTypes(e: any) {
    this.selectedOpportunityType = e;
    this.requestedOpportunities = [];
    this.requestedOpportunities = this.employerOpportunities.filter(o => o.opportunityTypeId == e.value)
  }


}

