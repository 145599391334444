<section id="dashboard" class="standard-padding">
<div id="user-actions" class="flex-columns justify-between mb-small">
    <div class="rounded-corners">
        <span class="text text-500 text-center">Upload Attachments</span>
        <a class="edit-profile button dark-green outline mt-small mb-small" (click)="openModal('uploadFile')">Upload</a>
    </div>
    <ng-template ngFor let-a [ngForOf]="school?.attachments">
        <div class="rounded-corners">
            <div>{{ a.id }}</div>
            <a target="_blank" [href]="a.attachmentURL">Open</a>
        </div>
    </ng-template>
</div>
</section>

<app-modal id="uploadFile" heading="Upload a File">
    <app-upload acceptType="resume" [entityType]="entityType" [entityId]="user?.staff.id"
        (filesUploaded)="imageUploaded($event)" (filesSelected)="imageSelected($event)">
    </app-upload>
</app-modal>
