import { Component } from '@angular/core';
import { GTMComponentComponent } from 'src/app/shared/components/gtmcomponent/gtmcomponent.component';

@Component({
  selector: 'app-next-step-career-readiness',
  standalone: false,
  templateUrl: './next-step-career-readiness.component.html',
  styleUrl: './next-step-career-readiness.component.scss'
})
export class NextStepCareerReadinessComponent extends GTMComponentComponent{

}
