import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '@auth0/auth0-angular';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { Employer, Opportunity, StudentOpportunity, Student, UNextResume, StudentOpportunityApplication, InviteMessage } from 'src/app/shared/models';
import { FieldOfInterest } from 'src/app/shared/models/fieldOfInterst.model';
import { ArraySortPipe } from 'src/app/shared/pipes';
import { UserService, EmployerService, FieldOfInterestService, OpportunityService, ResumeService, StudentService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-student-opportunity-application',
  templateUrl: './student-opportunity-application.component.html',
  styleUrl: './student-opportunity-application.component.scss'
})
export class StudentOpportunityApplicationComponent implements OnInit, OnChanges {


  working = 0;
  @Input()
  employer: Employer;

  selectedEmployer: Employer;
  employers: Employer[] = [];
  user: User;
  employerPageNumber: number;

  @Input()
  opportunity: Opportunity;

  studentOpportunity: StudentOpportunity;
  applicationFormGroup!: FormGroup;
  inviteForm: FormGroup;

  @Input()
  student: Student;
  resumes: UNextResume[] = [];
  resume: UNextResume;
  resumeList: DropDownOptions[] = [];
  opportunityTypeList: any;
  timeList: string[] = ['ASAP', 'Summertime', 'Winter Time'];
  inviteFriend: boolean = false;

  allInterests: FieldOfInterest[] = [];
  interestList: DropDownOptions[] = [];
  oppInterests: any[] = [];
  studentInterests: any[] = [];
  combinedInterests: any[] = [];


  constructor(
    private users: UserService,
    private snackbar: ToastService,
    private employerService: EmployerService,
    private interestService: FieldOfInterestService,
    private fb: FormBuilder,
    public cdn: CDNService,
    private resumeService: ResumeService,
    private studentService: StudentService,
    private opportunityService: OpportunityService,
    private messageService: MessageService

  ) {
    this.user = this.users.authenticatedUser;
  }

  async ngOnInit() {
    this.applicationFormGroup = this.createApplicationForm();
    this.employers = await this.employerService.getEmployers();
    this.student = await this.studentService.getStudent(this.user?.student?.urlName)
    this.resumes = await this.resumeService.getResumes();
    this.resumes = new ArraySortPipe().transform(this.resumes, 'modified', false);
    this.resumeList = this.resumes.map(x => { return { value: x.id, label: x.name } });
    this.opportunityTypeList = this.opportunityService.getOpportunityTypesDropdownValues();
    this.allInterests = (await this.interestService.get());
    this.interestList = this.allInterests
      .map(s => ({
        label: s.name,
        value: s.id
      }));
  }

  ngOnChanges() {
    this.inviteFriend = false;
    if (this.opportunity !== null) {
      this.findMatchingObjects();
    }
  }

  async loadEmployers(): Promise<void> {
    this.working = 0;
    try {
      this.working += 10;
      this.employers = await this.employerService.getEmployers();
    } catch (error) {
      this.snackbar.error({
        message: 'There was a problem loading your company information',
      });
    } finally {
      this.working = 0;
    }
  }

  createApplicationForm(): FormGroup {
    const fg = this.fb.group({
      AvailableTimes: new FormControl(this.opportunity?.startDate, [Validators.required]),
      OpportunityType: new FormControl(this.opportunity?.opportunityTypeId),
      ResumeId: new FormControl(),
      FieldOfInterests: new FormControl(),
      Invite: new FormControl(),
      Overview: new FormControl(this.opportunity?.description, [Validators.required]),
      Times: new FormControl(),
    });
    return fg;
  }

  createInviteForm() {
    this.findMatchingObjects();
    this.inviteFriend = true;

    this.inviteForm = this.fb.group({
      Name: new FormControl(),
      Email: new FormControl(),
    });
  }

  get resumeId(): FormControl {
    return this.applicationFormGroup.get('ResumeId') as FormControl;
  }
  get fieldOfInterests(): FormControl {
    return this.applicationFormGroup.get('FieldOfInterests') as FormControl;
  }
  get availableTimes(): FormControl {
    return this.applicationFormGroup.get('AvailableTimes') as FormControl;
  }
  get overview(): FormControl {
    return this.applicationFormGroup.get('Overview') as FormControl;
  }
  get invite(): FormControl {
    return this.applicationFormGroup.get('Invite') as FormControl;
  }

  get times(): FormControl {
    return this.applicationFormGroup.get('Times') as FormControl;
  }

  get name(): FormControl {
    return this.inviteForm.get('Name') as FormControl;
  }

  get email(): FormControl {
    return this.inviteForm.get('Email') as FormControl;
  }

  findMatchingObjects() {
    this.oppInterests = this.opportunity?.interests;
    this.studentInterests = this.student.fieldsOfInterest;
    this.oppInterests.forEach(i => {
      const opp = this.studentInterests.find(a => a.id == i.id);
      if (opp !== undefined) {
        this.combinedInterests.push(opp);
      }
    });
  }

  addInterest(e: any): void {
    const interest = this.combinedInterests.find(i => i.id == e.value)
    if (interest == undefined) {
      this.combinedInterests.push(this.allInterests.find(i => i.id == e.value));
    }
  }

  removeInterest(e: any): void {
    this.combinedInterests = this.combinedInterests.filter(
      interest => interest.id !== e.id
    );
  }

  // Send appliction object with studentOppotunity and interest list
  async apply() {
    try {
      const studentOpp: StudentOpportunity = {
        id: 0,
        applied: true,
        appliedDate: new Date(),
        accepted: false,
        acceptedDate: null,
        rejected: false,
        rejectedDate: null,
        completed: false,
        score: 0,
        studentInterested: true,
        employerInterested: false,
        resumeId: this.resumeId.value.value,
        availableTimes: this.times.value + " " + this.availableTimes.value

      };
      const application: StudentOpportunityApplication = {
        resumeId: 0,
        opportunityInterests: this.combinedInterests,
        studentOpportunity: studentOpp

      }
      await this.studentService.applyStudent(this.student.urlName, this.opportunity.id, application);
      this.snackbar.success({
        message: 'Your request has been sent. You will be notified shortly via your preferred contact method when the opportunity is scheduled.',
      });
    } catch (error) {
      this.snackbar.error({
        message: 'There was a problem sending your application',
      });
    }
  }

  // Invite Logic
  async sendInvite() {
    const message: InviteMessage = {
      name: this.name.value,
      email: this.email.value,
      link: ''
    }
    await this.messageService.inviteToApply(message);
    this.inviteFriend = false;
  }

  cancelInvite() {
    this.inviteFriend = false;
  }

}
