import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sort',
    pure: false,
})
export class ArraySortPipe implements PipeTransform {
    transform(array: any, field: string, asc: any): any[] {
        if (!Array.isArray(array) || !array.length) {
            return;
        }
        array.sort((a: any, b: any) => {
            if (typeof a === 'string' && typeof b === 'string') {
                if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                } else if (a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                if ((typeof a[field] === 'string' ? a[field].toLowerCase() : a[field])
                    < (typeof b[field] === 'string' ? b[field].toLowerCase() : b[field])) {
                    return -1;
                } else if ((typeof a[field] === 'string' ? a[field].toLowerCase() : a[field])
                    > (typeof b[field] === 'string' ? b[field].toLowerCase() : b[field])) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
        return asc ? array : array.reverse();
    }
}

@Pipe({
    name: 'doublesort',
    pure: false,
})
export class ArrayDoubleSortPipe implements PipeTransform {
    transform(array: any, field: string, asc: true | false = true, field2: string, asc2: true | false = true): any[] {
        if (!Array.isArray(array) || !array.length) {
            return;
        }
        array.sort((a: any, b: any) => {
            if ((typeof a[field] === 'string' ? a[field].toLowerCase() : a[field])
                < (typeof b[field] === 'string' ? b[field].toLowerCase() : b[field])) {
                return asc ? -1 : 1;
            } else if ((typeof a[field] === 'string' ? a[field].toLowerCase() : a[field])
                > (typeof b[field] === 'string' ? b[field].toLowerCase() : b[field])) {
                return asc ? 1 : -1;
            } else {
                if ((typeof a[field2] === 'string' ? a[field2].toLowerCase() : a[field2])
                    < (typeof b[field2] === 'string' ? b[field2].toLowerCase() : b[field2])) {
                    return asc2 ? -1 : 1;
                } else if ((typeof a[field2] === 'string' ? a[field2].toLowerCase() : a[field2])
                    > (typeof b[field2] === 'string' ? b[field2].toLowerCase() : b[field2])) {
                    return asc2 ? 1 : -1;
                } else {
                    return 0;
                }
            }
        });
        return array;
    }
}


@Pipe({
    name: 'matchSort'
  })
    export class ArrayMatchSortPipe implements PipeTransform {
        transform(array: string[], matchValue: string): string[] {
        if (!Array.isArray(array) || !array.length) {
            return [];
        }
    
        return array.sort((a, b) => {
            const similarityA = this.calculateSimilarity(a, matchValue);
            const similarityB = this.calculateSimilarity(b, matchValue);
    
            if (a.toLowerCase() === matchValue.toLowerCase() || b.toLowerCase() === matchValue.toLowerCase()) {
                return a.toLowerCase() === matchValue.toLowerCase() ? -1 : 1; // Exact matches come first
            }
    
            if (similarityA !== similarityB) {
                return similarityB - similarityA; // Sort by similarity first
            }
    
            const commonStartLengthA = this.calculateMatchStartLength(a, matchValue);
            const commonStartLengthB = this.calculateMatchStartLength(b, matchValue);
    
            if (commonStartLengthA !== commonStartLengthB) {
                return commonStartLengthB - commonStartLengthA; // Sort by common prefix length
            }
    
            return a.localeCompare(b); // If all else fails, sort alphabetically
        });
    }

    private calculateSimilarity(str: string, matchValue: string): number {
        // Convert input strings to lowercase for case-insensitive comparison
        const strLower = str.toLowerCase();
        const targetWordLower = matchValue.toLowerCase();
    
        let similarity = 0;           // Overall similarity between strings
        let currentSimilarity = 0;    // Current consecutive similarity
    
        for (let i = 0; i < strLower.length; i++) {
            // Check if the current character matches the corresponding character in the target value
            if (strLower[i] === targetWordLower[currentSimilarity]) {
                // Increment the current similarity count and update the overall similarity
                currentSimilarity++;
                similarity = Math.max(similarity, currentSimilarity);
            } else {
                // Reset the current similarity count if characters don't match
                currentSimilarity = 0;
            }
        }
    
        // Return the calculated similarity value
        return similarity;
    }
  
    // Checks the strings match from the start of each string and how long the match is. 
    // The better the match, the higher we will sort the string
    private calculateMatchStartLength(str: string, matchValue: string): number {
        const strLower = str.toLowerCase();
        const targetWordLower = matchValue.toLowerCase();
    
        let matchStartLength = 0;
    
        for (let i = 0; i < strLower.length && i < targetWordLower.length; i++) {
            if (strLower[i] === targetWordLower[i]) {
                matchStartLength++;
            } else {
                break;
            }
        }
    
        return matchStartLength;
    }
  }