import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SubscriptionApiConfig } from '../api';
import { SubscriptionSummary } from '../models';
import { skipAuthHeaders } from '../auth/auth-interceptor';

@Injectable()
export class SubscriptionService {

    constructor(public http: HttpClient) {
    }

    public postCustomerDetails(formData: any): Promise<any> {
        // debugger;
        return lastValueFrom(this.http.post<any>(SubscriptionApiConfig.getFullUrl('customerdetails'), {
            ...formData
        }));
    }

    public getSubscriptionDetails(): Promise<Array<any>> {
        return lastValueFrom(this.http.get<Array<any>>(SubscriptionApiConfig.getFullUrl('subscriptionoptions'), { headers: skipAuthHeaders }));
    }

    public cancelSubscription(employerId: number): Promise<any> {
        return lastValueFrom(this.http.post<any>(SubscriptionApiConfig.getFullUrl(`cancelsubscription/${employerId}`), {
            employerId
        }));
    }

    public getSubscriptionOptions(): Promise<Array<SubscriptionSummary>> {
        return lastValueFrom(this.http.get<Array<SubscriptionSummary>>(SubscriptionApiConfig.getFullUrl('pricingsummary'), { headers: skipAuthHeaders }));
    }
}
