import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Week } from '../../models/week.model';
import { CalendarService } from '../../services/calendar.service';

@Component({
  selector: 'app-week-scheduler',
  templateUrl: './week-scheduler.component.html',
  styleUrl: './week-scheduler.component.scss'
})
export class WeekSchedulerComponent implements OnInit {

  @Input() dateFormat: boolean = false;

  @Input() unavailableDays: string[] = [];

  @Input() week: Week;

  @Output()
  emitWeek: EventEmitter<boolean> = new EventEmitter<boolean>();

  daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  fullDayHours = '0'.repeat(24); // A string of 24 zeros
  availableHoursOfDay = Array.from({ length: 9 }, (_, i) => i + 9); // 9 to 17 (9am to 5pm)
  availabilityOptions = [{ value: 1, label: 'Available All Day' }, { value: 2, label: 'Unavailable All Day' }, { value: 3, label: 'Set Custom Availability' }]

  scheduleForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private calendar: CalendarService) {

  }
  ngOnInit(): void {
    this.createForm(this.week);

    const unavailableDays = this.daysOfWeek.filter(day => this.unavailableDays.includes(day));

    const availableDays = this.daysOfWeek.filter(day => !this.unavailableDays.includes(day));


    if(this.week == undefined){

      //Handle unavailable days
      unavailableDays.forEach(day => {
        this.setAvailability('Unavailable All Day', day);
        const controlName = this.getAvailabilityDropdownName(day);
        const control = this.scheduleForm.get(controlName);
        control.setValue('Unavailable All Day');
      });

      // Handle available days 
      availableDays.forEach(day => {
          this.setAvailability('Available All Day', day);
          const controlName = this.getAvailabilityDropdownName(day);
          const control = this.scheduleForm.get(controlName);
          control.setValue('Available All Day');
      
      });
    }

  }

  createForm(availability: Week) {
    this.scheduleForm = this.fb.group({
      Id: new FormControl(),
    });
    if (this.week) {
      for (const day of this.daysOfWeek) {
        this.scheduleForm.addControl(this.getAvailabilityDropdownName(day), new FormControl());
        const availabilityString = availability[day.toLowerCase()];
  
        let allUnchecked = true;
  
        for (let i = 0; i < availabilityString.length; i++) {
          const char = availabilityString.charAt(i);
          const isChecked = char === '1';
  
          if (isChecked) {
            allUnchecked = false; 
          }
          this.scheduleForm.addControl(this.getAvailabilityCheckboxName(day, i), new FormControl(isChecked));
        }
  
        if (allUnchecked) {
          this.setAvailability('Unavailable All Day', day);
          const controlName = this.getAvailabilityDropdownName(day);
          const control = this.scheduleForm.get(controlName);
          control?.setValue('Unavailable All Day');
        } else {
          this.setAvailability('Set Custom Availability', day); 
          const controlName = this.getAvailabilityDropdownName(day);
          const control = this.scheduleForm.get(controlName);
          control?.setValue('Set Custom Availability'); 
        }
      }
    } else {
      for (const day of this.daysOfWeek) {
        this.scheduleForm.addControl(this.getAvailabilityDropdownName(day), new FormControl());
        for (const hour of this.availableHoursOfDay) {
          this.scheduleForm.addControl(this.getAvailabilityCheckboxName(day, hour), new FormControl(false));
        }
      }
    }
  }

  getAvailabilityCheckboxName(day: string, hour: number) {
    return `${day}_${hour}`; // Unique control name
  }

  getAvailabilityDropdownName(day: string) {
    return `${day}_availability`; // Unique control name
  }

  setAvailability(availability: string, day: string) {
    this.availableHoursOfDay.forEach(hour => {
      const controlName = this.getAvailabilityCheckboxName(day, hour);
      const control = this.scheduleForm.get(controlName);

      if (control) {
        if (availability == 'Available All Day') {
          control.setValue(true);
          control.disable();
        } else if (availability == 'Unavailable All Day') {
          control.setValue(false);
          control.disable();
        } else {
          control.enable();
        }

      }
    });
    this.emitWeek.emit();
  }



  getSchedule() {
    const week: Week = {
      sunday: '',
      monday: '',
      tuesday: '',
      wednesday: '',
      thursday: '',
      friday: '',
      saturday: ''
    };
    this.daysOfWeek.forEach(day => {
      const dayAvailability: boolean[] = [];

      this.availableHoursOfDay.forEach(hour => {
        const controlName = this.getAvailabilityCheckboxName(day, hour);
        const control = this.scheduleForm.get(controlName);

        if (control) {
          dayAvailability.push(control.value);
        } else {
          console.warn(`Control not found for ${controlName}`);
          dayAvailability.push(false); // Default to false if control is missing
        }
      });

      // Convert dayAvailability to a string of 1s and 0s
      const dayAvailabilityString = dayAvailability.map(bool => bool ? '1' : '0').join('');
      let updatedHours = this.fullDayHours.slice(0, 9) + dayAvailabilityString + this.fullDayHours.slice(17, 23);

      if(this.dateFormat){
          updatedHours = this.calendar.getAvailabilityString(updatedHours);
      }

      // Assign the dayAvailabilityString to the corresponding property in the request object
      switch (day.toLowerCase()) {
        case 'sunday': week.sunday = updatedHours; break;
        case 'monday': week.monday = updatedHours; break;
        case 'tuesday': week.tuesday = updatedHours; break;
        case 'wednesday': week.wednesday = updatedHours; break;
        case 'thursday': week.thursday = updatedHours; break;
        case 'friday': week.friday = updatedHours; break;
        case 'saturday': week.saturday = updatedHours; break;
      }
    });

    return week;
  }
}
