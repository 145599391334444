<div class="opportunity-list" [ngClass]="{ 'grid': grid }">

    <div *ngIf="!opportunities?.length" class="text mb-small">
        No Opportunities Found...
    </div>
    <ng-template ngFor let-o [ngForOf]="opportunities">
        <app-opportunity-card [opportunity]="o" [student]="student" [employer]="employer" (openApplication)="openApplicationModal($event)" class="mr-small"></app-opportunity-card>
    </ng-template>
   
    
</div>

<app-modal id="applicationModal" [heading]="selectedOpportunity?.title">
    <app-student-opportunity-application [opportunity]="selectedOpportunity" [student]="student" ></app-student-opportunity-application>
</app-modal>