import { Component, OnInit,  } from '@angular/core';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { FilterOption, Student, StudentOpportunity, User } from 'src/app/shared/models';
import { ArraySortPipe } from 'src/app/shared/pipes';
import { OpportunityService, StudentService, UserService } from 'src/app/shared/services';

@Component({
  selector: 'app-student-opportunity-list',
  templateUrl: './student-opportunity-list.component.html',
  styleUrl: './student-opportunity-list.component.scss'
})
export class StudentOpportunityListComponent implements OnInit {


  user: User;
  student: Student;
  opportunities: StudentOpportunity[] = [];
  industries: DropDownOptions[] = [];
  employmentTypes: DropDownOptions[] = [];
 
  loading = 0;

  opportunityFilterDropDownOptions: FilterOption[] = [];
  opportunitySearchOptions: any[] = [];
  opportunityList: any[] = [];
  modifiedOpportunitiesList: StudentOpportunity[] = [];
  approvedOpportunitiesList: StudentOpportunity[] = [];
  completedOpportunitiesList: StudentOpportunity[] = [];
  filterActive: boolean = false;
  opportunityPageNumber: number = 1;

  constructor(
    private users: UserService,
    private students: StudentService,
    private opportunityService: OpportunityService,
  ) {
    if (!this.user) {
      this.user = this.users.authenticatedUser as User;
    }
  
  }

  async ngOnInit() {
    await this.loadOpportunities();
    this.modifiedOpportunitiesList = this.opportunities;
    this.approvedOpportunitiesList = this.opportunities.filter(a => {a.employerInterested == true});
    this.completedOpportunitiesList = this.opportunities.filter(a => {a.completed == true});

    this.createDropDownOptions();
    this.createOpportunityFilterObjects();
  }

  createDropDownOptions() {
    const locations = [...new Set(this.opportunities
      .flatMap(s => s.opportunity.locations))]
      .sort();

    const interests = [...new Set(this.opportunities
      .flatMap(s => s.opportunity.interests.name))]
      .sort();

    const employerNames = [...new Set(this.opportunities
      .map(s => s.opportunity.employerName))]
      .sort();

    const participation: DropDownOptions[] = [new DropDownOptions('Employer Interested', true), new DropDownOptions('Student Interested', true), new DropDownOptions('Applied', true),
      new DropDownOptions('Accepted', true), new DropDownOptions('Completed', true), new DropDownOptions('Rejected', true)];

    const types = this.opportunityService.getOpportunityTypes().map(a => a.name);

    if (locations.length) {
      this.opportunityFilterDropDownOptions.push({ id: 1, label: 'Location', active: false, name: 'location', options: locations });
    }
    if (employerNames.length) {
      this.opportunityFilterDropDownOptions.push({ id: 2, label: 'Employer Name', active: false, name: 'employer', options: employerNames });
    }
    if (interests.length) {
      this.opportunityFilterDropDownOptions.push({ id: 3, label: 'Field Offered', active: false, name: 'interest', options: interests });
    }

    this.opportunityFilterDropDownOptions.push({ id: 4, label: 'Participation Status', active: false, name: 'status', options: participation });
    this.opportunityFilterDropDownOptions.push({ id: 5, label: 'Opportunity Type', active: false, name: 'type', options: types });
  }

  createOpportunityFilterObjects() {
    this.opportunities.forEach(o => {
      const opp = {
        id: o.id,
        location: o.opportunity.locations,
        status: [new DropDownOptions('Employer Interested', o.employerInterested), new DropDownOptions('Student Interested', o.studentInterested), new DropDownOptions('Applied', o.applied),
          new DropDownOptions('Accepted', o.accepted), new DropDownOptions('Completed', o.completed), new DropDownOptions('Rejected', o.rejected)],
        employer: o.opportunity.employerName,
        interest: [...new Set(o.opportunity.interests.flatMap(s => s.name))].sort(),
        type: o.opportunity.opportunityType
      }

      this.opportunityList.push(opp);
    });

  }

  updateOpportunityList(data: { list: any[], sort: { value: string, asc: boolean }, search: string, reset: boolean }) {

    this.filterActive = !data.reset ? true : false;
    this.modifiedOpportunitiesList = [];
    if (data.list.length) {
      this.modifiedOpportunitiesList = new ArraySortPipe().transform(
        this.opportunities.filter(a => data.list.some(i => i.id === a.id)),
        data.sort.value,
        data.sort.asc
      );
    }
  }

  async loadOpportunities() {
    if (this.user) {
      try {
        this.loading = 10;
        this.student = await this.students.getStudent(this.user.student.urlName);
        this.loading = 20;
        
          this.opportunities = await this.students.getOpportunities(
            this.user.student.urlName,
            null,
            null,
            null,
            null,
            null);
          this.loading = 30;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = 0;
      }
    }
  }

}







