import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from '../../services';
import { CDNService } from '../../services/cdn.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, OnDestroy {

  @Input()
  id: string;
  @Input()
  size: 'small' | 'medium-small' | 'medium' | 'medium-large' | 'large' | 'full' = 'full';

  @Input()
  heading: string;

  private element: any;

  constructor(
    private modals: ModalService,
    private el: ElementRef,
    public cdn: CDNService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    document.body.appendChild(this.element);
    this.element.addEventListener('click', (el: any) => {
      if (el.target.className === 'modal' || el.target.className === 'modal-wrapper') {
        this.close();
      }
    });

    this.modals.add(this);
  }

  ngOnDestroy(): void {
    this.modals.remove(this.id);
    this.element.remove();
  }

  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('modal-open');
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('modal-open');
  }

}
