import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'cdn'
})
export class CDNPipe implements PipeTransform {

  transform(text: string | undefined) {
    if (text) {
        const found = environment.cdn.startPath.find(p => text.startsWith(p));

        if (found) {
            return text.replace(found, environment.cdn.replacePath);
        } else {
            return text;
        }

    } else {
      return '';
    }
  }

}
