import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewsletterSignUp } from '../models/newsletterSignUp.model';
import { ApiConfig } from '../api/api.config';

@Injectable()
export class NewsletterService {

    constructor(public http: HttpClient) { }

    public post(formData: NewsletterSignUp): Observable<NewsletterSignUp> {
        return this.http.post<NewsletterSignUp>(ApiConfig.getFullUrl('newsletter'), {
            name: formData.name,
            email: formData.email,
        });
    }
}
