import { Component } from '@angular/core';
import { CDNService } from 'src/app/shared/services/cdn.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {

  constructor(public cdn: CDNService) { }

}
