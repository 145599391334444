<app-progress-bar [splashScreen]="true" color="green" [value]="working">
    <div class="standard-padding">
        <div class="rounded-corners">
            <div class="text mb-medium">
                Check your email to verify your account.
            </div>
            <p class="text text-400">
                If you don't see the verification message in your inbox, please <span class="text text-underline text-green">check your spam folder</span>, or <a class="text text-green text-upper"
                    (click)="resend()">click here</a> to have your verification resent.
            </p>
            <p class="text text-400 mb-large">
                If you have verified your email, please sign out and back in.
            </p>
            <button type="button" class="button orange outline button-slim" (click)="signout()">Sign Out</button>
        </div>
    </div>
</app-progress-bar>