import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {

  @Input()
  splashScreen = false;

  private _value = 0;

  @Input()
  get value(): number {
    return this._value;
  }
  set value(val: number) {
    this._value = val > 100 ? 100 : val < 0 ? 0 : val;
  }

  @Input()
  color: 'green' | 'green-muted' | 'blue' | 'orange' | 'teal' | 'purple' | 'yellow' | 'red';

  @Input()
  showBackground = true;
}
