<div [ngClass]="{ 'open': !this.collapseNav }" class="container nav-header" [ngClass]="{ 'active': user }">
    <app-logo class="mr-4" [loggedIn]="user"></app-logo>
    <nav [ngClass]="{ 'open': !this.collapseNav }" class="ml-large mr-medium">
        <a [routerLink]="['/for-students']" (click)="closeNav()" *ngIf="!user" routerLinkActive="active" class="mx-auto">Students</a>
        <a [routerLink]="['/for-employers']" (click)="closeNav()" *ngIf="!user" routerLinkActive="active" class="mx-auto">Employers</a>
        <a [routerLink]="['/for-institutions']" (click)="closeNav()" *ngIf="!user" routerLinkActive="active" class="mx-auto">Institutions</a>
        <a [routerLink]="['/about-us']" (click)="closeNav()" *ngIf="!user" routerLinkActive="active" class="mx-auto">About</a>
        <a [routerLink]="['/contact-us']" (click)="closeNav()" *ngIf="!user" routerLinkActive="active" class="mx-auto">Contact</a>
        <a [routerLink]="['/dashboard']" (click)="closeNav()" *ngIf="!!user" routerLinkActive="active" class="mx-auto">Dashboard</a>
        <!-- <a [routerLink]="['/employers', user?.employee?.employerUrlName, 'opportunity']" *ngIf="user?.isEmployer" routerLinkActive="active" class="mx-auto">Opportunities</a> -->
        <a [routerLink]="['employers']" (click)="closeNav()" *ngIf="user?.isStudent" routerLinkActive="active" class="mx-auto">
            Opportunities
        </a>
        <!-- <a [routerLink]="['/my-favorites']" *ngIf="user?.isStudent" routerLinkActive="active" class="mx-auto">Favorites</a>-->
        <a [routerLink]="['/my-prospects']" (click)="closeNav()" *ngIf="user?.isEmployer" routerLinkActive="active" class="mx-auto">Prospects</a>
        <a [routerLink]="['/Analytics']" (click)="closeNav()" *ngIf="user?.isEmployer" routerLinkActive="active" class="mx-auto">Analytics</a>
        <a *ngIf="!user" class="button white mx-auto" (click)="closeNav()" [routerLink]="['/request-demo']">Request Demo</a>
        <a *ngIf="!user" class="button lime-green mx-auto" (click)="login()">Login/Register</a>
        <a *ngIf="!!user" class="button mx-auto" (click)="logout()">Sign Out</a>
        <div class="nav-footer text-center">
            ©{{ year }} Unext. All rights reserved.
        </div>
    </nav>
    <!--app-dropdown color="lime" [label]="defaultActionText" [persist]="false" (selected)="navSelection($event)" [actions]="availableNavActions"></app-dropdown-->
    <app-menu #menu (openStateChange)="toggleNav()"></app-menu> <!--(click)="closeNav()"-->
</div>