<app-progress-bar [value]="working" [splashScreen]="true"></app-progress-bar>

<div class="analytics-wrapper" *ngIf="section">
    <form [formGroup]="sectionForm">

        <div class="form edit-section">
            <div class="four-col row">
                <ng-select class="drop-down drop-down-button-blue" [items]="allSkills" placeholder="Search for a skill"
                    [closeOnSelect]="true" (search)="onSearchList('allSkills', $event)" [virtualScroll]="true"
                    (change)="selectedSkill($event)">
                </ng-select>
            </div>
        </div>

        <div class="mb-small flex-columns flex-wrap">
            <ng-template ngFor let-s [ngForOf]="currentResumeSection?.skills | sort: '0':true">
                <div class="mb-small mr-small skills">
                    <div class="sticker sticker-teal sticker-remove" (click)="deselectSkill(s)">
                        <div class="text text-white">
                            {{ s }}
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>

    </form>
    <app-analytics [section]="section" [analytics]="analytics"></app-analytics>
</div>

<div style="display: none;">
    <app-custom-quill #myCustomQuill [initialValue]="currentResumeSection?.description ?? section?.defaultDescription ?? ''">
    </app-custom-quill>
</div>
