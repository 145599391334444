import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { School, Staff } from 'src/app/shared/models';
import { SchoolService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-staff-profile',
  templateUrl: './staff-profile.component.html',
  styleUrl: './staff-profile.component.scss'
})


export class StaffProfileComponent implements OnInit{

  schoolId: number;
  user: User;
  school: School;
  urlName: string;
  schoolName: string;
  departmentName: string;
  staffid: number;
  isLoading = 0;
  staffList: Staff[] = [];

  @Input()
  staff: Staff;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public cdn: CDNService,
    private snackbar: ToastService,
    private schoolService: SchoolService
  ) {
    this.schoolName = this.route.snapshot.params.schoolName;
    this.urlName = this.route.snapshot.params.urlName;
  }

  async ngOnInit() {
    await this.loadStaff();
  }


  async loadStaff() {
    try {
      this.isLoading = 25;
      if(this.schoolName && this.urlName){

        this.staff = await this.schoolService.getStaffByUrlName(this.schoolName, this.urlName);
      }
      this.isLoading += 25;
      this.departmentName = this.staff.school.departments.find(d => d.id === this.staff.schoolDepartmentId)?.departmentName ?? 'no department';

      this.staffList = (await this.schoolService.getFacultyList(this.staff.schoolUrlName)).filter(x => x.id !== this.staff.id);

    } catch (error) {
      this.snackbar.error({
        message: error.message ?? error
      });
    } finally {
      this.isLoading = 0;
    }
  }

  shareProfile() {
    navigator.clipboard.writeText(`${window.location.origin}/school/${this.staff.schoolUrlName}/staff/${this.staff.urlName}`);
    this.snackbar.success({
      message: 'Copied to Clipboard!',
      title: 'Copied'
    });
  }

  async navigateToStaff(staff: any) { // Assuming 'staff' holds s.urlName and school info
    this.schoolName = staff.school?.urlName;
    this.urlName = staff.urlName; 
    this.router.navigate(['/school', this.schoolName, 'staff', this.urlName]);
    await this.loadStaff();
  }

}
