import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { School, Staff } from 'src/app/shared/models';
import { ArrayMatchSortPipe } from 'src/app/shared/pipes';
import { SchoolService, UserService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { ToastService } from 'src/app/shared/services/toast.service';


@Component({
  selector: 'app-edit-staff-profile',
  templateUrl: './edit-staff-profile.component.html',
  styleUrl: './edit-staff-profile.component.scss'
})

export class EditStaffProfileComponent implements OnInit {

  user: User;
  school: School;
  urlName: string;
  departments: DropDownOptions[] = [];
  positions: DropDownOptions[] = [];
  locations: DropDownOptions[] = [];
  working = 0;

  @Input()
  staff?: Staff;

  formGroup: FormGroup;

  constructor(
    public cdn: CDNService,
    private schools: SchoolService,
    private route: ActivatedRoute,
    private schoolService: SchoolService,
    private snackbar: ToastService,
    userService: UserService,
    private router: Router 
  ) {
    this.user = userService.authenticatedUser;

    if (!this.staff && this.school) {
      this.staff = {
        firstName:'',
        lastName:'',
        email: '',
        phone: '',
        schoolId: this.school.id,
        userId: '',
        positionId: 0,
        schoolDepartmentId: 0,
        urlName: ''
      }
    }

    this.route.params.subscribe(params => {
      // Retrieve the ID from the route parameters
      this.urlName = params['urlName'];
    });
      
  }

  async ngOnInit(): Promise<void> {
    this.createFormGroup();
    this.school = await this.schoolService.get(this.urlName);
    this.departments = this.school.departments.map(x => { return { value: x.id, label: x.departmentName } });
    this.positions = this.school.positions.map(x => { return { value: x.id, label: x.name } });
    this.locations = this.school.addresses.map(x => { return { value: x.id, label: x.address.city.name + ", " + x.address.city.state.name } });
  }

  async saveStaff(): Promise<void> {
    if (!this.school) {
      this.snackbar.error({
        title: 'Setup Error',
        message: 'Something isn\'t set up right here, please refresh and try again.'
      });
      return;
    }
    const email = this.formGroup.get('Email').value;
    const domain: string[] = this.school.emailDomain.toLowerCase().split('|');
    if(domain.find(d => email.toLowerCase().endsWith(`@${d}`))) {
      this.staff = {
        firstName: this.formGroup.get('FirstName').value,
        lastName: this.formGroup.get('LastName').value,
        email: this.formGroup.get('Email').value,
        phone: this.formGroup.get('Phone').value,
        schoolId: this.school.id,
        userId: undefined,
        positionId: this.formGroup.get('Position').value.value,
        schoolDepartmentId: this.formGroup.get('Department').value.value,
        urlName: '',
        schoolLocationId: this.formGroup.get('Location').value.value
      }
      try {
        this.working = 50;
        this.staff = await this.schools.createStaff(this.school.urlName, this.staff);
        this.snackbar.success({
          title: 'Success!',
          message: `${ this.staff.firstName } ${ this.staff.lastName } was added, going back to school...`
        });
        this.router.navigate([ '/school', this.school.urlName, 'edit' ]);
      } catch (error) {
        this.snackbar.error({
          title: 'Error saving Staff',
          message: 'There was an error saving'
        });          
      } finally {
        this.working = 0;
      }
    } else {
      this.snackbar.error({
        title: 'Error saving Staff',
        message:'Email must match with the school domain.'
      });          
  }
    
  }

  private createFormGroup(): void {
    this.formGroup = new FormGroup({
      FirstName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      LastName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      Email: new FormControl('', [Validators.required, Validators.email]),
      Department: new FormControl('',  [Validators.required]),
      Position: new FormControl('',  [Validators.required]),
      Location: new FormControl('',  [Validators.required]),
    });
  }

  onSearch(value: any, array: any): any {
    // Create a set from this.allMajors to ensure uniqueness
    const orgSet = new Set(array);
    // Transform value.items and convert it to a set to ensure uniqueness
    const diffSet = new Set(new ArrayMatchSortPipe().transform(value.items, value.term));
    // Combine sets without duplicates
    const combinedSet = new Set([...diffSet, ...orgSet ]);
    // Convert the set back to an array
    return Array.from(combinedSet);
  }
}
