import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TestimonialModel } from '../models/testimonial.model';


@Injectable()
export class TestimonialService {

    private http: HttpClient;

    constructor(handler: HttpBackend) {
        this.http = new HttpClient(handler);
    }

    public get(): Observable<TestimonialModel[]> {
        return this.http.get<TestimonialModel[]>('/assets/json/testimonials.json');
    }
}
