import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { filter, map, Observable, Subscription } from 'rxjs';
import { User } from './shared/models/user.model';
import { CacheService, ModalService, UserService } from './shared/services';
import { AuthService } from '@auth0/auth0-angular';
import { ToastService } from './shared/services/toast.service';
import { NgcCookieConsentService, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Title } from '@angular/platform-browser';
import packageJson from '../../package.json';
import { PromptUpdateService } from './shared/workers/prompt-update.service';
import { LogUpdateService } from './shared/workers/log-update.service';
import { CheckForUpdateService } from './shared/workers/check-update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public user: User;
  public isExpanded = true;
  title = 'Unext';
  loggedIn = false;
  private subscriptions: Subscription[] = [];

  // @ViewChild('mobileNav', { static: false }) mobileNav: MobileNavComponent;

  zoomLevel: number;
  hideZoom = false;
  private version: string = packageJson.version;

  constructor(
    private users: UserService,
    private snackBar: ToastService,
    private router: Router,
    private auth0: AuthService,
    private modals: ModalService,
    private ccService: NgcCookieConsentService,
    private cache: CacheService,
    private titleService: Title,
    prompt: PromptUpdateService, // need constructor to run
    log: LogUpdateService, // need constructor to run
    check: CheckForUpdateService, // need constructor to run
    private gtm: GoogleTagManagerService) {



    if (!window.location.href.indexOf('nochrome=true')) {
      this.user = this.users.authenticatedUser;
      this.loggedIn = this.user !== null;

      if (localStorage.getItem('version') !== this.version) {
        localStorage.setItem('version', this.version);
        if (this.loggedIn) {
          this.users.clearProfile();
          this.users.getProfile()
          this.router.navigate([ '/dashboard' ]);
        }
      }

      if (!this.user && window.location.pathname && window.location.pathname !== '/' && window.location.pathname !== '/home' && window.location.pathname !== 'handle-login') {
        localStorage.setItem('initialPath', window.location.pathname);
      } else if (this.user && window.location.pathname && window.location.pathname === '/home') {
        if (localStorage.getItem('initialPath')?.length) {
          this.router.navigate([ localStorage.getItem('initialPath') ]);
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
    }


    this.subscriptions.push(
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route?.firstChild) {
            route = route.firstChild;
          }
          if (route?.snapshot?.data['title']) {
            routeTitle = route.snapshot.data['title'];
          }

          if (routeTitle.indexOf(':') >= 0) {
            const r = route.snapshot.url.map((u) => u.path);
            r.splice(0, 1); // strip off the main route?

            routeTitle = `${routeTitle.substring(
              0,
              routeTitle.indexOf(':')
            )}${r.join('/')}`;
          }
          const gtmTag = {
            event: 'page',
            pageName: route.snapshot.url,
            userType: this.users.authenticatedUser?.isAdmin ? 'admin' : this.users.authenticatedUser?.isEmployer ? 'employer' : this.users.authenticatedUser?.isStudent ? 'student' : 'anonymous'
          };
          this.gtm.pushTag(gtmTag);

          route.queryParams.subscribe(params => {
            if (params.nochrome === 'true') {
              document.body.classList.add('no-chrome');
            }
            if (params.uid) {
              localStorage.setItem('uid', params.uid);
            }
          });

          return routeTitle;
        })
      ).subscribe((title: string) => {
        if (title) {
          this.titleService.setTitle(title);
        }
      })
    );
    this.subscriptions.push(this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    }));
    this.subscriptions.push(this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    }));
    this.subscriptions.push(this.ccService.initialized$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    }));
    this.subscriptions.push(this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
      console.log(event);
      // you can use this.ccService.getConfig() to do stuff...
    }));
    this.subscriptions.push(this.ccService.revokeChoice$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    }));
    this.subscriptions.push(this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
      console.log(event);
      // you can use this.ccService.getConfig() to do stuff...
    }));
  }

  async ngOnInit(): Promise<void> {

    if (window.location.href.endsWith('?nochrome=true')) {
      return;
    }

    await this.cache.initialize();

    this.subscriptions.push(this.auth0.isAuthenticated$.subscribe(async isAuthenticated => {
      this.loggedIn = isAuthenticated;
      if (this.loggedIn) {
        await this.users.getProfile();
      } else if (this.users.authenticatedUser) {
        this.users.clearProfile();
        this.user = this.users.authenticatedUser;
      }
    }));

    this.subscriptions.push(this.auth0.user$.subscribe(async (user: any) => {
      if (user) {
        this.users.verified = user.email_verified;
        if (user.email !== this.users.authenticatedUser?.email) {
          await this.users.checkstatus();
          await this.users.getProfile();
          localStorage.setItem('user', JSON.stringify(user));
        }
        // TODO:  REENABLE
        // if (!this.users.verified) {
        //   this.router.navigate([ 'verify' ]);
        // }
      } else {
        this.user = null;
        localStorage.removeItem('user');
        // localStorage.clear();
      }
    }));

    this.subscriptions.push(this.auth0.getAccessTokenSilently({ cacheMode: 'off' }).subscribe((token: any) => {
      localStorage.setItem('a0at', token);
      this.users.getProfile();
    }));

    this.subscriptions.push(this.users.profileLoadedObservable$.subscribe((u: User) => {
      this.user = u;
      if (u?.messaging) {
        this.snackBar.success({ message: u.messaging });
      }
    }));
    this.subscriptions.push(this.users.loggedInObservable$.subscribe((message: string) => {
      this.user = this.users.authenticatedUser;
      if (this.user && localStorage.getItem('initialPath')) {
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
        //this.router.navigateByUrl(localStorage.getItem('initialPath'));
        localStorage.removeItem('initialPath');
      }
      else if (this.user && this.router.url === '/home') {
        this.router.navigate(['/dashboard']);
      }
      if (message && message.length) {
        this.snackBar.success({ message });
      }
    }));

    setInterval(() => { this.checkZoom(); }, 10000);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  checkZoom(): void {
    this.zoomLevel = (window.outerWidth / window.innerWidth);
  }

  get highZoom(): boolean {
    return this.zoomLevel < 0.9 || this.zoomLevel > 1.1;
  }
  get extremeZoom(): boolean {
    return this.zoomLevel < 0.71 || this.zoomLevel > 1.49;
  }

  async onLogin(status: boolean): Promise<Observable<User>> {
    this.loggedIn = status;
    if (this.loggedIn) {
      return await this.users.checkstatus();
    }
  }

  toggleSidenav($event) {
    if (this.isExpanded !== $event) {
      this.isExpanded = $event;
      // this.mobileNav.closeNav();
    }
  }

  subscribeNow(subscribed: boolean, id: string): void {
    this.closeModal(id);
    this.user.employee.employer.subscribed = subscribed;

    if (subscribed) {
      this.snackBar.success({
        title: 'Thank you for subscribing!',
        message: 'You now have access to create opportunities and connnect with students.'
      });
    } else {
      this.snackBar.warning({
        title: 'No Subscription',
        message: 'You will not be able to use all the features Unext has to offer until you subscribe.'
      });
    }
  }

  openModal(id: string): void {
    this.modals.open(id);
  }

  closeModal(id: string): void {
    this.modals.close(id);
  }
}
