import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { Employer, EntityType } from 'src/app/shared/models';
import { EmployerService, SizeService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { QuillConfiguration } from 'src/app/static/quill/quilll.config';

@Component({
  selector: 'app-employer-edit',
  templateUrl: './employer-edit.component.html',
  styleUrls: ['./employer-edit.component.scss']
})
export class EmployerEditComponent implements OnInit {

  working = 0;
  employer: Employer | undefined;
  employees: User[] | undefined;
  sizes: DropDownOptions[] = [];
  selectedSize: DropDownOptions | undefined;
  formGroup!: FormGroup;

  quillConfiguration = QuillConfiguration;
  entityType = EntityType;

  constructor(
    private employerService: EmployerService,
    private sizeService: SizeService,
    private snackbar: ToastService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public cdn: CDNService
  ) {
    this.formGroup = this.createForm();
  }

  async ngOnInit(): Promise<void> {
    try {
      this.working = 25;
      this.sizes = (await this.sizeService.get()).map(ss => {
          return {
            label: ss.value,
            value: ss.id,
          }
        });
      this.working = 55;
      this.employer = await this.employerService.getEmployer(this.route.snapshot.params.id);
      this.employees = await this.employerService.getEmployees(this.employer.urlName);
      this.formGroup = this.createForm();
    } catch (error) {
      this.snackbar.error({
        message: error.message ?? error
      });
    } finally {
      this.working = 0;
    }

  }

  createForm(): FormGroup {
    const fg = this.fb.group({
      Id: new FormControl(this.employer?.id ?? 0),
      Name: new FormControl(this.employer?.name, [Validators.required]),
      Description: new FormControl(this.employer?.description, [Validators.required]),
      LogoUrl: new FormControl(this.employer?.logoUrl),
      SmallLogoUrl: new FormControl(this.employer?.smallLogoUrl),
      Domain: new FormControl(this.employer?.domain, [Validators.required]),
      EmailDomains: new FormControl(this.employer?.emailDomains.join(', '), [Validators.required]),
      ContactEmail: new FormControl(this.employer?.contactEmail, [Validators.required]),
      RecruitmentPortal: new FormControl(this.employer?.recruitmentPortal),
      IsPrivate: new FormControl(this.employer?.isPrivate),
      ShadowsAvailable: new FormControl(this.employer?.shadowsAvailable),
      Size: new FormControl(this.employer?.size, [Validators.required]),
      Active: new FormControl(this.employer?.active),
      Verified: new FormControl(this.employer?.verified),
      Subscribed: new FormControl(this.employer?.subscribed),
      Featured: new FormControl(this.employer?.featured)
    });

    this.selectedSize = fg.get('Size').value as any;

    return fg;
  }

  get name(): FormControl {
    return this.formGroup.get('Name') as FormControl;
  }
  get description(): FormControl {
    return this.formGroup.get('Description') as FormControl;
  }
  get logoUrl(): FormControl {
    return this.formGroup.get('LogoUrl') as FormControl;
  }
  get smallLogoUrl(): FormControl {
    return this.formGroup.get('SmallLogoUrl') as FormControl;
  }
  get domain(): FormControl {
    return this.formGroup.get('Domain') as FormControl;
  }
  get emailDomains(): FormControl {
    return this.formGroup.get('EmailDomains') as FormControl;
  }
  get contactEmail(): FormControl {
    return this.formGroup.get('ContactEmail') as FormControl;
  }
  get recruitmentPortal(): FormControl {
    return this.formGroup.get('RecruitmentPortal') as FormControl;
  }
  get size(): FormControl {
    return this.formGroup.get('Size') as FormControl;
  }
  get isPrivate(): FormControl {
    return this.formGroup.get('IsPrivate') as FormControl;
  }
  get shadowsAvailable(): FormControl {
    return this.formGroup.get('ShadowsAvailable') as FormControl;
  }
  get verified(): FormControl {
    return this.formGroup.get('Verified') as FormControl;
  }
  get active(): FormControl {
    return this.formGroup.get('Active') as FormControl;
  }
  get featured(): FormControl {
    return this.formGroup.get('Featured') as FormControl;
  }

  selectSize(value: DropDownOptions): void {
    this.formGroup.get('Size').setValue(value.label);
  }
  toggleShadow(): void {
    this.shadowsAvailable.setValue(!this.shadowsAvailable.value);
  }
  togglePrivate(): void {
    this.isPrivate.setValue(!this.isPrivate.value);
  }
  toggleVerified(): void {
    this.verified.setValue(!this.verified.value);
  }
  toggleActive(): void {
    this.active.setValue(!this.active.value);
  }
  toggleFeatured(): void {
    this.featured.setValue(!this.featured.value);
  }

  async save(): Promise<void> {

    if (this.formGroup.valid) {
      try {
        this.working = 35;
        const updateObj = Object.assign({}, this.employer) as any;
        updateObj.name = this.name.value;
        updateObj.domain = this.domain.value;
        updateObj.emailDomains = this.emailDomains.value.replace(' ', '').split(',');
        updateObj.description = this.description.value;
        updateObj.contactEmail = this.contactEmail.value;
        updateObj.recruitmentPortal = this.recruitmentPortal.value;
        updateObj.size = this.size.value;
        updateObj.shadowsAvailable = this.shadowsAvailable.value;
        updateObj.isPrivate = this.isPrivate.value;
        updateObj.active = this.active.value;
        updateObj.verified = this.verified.value;
        updateObj.featured = this.featured.value;
        
        await this.employerService.updateEmployer(updateObj);
        this.snackbar.success({
          title: 'Success!',
          message: 'The employer account has been updated.'
        });
      } catch (error) {
        this.snackbar.error({
          message: error.message,
          title: 'There was an error updating the employer'
        });
      } finally {
        this.working = 0;
      }
    } else {
      alert(this.formGroup.errors.join(','));
    }

  }
}
