import { Component, Input } from '@angular/core';
import { Employer, Student, Staff, User } from 'src/app/shared/models';
import { ModalService } from 'src/app/shared/services';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent {

  @Input()
  public isEdit = false;

  @Input()
  get user(): User {
    return this.self;
  }
  set user(user: User) {
    this.self = user;
  }
  private self: User;

  @Input()
  showEmployer = true;

  @Input()
  employer: Employer;
  @Input()
  student: Student;
  @Input()
  staff: Staff;
  @Input()
  showEdit = false
  @Input()
  direction: 'horizontal' | 'vertical' = 'vertical';

  constructor(
    private modals: ModalService,
  ) { }


  public openModal(id: string): void {
    this.modals.open(id);
  }
  public closeModal(id: string): void {
    this.modals.close(id);
  }
  public getDegreeType(id: number): string | undefined {
    return [{
      label: `Associate''s Degree`,
      value: 1
    }, {
      label: `Bachelor's Degree`,
      value: 2
    }, {
      label: `Master's Degree`,
      value: 3
    }, {
      label: `PhD`,
      value: 4
    }].find(x => x.value == id)?.label;
  }

  // public uploadDialog(): void {
  //   const dialogRef = this.dialog.open(UserAvatarSelectorComponent, {
  //     autoFocus: true,
  //     minWidth: '70vw',
  //     minHeight: '70vh',
  //     maxHeight: '90vh',
  //     maxWidth: '80vw',
  //     position: {
  //       top: '10%',
  //       left: '10%',
  //     },
  //     data: {
  //       user: this.user
  //     }
  //   });

  //   dialogRef.afterClosed().toPromise().then(result => {
  //     console.log('upload dialog closed...');
  //   });
  // }
}
