import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input()
  public color: 'green' | 'blue' | 'white' = 'blue' ;
  @Input()
  public actions: DropDownOptions[] = [];

  @Input()
  public persist = true;
  @Input()
  public fill = false;
  @Output()
  public selected = new EventEmitter<DropDownOptions>();

  @Input()
  get label(): string {
    return this.myLabel;
  }
  set label(val: string) {
    this.myLabel = val;
    this.init();
  }

  @Input()
  get value(): string {
    return this.myValue;
  }
  set value(val: string) {
    this.myValue = val;
    this.init();
  }

  @Input()
  required = false;
  @Input()
  disabled = false;

  private myLabel: string;
  private myValue: string;

  public displayLabel: string;
  public open = false;

  constructor(
    private renderer2: Renderer2,
    private element: ElementRef,
    protected router: Router
  ) {
    this.renderer2.listen('window', 'click', (e: Event) => {
      if (this.open && !this.element.nativeElement.contains(e.target)) {
        this.open = false;
      }
    });
  }

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    if (this.value != null && this.actions?.length) {
      if (this.actions.length) {
        const a = this.actions.filter(ac => ac.value === this.value);
        if (a?.length) {
          this.displayLabel = a[0].label;
        }
      }
    } else {
      this.displayLabel = this.label;
    }
  }

  selectOption(option: DropDownOptions, suppressEvents: boolean | undefined = undefined): void {
    if (this.persist) {
      this.displayLabel = option.label;
      this.myValue = option.value;
    }
    if (!suppressEvents) {
      this.selected.next(option);
    }
    this.open = false;
  }

}

export class DropDownOptions {
  public label: string;
  public value: any;


    constructor(label: string, value: any) {
      this.label = label;
      this.value = value;
  }
}
