import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './static/privacy-policy/privacy-policy.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { TopNavComponent } from './navigation/top-nav/top-nav.component';
import { SignupComponent } from './newsletter/signup/signup.component';
import { InstagramComponent } from './navigation/social/instagram/instagram.component';
import { FacebookComponent } from './navigation/social/facebook/facebook.component';
import { SnapchatComponent } from './navigation/social/snapchat/snapchat.component';
import { LinkedinComponent } from './navigation/social/linkedin/linkedin.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { LogoComponent } from './navigation/logo/logo.component';
import { environment } from 'src/environments/environment';
import { UserService } from './shared/services/user.service';
import { ContactUsComponent } from './static/contact-us/contact-us.component';
import { AboutUsComponent } from './static/about-us/about-us.component';
import { FaqsComponent } from './static/faqs/faqs.component';
import { FaqItemComponent } from './static/faqs/faq-item/faq-item.component';
import { ForEmployersComponent } from './static/for-employers/for-employers.component';
import { RandomQuoteComponent } from './quotes/random-quote/random-quote.component';
import { FAQService } from './shared/services/faq.service';
import { TestimonialService } from './shared/services/testimonial.service';
import { UserCardComponent } from './user/user-card/user-card.component';
import { NewsletterService } from './shared/services/newsletter.service';
import { EmployerCardComponent } from './employer/employer-card/employer-card.component';
import { EmployerProfileComponent } from './employer/employer-profile/employer-profile.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { EmployerUpdateComponent } from './employer/employer-update/employer-update.component';
import { EmployerStatusComponent } from './employer/employer-status/employer-status.component';
import { ContactUsService } from './shared/services/contactUs.service';
import { BannerComponent } from './shared/components/banner/banner.component';
import { ForStudentsComponent } from './static/for-students/for-students.component';
import { ForInstitutionsComponent } from './static/for-institutions/for-institutions.component';
// import { NgxStripeModule } from 'ngx-stripe';
import { StripeComponent } from './stripe/stripe.component';
import { EmployerOpportunityListComponent } from './employer/employer-opportunity-list/employer-opportunity-list.component';
import { EmployerOpportunityComponent } from './employer/employer-opportunity/employer-opportunity.component';
import { EmployerOpportunitiesComponent } from './employer/employer-opportunities/employer-opportunities.component';
import { EmployerOpportunityAddComponent } from './employer/employer-opportunity-add/employer-opportunity-add.component';
import { LeftNavigationComponent } from './shared/components/left-navigation/left-navigation.component';
import { OpportunityTypePipe } from './shared/pipes/opportunityType.pipe';
import { PayTypePipe } from './shared/pipes/payType.pipe';
import { DayTimeHourPipe, LocalTimePipe } from './shared/pipes/LocalTime.pipe';
import { ArrayDoubleSortPipe, ArraySortPipe, ArrayMatchSortPipe } from './shared/pipes/arraysort.pipe';
import { SkillService } from './shared/services/skill.service';
import { EmployerService } from './shared/services/employer.service';
import { OpportunityService } from './shared/services/opportunity.service';
import { StudentService } from './shared/services/student.service';
import { SubscriptionService } from './shared/services/subscription.service';
import { UploadComponent } from './shared/components/upload/upload.component';
import { AffiliateService, CacheService, CertificationService, ExperienceService, IndustryService, LocationService, ModalService,
          ResumeService,
          SchoolService, SizeService, UploadService } from './shared/services';
import { UserAvatarSelectorComponent } from './user/user-avatar-selector/user-avatar-selector.component';
import { StudentResumesComponent } from './student/student-resumes/student-resumes.component';
import { StudentOpportunitiesComponent } from './student/student-opportunities/student-opportunities.component';
// import { StudentOpportunityComponent } from './student/student-opportunity/student-opportunity.component';
import { AvatarImageComponent } from './shared/components/avatar-image/avatar-image.component';
import { StudyService } from './shared/services/study.service';
import { StudentProfileProgressComponent } from './student/student-profile-progress/student-profile-progress.component';
import { MessageCenterComponent } from './shared/components/message-center/message-center.component';
import { MessageService } from './shared/services/message.service';
import { AdminService } from './shared/services/admin.service';
import { StudentCalendarComponent } from './student/student-calendar/student-calendar.component';
import { CultureService } from './shared/services/culture.service';
import { AudienceService } from './shared/services/audience.service';
import { AuthInterceptor } from './shared/auth/auth-interceptor';
import { DropdownComponent } from './shared/components/dropdown/dropdown.component';
import { DropdownAutocompleteComponent } from './shared/components/dropdown-autocomplete/dropdown-autocomplete.component';
import { MobileFooterComponent } from './navigation/mobile-footer/mobile-footer.component';
import { MenuComponent } from './shared/components/menu/menu.component';
import { FindNextComponent } from './home/find-next/find-next.component';
import { WhyWaitComponent } from './home/why-wait/why-wait.component';
import { RecruitingComponent } from './home/recruiting/recruiting.component';
import { SeparatorComponent } from './shared/components/separator/separator.component';
import { PartnersComponent } from './home/partners/partners.component';
import { OpportunitiesComponent } from './home/opportunities/opportunities.component';
import { CandidatesComponent } from './home/candidates/candidates.component';
import { ShareButtonComponent } from './shared/components/share-button/share-button.component';
import { PricingComponent } from './static/pricing/pricing.component';
import { TableOfContentsComponent } from './shared/components/table-of-contents/table-of-contents.component';
import { TermsAndConditionsComponent } from './static/terms-and-conditions/terms-and-conditions.component';
import { NotifyComponent } from './static/notify/notify.component';
import { OpportunityListComponent } from './shared/components/opportunity/opportunity-list/opportunity-list.component';
import { OpportunityCardComponent } from './shared/components/opportunity/opportunity-card/opportunity-card.component';
import { AddressCityNamePipe, AddressFullNamePipe } from './shared/pipes/addressPipe.pipe';
import { ProgressBarComponent } from './shared/components/progress-bar/progress-bar.component';
import { ModalComponent } from './shared/components/modal/modal.component';
import { MobileSubNavComponent } from './navigation/mobile-sub-nav/mobile-sub-nav.component';
import { PageModeService } from './shared/services/pageMode.service';
import { UserProfileEditComponent } from './user/user-profile-edit/user-profile-edit.component';
import { UserMessagesSummaryComponent } from './user-messages-summary/user-messages-summary.component';
import { UserProfileViewsComponent } from './user/user-profile-views/user-profile-views.component';
import { FieldOfInterestService } from './shared/services/fieldofinterest.service';
import { ToggleBarComponent } from './shared/components/toggle-bar/toggle-bar.component';
import { StudentAddProfileAttributeComponent } from './student/student-add-profile-attribute/student-add-profile-attribute.component';
import { StudentAddExperienceComponent } from './student/student-add-experience/student-add-experience.component';
import { QuillModule } from 'ngx-quill';
import { StudentResumeCustomSectionComponent } from './student/resume-builder/student-resume-custom-section/student-resume-custom-section.component';
import { MyOpportunitiesComponent } from './student/my-opportunities/my-opportunities.component';
import { StudentProfileComponent } from './student/student-profile/student-profile.component';
import { OpportunityAddAttributeComponent } from './opportunity/opportunity-add-attribute/opportunity-add-attribute.component';
import { JsonDateInterceptor } from './shared/interceptors/jsonDate.interceptor';
import { EmployerProspectsComponent } from './employer/employer-prospects/employer-prospects.component';
import { EmployerAddProfileAttributeComponent } from './employer/employer-add-profile-attribute/employer-add-profile-attribute.component';
import { AuthModule } from '@auth0/auth0-angular';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { HandleLoginComponent } from './handle-login/handle-login.component';
import { CDNService } from './shared/services/cdn.service';
import { StepperComponent } from './shared/components/stepper/stepper.component';
import { YoutubeVideoComponent } from './shared/components/youtube-video/youtube-video.component';
import { EmployerExistingOpportunitiesComponent } from './employer/employer-existing-opportunities/employer-existing-opportunities.component';
import { EmployerEmployeeListComponent } from './employer/employer-employee-list/employer-employee-list.component';
import { SubscribeGuard } from './shared/guard/subscribe.guard';
import { InfoModalComponent } from './shared/components/info-modal/info-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EmployerProspectCardComponent } from './employer/employer-prospect-card/employer-prospect-card.component';
import { PricingSummaryComponent } from './shared/components/pricing-summary/pricing-summary.component';
import { UserResumeCardComponent } from './user/user-resume-card/user-resume-card.component';
import { LinkPipe } from './shared/pipes/link.pipe';
import { ListScrollComponent } from './shared/components/list-scroll/list-scroll.component';
import { ProfileGuard } from './shared/guard/profile.guard';
import { NoOrganizationComponent } from './user/no-organization/no-organization.component';
import { AdminEmployersComponent } from './admin/employer/employers/admin-employers.component';
import { PhoneMasksDirective } from './shared/directive/phone.mask.directive';
import { EmployerEditComponent } from './admin/employer/employer-edit/employer-edit.component';
import { CDNPipe, PhonePipe, RemovePipe, UnicodeFormatPipe } from './shared/pipes';
import { AdminUsersComponent } from './admin/users/admin-users.component';
import { GTMComponentComponent } from './shared/components/gtmcomponent/gtmcomponent.component';
import { StrongRelationshipsComponent } from './home/strong-relationships/strong-relationships.component';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
// import { StudentResumeBuilderComponent } from './student/resume-builder/student-resume-builder/student-resume-builder.component';
// import { StudentResumeSectionEditComponent } from './student/resume-builder/student-resume-edit-section/student-resume-edit-section.component';
import { CustomQuillComponent } from './shared/components/custom-quill/custom-quill.component';
// import { ResumeSectionEducationComponent } from './student/resume-builder/resume-sections/resume-section-education/resume-section-education.component';
// import { ResumeSectionExperienceComponent } from './student/resume-builder/resume-sections/resume-section-experience/resume-section-experience.component';
// import { ResumeSectionContactInfoComponent } from './student/resume-builder/resume-sections/resume-section-contact-info/resume-section-contact-info.component';
// import { ResumeSectionAwardComponent } from './student/resume-builder/resume-sections/resume-section-award/resume-section-award.component';
import { StudentResumePreviewComponent } from './student/resume-builder/student-resume-preview/student-resume-preview.component';
import { DefaultResumeTemplateComponent } from './student/resume-builder/default-resume-template/default-resume-template.component';
// import { ResumeSectionOrganizationComponent } from './student/resume-builder/resume-sections/resume-section-organization/resume-section-organization.component';
import { DegreeService } from './shared/services/degree.service';
import { StudentApplyDocumentsComponent } from './student/student-apply-documents/student-apply-documents.component';
// import { ResumeGeneralComponent } from './student/resume-builder/resume-sections/resume-section-general/resume-section-general.component';
import { RequestDemoComponent } from './home/request-demo/request-demo.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ArrayFilterPipe } from './shared/pipes/filterPipe.pipe';
import { PreviousEntriesComponent } from './student/resume-builder/previous-entries/previous-entries.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LogUpdateService } from './shared/workers/log-update.service';
import { CheckForUpdateService } from './shared/workers/check-update.service';
import { PromptUpdateService } from './shared/workers/prompt-update.service';
import { StudentResumeEditWrapperComponent } from './student/resume-builder/student-resume-edit-wrapper/student-resume-edit-wrapper.component';
import { VolunteeeringComponent } from './student/resume-builder/volunteeering/volunteeering.component';
import { AnalyticsComponent, AwardsComponent, ClubsandOrganizationsComponent, ContactinfoComponent, EducationComponent, ExperienceComponent, LanguagesComponent, SectionMapperComponent, SkillsComponent } from './student/resume-builder/sections';
import { SmartWordService } from './shared/services/smartwords.service';
import { TipsComponent } from './student/resume-builder/sections/tips/tips.component';
import { GeneralComponent } from './student/resume-builder/sections/general/general.component';
import { ResumeTemplateComponent } from './student/resume-builder/resume-template/resume-template.component';
import { LibraryComponent } from './library/library.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ActionMenuComponent } from './shared/components/action-menu/action-menu.component';
import { EditSchoolProfileComponent } from './admin/school/edit-school-profile/edit-school-profile.component';
import { EditStaffProfileComponent } from './admin/school/staff/edit-staff-profile/edit-staff-profile.component';
import { SchoolProfileComponent } from './admin/school/school-profile/school-profile.component';
import { SchoolAddProfileAttributeComponent } from './admin/school/school-add-profile-attribute/school-add-profile-attribute.component';
import { JobDescriptionService } from './shared/services/jobdescription.service';
import { StaffProfileComponent } from './admin/school/staff/staff-profile/staff-profile.component';
import { SchoolSettingsComponent } from './school/school-settings/school-settings.component';
import { AdminSchoolsComponent } from './admin/school/schools/admin-schools/admin-schools.component';
import { EntityEditComponent } from './shared/components/entity-edit/entity-edit.component';
import { EntityListComponent } from './shared/components/entity-list/entity-list.component';
import { EmployerProfileEditComponent } from './employer/employer-profile-edit/app-employer-profile-edit.component';
import { StudentOpportunityApplicationComponent } from './student/student-opportunity-application/student-opportunity-application.component';
import { AllEmployerListComponent } from './employer/all-employer-list/all-employer-list.component';
import { EmployerSubscriptionComponent } from './employer/employer-subscription/employer-subscription.component';
import { FilterMenuComponent } from './shared/components/filter-menu/filter-menu.component';
import { Dashboard3Component } from './dashboard-3/dashboard-3.component';
import { StaffListComponent } from './admin/school/staff/staff-list/staff-list.component';
import { StudentListComponent } from './admin/school/student/student-list/student-list.component';
import { ResumeProcessComponent } from './student/resume-builder/resume-process/resume-process.component';
import { NextStepCareerReadinessComponent } from './home/next-step-career-readiness/next-step-career-readiness.component';
import { ConnectWhatsNextComponent } from './home/connect-whats-next/connect-whats-next.component';
import { SelectedYourMajorComponent } from './home/selected-your-major/selected-your-major.component';
import { StickerComponent } from './home/sticker/sticker.component';
import { ConfidentTalentPoolComponent } from './home/confident-talent-pool/confident-talent-pool.component';
import { WhyUnextComponent } from './home/why-unext/why-unext.component';
import { RightImageComponent } from './home/right-image/right-image.component';
import { LeftImageComponent } from './home/left-image/left-image.component';
import { TextMarqueeComponent } from './home/text-marquee/text-marquee.component';
import { StudentOpportunityListComponent } from './shared/components/opportunity/student-opportunity-list/student-opportunity-list.component';
import { StudentOpportunityCardComponent } from './shared/components/opportunity/student-opportunity-card/student-opportunity-card.component';
import { RequestOpportunityComponent } from './opportunity/request-opportunity/request-opportunity.component';
import { WeekSchedulerComponent } from './shared/components/week-scheduler/week-scheduler.component';
import { CarouselComponent } from './shared/components/carousel/carousel.component';
import { BaseChartDirective } from 'ng2-charts';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { LineGraphComponent } from './reports/line-graph/line-graph.component';
import { SchoolDataDashboardComponent } from './admin/school/school-data-dashboard/school-data-dashboard.component';
import { ReportingService } from './shared/services/reporting.service';
import { CalendarService } from './shared/services/calendar.service';
import { ScheduleOpportunityComponent } from './opportunity/schedule-opportunity/schedule-opportunity.component';
import { ContactSectionComponent } from './shared/components/contact-section/contact-section.component';
import { EmployerOpportunityEditComponent } from './employer/employer-opportunity-edit/employer-opportunity-edit.component';
import { SortDataService } from './shared/services/sort.data.service';


const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};

@NgModule({
    declarations: [
        AdminEmployersComponent,
        AdminUsersComponent,
        AdminSchoolsComponent,
        AppComponent,
        BannerComponent,
        ContactSectionComponent,
        HomeComponent,
        PageNotFoundComponent,
        PrivacyPolicyComponent,
        UserProfileComponent,
        Dashboard3Component,
        TopNavComponent,
        FooterComponent,
        SignupComponent,
        InstagramComponent,
        FacebookComponent,
        SnapchatComponent,
        LinkedinComponent,
        LogoComponent,
        ContactUsComponent,
        AboutUsComponent,
        FaqsComponent,
        FaqItemComponent,
        ForEmployersComponent,
        ForStudentsComponent,
        ForInstitutionsComponent,
        RandomQuoteComponent,
        UserCardComponent,
        EmployerCardComponent,
        EmployerProfileComponent,
        UserListComponent,
        EmployerUpdateComponent,
        EmployerStatusComponent,
        StripeComponent,
        EmployerOpportunityListComponent,
        EmployerOpportunityComponent,
        EmployerOpportunitiesComponent,
        EmployerOpportunityAddComponent,
        LeftNavigationComponent,
        OpportunityTypePipe,
        AddressCityNamePipe,
        AddressFullNamePipe,
        PayTypePipe,
        LocalTimePipe,
        DayTimeHourPipe,
        ArraySortPipe,
        ArrayDoubleSortPipe,
        ArrayMatchSortPipe,
        LinkPipe,
        RemovePipe,
        CDNPipe,
        ArrayFilterPipe,
        PhonePipe,
        StudentProfileComponent,
        UploadComponent,
        UserAvatarSelectorComponent,
        StudentResumesComponent,
        StudentOpportunitiesComponent,
        // StudentOpportunityComponent,
        StudentAddExperienceComponent,
        ResumeProcessComponent,
        AvatarImageComponent,
        StudentProfileProgressComponent,
        MessageCenterComponent,
        StudentCalendarComponent,
        DropdownComponent,
        DropdownAutocompleteComponent,
        MobileFooterComponent,
        MenuComponent,
        FindNextComponent,
        WhyWaitComponent,
        RecruitingComponent,
        SeparatorComponent,
        PartnersComponent,
        OpportunitiesComponent,
        CandidatesComponent,
        ShareButtonComponent,
        PricingComponent,
        TableOfContentsComponent,
        TermsAndConditionsComponent,
        NotifyComponent,
        OpportunityListComponent,
        OpportunityCardComponent,
        ProgressBarComponent,
        ModalComponent,
        MobileSubNavComponent,
        UserProfileEditComponent,
        UserMessagesSummaryComponent,
        UserProfileViewsComponent,
        ToggleBarComponent,
        StudentAddProfileAttributeComponent,
        StudentResumeCustomSectionComponent,
        MyOpportunitiesComponent,
        OpportunityAddAttributeComponent,
        EmployerProspectsComponent,
        EmployerProfileEditComponent,
        EmployerAddProfileAttributeComponent,
        VerifyAccountComponent,
        HandleLoginComponent,
        StepperComponent,
        YoutubeVideoComponent,
        EmployerExistingOpportunitiesComponent,
        EmployerEmployeeListComponent,
        InfoModalComponent,
        EmployerProspectCardComponent,
        PricingSummaryComponent,
        UserResumeCardComponent,
        ListScrollComponent,
        NoOrganizationComponent,
        PhoneMasksDirective,
        EmployerEditComponent,
        GTMComponentComponent,
        StrongRelationshipsComponent,
        // StudentResumeBuilderComponent,
        // StudentResumeSectionEditComponent,
        CustomQuillComponent,
        // ResumeSectionEducationComponent,
        // ResumeSectionExperienceComponent,
        // ResumeSectionContactInfoComponent,
        // ResumeSectionAwardComponent,
        StudentResumePreviewComponent,
        DefaultResumeTemplateComponent,
        // ResumeSectionOrganizationComponent,
        StudentApplyDocumentsComponent,
        ConnectWhatsNextComponent,
        SelectedYourMajorComponent,
        UnicodeFormatPipe,
        // ResumeGeneralComponent,
        RequestDemoComponent,
        PreviousEntriesComponent,
        StudentResumeEditWrapperComponent,
        AwardsComponent,
        ClubsandOrganizationsComponent,
        ContactinfoComponent,
        EducationComponent,
        ExperienceComponent,
        LanguagesComponent,
        SectionMapperComponent,
        SkillsComponent,
        VolunteeeringComponent,
        AnalyticsComponent,
        TipsComponent,
        NextStepCareerReadinessComponent,
        GeneralComponent,
        ResumeTemplateComponent,
        LibraryComponent,
        ActionMenuComponent,
        EditSchoolProfileComponent,
        EditStaffProfileComponent,
        SchoolProfileComponent,
        SchoolAddProfileAttributeComponent,
        StaffProfileComponent,
        SchoolSettingsComponent,
        EntityEditComponent,
        EntityListComponent,
        StudentOpportunityApplicationComponent,
        AllEmployerListComponent,
        EmployerSubscriptionComponent,
        FilterMenuComponent,
        StaffListComponent,
        StudentListComponent,
        StickerComponent,
        ConfidentTalentPoolComponent,
        WhyUnextComponent,
        LeftImageComponent,
        RightImageComponent,
        TextMarqueeComponent,
        StudentOpportunityListComponent,
        StudentOpportunityCardComponent,
        RequestOpportunityComponent,
        WeekSchedulerComponent,
        CarouselComponent,
        LineGraphComponent,
        SchoolDataDashboardComponent,
        ScheduleOpportunityComponent,
        EmployerOpportunityEditComponent
    ],
    imports: [
//      NgChartsModule,
        BaseChartDirective,
        NgxPaginationModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        FormsModule,
        NgSelectModule,
        HttpClientModule,
        // NgxStripeModule.forRoot(environment.api.stripe.publicApiKey),
        ReactiveFormsModule,
        QuillModule.forRoot(),
        AuthModule.forRoot({
            domain: environment.auth0.domain,
            clientId: environment.auth0.clientId,
            authorizationParams: {
                redirect_uri: `${document.location.origin}/handle-login`,
                audience: environment.auth0.audience,
                scope: environment.auth0.scope,
            },
            cacheLocation: 'localstorage',
            useRefreshTokens: environment.auth0.useRefreshTokens,
            useRefreshTokensFallback: true
        }),
        FontAwesomeModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
      ], 
        providers: [
        [provideCharts(withDefaultRegisterables())],
        AffiliateService,
        AdminService,
        AudienceService,
        CacheService,
        CDNService,
        CertificationService,
        CheckForUpdateService,
        ContactUsService,
        CultureService,
        DegreeService,
        EmployerService,
        ExperienceService,
        FAQService,
        FieldOfInterestService,
        IndustryService,
        JobDescriptionService,
        LocationService,
        LogUpdateService,
        MessageService,
        ModalService,
        NewsletterService,
        OpportunityService,
        PageModeService,
        PromptUpdateService,
        ResumeService,
        SchoolService,
        SizeService,
        SkillService,
        SmartWordService,
        SortDataService,
        StudentService,
        StudyService,
        SubscribeGuard,
        ProfileGuard,
        SubscriptionService,
        TestimonialService,
        UploadService,
        UserService,
        ReportingService,
        CalendarService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JsonDateInterceptor,
            multi: true
        },
        {
            provide: 'googleTagManagerId',
            useValue: environment.google.tagId
        },
        //provideHttpClient(withInterceptorsFromDi())
      ], bootstrap: [AppComponent], 
     })
export class AppModule { }
