import { Component, Input } from '@angular/core';
import { Employer, Opportunity, Student } from 'src/app/shared/models';
import { ModalService } from 'src/app/shared/services';

@Component({
  selector: 'app-opportunity-list',
  templateUrl: './opportunity-list.component.html',
  styleUrls: ['./opportunity-list.component.scss']
})
export class OpportunityListComponent {

  @Input()
  opportunities: Opportunity[] = [];
  @Input()
  student: Student;
  @Input()
  employer: Employer;
  @Input()
  grid: boolean;

  selectedOpportunity: Opportunity;

  constructor(
    private modalService: ModalService
  ){

  }


  openApplicationModal(e: any){
    this.selectedOpportunity = e
    this.modalService.open('applicationModal');

  }

}
