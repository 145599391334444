<div class="table-of-contents">
    <div class="text text-white heading-3 mb-medium" *ngIf="hasHeader">
        Table of contents
    </div>

    <ol class="contents">
        <li *ngFor="let l of links" class="text text-white">
            <div class="link" *ngIf="l.href?.length && l.scroll" (click)="onClick(l.href)">
                {{ l.heading }}
            </div>
            <a *ngIf="l.href?.length && !l.scroll" [href]="l.href" class="external link" [target]="l.external ? '_blank' : '_self'">
                {{ l.heading }}
            </a>
            <div *ngIf="!l.href?.length">
                {{ l.heading }}
            </div>
        </li>
    </ol>

    <div class="flair flair-1">
    </div>
    <div class="flair flair-2">
    </div>
</div> 
