<div *ngIf="user" class="user-card {{ direction }}" [ngClass]="{ 'read-only': !isEdit }">
    <div class="name text heading-3">
        {{ user.firstName }} {{ user.lastName }}
    </div>
    <div class="avatar rounded-circle" (click)="openModal('edit-avatar')" style="cursor: pointer;">
        <app-avatar-image [size]="'auto'" [image]="user.avatar" [title]="user.firstName + ' ' + user.lastName">
        </app-avatar-image>
    </div>
    <div class="employer text heading-4" *ngIf="user?.isEmployer">
        {{ user.employee?.employer.name }}
    </div>
    <div class="title text text-400" *ngIf="user?.isEmployer">
        {{ user.title }}
    </div>
    <div class="title text text-400" *ngIf="user?.isStudent">
        {{ student?.schoolName }}
        <div *ngIf="!student?.schoolVerified" class="text text-warning">
            Your school has not been verified yet.
        </div>
    </div>
    <div class="title text text-400" *ngIf="user?.isStudent && student.graduationYear">
        {{ student.graduationYear }}
    </div>
    <div class="title text text-400" *ngIf="user?.isStudent && student.degree">
        {{ getDegreeType(student.degree) }}
    </div>

    <div class="location text text-400 mb-small" *ngIf="user !== null && user.location !== null">
        {{ user?.location?.address | addressCityName }}
    </div>
    <a href="mailto:{{user.email}}" class="email text text-green text-400">
        {{ user.email }}
    </a>

    <a *ngIf="showEdit" class="edit-profile button btn-blue mt-small mb-small" [routerLink]="['/my-profile']">Edit
        Profile</a>
</div>

<app-modal id="edit-avatar" heading="Edit Avatar Photo" size="medium">
    <app-user-avatar-selector [(user)]="user"></app-user-avatar-selector>
    <button class="button orange outline mt-small" (click)="closeModal('edit-avatar')">Close</button>
</app-modal>