import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-list-scroll',
  templateUrl: './list-scroll.component.html',
  styleUrls: ['./list-scroll.component.scss']
})
export class ListScrollComponent implements AfterViewInit, OnInit {

  @ViewChild('body', { static: false })
  body: ElementRef<HTMLElement>;

  forwardScroll = false;
  backScroll = false;
  scrollSize = 420;

  ngOnInit(): void {
    setTimeout(() => {
      this.ngAfterViewInit();
    }, 10000)
  }

  ngAfterViewInit(): void {
    this.checkScroll();
  }

  checkScroll() {

    if (this.body.nativeElement.children[0]) {

      if (this.body.nativeElement.scrollLeft >= this.body.nativeElement.scrollWidth - this.body.nativeElement.clientWidth) {
        this.forwardScroll = false;
      } else {
        this.forwardScroll = true;
      }
      
      if (this.body.nativeElement.scrollLeft == 0) {
        this.backScroll = false;
      } else {
        this.backScroll = true;
      }
    }
  }

  back(): void {
    this.body.nativeElement.scrollBy({ left: -this.scrollSize, behavior: 'smooth' });
    setTimeout(() => {
      this.checkScroll();
    }, 500)
  }

  forward(): void {
    this.body.nativeElement.scrollBy({ left: this.scrollSize, behavior: 'smooth' });
    setTimeout(() => {
      this.checkScroll();
    }, 500)

  }

}
