import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EntityAction, EntityColumn, EntityFilter, EntitySearch } from 'src/app/shared/components/entity-list/entity-list.component';
import { School } from 'src/app/shared/models/school.model';
import { ArraySortPipe } from 'src/app/shared/pipes';
import { SchoolService } from 'src/app/shared/services';
import { AdminService } from 'src/app/shared/services/admin.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-admin-schools',
  templateUrl: './admin-schools.component.html',
  styleUrls: ['./admin-schools.component.scss']
})
export class AdminSchoolsComponent implements OnInit {

  displayedColumns: EntityColumn[] = [{
    label: 'Name', value: 'name', displayType: 'text', width: 360, display: true, sortable: true
  }, {
    label: 'Size', value: 'size', displayType: 'text', width: 160, display: true, sortable: true
  }, {
    label: 'Domain', value: 'domain', displayType: 'text', width: 260, display: true, sortable: false
  }, {
    label: 'Email Domain', value: 'emailDomain', displayType: 'text', width: 360, display: true, sortable: false
  }, {
    label: 'Active', value: 'active', displayType: 'toggle', width: undefined, display: true, sortable: true
  }];

  actions: EntityAction[] = [{
    label: 'View',
    action: (school: School) => { this.router.navigate(['/school', school.urlName]); }
  }, {
    label: 'Edit',
    action: (school: School) => { this.router.navigate(['/school', school.urlName, 'edit']); }
  }];

  filters: EntityFilter[] = [{
    displayType: 'toggle',
    label: 'Only Active',
    value: 'active'
  }, {
    displayType: 'toggle',
    label: 'Only Verified',
    value: 'verified'
  }, {
    displayType: 'text',
    label: 'Name',
    value: 'name'
  }];

  allschools: School[] = [];
  schools: School[] = [];
  isLoading = false;
  showVerified = true;
  showUnverified = true;
  showActive = true;
  showInactive = true;

  constructor(
    private admin: AdminService,
    private schoolService: SchoolService,
    private snackbar: ToastService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getSchools();
  }

  async getSchools(): Promise<void> {
    this.isLoading = true;
    try {
      const ss = await this.schoolService.getSchools(true, true);
      this.allschools = ss;
      this.schools = ss;
    } catch (err) {
      this.snackbar.error({
        title: 'dismiss',
        message: `There was an error getting schools\n${err?.error ?? err}`,
        closeDuration: 5000
      });
    } finally {
      this.isLoading = false;
    }
  }

  filterSchools(filter: EntitySearch): void {

    this.schools = this.allschools;

    if (filter.filter?.length && filter.filter.values !== undefined) {
      filter.filter.forEach(f => {
        if (f.value !== undefined && f.value !== null) {
          if (typeof f.value === 'string') {
            this.schools = this.schools.filter(x => x[f.name].toLowerCase().indexOf(f.value.toLowerCase()) >= 0);
          } else if (typeof f.value === 'boolean') {
            if (f.value) {
              this.schools = this.schools.filter(x => x[f.name] === f.value);
            }
          } else {
            this.schools = this.schools.filter(x => x[f.name] === f.value);
          }
        }
      });
    }

    this.schools = new ArraySortPipe().transform(this.schools, filter.sort, filter.direction === 'asc');
  }


  navigate(value: School): void {
    this.router.navigate(['/school', value.urlName, 'edit']);
  }

  getProperty(e: any, property: string): any {
    const path: string[] = property.split('.');
    return this.getSubProperty(e, path);
  }

  getSubProperty(e: any, path: string[]): any {
    if (!path.length) {
      return e;
    } else if (path.length === 1) {
      return e[path[0]];
    } else {
      const root = path.splice(0, 1);
      return this.getSubProperty(e[root[0]], path);
    }
  }
}
