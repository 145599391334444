import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { DropdownComponent, DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { StepperComponent } from 'src/app/shared/components/stepper/stepper.component';
import { Audience, EntityType, IdName } from 'src/app/shared/models';
import { Employer } from 'src/app/shared/models/employer.model';
import { Opportunity, OpportunityType } from 'src/app/shared/models/opportunity.model';
import { User } from 'src/app/shared/models/user.model';
import { AudienceService, EmployerService, FieldOfInterestService, ModalService, OpportunityService, ResumeService, SchoolService,
        StudyService, UploadService, UserService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { SkillService } from 'src/app/shared/services/skill.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { QuillConfiguration } from 'src/app/static/quill/quilll.config';

type stepId = 'info' | 'demography' | 'overview'

@Component({
  selector: 'app-employer-opportunity-edit',
  templateUrl: './employer-opportunity-edit.component.html',
  styleUrls: ['./employer-opportunity-edit.component.scss']
})
export class EmployerOpportunityEditComponent implements OnInit {

  @ViewChild('stepper')
  stepper!: StepperComponent

  @ViewChild('ddLocation')
  ddLocation!: DropdownComponent;

  @ViewChild('ddOpportunityTypeLength')
  ddOpportunityTypeLength!: DropdownComponent;

  formGroup: FormGroup;

  employerUrlName: string;
  urlName: string;
  opportunity: Opportunity;
  activeOpportunityType: string;
  employer: Employer;
  user: User;
  working = 0;

  opportunityTypeList: DropDownOptions[] = [];
  employees: DropDownOptions[] = [];

  @Output()
  opportunityAdded: EventEmitter<Opportunity>;

  // Based on step index
  activeStep = 0

    steps: { id: stepId, title: string, split: boolean, valid: () => boolean, next: () => void }[] = [{
    id: 'info',
    title: 'Main Information',
    split: true,
    valid: () => {
      return true
    },
    next: () => {
      
      this.next()
    }
    }, {
      id: 'demography',
      title: 'Demography',
      split: false,
      valid: () => {
        return true
      },
      next: () => this.next()
  }, {
    id: 'overview',
    title: 'Overview',
    split: true,
    valid: () => {
      return true
    },
    next: () => {
      this.router.navigate(['/employers', this.employer.urlName ])
    }
  }, ]

  opportunityTypes: DropDownOptions[] = [
    { value: OpportunityType.Internship, label: 'Internship' },
    { value: OpportunityType.Employment, label: 'Employment' },
    { value: OpportunityType.Volunteering, label: 'Volunteering' },
    { value: OpportunityType.JobShadowing, label: 'Job Shadowing' },
    { value: OpportunityType.CoOp, label: 'Co-Op'},
    { value: OpportunityType.Other, label: 'Other' },
  ];

  payTypes: DropDownOptions[] = [
    { value: 0, label: 'Once' },
    { value: 1, label: 'Hourly' },
    { value: 2, label: 'Daily' },
    { value: 3, label: 'Weekly' },
    { value: 4, label: 'Monthly'},
    { value: 5, label: 'Commission' },
    { value: 6, label: 'Salary' }
  ];

  educationLevels: string[] = [
    'Associate\'s Degree',
    'Bachelor\'s Degree',
    'Master\'s Degree',
    'PhD'
  ];
  degreeLookup: IdName[] = [{
    name: 'Associate\'s Degree',
    id: 1
  }, {
    name: 'Bachelor\'s Degree',
    id: 2
  }, {
    name: 'Master\'s Degree',
    id: 3
  }, {
    name: 'PhD',
    id: 4
  }];

  opportunityTypeLengths: DropDownOptions[] = [];
  selectLocations: DropDownOptions[] = [];
  selectAudiences: DropDownOptions[] = [];

  availableLocations: string[] = [];
  availableSkills: string[] = [];
  availableMajors: string[] = [];
  availableMinors: string[] = [];
  availableSchools: string[] = [];
  availableInterests: string[] = [];
  availableCertifications: DropDownOptions[] = [];
  allaudiences: Audience[] = [];
  quillConfiguration = QuillConfiguration;
  newAudienceName = '';

  entityType = EntityType;
  opportunityTypeEnum = OpportunityType;

  constructor(
    route: ActivatedRoute,
    private router: Router,
    private snackBar: ToastService,
    private fb: FormBuilder,
    private employerService: EmployerService,
    private skillService: SkillService,
    private studyService: StudyService,
    private schoolService: SchoolService,
    private interestService: FieldOfInterestService,
    private opportunities: OpportunityService,
    private audienceService: AudienceService,
    private users: UserService,
    private modals: ModalService,
    private upload: UploadService,
    public cdn: CDNService,
    public resumeService: ResumeService,
    public opportunityService: OpportunityService
  ) {
    if (route.snapshot.params.employerUrlName !== null && route.snapshot.params.employerUrlName !== undefined) {
      this.employerUrlName = route.snapshot.params.employerUrlName;
    }
    if (route.snapshot.params.urlName !== null && route.snapshot.params.urlName !== undefined) {
      this.urlName = route.snapshot.params.urlName;
    }

    this.user = this.users.authenticatedUser;
    this.opportunity = this.getDefaultOpportunity();
    this.createForm();
  }

  next() {
    this.stepper.next()
  }
  async saveAndNext(){
    const saveSuccessful = await this.onSave()
        if (saveSuccessful) {
          this.stepper.next()
        }
  }

  async ngOnInit() {
    this.working = 10;
    await this.loadEmployer();
    await this.loadLists();
    this.createForm();
    this.working = 0;

  }

  async loadEmployer(): Promise<void> {
    try {
      this.employer = await this.employerService.getEmployer(this.employerUrlName);
      this.working += 20;
      this.availableLocations = this.employer.addresses;
      this.selectLocations = [];
      this.employees = [];
      (await this.employerService.getEmployees(this.employer.urlName)).forEach((e: User) => { 
        this.employees.push({ value: e.id, label: `${e.firstName} ${e.lastName}` });
      });
      this.working += 20; 

      this.availableLocations.forEach((value: string) => {
        this.selectLocations.push({
          value: value,
          label: value
        });
      });
      this.selectLocations.sort((first, second) => {
        return first.value.toLowerCase() > second.value.toLowerCase() ? 1 :
               first.value.toLowerCase() < second.value.toLowerCase() ? -1 : 0;
      });
      this.selectLocations.splice(0, 0, {
        label: 'Remote',
        value: null
      });

      this.working += 20;
      await this.loadOpportunity();
      this.working += 20;

    } catch (error) {
      console.error(error);
      this.snackBar.error({
        title: 'Error',
        message: 'Could not load employer'
      });
    }
  }

  getDefaultOpportunity(): Opportunity {
   

    return {
      accepted: false,
      allowRemote: false,
      applicationCap: null,
      applied: false,
      bannerUrl: '',
      complete: false,
      description: '',
      employerId: this.employer ? this.employer.id : 0,
      employerLogoUrl: this.employer ? this.employer.logoUrl : '',
      employerName: this.employer ? this.employer.name : '',
      employerUrlName: this.employer ? this.employer.urlName : '',
      expectations: '',
      favorite: false,
      hours: null,
      id: 0,
      industries: [],
      // location: [],
      numberApplicants: 0,
      opportunityLengthId: 0,
      opportunityLength: '',
      opportunityType: '',
      opportunityTypeId: null,
      pay: null,
      payType: null,
      publishEndDate: null,
      publishStartDate: null,
      rejected: false,
      score: 0,
      skills: [],
      startDate: null,
      thumbnailUrl: '',
      title: '',
      urlName: '',
      views: 0,
      locations: [],
      majors: [],
      minors: [],
      schools: [],
      degrees: [],
      interests: [],
      applicationUrl: '',
      requireResume: this.employer ? this.employer.requireResume : false,
      requireCoverLetter: this.employer ? this.employer.requireCoverLetter : false,
      requireVideoResume: this.employer ? this.employer.requireVideoResume : false,
      requireTranscript: this.employer ? this.employer.requireTranscript : false,
      youtubeUrl: null,
      active: false
    };
  }

  async loadOpportunity(): Promise<void> {
    try {
      if (this.urlName === 'new') {
        this.opportunity = this.getDefaultOpportunity();
        this.createForm();
      } else {
        this.opportunity = await this.opportunities.getOpportunity(this.urlName).toPromise();
        if (this.opportunity.allowRemote) {
          this.opportunity.locations.push('Remote');
        }
        await this.populateOpportunityLengths(this.opportunity.opportunityTypeId);
        this.createForm();
      }

      this.activeOpportunityType = this.opportunity.opportunityType;

      this.working += 20;
    } catch (error) {
      console.error(error);
      this.snackBar.error({
        title: 'Error',
        message: 'Could not load employer'
      });
    }
  }

  async loadLists(): Promise<void> {
    try {
      this.opportunityTypeList = this.opportunityService.getOpportunityTypesDropdownValues();
      this.availableSkills = await  this.skillService.get();
      this.working += 10;
      this.availableMajors = await this.studyService.get();
      this.working += 10;
      this.availableMinors = this.availableMajors;
      this.working += 10;
      this.availableSchools = await this.schoolService.getSchoolNames();
      this.working += 10;
      this.availableInterests = (await this.interestService.get()).map(s => s.name);

      this.availableCertifications = (await this.resumeService.getCertifications()) .map(s => ({
        label: s.name,
        value: s.id
      }));
      this.working += 10;
      this.allaudiences = await this.audienceService.get(this.employer.urlName);
      this.selectAudiences = [];
      this.allaudiences.forEach(a => {
        this.selectAudiences.push({
          label: a.name,
          value: a.id
        });
      });
    } catch (error) {
      console.error(error);
      this.snackBar.error({
        title: 'Error',
        message: 'There was a problem gathering the information needed.'
      });
    }
  }

  createForm(): void {

    const selectedDegrees: string[] = [];
    if (this.opportunity?.degrees) {
      this.opportunity.degrees.forEach(d => {
        selectedDegrees.push(this.degreeLookup.find(dl => dl.id === d).name);
      });
    }

    this.formGroup = this.fb.group({
      Minors: new FormControl(this.opportunity ? this.opportunity.minors ?? [] : []),
      Majors: new FormControl(this.opportunity ? this.opportunity.majors ?? [] : []),
      Name: new FormControl(this.opportunity?.title),
      ApplicationLink: new FormControl(this.opportunity?.applicationUrl),
      Contact: new FormControl(this.opportunity?.contactEmail),
      Description: new FormControl(this.opportunity?.description),
      School: new FormControl(this.opportunity?.schools),
      OpportunityType: new FormControl(this.opportunity?.opportunityType),
      FieldOfInterest: new FormControl(this.opportunity?.interests),
      GraduationYear: new FormControl(this.opportunity?.graduationYear),
      Skills: new FormControl(this.opportunity?.skills),
      ManagerId: new FormControl(this.opportunity?.managerId),
      // opportunity types
      Shadowing: new FormControl(),
      Tour : new FormControl(),
      Volunteering: new FormControl(),
      Employment: new FormControl(),
      CoOp: new FormControl(),
      Internship: new FormControl(),
      
      // candiate Types
      UnderGraduate: new FormControl(),
      Graduate: new FormControl(),
      Doctorate: new FormControl(),
      TechnicalDiploma: new FormControl(),
      HighSchool: new FormControl(),
      PostGraduate: new FormControl(),
      JobCenters: new FormControl(),


    });
  }

  isPaid(opportunityType: string) {
    return opportunityType && opportunityType !== '3' && opportunityType !== '5';
  }
  showHours(opportunityType: string) {
    return opportunityType && opportunityType !== '5';
  }

  async onSave(): Promise<boolean> {
    let saved = false;
    try {
      this.working = 25;

      const opportunityObj = this.getOpportunity()
      const selectedDegrees: number[] = [];
      ((this.degrees?.value ?? []) as string[]).forEach(d => {
        selectedDegrees.push(this.degreeLookup.find(dl => dl.name === d).id);
      });

      if (this.opportunity?.id) {
        this.opportunity = await this.opportunities.updateOpportunity(
          this.opportunity.id, {
            title: opportunityObj.title,
            description: opportunityObj.description,
            expectations: opportunityObj.expectations, // this.expectations.value,
            hours: opportunityObj.hours,
            pay: opportunityObj.pay,
            payType: opportunityObj.payType,
            opportunityTypeId: opportunityObj.opportunityTypeId,
            startDate: opportunityObj.startDate,
            publishStartDate: opportunityObj.publishStartDate,
            publishEndDate: opportunityObj.publishEndDate,
            applicationCap: opportunityObj.applicationCap,
            employerId: opportunityObj.employerId,
            allowRemote: opportunityObj.allowRemote,
            opportunityLengthId: opportunityObj.opportunityLengthId,
            opportunityLength: opportunityObj.opportunityLength,
            requireCoverLetter: opportunityObj.requireCoverLetter,
            requireResume: opportunityObj.requireResume,
            requireVideoResume: opportunityObj.requireVideoResume,
            requireTranscript: opportunityObj.requireTranscript,
            youtubeUrl: opportunityObj.youtubeUrl,
            applicationUrl: opportunityObj.applicationUrl,
            id: this.opportunity.id,
            active: this.opportunity.active,
            locations: this.locations.value,
            schools: this.schools.value,
            majors: this.majors.value,
            minors: this.minors.value,
            degrees: selectedDegrees,
            skills: this.skills.value
          });
        saved = true;
        if (!this.opportunity.opportunityType) {
          this.opportunity.opportunityType = this.opportunityTypes.find(ot => ot.value === this.opportunity.opportunityTypeId).label;
        }
        // this.snackBar.success({
        //   title: 'Opportunity Created',
        //   message: 'Your opportunity has been updated.'
        // });
      } else {
        this.opportunity.degrees = selectedDegrees;
        this.opportunity = await this.opportunities.createOpportunity(opportunityObj).toPromise();
        saved = true;
        // this.snackBar.success({
        //   title: 'Opportunity Created',
        //   message: 'Your opportunity has been created.'
        // });
      console.log(this.opportunity);

      }
      this.steps[this.activeStep].next();
    } catch (error) {
      this.snackBar.error({
        title: 'Error creating opportunity',
        message: 'There was an error creating your opportunity, please check the form and try again'
      });
    } finally {
      this.working = 0;
    }
    return saved;
  }

  toggleActive(): void {
    const a = this.formGroup.get('Active');
    a.setValue(!a.value);
  }
  cancel(): void {
    this.router.navigate(['/employers', this.employerUrlName, 'opportunity']);
  }

  validateStartDates(c: AbstractControl) {
    if (c.get('StartDate')) {
      const start = moment(c.get('StartDate').value);
      const now = moment(new Date());

      if (start.isValid()) {
        if (start.isBefore(now)) {
          c.get('StartDate').setErrors({
            invalidPastDate: true
          });
          return {
            invalidPastDate: true
          };
        } else {
          c.get('StartDate').setErrors(null);
          return null;
        }
      } else {
        // c.get('StartDate').setErrors({
        //   required: true
        // });
        return {
          required: true
        };
      }
    }
  }
  validatePublishDates(c: AbstractControl) {
    if (c.get('PublishStartDate') && c.get('PublishEndDate')) {
      if (!c.get('PublishStartDate').value || !c.get('PublishEndDate').value) {
        return null;
      }

      const start = moment(c.get('PublishStartDate').value);
      const end = moment(c.get('PublishEndDate').value);
      if (start < end) {
        c.get('PublishEndDate').setErrors(null);
        return null;
      } else {
        c.get('PublishEndDate').setErrors({
          afterDate: true
        });
        return {
          afterDate: true
        };
      }
    } else {
      return null;
    }
  }

  selectLocation(value: DropDownOptions): void {
    const val = this.locations.value;
    if (val && val.length) {
      val.push(value.label);
      this.locations.setValue(val);
    } else {
      this.locations.setValue([ `${value.label}` ]);
    }
    this.ddLocation.value = null;
  }
  deselectLocation(value: string): void {
    this.locations.setValue(this.locations.value.filter(v => v !== value));
  }

  async selectAudience(value: DropDownOptions): Promise<void> {
    const aud = this.allaudiences.find(aa => aa.id === value.value);
    if (aud) {
      try {
        this.working = 10;
        let current = this.skills.value as string[];
        current.forEach(async s => {
          await this.opportunities.removeProfile(this.opportunity.urlName, 'skill', s).toPromise();
        });
        this.working = 20;
        this.skills.setValue(aud.skills);
        aud.skills.forEach(async s => {
          await this.opportunities.addProfile(this.opportunity.urlName, 'skill', s).toPromise();
        });
        this.working = 30;
        current = this.majors.value as string[];
        current.forEach(async m => {
          await this.opportunities.removeProfile(this.opportunity.urlName, 'major', m).toPromise();
        });
        this.working = 40;
        this.majors.setValue(aud.majors);
        aud.majors.forEach(async m => {
          await this.opportunities.addProfile(this.opportunity.urlName, 'major', m).toPromise();
        });
        this.working = 50;
        current = this.skills.value as string[];
        current.forEach(async m => {
          await this.opportunities.removeProfile(this.opportunity.urlName, 'minor', m).toPromise();
        });
        this.working = 60;
        this.minors.setValue(aud.minors);
        aud.minors.forEach(async m => {
          await this.opportunities.addProfile(this.opportunity.urlName, 'minor', m).toPromise();
        });
        this.working = 70;
        current = this.schools.value as string[];
        current.forEach(async s => {
          await this.opportunities.removeProfile(this.opportunity.urlName, 'school', s).toPromise();
        });
        this.working = 80;
        this.schools.setValue(aud.schools);
        aud.schools.forEach(async s => {
          await this.opportunities.addProfile(this.opportunity.urlName, 'school', s).toPromise();
        });
        this.working = 90;
        this.interests.setValue(aud.interests);
        aud.interests.forEach(async s => {
          await this.opportunities.addProfile(this.opportunity.urlName, 'interest', s).toPromise();
        });

         this.formGroup.get('AudienceDescription').setValue(aud.description);
         this.formGroup.get('Details').setValue(aud.details);
 
         this.formGroup.get('Shadowing').setValue(aud.jobShadow);
         this.formGroup.get('Tour').setValue(aud.tour);
         this.formGroup.get('Volunteering').setValue(aud.volunteering);
         this.formGroup.get('Employment').setValue(aud.employment);
         this.formGroup.get('CoOp').setValue(aud.coOp);
         this.formGroup.get('Internship').setValue(aud.internship);
         this.formGroup.get('UnderGraduate').setValue(aud.underGraduate);
         this.formGroup.get('Graduate').setValue(aud.graduate);
         this.formGroup.get('Doctorate').setValue(aud.doctorate);
         this.formGroup.get('TechnicalDiploma').setValue(aud.technicalDiploma);
         this.formGroup.get('HighSchool').setValue(aud.highSchool);
         this.formGroup.get('PostGraduate').setValue(aud.postGraduate);
         this.formGroup.get('JobCenters').setValue(aud.jobCenters);
        this.working = 100;
        this.snackBar.success({
          message: 'Audience has been applied to the opportunity.'
        });
      } catch (error) {
        this.snackBar.error({
          message: 'There was a problem updating your audience, please try again later.'
        });
      } finally {
        this.working = 0;
      }
    }
  }

  async populateOpportunityLengths(id: number) {
    this.opportunityTypeLengths = [];
    (await this.opportunities.getOpportunityLengths(id)).map(val => {
      this.opportunityTypeLengths.push({
        label: val.value,
        value: val.id
      });
    });
  }

  async selectOpportunityType(value: DropDownOptions): Promise<void> {
    if (value?.value) {
      this.steps[0].title = value.label;
      this.opportunityType.setValue(value.value);
      switch (this.opportunityType?.value ?? '')
      {
        case this.opportunityTypeEnum.CoOp:
          this.applicationUrl.setValue(this.employer.externalCoopLink );
          this.applicationUrl.enable();
          break;
        case this.opportunityTypeEnum.Internship:
          this.applicationUrl.setValue(this.employer.externalInternshipLink);
          this.applicationUrl.enable();
          break;
        case this.opportunityTypeEnum.Volunteering:
          this.applicationUrl.setValue(this.employer.externalVolunteerLink);
          this.applicationUrl.disable();
          break;
        case this.opportunityTypeEnum.Employment:
          this.applicationUrl.setValue(this.employer.externalEmploymentLink );
          this.applicationUrl.enable();
          console.log(this.applicationUrl);
          break;
        default:
          this.applicationUrl.disable();
          break;
      }

      this.activeOpportunityType = value.label;
      this.opportunityTypeLengths = [];

      await this.populateOpportunityLengths(value.value)

      if (this.showPay(value.label)) {
        this.pay.addValidators(Validators.required);
        this.payType.addValidators(Validators.required);
      } else {
        this.pay.removeValidators(Validators.required);
        this.payType.removeValidators(Validators.required);
      }

      if (this.showLength(value.label)) {
        this.opportunityLength.addValidators(Validators.required);
      } else {
        this.opportunityLength.removeValidators(Validators.required);
      }

      this.pay.updateValueAndValidity();
      this.payType.updateValueAndValidity();
      this.opportunityLength.updateValueAndValidity();
      this.stepper.next()
    }
  }
  getOpportunityTypeLength(): string {
    return this.opportunityTypeLengths.find(ot => ot.label === this.opportunityLength.value)?.label;
  }
  selectPayType(value: DropDownOptions): void {
    this.payType.setValue(value.value);
    if (this.opportunityType.value === OpportunityType.Employment || this.opportunityType.value === OpportunityType.Internship) {
      this.pay.addValidators(Validators.required);
    } else {
      this.pay.removeValidators(Validators.required);
    }
  }
  selectOpportunityLength(value: DropDownOptions): void {
    this.opportunityLengthId.setValue(value.value);
    this.opportunityLength.setValue(value.label);
    this.ddOpportunityTypeLength.selectOption(value, true);
  }

  removeSchool(value: string): void {
    console.debug(`Remove ${value}`);
  }
  selectSchool(value: string): void {
    const currentValue = this.schools.value;
    if (!currentValue?.length) {
      this.schools.setValue([ value ]);
    } else {
      currentValue.push(value);
      this.schools.setValue(currentValue);
    }
  }
  selectDegree(value: string): void {
    const currentValue = this.degrees.value;
    if (!currentValue?.length) {
      this.degrees.setValue([ value ]);
    } else {
      currentValue.push(value);
      this.degrees.setValue(currentValue);
    }
  }
  selectInterest(value: string): void {
    const currentValue = this.interests.value;
    if (!currentValue?.length) {
      this.interests.setValue([ value ]);
    } else {
      currentValue.push(value);
      this.interests.setValue(currentValue);
    }
  }
  selectCertification(value: string): void {
    const currentValue = this.certifications.value;
    if (!currentValue?.length) {
      this.certifications.setValue([ value ]);
    } else {
      currentValue.push(value);
      this.certifications.setValue(currentValue);
    }
  }

  selectMajor(value: string): void {
    const currentValue = this.majors.value;
    if (!currentValue?.length) {
      this.majors.setValue([ value ]);
    } else {
      currentValue.push(value);
      this.majors.setValue(currentValue);
    }
  }

  selectMinor(value: string): void {
    const currentValue = this.minors.value;
    if (!currentValue?.length) {
      this.minors.setValue([ value ]);
    } else {
      currentValue.push(value);
      this.minors.setValue(currentValue);
    }
  }

  selectSkill(value: string): void {
    const currentValue = this.skills.value;
    if (!currentValue?.length) {
      this.skills.setValue([value]);
    } else {
      currentValue.push(value);
      this.skills.setValue(currentValue);
    }
  }

  view(audience: Audience, e: Event): boolean {
    e.preventDefault();

    this.router.navigate(['employer', this.employerUrlName, 'audience']);

    return false;
  }

  get isNew(): boolean {
    return this.opportunity?.id === 0;
  }

  getFormControl(name: string): FormControl {
    return this.formGroup?.get(name) as FormControl;
  }
  get active(): FormControl {
    return this.getFormControl('Active');
  }
  get audience(): FormControl {
    return this.getFormControl('Audience');
  }

  get title(): FormControl {
    return this.getFormControl('Title');
  }
  get opportunityType(): FormControl {
    return this.getFormControl('OpportunityType');
  }

  get description(): FormControl {
    return this.getFormControl('Description');
  }
  get expectations(): FormControl {
    return this.getFormControl('Expectations');
  }

  get startDate(): FormControl {
    return this.getFormControl('StartDate');
  }
  get publishStartDate(): FormControl {
    return this.getFormControl('PublishStartDate');
  }
  get publishEndDate(): FormControl {
    return this.getFormControl('PublishEndDate');
  }
  get opportunityLengthId(): FormControl {
    return this.getFormControl('OpportunityLengthId');
  }
  get opportunityLength(): FormControl {
    return this.getFormControl('OpportunityLength');
  }

  get allowRemote(): FormControl {
    return this.getFormControl('AllowRemote');
  }
  get locations(): FormControl {
    return this.getFormControl('Locations');
  }

  get pay(): FormControl {
    return this.getFormControl('Pay');
  }
  get payType(): FormControl {
    return this.getFormControl('PayType');
  }
  get hours(): FormControl {
    return this.getFormControl('Hours');
  }

  get skills(): FormControl {
    return this.getFormControl('Skills');
  }

  get applicationCap(): FormControl {
    return this.getFormControl('ApplicationCap');
  }
  get majors(): FormControl {
    return this.getFormControl('Majors');
  }
  get minors(): FormControl {
    return this.getFormControl('Minors');
  }
  get schools(): FormControl {
    return this.getFormControl('Schools');
  }
  get degrees(): FormControl {
    return this.getFormControl('Degrees');
  }
  get interests(): FormControl {
    return this.getFormControl('Interests');
  }
  get certifications(): FormControl {
    return this.getFormControl('Certifications');
  }
  get applicationUrl(): FormControl {
    return this.getFormControl('ApplicationUrl')
  }
  get needsApplicationUrl(): FormControl {
    return this.getFormControl('NeedsApplicationUrl')
  }
  get youtubeUrl(): FormControl {
    return this.getFormControl('YoutubeUrl')
  }
  get requireResume(): FormControl {
    return this.getFormControl('RequireResume')
  }
  get requireVideoResume(): FormControl {
    return this.getFormControl('RequireVideoResume')
  }
  get requireCoverLetter(): FormControl {
    return this.getFormControl('RequireCoverLetter')
  }
  get requireTranscript(): FormControl {
    return this.getFormControl('RequireTranscript')
  }

  imageUploaded(e: string[]): void {
    this.opportunity.thumbnailUrl = e[0];
    this.modals.close('uploadImage');
  }
  async imageSelected(e: File[]): Promise<void> {
    this.working = 10;
    try {
      this.opportunity.thumbnailUrl = (await this.upload.push(e, 'new', 0))[0];
      this.working = 50;
      this.modals.close('uploadImage');
    } catch (error) {
      this.snackBar.error({
        message: error,
      });
    } finally {
      this.working = 0;
    }
  }

  bannerUploaded(e: string[]): void {
    this.opportunity.bannerUrl = e[0];
    this.modals.close('uploadBanner');
  }
  async bannerSelected(e: File[]): Promise<void> {
    this.working = 10;
    try {
      this.opportunity.bannerUrl = (await this.upload.push(e, 'new', 0))[0];
      this.working = 50;
      this.modals.close('uploadBanner');
    } catch (error) {
      this.snackBar.error({
        message: error.message,
      });
    } finally {
      this.working = 0;
    }
  }

  openModal(id: string): void {
    this.modals.open(id);
  }

  close(id: string): void {
    this.modals.close(id);
  }

  getFormValidationErrors(): string[] {
    const message = [];
    Object.keys(this.formGroup.controls).forEach(key => {

    const controlErrors: ValidationErrors = this.formGroup.get(key).errors;
    if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          message.push(`Input: ${key}, Error: ${keyError}, Current Value: ${this.formGroup.get(keyError)?.value}`);
        });
      }
    });
    return message;
  }

  separate(value: string): string[] {
    return value && value.length ? value.split('|') : null;
  }

  async removeSelection(search: string, control: FormControl, attribute: string): Promise<void> {
    const value: string[] = control.value;

    for (let i = 0; i < value.length; ++i) {
      if (value[i] === search) {
        value.splice(i, 1);
        break;
      }
    }
    control.setValue(value);
    if (attribute === 'degree') {
      search = this.degreeLookup.find(dl => dl.name === search).id.toString();
    }
    await this.opportunities.removeProfile(this.opportunity.urlName, attribute, search).toPromise();
  }

  async createAudience(): Promise<void> {
    try {
      this.working = 25;
      const aud = await this.audienceService.updateAudience({
        id: 0,
        interests: this.interests.value,
        locations: this.locations.value,
        majors: this.majors.value,
        minors: this.minors.value,
        name: this.newAudienceName,
        schools: this.schools.value,
        skills: this.skills.value,
        jobShadow: this.formGroup.get('Shadowing').value,
        tour: this.formGroup.get('Tour').value,
        volunteering: this.formGroup.get('Volunteering').value,
        employment: this.formGroup.get('Employment').value,
        coOp: this.formGroup.get('CoOp').value,
        internship: this.formGroup.get('Internship').value,

        underGraduate: this.formGroup.get('UnderGraduate').value,
        graduate: this.formGroup.get('Graduate').value,
        doctorate: this.formGroup.get('Doctorate').value,
        technicalDiploma: this.formGroup.get('TechnicalDiploma').value,
        highSchool: this.formGroup.get('HighSchool').value,
        postGraduate: this.formGroup.get('PostGraduate').value,
        jobCenters: this.formGroup.get('JobCenters').value,
        description: this.formGroup.get('audienceDescription').value,
        details: this.formGroup.get('Details').value
        

      });
      this.working = 50;
      this.newAudienceName = '';
      this.allaudiences.push(aud);
      this.close('saveAudience');
    } catch (error) {
      this.snackBar.error({
        message: 'There was a problem creating your audience'
      });
    } finally {
      this.working = 0;
    }
  }
  async updateAudience(id: number): Promise<void> {
    try {
      this.working = 25;
      const aud = await this.audienceService.updateAudience({
        id,
        interests: this.interests.value,
        locations: this.locations.value,
        majors: this.majors.value,
        minors: this.minors.value,
        name: this.allaudiences.find(a => a.id === id).name,
        schools: this.schools.value,
        skills: this.skills.value,
      }, id);
      this.working = 50;
      this.allaudiences = this.allaudiences.filter(aa => aa.id !== aud.id);
      this.allaudiences.push(aud);
      this.close('saveAudience');
    } catch (error) {
      this.snackBar.error({
        message: 'There was a problem creating your audience'
      });
    } finally {
      this.working = 0;
    }
  }

  currentStepValid() {
    return this.steps[this.activeStep].valid()
  }

  getOpportunity() {
    let allowRemote = false;
    if (this.locations?.value) {
      for (const l of (this.locations?.value ?? [])) {
        if (l === 'Remote') {
          allowRemote = true;
          break
        }
      }
    } else {
      allowRemote = true;
    }
    const locs = ((this.locations?.value ?? []) as string[]).filter((x) => x !== 'Remote');

    const selectedDegrees: number[] = [];
    ((this.degrees?.value ?? []) as string[]).forEach(d => {
      selectedDegrees.push(this.degreeLookup.find(dl => dl.name === d).id);
    });

    if (typeof this.opportunityType.value === 'string' || this.opportunityType.value instanceof String)
    {
      this.opportunityType.setValue(parseInt(this.opportunityType.value as string, 10));
    }
    if (!this.locations?.value?.length) {
      this.locations?.setValue([]);
    }

    return {
      title: this.title?.value,
      description: this.description?.value,
      expectations: '', // this.expectations.value,
      hours: (this.hours?.value?.length ? parseInt(this.hours.value, 10) : 0).toString() as any,
      opportunityTypeId: this.opportunityType?.value,
      startDate: this.startDate?.value,
      publishStartDate: this.publishStartDate?.value,
      publishEndDate: this.publishEndDate?.value,
      applicationCap: parseInt(this.applicationCap?.value, 10),
      employerId: this.employer.id,
      allowRemote,
      opportunityLengthId: this.opportunityLengthId?.value,
      opportunityLength: this.opportunityLength?.value,
      locations: locs,
      skills: this.skills?.value,
      majors: this.majors?.value,
      minors: this.minors?.value,
      schools: this.schools?.value,
      degrees: selectedDegrees,
      interests: this.interests?.value,
      bannerUrl: this.opportunity?.bannerUrl,
      thumbnailUrl: this.opportunity?.thumbnailUrl,
      requireCoverLetter: this.requireCoverLetter?.value || false,
      requireResume: this.requireResume?.value || false,
      requireVideoResume: this.requireVideoResume?.value || false,
      requireTranscript: this.requireTranscript?.value || false,
      youtubeUrl: this.youtubeUrl?.value,
      applicationUrl: this.applicationUrl?.value,
    } as Opportunity
  }

  toggle(control: FormControl, value?: boolean) {
    value = value == null ? !control.value : value
    if (value !== control.value) {
      control.setValue(value)
    }
  }

  async publish(): Promise<void> {
    this.opportunity.active = true;
    await this.onSave()
  }
  toggleStartDateTBD(): void {
    const edit = this.startDate.enabled;
    if (edit) {
      this.startDate.disable();
      this.startDate.setValue(null);
      this.startDate.removeValidators(Validators.required);
    } else {
      this.startDate.addValidators(Validators.required);
      this.startDate.enable();
      this.startDate.markAsDirty();
    }
  }

  showPay(value: string): boolean {
    let show = false;
    switch (value) {
      case 'Internship':
      case 'Employment':
      case 'Co-Op':
      case 'Other':
        show = true;
        break;
    }
    return show;
  }

  showCap(value: string): boolean {
    let show = false;

    switch (value) {
      case 'Tour':
      case 'Job Shadowing':
        show = true;
        break;
    }
    return show;
  }

  showLength(value: string): boolean {
    let show = false;
    switch (value) {
      case 'Internship':
      case 'Employment':
      case 'Volunteering':
      case 'Job Shadowing': 
        show = true;
        break;
    }
    return show;
  }

  publishRequirements(): string[] {
    const missing: string[] = [];
    if (!this.opportunity.title?.length) {
      missing.push('A title is required');
    }
    if (!this.opportunity.description?.length) {
      missing.push('A description is required.')
    }
    if (this.opportunity.opportunityLengthId === null || this.opportunity.opportunityLengthId === undefined) {
      missing.push('Opportunity Length is required.');
    }
    if (!this.opportunity.schools.length) {
      missing.push('Select at least 1 school.');
    }
    if (!this.opportunity.degrees.length) {
      missing.push('Select at least 1 education level.');
    }
    if (!this.opportunity.majors.length) {
      missing.push('Select at least 1 major.');
    }
    if (!this.opportunity.skills.length)   {
      missing.push('Select at least 1 skill.');
    }
    // interests not required
    // minors not required

    return missing;
  }

  async suggestDescription(): Promise<void> {
    try {
      this.working = 60;
      const result = await this.opportunities.SuggestJobDescription(this.title.value);
      if (result?.value.length) {
        this.description.setValue(result.value.replace(/\n/g,'<br />'));        
      }  
    } catch (error) {
      this.snackBar.error({
        title: 'There was an error getting your job description',
        message: error?.message
      });
    } finally {
      this.working = 0;
    }
  }

  async suggestThumbnail(): Promise<void> {

    try {
      this.working = 50;
      const f = await this.opportunities.SuggestImage(this.opportunity.id, this.title.value, 'medium', 'thumb');
      this.opportunity.thumbnailUrl = f.value;
      this.snackBar.success({message: 'saved'});
    } catch (error) {
      this.snackBar.error({ message: error?.message ?? error });
    } finally {
      this.working = 0;
    }
    }

  async suggestBanner(): Promise<void> {
    try {
      this.working = 50;
      const f = await this.opportunities.SuggestImage(this.opportunity.id, this.title.value, 'large', 'banner');
      this.opportunity.bannerUrl = f.value;
      this.snackBar.success({message: 'saved'});
    } catch (error) {
      this.snackBar.error({ message: error?.message ?? error });
    } finally {
      this.working = 0;
    }
  }

  toggleProp(prop: string) {
    const control = this.formGroup.get(prop)
    control.setValue(!control.value);
  }

 
  async logoSelected(e: File[]): Promise<void> {
    this.working = e?.length ?? 10;
    try {
      this.working = 50;
      this.modals.close('uploadLogo');
    } catch (error) {
      this.snackBar.error({
        message: 'There was a problem selecting your logo',
      });
    } finally {
      this.working = 0;
    }
  }

  logoUploaded(e: string[]): void {
    if (e?.length) {
      this.employer.logoUrl = e[0];
      this.modals.close('uploadlogo');
    }
  }

}
