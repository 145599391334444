import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class SchoolApiConfig {
    static path: string = environment.api.school.path;
    static version: string | number = environment.api.culture.version;

    public static setApiVersion(version: string | undefined = 'api'): void {
        SchoolApiConfig.version = version;
    }

    public static getApiVersion(): string | number {
        return SchoolApiConfig.version;
    }

    public static getPath(): string {
        return SchoolApiConfig.path;
    }

    public static getFullUrl(path): string {
        if (SchoolApiConfig.getApiVersion()) {
            return [SchoolApiConfig.getPath(), SchoolApiConfig.getApiVersion(), path].join('/');
        } else {
            return [SchoolApiConfig.getPath(), path].join('/');
        }
    }
}
