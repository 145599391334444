<form [formGroup]="scheduleForm">

    <div class="flex-rows mb-small">
        
        <div *ngFor="let day of daysOfWeek">
            <label class="form-label">{{ day }}</label>
            <ng-select [items]="availabilityOptions" [formControlName]="getAvailabilityDropdownName(day)"
            (change)="setAvailability($event.label, day)" class="drop-down drop-down-button-blue"
            [placeholder]="'Set Availability'"></ng-select>
            <div class="flex-columns">
                <div *ngFor="let hour of availableHoursOfDay">
                    <label>
                        <input type="checkbox" [formControlName]="getAvailabilityCheckboxName(day, hour)" (change)="emitWeek.emit()">
                        {{ hour | dayTimeHour }}
                </label>
            </div>
        </div>
        
    </div>
</div>

</form>