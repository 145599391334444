<div class="student-wrapper" [ngClass]="{ 'employer-view': user?.isEmployer }">

    <div class="bg-teal pt-large px-medium">
        <div class="text text-title text-700 text-lime mb-medium">
            Advisors
        </div>
        <a class="flex-rows" [routerLink]="[ '/dashboard']">
            <img src="/assets/images/icons/dashboard.svg" style="height: 76px;" class="mx-auto" />
            <div class="text text-white mx-auto text-center mb-medium">
                Dashboard
            </div>
        </a>
        <div class="mb-medium px-medium">
            <div class="spacer spacer-white"></div>
        </div>
        <a class="flex-rows" [routerLink]="[ '/school', user?.student?.schoolUrlName, 'meet']">
            <img src="/assets/images/icons/shake.svg" style="height: 43px;" class="mx-auto" />
            <div class="text text-white mx-auto text-center mb-medium mt-medium">
                Meetings & Feedback
            </div>
        </a>
    </div>
    <div class="bg-cream pt-large pl-medium pr-large">
        <div class="text text-title text-700 mb-large">
            Student Profile
        </div>
        <div class="rounded-corners bg-white profile mb-large">
            <div style="grid-row: 1 /span 2;">
                <div class="avatar">
                    <img [src]="user?.avatar | cdn" alt="profile pic" />
                </div>
                    <a class="btn green mb-small no-link" (click)="shareProfile()">Share Profile</a>

            </div>
            <div class="contact-info">
                <div class="text text-title text-teal">
                    {{ user?.firstName }} {{ user?.lastName }}
                </div>
                <div></div>
                <!-- <div class="text text-subtitle text-700 mb-small">
                    {{ student?.schoolName }}
                </div>
                <div></div> -->
                <div class="text text-subtitle text-800 mb-medium">
                    {{ student?.user?.location?.address?.city?.name }} {{
                    student?.user?.location?.address?.city?.state?.name }}
                </div>
                <!-- <div class="text">
                    <label class="text text-800 mr-small">Phone</label>
                    <a class="btn no-link" href="tel:{{student?.user?.phone}}">{{ student?.user?.phone }}</a>
                    <a class="no-link" href="#">(111) 222-333 (show?)</a>
                </div> -->
                <div class="flex-columns">
                    <label class="text text-800 mr-small">Major{{ student?.majors?.length > 1 ? 's' : '' }}</label>
                    <div class="text">
                        <ng-template ngFor let-m [ngForOf]="student?.majors | sort:'':'asc'" let-isLast="last">
                            {{ m }}{{ !isLast ? ',' : '' }}
                        </ng-template>
                    </div>
                </div>
                <!-- <div class="flex-columns">
                    <label class="text text-800 mr-small">Email</label>
                    <a class="btn no-link" href="tel:{{student?.user?.phone}}">{{ student?.user?.phone }}</a>
                    <a class="no-link" href="#"> {{ 'abc@xyz.com' }} (show?)</a>
                </div> -->
                <div class="flex-columns">
                    <label class="text text-800 mr-small">Year in School</label>
                    <div class="text">
                        {{ getYearInSchool() }}
                    </div>
                </div>

            </div>

            <!-- <div class="separator mb-large"></div> -->

            <div class="flex-columns mb-large" style="grid-column: 2;">
                <div class="flex-rows">
                    <div class="text text-700">
                        About
                        <!-- <ng-template ngFor let-e [ngForOf]="student?.fieldsOfInterest | sort:'':'asc'"
                            let-isLast="last">
                            {{ (e.name ?? e ?? '') }}{{ (isLast ? '' : ', ') }}
                        </ng-template> -->
                    </div>
                    <div class="text" [innerHTML]="student?.biography">
                        
                    </div>
                </div>
            </div>
            <div class="sharable" style="grid-column: 1 / span 2;" *ngIf="user?.isStaff || user?.isStudent">
                <!-- <div class="resumes mr-small">
                    <div class="text text-subtitle text-800">
                        Resumes
                    </div>
                    <div>
                        Post release data?
                    </div>
                </div>
                <div class="coverletters mr-small">
                    <div class="text text-subtitle text-800">
                        Coverletters
                    </div>
                    <div>
                        Post release data?
                    </div>
                </div> -->
                <div></div>
                <!-- <a class="btn green mb-small no-link" (click)="shareProfile()">Share Profile</a> -->

            </div>

        </div>

        <div class="text text-title text-700 mb-large">
            Student Data
        </div>

        <div class="student-data mb-large">

            <!-- <div class="advisor mr-small">
                <div class="card">
                    Advisor Card
                </div>
            </div> -->

            <div class="data flex-rows" disabled-flag>
                <div class="flex-columns mb-small">
                    <span class="text text-title text-teal text-700">11</span> <span class="text text-subtitle text-700 ml-small"> Resumes</span>
                </div>
                <div class="mb-small">
                    <div class="spacer spacer-white"></div>
                </div>
                <div class="mb-small">
                    <span class="text text-title text-teal text-700">2</span> <span class="text text-subtitle text-700 ml-small"> Job Shadows</span>
                </div>
                <div class="mb-small">
                    <div class="spacer spacer-white"></div>
                </div>
                <div>
                    <span class="text text-title text-teal text-700">4</span> <span class="text text-subtitle text-700 ml-small"> Internships</span>
                </div>
            </div>
        </div>

    </div>