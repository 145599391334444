import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NewsletterService } from 'src/app/shared/services/newsletter.service';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/shared/services/toast.service';
import { GTMComponentComponent } from 'src/app/shared/components/gtmcomponent/gtmcomponent.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent extends GTMComponentComponent implements OnInit {

  public formGroup = new FormGroup({});
  private subscriptions: Subscription[] = [];

  constructor(
    private news: NewsletterService,
    private snackBar: ToastService,
    gtm: GoogleTagManagerService
    ) { 
      super(gtm);
    }

  ngOnInit(): void {
    this.formGroup.addControl('email', new FormControl('', [Validators.required, Validators.email]));
    this.formGroup.addControl('name', new FormControl('', [Validators.required]));
  }

  public onSave(e: Event): void {
    e.preventDefault();
    this.subscriptions.push(this.news.post({ name: this.name.value, email: this.email.value }).subscribe(() => {
      this.snackBar.success({
        message: 'Successfully Subscribed!'
      });

      this.formGroup.reset();
    }, (err) => {
      console.log(err);
      this.snackBar.error({
        message: 'There was a problem. Please try again later.'
      });
    }));
  }

  get email() { return this.formGroup.get('email'); }

  get name() { return this.formGroup.get('name'); }

}
