import { CanDeactivateFn } from "@angular/router";
import { ComponentCanDeactivate } from "../components/can-deactivate/can.deactivate.component";

export type CanDeactivateType = boolean;

export interface CanComponentDeactivate {
    canDeactivate: () => CanDeactivateType;
  }
export const canDeactivateGuard: CanDeactivateFn<ComponentCanDeactivate> = (
  component: ComponentCanDeactivate,
  ): CanDeactivateType => {
    return component?.canDeactivate ? component?.canDeactivate() : true;
}