import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'app-request-demo',
  templateUrl: './request-demo.component.html',
  styleUrls: ['./request-demo.component.scss']
})
export class RequestDemoComponent implements AfterViewInit {

  ngAfterViewInit(): void {
    (window as any).hbspt.forms.create({
      region: 'na1',
      portalId: '8012647',
      formId: 'ad56e9f6-3287-4800-a5de-ea2e0ba65b13',
      target: '#hubspotForm'
    });
  
  }

}