<app-progress-bar [value]="isLoading" [splashScreen]="true"></app-progress-bar>
<div class="flex-columns no-gap faculty">
    <div class="bg-teal px-medium py-large">
        <a class="flex-rows" [routerLink]="[ '/dashboard']">
            <img src="/assets/images/icons/dashboard.svg" style="height: 76px;" class="mx-auto" />
            <div class="text text-white mx-auto text-center mb-medium">
                Dashboard
            </div>
        </a>
        <div class="mb-medium">
            <div class="spacer spacer-white"></div>
        </div>
        <a class="flex-rows" [routerLink]="[ '/school', school?.urlName, 'meet']">
            <img src="/assets/images/icons/shake.svg" style="height: 43px;" class="mx-auto" />
            <div class="text text-white mx-auto text-center mb-medium mt-medium">
                Meetings & Feedback
            </div>
        </a>
    </div>
    <div class="bg-cream px-medium py-large">
        <div >
            <div class="text text-title mb-large">
                Advisor
            </div>
            <div class="faculty bg-white mb-large mx-small px-medium py-medium rounded-corners">
                <div class="avatar mr-medium">
                    <img [src]="cdn.toCDN(staff?.user.avatar, true)" alt="profile pic" />
                </div>

                <div class="flex-rows">
                    <h1 class="text text-title no-wrap">{{staff?.user.firstName }} {{ staff?.user.lastName }}</h1>
                    <div class="text text-subtitle">{{school?.primaryAddress}}</div>
                    <div class="text text-subtitle">{{ staff?.positionName }} {{ staff?.departmentName }}</div>
                    <div class="flex-columns">
                        <div class="text text-label text-700">Email</div>
                        <div class="text">{{ staff?.user.email }}</div>
                    </div>
                    <div class="flex-columns">
                        <div class="text text-label text-700">Phone</div>
                        <div class="text">{{ staff?.user.phone }}</div>
                    </div>
                </div>
                <!--
                <div class="user-card mb-medium">
                    <app-user-card [user]="staff?.user" [staff]="staff" [isEdit]="false"></app-user-card>

                    <div>{{ staff?.schoolName }}</div>
                    <div>{{ departmentName }}</div>
                </div> -->


                <div class="flex-columns">
                    <div class="flex-rows">
                        <a class="btn bg-white mb-small"  href="{{staff?.school?.meetingUrl ?? staff?.school?.domain}}">Schedule a Meeting</a>
                        <a (click)="shareProfile()" class="button lime-green mt-auto mb-large">Share Profile</a>
                    </div>
                </div>

            </div>
        </div>

        <div class="text text-title mb-large" [ngClass]="{'hidden': staffList?.length <= 1 }">
            Other Advisors
        </div>
        <div class="faculty-list bg-white px-medium py-medium rounded-corners" [ngClass]="{'hidden': staffList?.length <= 1 }">
            <div [ngClass]="{'hidden': staffList?.length <= 1 }"  style="max-width: 80vw;">
                    <app-list-scroll>
                    <ng-template ngFor let-s [ngForOf]="staffList">
                        <div style="min-width:380px" class="mr-medium mb-small mr-small profile-card bg-white pl-small pr-small py-small">
                            <div class="text text-title">
                                {{ s.firstName }} {{ s.lastName }}
                            </div>
                            <div class="flex-columns">
                                <div class="flex-rows">
                                    <div class="text text-rounded-mplus text-400">
                                        {{ s.positionName }}
                                    </div>
                                    <div class="text text-rounded-mplus text-400">
                                        {{ s.departmentName }}
                                    </div>
                                </div>
                                <div class="avatar ml-auto">
                                    <img [src]="cdn.toCDN(s?.user.avatar, true)" alt="profile pic" />
                                </div>
                            </div>
                            <a class="profile btn btn-white mr-small border-teal" (click)="navigateToStaff(s)"
                              >View Profile</a>
                        </div>
                    </ng-template>
                </app-list-scroll>
            </div>
        </div>

    </div>
</div>