import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { StudyApiConfig } from '../api/study.api.config';
import { skipAuthHeaders } from '../auth/auth-interceptor';

@Injectable()
export class StudyService {

    constructor(private http: HttpClient) { }

    public get(degree?: number): Promise<string[]> {
        if (degree) {
            let params: HttpParams = new HttpParams();
            params = params.set('degree', degree);
    
            return lastValueFrom(this.http.get<string[]>(StudyApiConfig.getFullUrl('names'), { params, headers: skipAuthHeaders }));
        } else {
            return lastValueFrom(this.http.get<string[]>(StudyApiConfig.getFullUrl('names'), { headers: skipAuthHeaders }));            
        }
    }
}
