import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Employer, Opportunity, OpportunityType, Student, StudentOpportunity } from 'src/app/shared/models';
import { ModalService, OpportunityService, StudentService, UserService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-opportunity-card',
  templateUrl: './opportunity-card.component.html',
  styleUrls: ['./opportunity-card.component.scss']
})
export class OpportunityCardComponent implements OnInit {

  @ViewChild('appModal') appModal: ModalComponent

  @Input()
  opportunity: Opportunity;
  @Input()
  student: Student;

  @Input()
  employer: Employer;

  daysPosted: number;
  daysLeft: number;
  destinationLink: string;
  loading = false;

  showOptions = false

  working = 0
  opportunityType = OpportunityType;

  external: boolean = false;
  internal: boolean = false;
  externalApplicationLink: string;

  @Output()
  openApplication: EventEmitter<StudentOpportunity> = new EventEmitter<StudentOpportunity>();

  constructor(
    private students: StudentService,
    public cdn: CDNService,
    private userService: UserService,
    private router: Router,
    private snackBar: ToastService,
    private opportunityService: OpportunityService,
    private modalService: ModalService,
    private studentService: StudentService) {

    }

  ngOnInit(): void {
    const now = new Date();
    const start = new Date(this.opportunity.publishStartDate);
    const end = new Date(this.opportunity.publishEndDate);
    if (this.pubishedYet(this.opportunity)) {
      this.daysPosted = Math.floor((now.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
    } else {
      this.daysPosted = Math.floor((start.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
      if (!this.daysPosted) {
        this.daysPosted = 1;
      }
    }
    this.daysLeft = Math.floor((end.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
    this.destinationLink = this.getOpportunityLink(this.opportunity);
    if (this.userService.authenticatedUser?.employee?.employer) {
      this.showOptions = this.userService.authenticatedUser.employee.employer.id == this.opportunity.employerId && !!this.opportunity.id
    } else {
      this.showOptions = false;
    }

    this.checkApplicationType();
  }

  getOpportunityLink(opportunity: Opportunity) {
    return `/employers/${ encodeURIComponent(opportunity?.employerUrlName) }/opportunity/${ encodeURIComponent(opportunity?.urlName) }`
  }

  checkApplicationType(){
    if(this.opportunity.opportunityTypeId === OpportunityType.Employment && this.employer?.externalEmploymentLink !== null){
      this.external = true;
      this.externalApplicationLink = this.employer?.externalEmploymentLink
    }else if(this.opportunity.opportunityTypeId === OpportunityType.Internship && this.employer?.externalInternshipLink !== null){
      this.external = true;
      this.externalApplicationLink = this.employer?.externalInternshipLink
    }else if(this.opportunity.opportunityTypeId === OpportunityType.CoOp && this.employer?.externalCoopLink !== null){
      this.external = true;
      this.externalApplicationLink = this.employer?.externalCoopLink
    }else if(this.opportunity.opportunityTypeId === OpportunityType.JobShadowing || 
      this.opportunity.opportunityTypeId === OpportunityType.Volunteering || 
      this.opportunity.opportunityTypeId === OpportunityType.Other)
    {
      this.internal = true;
    }
  }


  async redirectToExternalLink() {
    await this.trackApplication();
    window.open(this.externalApplicationLink, '_blank', 'noopener noreferrer');
  }


  async trackApplication() {
    const studentOpp: StudentOpportunity = {
      id: 0,
      applied: true,
      appliedDate: new Date(),
      accepted: false,
      acceptedDate: null,
      rejected: false,
      rejectedDate: null,
      completed: false,
      score: 0,
      studentInterested: true,
      employerInterested: false,
    };
    
    await this.studentService.updateStudentOpportunity(this.student.urlName, studentOpp)
  }

  async toggleFavorite(e: Event): Promise<boolean> {
    e.preventDefault();
    e.stopPropagation();
    if (!this.loading) {
      this.opportunity.favorite = !this.opportunity.favorite;
      this.loading = true;
      this.opportunity.favorite = await this.students.favorite(this.student.urlName, this.opportunity.id);
      this.loading = false;
    }
    return false;
  }

  navigate(e: Event) {
    e.stopPropagation()
    this.router.navigate([this.destinationLink])
  }

  openDetails(e: Event) {
    e.stopPropagation()
    if (!this.showOptions) return
    this.appModal.open()
  }

  copyToClipboard() {
    navigator.clipboard.writeText(new URL(this.destinationLink, window.location.origin).toString());
    this.snackBar.success({
      message: 'Copied to Clipboard!',
      title: 'Copied'
    });
    this.appModal.close()
  }

  async stopOpportunity() {
    try {
      if (this.opportunity.id) {
        this.working = 25
        this.opportunity.publishEndDate = new Date()
        await this.opportunityService.updateOpportunity(this.opportunity.id, this.opportunity);
        this.appModal.close()
        this.snackBar.success({
          message: 'Stopped opportunity',
          title: 'Updated'
        });
      }
    } catch (error) {
      this.snackBar.error({
        message: error.message,
        title: 'Error stopping opportunity'
      });
    } finally {
      this.working = 0
    }
  }

  async duplicateOpportunity() {
    try {
      if (this.opportunity.id) {
        this.working = 25
        const result = await this.opportunityService.createOpportunity(this.opportunity).toPromise()
        this.appModal.close()
        this.router.navigate([this.getOpportunityLink(result), 'edit'])
      }
    } catch (error) {
      this.snackBar.error({
        message: error.message,
        title: 'Error duplicating opportunity'
      })
    } finally {
      this.working = 0
    }
  }

  pubishedYet(value: Opportunity): boolean {
    return new Date(value.publishStartDate) < new Date();
  }

  chipColor(): string {
    let color: string;

    switch (this.opportunity.opportunityTypeId) {
      case OpportunityType.Internship:
        color = 'orange';
        break;
      case OpportunityType.Employment:
        color = 'purple';
        break;
      case OpportunityType.Volunteering:
        color = 'green';
        break;
      case OpportunityType.Other:
        color = 'gray';
        break;
      case OpportunityType.CoOp:
        color = 'green-muted';
        break;
      case OpportunityType.JobShadowing:
      default:
        color = 'blue';
        break;  
    }

    return color;
  }

  open(e: string){
    this.modalService.open(e);
  }

  openApplicationModal(e: any){
    this.openApplication.emit(e);
  }
}
