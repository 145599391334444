import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntityAction, EntityFilter, EntitySearch } from 'src/app/shared/components/entity-list/entity-list.component';
import { Employee, Employer, Student } from 'src/app/shared/models';
import { User } from 'src/app/shared/models/user.model';
import { ArraySortPipe } from 'src/app/shared/pipes';
import { EmployerService, ModalService } from 'src/app/shared/services';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {

  @Input()
  listType: 'student' | 'employee' | '' = '';

  @Input()
  users: User[] = [];
  allUsers: User[] = [];
  displayedColumns = [{
    label: 'First Name', value: 'firstName', displayType: 'text', width: '200px', display: true, sortable: true
  }, {
    label: 'Last Name', value: 'lastName', displayType: 'text', width: '200px', display: true, sortable: true
  }, {
    label: 'Email', value: 'email', displayType: 'text', width: '400px', display: true, sortable: true
  }, {
    label: 'school', value: 'student.schoolName', displayType: 'text', width: '400px', display: true, sortable: true
  }, {
    label: 'employer', value: 'employee.employerName', displayType: 'text', width: '400px', display: true, sortable: true
  }, {
    label: 'active', value: 'active', displayType: 'toggle', width: 'auto', display: true, sortable: true
  }, {
    label: 'created', value: 'created', displayType: 'date', width: 'auto', display: true, sortable: true
  }];

  actions: EntityAction[] = [{
    label: 'View',
    action: (user: User) => { this.select(user); }
  }];


  filters: EntityFilter[] = [{
    displayType: 'toggle',
    label: 'Only Active',
    value: 'active'
  }, {
    displayType: 'toggle',
    label: 'Only Verified',
    value: 'verified'
  }, {
    displayType: 'text',
    label: 'Name',
    value: 'name'
  }];

  isLoading = false;
  showActive = true;
  showInactive = true;
  selectedUser: User | undefined;
  selectedStudent: Student | undefined;
  selectedEmployee: Employee | undefined;
  selectedEmployer: Employer | undefined;

  constructor(
    private employers: EmployerService,
    private modals: ModalService,
    private admin: AdminService,
    private route: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getUsers();

    this.route.params.subscribe(async p => {
      if (p.type === 'student') {
        this.users = this.allUsers.filter(u => u.isStudent);
        this.displayedColumns = this.displayedColumns.filter(c => c.label !== 'employer')
      } else if (p.type === 'employee') {
        this.users = this.allUsers.filter(u => u.isEmployer);
        this.displayedColumns = this.displayedColumns.filter(c => c.label !== 'school')
      }

      if (this.users) {
        this.users = this.users.sort((a: any, b: any) => {
          const da = new Date(a.created);
          const db = new Date(b.created);

          if (da < db) {
            return 1;
          } else if (da > db) {
            return -1;
          } else {
            return 0;
          }
        });
      }

    });
  }

  async getUsers(): Promise<void> {
    this.isLoading = true;
    try {
      this.allUsers = await this.admin.getUsers();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async select(value: User): Promise<void> {
    this.selectedEmployee = undefined;
    this.selectedStudent = undefined;
    this.selectedEmployer = undefined;

    this.selectedUser = value;
    if (value.employee) {
      this.selectedEmployee = value.employee;
      this.selectedEmployer = await this.employers.getEmployer(value.employee.employerUrlName);

    } else if (value.student) {
      this.selectedStudent = value.student;
    }

    this.modals.open('user-profile');
  }

  filterUsers(filter: EntitySearch): void {

    this.users = this.allUsers;

    if (filter.filter?.length && filter.filter.values !== undefined) {
      filter.filter.forEach(f => {
        if (f.value !== undefined && f.value !== null) {
          if (typeof f.value === 'string') {
            this.users = this.users.filter(x => x[f.name].toLowerCase().indexOf(f.value.toLowerCase()) >= 0);
          } else if (typeof f.value === 'boolean') {
            if (f.value) {
              this.users = this.users.filter(x => x[f.name] === f.value);
            }
          } else {
            this.users = this.users.filter(x => x[f.name] === f.value);
          }
        }
      });
    }

    this.users = new ArraySortPipe().transform(this.users, filter.sort, filter.direction === 'asc');
  }

  navigate(value: User): void {
    this.select(value);
  }
  getProperty(e: any, property: string): any {
    const path: string[] = property.split('.');
    return this.getSubProperty(e, path);
  }

  getSubProperty(e: any, path: string[]): any {
    if (!e) {
      return '';
    } else if (!path.length) {
      return e;
    } else if (path.length === 1) {
      return e[path[0]];
    } else {
      const root = path.splice(0, 1);
      return this.getSubProperty(e[root[0]], path);
    }
  }

}
