import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Employer } from '../models/employer.model';
import { AdminApiConfig } from '../api';
import { User } from '../models/user.model';
import { School } from '../models/school.model';
import { Subscription } from '../models/subscription.model';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    constructor(private http: HttpClient) { }

    public getSubscriptions(): Observable<Subscription[]> {
        return this.http.get<Subscription[]>(AdminApiConfig.getFullUrl('subscriptions'));
    }

    public getEmployers(): Observable<Employer[]> {
        return this.http.get<Employer[]>(AdminApiConfig.getFullUrl('employers'));
    }
    public getSchools(): Observable<School[]> {
        return this.http.get<School[]>(AdminApiConfig.getFullUrl('schools'));
    }

    public getUsers(): Promise<User[]> {
        return lastValueFrom(this.http.get<User[]>(AdminApiConfig.getFullUrl('users')));
    }

    public updateEmployer(value: { id }): Observable<Employer> {
        return this.http.post<Employer>(AdminApiConfig.getFullUrl(`employers/${value.id}`), value);
    }

    public updateSchool(value: { id }): Observable<School> {
        return this.http.post<School>(AdminApiConfig.getFullUrl(`schools/${value.id}`), value);
    }

    public updateUser(value: { id }): Observable<User> {
        return this.http.post<User>(AdminApiConfig.getFullUrl(`users/${value.id}`), value);
    }
}



