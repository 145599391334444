import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ReportingApiConfig } from '../api/reporting.api.config';
@Injectable()
export class ReportingService {

    constructor(private http: HttpClient) { }

    public getBasicInfo(): Promise<boolean> {
        return lastValueFrom(this.http.get<boolean>(ReportingApiConfig.getFullUrl(`basic-info`)));
    }

    public getEngagementInfo(): Promise<boolean> {
        return lastValueFrom(this.http.get<boolean>(ReportingApiConfig.getFullUrl(`engagement-info`)));
    }
}