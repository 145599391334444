<div #logo *ngIf="employer?.logoUrl" class="banner">
    <div class="banner-inner"
        [ngStyle]="{ 'background-image': 'url(' + cdn.toCDN(opportunity?.bannerUrl ?? employer?.logoUrl, true) + ')' }">
        <div hidden>
            {{ opportunity.title }}
        </div>
    </div>
</div>
<div class="flex-columns no-gap" style="height: 75vh;">
    <div class="bg-cream pl-medium pr-medium py-large main-panel">
        <div class="mb-medium info">
            <div class="flex-rows ml-large" style="position: relative;">
                <div class="logo-wrapper">
                    <div class="bg-white border-teal mb-small border border-round logo" *ngIf="employer?.smallLogoUrl">
                        <div class="logo-inner"
                            [ngStyle]="{ 'background-image': 'url(' + cdn.toCDN(opportunity?.thumbnailUrl ?? employer?.smallLogoUrl, true) + ')' }">
                            <span class="sr-only">{{ opportunity.title + ' logo' }}</span>
                        </div>
                    </div>
                    <div class="actions mb-medium">
                        <button *ngIf="!user" (click)="login()" class="btn lime-green mb-small">Sign up to
                            Apply
                        </button>
                        <a *ngIf="user?.isStudent && opportunity?.applicationUrl?.length"
                            [href]="opportunity.applicationUrl" (click)="handle('opportunity', 'apply external', 'click', opportunity.title)"
                            class="button button-link btn-green" (click)="open('apply-for-opportunity')">Apply
                            Externally
                        </a>
                        <button
                            *ngIf="user?.isStudent && !opportunity?.applicationUrl?.length && !studentOpportunity?.applied"
                            (click)="handle('opportunity', 'apply', 'click', opportunity.title)"
                            class="btn lime-green mb-small" (click)="open('apply-for-opportunity')">Apply for
                            Position
                        </button>
                        <div *ngIf="user?.isStudent && !opportunity?.applicationUrl?.length && studentOpportunity?.applied === true"
                            class="sticker sticker-lime border border-teal">
                            You've Applied for this Position
                        </div>
                    </div>
                </div>

            </div>
            <div class="flex-rows">
                <div class="text text-title text-right mb-medium">
                    {{ opportunity?.title}}
                </div>

                <span class="chip mb-small ml-auto chip-{{ chipColor() }} opportunity-type">
                    {{ opportunity?.opportunityType }}
                </span>
                <span class="chip chip-green-muted mb-small ml-auto" *ngIf="opportunity?.allowRemote">
                    Remote
                </span>

                <div class="start-date text text-right">
                    <div class="text text-right large">Start Date:</div>
                    {{ formatStartDate(opportunity?.startDate) }}
                </div>

            </div>
        </div>
        <div class="spacer spacer-teal"></div>

        <div class="majors ml-auto mb-small">
            <div class="flex-columns flex-wrap">
                <ng-template ngFor let-i [ngForOf]="opportunity?.majors | sort:'name':true">
                    <span class="chip chip-green mr-small mb-small">
                        {{ i }}
                    </span>
                </ng-template>
            </div>
        </div>

        <div class="locations mb-small">
            <div class="flex-rows">
                <div class="text large mb-small">Locations</div>

                <ng-container *ngIf="opportunity?.locations && opportunity?.locations.length">
                    <ng-template ngFor let-i [ngForOf]="opportunity?.locations | sort:'name':true">
                        <div class="chip chip-green mr-small mb-small">
                            {{ i }}
                        </div>
                    </ng-template>
                </ng-container>
            </div>
        </div>

        <div>
            <div class="text large mt-medium mb-small">Description</div>
            <div class="description text mb-small" [ngClass]="{ 'fill': !opportunity?.thumbnailUrl }"
                [innerHTML]="opportunity?.description">
            </div>


        </div>
        <div class="video  mb-small">
            <app-youtube-video [width]="320" *ngIf="opportunity?.youtubeUrl" [url]="opportunity?.youtubeUrl">
            </app-youtube-video>
        </div>

        <div class="rounded-corners" *ngIf="user?.isEmployer && opportunity?.id">

            <div class="flex-columns justify-between">
                <a class="button button-link btn-fill btn-blue text"
                    [routerLink]="[ '/employers', employerUrlName, 'opportunity', urlName, 'edit' ]">
                    Edit Opportunity
                </a>
                <button class="btn btn-green" *ngIf="!opportunity.scheduled" (click)="openScheduler()" >Schedule Opportunity</button>
            </div>
            <div class="prospects">
                <div class="text heading-4 mb-medium">
                    Total Prospects
                </div>
                <div class="prospect-wrapper" *ngFor="let prospect of prospects | sort:'score':false">
                    <app-employer-prospect-card (click)="prospectClicked(prospect)" [prospect]="prospect">
                    </app-employer-prospect-card>
                </div>
            </div>
        </div>

    </div>
    <div class="bg-teal pl-medium pr-medium py-large">
        <div class="info name text text-white heading-4 mt-auto">
            {{employer?.name}}
        </div>
        <div class="info address text text-white mb-auto">
            {{employer?.primaryAddress}}
        </div>

        <button class="button lime-green mb-medium" [routerLink]="['/employers', opportunity?.employerUrlName]">Company
            Profile</button>

        <div class="spacer spacer-white"></div>

        <div class="industries">
            <div class="text text-white large  mt-medium">Industries</div>
            <ng-template ngFor let-i [ngForOf]="employer?.industries | sort:'name':true">
                <div class="chip chip-green mr-small mb-small">
                    {{ i }}
                </div>
            </ng-template>
        </div>

    </div>
</div>

<app-modal id="apply-for-opportunity" size="medium">
    <app-student-apply-documents [opportunity]="opportunity" [user]="user" [studentOpportunity]="studentOpportunity"
        [student]="user?.student" [employer]="employer" (applied)="apply($event, 'apply-for-opportunity')"
        (cancelled)="cancelApplication('apply-for-opportunity')">
    </app-student-apply-documents>
</app-modal>

<app-modal [heading]="'Schedule ' + opportunity?.title" size="medium" id="schedule-opportunity">
    <app-schedule-opportunity  [opportunity]="opportunity"></app-schedule-opportunity>
</app-modal>