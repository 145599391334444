import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  @Output()
  public openStateChange = new EventEmitter<boolean>();

  public open = false;

  stateChange(): void {
    this.open = !this.open;
    this.openStateChange.emit(this.open);
  }

  public openMenu(): void {
    this.open = true;
  }
  public closeMenu(): void {
    this.open = false;
  }
}
