import { Component } from '@angular/core';

@Component({
  selector: 'app-selected-your-major',
  templateUrl: './selected-your-major.component.html',
  styleUrl: './selected-your-major.component.scss'
})
export class SelectedYourMajorComponent {

}
