import { Component } from '@angular/core';
import { FAQGroup } from '../../shared/models/faq.model';
import { FAQService } from 'src/app/shared/services/faq.service';
import { CDNService } from 'src/app/shared/services/cdn.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent {

  public selectedGroup = 'Employers';
  public groups: FAQGroup[] = [];

  constructor(
    private faqs: FAQService,
    public cdn: CDNService
    ) {
    this.faqs.get().toPromise().then((fqs: FAQGroup[]) => {
      this.groups = fqs.filter(f => f.Name !== 'Schools');
    });
  }

}
