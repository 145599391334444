<div class="standard-padding">
    <div class="text heading-2 mb-small tablet">
        Opportunities
    </div>

    <div class="rounded-corners add-new tablet">
        <div class="text     heading-4 mb-small">
            Create a New Opportunity<app-info-modal [audience]="'Employers'" [section]="'Opportunities'" [item]="'Create New Opportunity'"></app-info-modal>
        </div>
        <p class="text text-400">
            Shadowing, volunteering, internship, Co-op, employment, and more. Create a new opportunity and fill your vacancies with great talent. 
            In this section you will be able to post images, videos, and information to properly display your culture and opportunity. 
            Additionally, you will select the right students for the job, which will help funnel the best candidate for the position. 
        </p>
        <a class="button btn-no-wrap btn-extra-padding btn-green"
            [routerLink]="[ 'new', 'edit' ]">
            Create Opportunity
        </a>
    </div>

    <a type="button" class="button btn-no-wrap btn-extra-padding btn-green mobile mt-small mb-small"
        [routerLink]="[ 'new', 'edit' ]">
        Create Opportunity
    </a>
</div>
<div class="standard-padding">
    <app-separator [showLogo]="false" [showOr]="true"></app-separator>
</div>
<div class="standard-padding">
    <app-employer-existing-opportunities #existingOpportunities 
        [urlName]="urlName" [showInactive]="true" [showUnpublished]="true" ></app-employer-existing-opportunities>
</div>
