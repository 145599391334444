import { Component, Input } from '@angular/core';
import { CDNService } from '../../services/cdn.service';

@Component({
  selector: 'app-avatar-image',
  templateUrl: './avatar-image.component.html',
  styleUrls: ['./avatar-image.component.scss']
})
export class AvatarImageComponent {

  constructor(
    public cdn: CDNService
  ) { }
  @Input()
  image: string;
  @Input()
  size: 'sm' | 'md' | 'lg' | 'wide' | 'auto';
  @Input()
  title: string;
  @Input()
  circle = true;
  @Input()
  fit: 'cover' | 'contain' = 'cover';
}
