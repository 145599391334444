<div class="flex-rows menu-container">
    <div class="menu-button flex-columns justify-between" [class]="displayStyle" [ngClass]="{ 'open': display }"
        (click)="expand($event)">
        <div *ngIf="displayStyle === 'dropdown'">
            {{ label ?? 'More' }}
        </div>
        <div class="image-wrapper">
            <svg *ngIf="displayStyle === 'dropdown'" width="23" height="10" viewBox="0 0 23 10"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M23 0.3125H0.5H0L11.5 9.29688L23 0.3125Z" />
            </svg>
            <svg *ngIf="displayStyle === 'dots'" width="3" height="13" viewBox="0 0 3 13"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M0 11.25C0 10.9185 0.131696 10.6005 0.366117 10.3661C0.600537 10.1317 0.918479 10 1.25 10C1.58152 10 1.89946 10.1317 2.13388 10.3661C2.3683 10.6005 2.5 10.9185 2.5 11.25C2.5 11.5815 2.3683 11.8995 2.13388 12.1339C1.89946 12.3683 1.58152 12.5 1.25 12.5C0.918479 12.5 0.600537 12.3683 0.366117 12.1339C0.131696 11.8995 0 11.5815 0 11.25ZM0 6.25C0 5.91848 0.131696 5.60054 0.366117 5.36612C0.600537 5.1317 0.918479 5 1.25 5C1.58152 5 1.89946 5.1317 2.13388 5.36612C2.3683 5.60054 2.5 5.91848 2.5 6.25C2.5 6.58152 2.3683 6.89946 2.13388 7.13388C1.89946 7.3683 1.58152 7.5 1.25 7.5C0.918479 7.5 0.600537 7.3683 0.366117 7.13388C0.131696 6.89946 0 6.58152 0 6.25ZM0 1.25C0 0.918479 0.131696 0.600537 0.366117 0.366117C0.600537 0.131696 0.918479 0 1.25 0C1.58152 0 1.89946 0.131696 2.13388 0.366117C2.3683 0.600537 2.5 0.918479 2.5 1.25C2.5 1.58152 2.3683 1.89946 2.13388 2.13388C1.89946 2.3683 1.58152 2.5 1.25 2.5C0.918479 2.5 0.600537 2.3683 0.366117 2.13388C0.131696 1.89946 0 1.58152 0 1.25Z" fill="black"/>
            </svg>      
        </div>
    </div>
    <div #menuPanel class="menu-panel" [ngStyle]="{'display': display ? 'block' : 'none'}">
        <ng-template ngFor let-a [ngForOf]="actions" let-last="last">
            <div (click)="handleAction(a.action, $event)" class="flex-columns text-400 justify-between action">
                <div>
                    {{a.label}}
                </div>
                <div>
                    <img [src]="a.icon" alt="">
                </div>
            </div>
            <div *ngIf="!last" class="spacer"></div>
        </ng-template>
    </div>
</div>