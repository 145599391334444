<div class="modal">
    <div class="modal-wrapper">
        <div class="modal-body {{ size }} flex-rows">
            <div class="modal-header">
                <h2>{{ heading }}</h2>
                <img class="mb-auto" [src]="cdn.toCDN('images/icons/delete.png')" alt="close"
                    (click)="close()" />
            </div>
            <div>
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
<div class="modal-background"></div>