<div *ngIf="user && isActive() && !isVerified()" class="name text text-500">
    <div class="mb-large">
        Your organization's registration with Unext is not complete, please wait while we verify your information.
    </div>
    <button type="button" class="button orange outline button-slim" (click)="signout()">Sign Out</button>
</div>
<div *ngIf="user && !isActive()" class="name text text-500">
    <p>
        Your organization is not active, please sign up with a different account.        
    </p>
    <p class="mb-large">
        If you are a student, make sure you signed up with a .edu email account.
    </p>
    <button type="button" class="button orange outline button-slim" (click)="signout()">Sign Out</button>
</div>
