import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class ApiConfig {
    static path: string = environment.api.unext.path;
    static version: string | number = environment.api.unext.version;

    public static setApiVersion(version: string | undefined = 'api'): void {
        ApiConfig.version = version;
    }

    public static getApiVersion(): string | number {
        return ApiConfig.version;
    }

    public static getPath(): string {
        return ApiConfig.path;
    }

    public static getFullUrl(path): string {
        if (ApiConfig.getApiVersion()) {
            return [ApiConfig.getPath(), ApiConfig.getApiVersion(), path].join('/');
        } else {
            return [ApiConfig.getPath(), path].join('/');
        }
    }
}
