import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-left-navigation',
  templateUrl: './left-navigation.component.html',
  styleUrl: './left-navigation.component.scss'
})
export class LeftNavigationComponent implements OnInit{
  working = 0;

  async ngOnInit() {
    this.working = 10;
    this.working = 0;
  }
}
