import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExperienceApiConfig } from '../api/experience.api.config';
import { IdValue } from '../models/idValue.model';
import { skipAuthHeaders } from '../auth/auth-interceptor';

@Injectable({
    providedIn: 'root'
})
export class ExperienceService {
    constructor(private http: HttpClient) { }

    public getExperienceTypes(): Observable<IdValue[]> {
        return this.http.get<IdValue[]>(ExperienceApiConfig.getFullUrl('type'), { headers: skipAuthHeaders });
    }
}
