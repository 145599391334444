<app-progress-bar [value]="working" [splashScreen]="true"></app-progress-bar>

<div class="analytics-wrapper" *ngIf="section">
    <form [formGroup]="sectionForm">
        <!-- Experience Section -->
        <div class="form edit-section" *ngIf="section?.name === 'Experience'">
            <div class="four-col row">
                <label for="employer" class="form-label text text-black">
                    Employer Name
                </label>
                <input name="employer" type="text" formControlName="employerName"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="two-col row">
                <label for="location" class="form-label text text-black">
                    Location
                </label>
                <input name="location" type="text" formControlName="location"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="four-col row">
                <label for="title" class="form-label text text-black">
                    Title/Role
                </label>
                <input name="title" type="text" formControlName="title"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="two-col row">
                <label for="startDate" class="form-label text text-black">
                    Start Date
                </label>
                <input name="startDate" type="date" formControlName="startDate"
                    (change)="endDate.updateValueAndValidity()"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="two-col row">
                <label for="endDate" class="form-label text text-black">
                    End Date
                </label>
                <input name="endDate" type="date" formControlName="endDate"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="two-col row">
                <label for="current" class="form-label text text-black">
                    Currently Here
                </label>
                <input id="current" (click)="toggleCurrent()" type="checkbox" name="current"
                    style="height: auto;" formControlName="current"
                    class="form-input border border-teal text" />
            </div>


            <div class="two-col row">
                <label for="remote" class="form-label text text-black">
                    Is Remote
                </label>
                <input id="remote" type="checkbox" name="remote" style="height: auto;" formControlName="remote"
                    class="form-input border border-teal text" />
            </div>

        </div>

        <!-- Volunteering -->
        <div *ngIf="section?.name === 'Volunteering'" class="form edit-section">
            <div class="four-col row">
                <label for="employer" class="form-label text text-black">
                    Organization / Employer
                </label>
                <input name="employer" type="text" formControlName="employerName"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="four-col row">
                <label for="title" class="form-label text text-black">
                    Role
                </label>
                <input name="title" type="text" formControlName="title"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="two-col row">
                <label for="location" class="form-label text text-black">
                    Location
                </label>

                <input name="location" type="text" formControlName="location"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="two-col row">
                <label for="startDate" class="form-label text text-black">
                    Start Date
                </label>
                <input name="startDate" type="date" formControlName="startDate"
                    class="form-input border border-teal text form-input-100" />
            </div>
            <div class="two-col row">
                <div class="flex-columns">
                    <label for="endDate" class="form-label text text-black">
                        End Date
                    </label>
                </div>
                <input name="endDate" type="date" formControlName="endDate"
                    class="form-input border border-teal text form-input-100 hide-disabled" />
            </div>
            <div class="one-col row">
                <label for="current" class="form-label text text-black ml-auto">
                    Currently Volunteering
                </label>
                <input id="current" (click)="toggleCurrent()" type="checkbox" name="current"
                style="height: auto;" formControlName="current"
                class="form-input form-input-rounded text mr-small" />
            </div>
        </div>

        <div class="form edit-section">

            <div class="six-col">
                <label class="form-label text text-black">
                    Description
                </label>
                <div style="height: 350px;">
                    <app-custom-quill #myCustomQuill
                        [initialValue]="currentResumeSection?.description ?? section?.defaultDescription ?? ''"
                        placeholder="Enter description here..." (changed)="descriptionChange($event)"
                        (filled)="markDescriptionPristine()"
                        [checkWeakWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.weakWords)"
                        [checkStrongWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.strongWords)"
                        [checkActionWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.actionWords)"
                        [checkMetrics]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.metrics)"
                        [checkTense]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.tense)"
                        [presentTense]="current" (wordListEmitter)="updateWordList($event)" [words]="words">
                    </app-custom-quill>
                </div>
            </div>
        </div>

        <div class="form edit-section mt-large">
            <div class="three-col">
                <div class="mr-auto" *ngIf="id?.value">
                    <button type="button" (click)="delete()">
                        Delete
                    </button>
                </div>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['employerName']?.errors?.required">
                    Employer is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['location']?.errors?.minlength">
                    Too short
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['title']?.errors?.required">
                    Title is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.get('startDate')?.hasError('required')">
                    Start Date is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.get('startDate')?.hasError('invalidDate')">
                    Start Date is invalid
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['endDate']?.errors?.required">
                    End Date is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['endDate']?.errors?.invalidDate">
                    End Date is invalid
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['endDate']?.errors?.afterDate">
                    Start Date must be before End Date
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['employerName']?.errors?.required">
                    Organization/Employer is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['title']?.errors?.required">
                    Role is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['location']?.errors?.minlength">
                    Too short
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['startDate']?.errors?.required">
                    Start Date is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['startDate']?.errors?.invalidDate">
                    Start Date is invalid
                </label>
                <label class="text text-warning" *ngIf="sectionForm.touched && sectionForm.controls['endDate']?.errors?.required">
                    End Date is required
                </label>
                <label class="text text-warning" *ngIf="sectionForm.touched && sectionForm.controls['endDate']?.errors?.invalidDate">
                    End Date is invalid
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['endDate']?.errors?.afterDate">
                    Start Date must be before End Date
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.required ?? false">
                    Description is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.minlength ?? false">
                    Description is not long enough ({{ description?.errors?.minlength?.actualLength }} of {{
                    description?.errors?.minlength?.requiredLength }})
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.maxlength ?? false">
                    Description is too long ({{ description?.errors?.maxlength?.actualLength }} of {{
                    description?.errors?.maxlength?.requiredLength }})
                </label>

            </div>
            <div class="three-col row">
                <div class="ml-auto">
                    <button type="submit" (click)="cancel()" [disabled]="!hasUnsavedChanges">
                        Cancel
                    </button>    
                </div>
                <div>
                    <button type="submit" class="button lime-green" (click)="save()" [disabled]="!sectionForm?.valid">
                        Save Section
                    </button>    
                </div>
            </div>
        </div>
    </form>
    <app-analytics [section]="section" [analytics]="analytics"></app-analytics>
</div>