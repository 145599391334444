import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalService {
    private modals: any[] = [];

    add(modal: any): void {
        this.modals.push(modal);
    }
    remove(id: string): void {
        this.modals = this.modals.filter(m => m.id !== id);
    }
    open(id: string): void {
        const modal = this.modals.find(m => m.id === id);
        modal?.open();
    }
    close(id: string): void {
        const modal = this.modals.find(m => m.id === id);
        modal?.close();
    }
    closeAll(): void {
        this.modals?.forEach(m => m.close());
    }
}
