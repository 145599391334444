import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'filter',
    pure: false,
})
export class ArrayFilterPipe implements PipeTransform {
    transform(array: any, field:string, value: any): any[] {
        if (!Array.isArray(array) || !array.length) {
            return [];
        }
        return array.filter(a => a[field] === value);
    }
}