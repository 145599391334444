<app-progress-bar [splashScreen]="true" [value]="isLoading"></app-progress-bar>

<!-- <div class="admin form">
    <table class="table grid mt-medium">
        <thead>
            <tr>
                <ng-template ngFor let-c [ngForOf]="displayedColumns">
                    <th [width]="c.width">{{ c.label }}</th>
                </ng-template>
                <th width="auto">
                    Actions
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-u [ngForOf]="users">
                <tr (click)="select(u, 'user-profile')" style="cursor:pointer;">
                    <ng-template ngFor let-c [ngForOf]="displayedColumns">
                        <td>
                            <input class="form-input form-input-rounded form-input-100" *ngIf="c.display === 'text'" type="text" readonly [value]="getProperty(u, c.value)" />
                            <input class="form-input form-input-rounded form-input-100" *ngIf="c.display === 'date'" type="date" readonly [value]="getProperty(u, c.value)" />
                            <app-toggle-bar *ngIf="c.display === 'toggle'" [isOn]="getProperty(u, c.value)" size="small" [disabled]="true"></app-toggle-bar>
                        </td>
                    </ng-template>

                    <td>
                        <button type="button" class="button dark-green">View</button>
                    </td>
                </tr>
            </ng-template>
        </tbody>
        <tfoot>
            <tr>
                <ng-template ngFor let-c [ngForOf]="displayedColumns">
                    <td>
                        &nbsp;
                    </td>
                </ng-template>
                <td>
                    &nbsp;
                </td>
            </tr>
        </tfoot>
    </table>
</div> -->

<app-entity-list [columns]="displayedColumns" [entities]="users" [actions]="actions" [filters]="filters" 
    (rowClick)="navigate($event)" (search)="filterUsers($event)"></app-entity-list>

<app-modal id="user-profile">
    <app-user-profile [user]="selectedUser" [student]="selectedStudent" [employee]="selectedEmployee" [employer]="selectedEmployer">

    </app-user-profile>
</app-modal>