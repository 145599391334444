import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SchoolApiConfig } from '../api';
import { Department, School, SchoolDepartment, Staff, Student } from '../models';
import { skipAuthHeaders } from '../auth/auth-interceptor';
import { SchoolAddress } from '../models/address/school.address.model';

@Injectable()
export class SchoolService {
    

    constructor(private http: HttpClient) { }

    public getSchools(includeInactives: boolean | undefined = undefined, includeUnVerified: boolean | undefined = undefined): Promise<School[]> {
        let params = new HttpParams();
        if (includeInactives) {
            params = params.append('includeInactives', true);
        }
        if (includeUnVerified) {
            params = params.append('includeUnVerified', true);
        }
        return lastValueFrom(this.http.get<School[]>(SchoolApiConfig.getFullUrl(''), { params }));
    }

    public getSchoolNames(): Promise<string[]> {
        return lastValueFrom(this.http.get<string[]>(SchoolApiConfig.getFullUrl('names'), { headers: skipAuthHeaders }));
    }

    public getSchool(id: number): Promise<School> {
        return lastValueFrom(this.http.get<School>(SchoolApiConfig.getFullUrl(`id/${id}`)));
    }

    public get(name: string): Promise<School> {
        return lastValueFrom(this.http.get<School>(SchoolApiConfig.getFullUrl(name), { headers: skipAuthHeaders }));
    }

    public getStaff(userId: string): Promise<Staff> {
        return lastValueFrom(this.http.get<Staff>(SchoolApiConfig.getFullUrl(`staff`),  { params: {userId: userId}}));
    }

    public getFacultyList(schoolId: string): Promise<Staff[]> {
        return lastValueFrom(this.http.get<Staff[]>(SchoolApiConfig.getFullUrl(`${schoolId}/faculty`)));
    }

    public getStudentList(schoolId: string): Promise<Student[]> {
        return lastValueFrom(this.http.get<Student[]>(SchoolApiConfig.getFullUrl(`${schoolId}/students`)));
    }

    public switchToStaff(studentId: number): Promise<Staff> {
        return lastValueFrom(this.http.get<Staff>(SchoolApiConfig.getFullUrl(`switch/${studentId}`)));
    }

    public getStaffByUrlName(schoolUrl: string , urlName: string): Promise<Staff> {
        return lastValueFrom(this.http.get<Staff>(SchoolApiConfig.getFullUrl(`${schoolUrl}/staff/${urlName}`)));
    }

    // removeProfile(urlName: string, arg1: string, city: string) {
    //     throw new Error('Method not implemented.');
    // }
    // addProfile(urlName: string, arg1: string, arg2: string) {
    //     throw new Error('Method not implemented.');
    // }

    public createStaff(urlName: string, model: Staff): Promise<Staff>{
        return lastValueFrom(this.http.post<Staff>(SchoolApiConfig.getFullUrl(`${urlName}/staff`), model));
    }

    public updateSchool(urlName: string, model: School): Promise<School>{
        return lastValueFrom(this.http.post<School>(SchoolApiConfig.getFullUrl(`${urlName}`), model));
    }

    public getDepartments(urlName: string): Promise<Department[]>{
        return lastValueFrom(this.http.get<Department[]>(SchoolApiConfig.getFullUrl(`${urlName}/department`)));
    }

    public getSchoolDepartments(schoolId: number): Promise<SchoolDepartment[]>{
        return lastValueFrom(this.http.get<SchoolDepartment[]>(SchoolApiConfig.getFullUrl(`${schoolId}/departments`)));
    }

    public removeDepartment(urlName: string, departmentId: number): Promise<SchoolDepartment[]> {
        const url = SchoolApiConfig.getFullUrl(`${urlName}/department/${departmentId}`);
        return lastValueFrom(this.http.delete<SchoolDepartment[]>(url));
    }

    public addDepartment(urlName: string, schoolDepartment: SchoolDepartment): Promise<SchoolDepartment[]>{
        return lastValueFrom(this.http.post<SchoolDepartment[]>(SchoolApiConfig.getFullUrl(`${urlName}/department`),schoolDepartment));
    }

    public updateAttribute(urlName: string, name: string, value: string): Promise<boolean> {
        return lastValueFrom(this.http.post<boolean>(
            SchoolApiConfig.getFullUrl(`${urlName}/attributes`), {
            name,
            value
        }));
    }


    public createLocation(schoolId: number, value: SchoolAddress): Promise<SchoolAddress> {
        return lastValueFrom(this.http.post<SchoolAddress>(
            SchoolApiConfig.getFullUrl(`${schoolId}/location`), value));
    }

    
}
