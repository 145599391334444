<app-progress-bar [splashScreen]="true" color="green" [value]="loading"></app-progress-bar>

<div class="background tilt"></div>

<div class="resume-builder-body">
    <app-left-navigation class="dark-green">
        <div class="p-4">
            <h1>Resume Builder</h1>
            <p class="text text-500 pr-medium">
                Our Resume Builder helps you create a professional resume quickly and easily, saving you time, improving your resume’s readability and appearance, 
                helps you avoid mistakes, and ultimately increases the chances of you getting an interview.
            </p>
            <hr/>
            <div class="row">
                <button class="lime-green outline">Resume Tips</button>
            </div>
        </div>
    </app-left-navigation>

    <div class="resume-builder-main">
            <h2 class="sans-serif-bold upper after-line">Getting Started</h2>
            <div class="flex-columns gap-1">
                <div class="col">
                    <div role="button" class="resume-tile button wide mr-medium" (click)="openModal('create-resume')" style="cursor: pointer;">
                        <div class="actions ml-auto">
                            <img class="add" [src]="cdn.toCDN('images/icons/add.png')" alt="add" />
                        </div>
                    </div>
                    <p class="text serif">
                        Create a New Resume
                    </p>
                    <p class="text">
                        Create a resume using our tools top land your next gig.
                    </p>
                </div>
                <div class="col">
                    <div role="button" class="resume-tile wide button" (click)="openModal('parse-resume')">
                        <div class="actions ml-auto">
                            <img class="add" [src]="cdn.toCDN('images/icons/add.png')" alt="parse" />
                        </div>
                    </div>
                    <div class="text serif">
                        Use an existing resume
                    </div>
                    <div class="text">
                        Upload an existing resume and improve what you have.
                    </div>
                </div>
            </div>

            <h2 class="sans-serif-bold after-line upper">Recent Resumes</h2>
        
        <div>        
            <div>
                <div class="flex-columns justify-between no-wrap automatic-title">
                    <app-toggle-bar *ngIf="hasInactive()" [isOn]="showInactive" (toggled)="toggleInactive(!showInactive)" size="medium"
                    [textNowrap]="true" onText="Archived" offText="Active"></app-toggle-bar>
                </div>

                <div class="flex-columns justify-between grid-quarters">
                  
                    <ng-template ngFor let-u [ngForOf]="uresumes">
                        <div role="button" (click)="editResume(u)" class="resume-entry flex-rows align-start" [ngClass]="{'hidden': (!u.active && !showInactive) || (u.active && showInactive)}">
                            <div class="resume-tile" [ngClass]="{ 'inactive': !u.active,  }">
                                <div class="flex-columns justify-between">
                                    <div class="text hover" (click)="editResume(u)">
                                        <app-logo [asLink]="false"></app-logo>
                                    </div>
                                </div>
                                <app-action-menu [resume]="u" 
                                [user]="user"
                                [displayStyle]="'dots'"
                                (emit)="handleAction($event, u)">
                                </app-action-menu>
                            </div>
                            <div class="text resume-name mb-auto hover" (click)="editResume(u)">
                                {{ u.name }}
                            </div>
                            <div class="text upload-date hover" *ngIf="u.modified"
                                (click)="editResume(u)">
                                Updated {{ u.modified | date }}
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>    

</div>




<app-modal id="parse-resume" size="medium" heading="Create a Unext Resume from an existing resume">
    <app-upload acceptType="resume" [entityType]="entityType" [entityId]="user?.id" [maxFiles]="1"
        (filesUploaded)="uploadAndGo($event, 'parse-resume')" [isResume]="true">
    </app-upload>
</app-modal>

<app-modal id="add-resume" size="medium" heading="Upload a Resume">
    <app-upload acceptType="resume" [entityType]="entityType" [entityId]="user?.id" [maxFiles]="1"
        (filesUploaded)="filesUploaded($event, 'add-resume')">
    </app-upload>
</app-modal>

<app-modal id="add-video-resume" size="medium" heading="'Upload a Video Resume">
    <app-upload acceptType="video" [entityType]="entityType" [entityId]="user?.id" [maxFiles]="1"
        (filesUploaded)="filesUploaded($event, 'add-resume')">
    </app-upload>
</app-modal>

<app-modal id="add-transcript" size="medium" heading="Upload a transcript">
    <app-upload acceptType="transcript" [entityType]="entityType" [entityId]="user?.id" [maxFiles]="1"
        (filesUploaded)="filesUploaded($event, 'add-transcript')">
    </app-upload>
</app-modal>

<app-modal id="confirm-delete" size="medium" heading="This will hide your resume, do you want to continue?">
    <div class="text">
        Anyone who had previoulsy access to your resume will still be able to see it. It will be hidden in this list and
        not be availble for selection.
    </div>
    <div class="confirm row">
        <button class="button dark-green mr-medium mb-small" (click)="confirmDelete('confirm-delete')">Yes</button>
        <button class="button btn-blue" (click)="cancelDelete('confirm-delete')">No</button>
    </div>
</app-modal>

<app-modal id="create-resume" size="medium-small" heading="Create a New Resume">
    <form [formGroup]="resumeBuilderFormGroup" class="form">
        <div class="flex-rows mb-medium">
            <label for="resumeName" class="form-label">Resume Name</label>
            <input title="resumeName" placeholder="e.g. Resume for New Job" [required]="resumeBuilderFormGroup.get('resumeName').errors?.required" type="text" name="resumeName"
                formControlName="resumeName" class="form-input form-input-rounded form-input-green" />
            <label class="form-error" *ngIf="resumeBuilderFormGroup.get('resumeName').errors?.required">
                Resume name is required
            </label>
            <label for="jobDescription" class="form-label">Job Description</label>
            <textarea id="jobDescription" name="jobDescription" rows="10" formControlName="jobDescription" class="form-input form-input-rounded form-input-green"></textarea>
        </div>
        <div class="confirm row justify-end">
            <button type="button" class="btn outline" (click)="close('create-resume')">Cancel</button>
            <button type="button" class="btn dark-green" (click)="startResumeBuilder('create-resume')">Begin</button>
        </div>
    </form>
</app-modal>

<app-modal id="begin-archive" size="small" heading="Confirm Archive">
    <div class="flex-columns flex-wrap">
        <p>Are you sure you want to archive this resume? You will still be able find and edit it, but it won't clutter
            your working resumes.</p>
        <button type="button" class="button button-slim btn-green mt-auto mb-auto mr-small"
            (click)="archive('begin-archive')">Yes</button>
        <button type="button" class="button button-slim btn-orange mt-auto mb-auto"
            (click)="cancelArchive('begin-archive')">No</button>
    </div>
</app-modal>