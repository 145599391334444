import { AfterViewInit, Component, Input } from '@angular/core';
import { Employer } from 'src/app/shared/models';
import { Opportunity } from 'src/app/shared/models/opportunity.model';
import { StudentOpportunity } from 'src/app/shared/models/studentOpportunity.model';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { StudentService } from 'src/app/shared/services/student.service';

@Component({
  selector: 'app-student-opportunities',
  templateUrl: './student-opportunities.component.html',
  styleUrls: ['./student-opportunities.component.scss']
})
export class StudentOpportunitiesComponent implements AfterViewInit {

  @Input()
  user: User;
  @Input()
  employer: Employer;
  @Input()
  filter: 'none' | 'score' | 'location' | 'favorites' | 'applied' | 'score' = 'none';
  @Input()
  filterText: string;
  @Input()
  sort: 'none' | 'created' | 'location' | 'score' = 'none';
  @Input()
  max: number;
  @Input()
  showMore = false;
  @Input()
  showMoreText: string;
  @Input()
  showMoreLink: Array<string>;
  @Input()
  get opportunities(): StudentOpportunity[] {
    return this.opps;
  }
  set opportunities(val: StudentOpportunity[]) {
    this.opps = val;
    if (!this.selfLoading) {
      this.all = this.opps.map(x => x.opportunity);
    }
  }
  @Input()
  selfLoading = true;

  opps: StudentOpportunity[] = [];
  all: Opportunity[] = [];
  loading = 0;

  constructor(
    private users: UserService,
    private students: StudentService,
    public cdn: CDNService
  ) {
    if (!this.user) {
      this.user = this.users.authenticatedUser as User;
    }
  }

  ngAfterViewInit() {
    this.loadOpportunities();
  }

  async loadOpportunities() {
    if (this.user && this.selfLoading) {
      try {
        this.loading = 25;
        this.opportunities = await this.students.getOpportunities(
          this.user.student.urlName,
          this.employer?.urlName,
          this.max,
          this.filter,
          this.filterText,
          this.sort);
        this.loading = 50;
        if (this.opportunities) {
          this.all = this.opportunities.map(x => x.opportunity);
          this.loading = 70;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = 0;
      }
    }
  }
}
