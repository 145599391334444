import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityType } from 'src/app/shared/models';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';

@Component({
  selector: 'app-user-avatar-selector',
  templateUrl: './user-avatar-selector.component.html',
  styleUrls: ['./user-avatar-selector.component.scss']
})
export class UserAvatarSelectorComponent {

  @Input()
  user: User;

  @Output()
  userChange = new EventEmitter<User>();

  entityType = EntityType;

  constructor(
    private users: UserService,
    public cdn: CDNService,
  ) {
  }

  async filesUploaded(files: string[]): Promise<void> {
    if (files?.length) {
      this.user.avatar = files[0];
      this.users.clearProfile();
      this.users.getProfile();
      this.user = await this.users.getUserProfile(this.user.id);
      this.userChange.emit(this.user);
    }
  }

}
