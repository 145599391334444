<div id="page-not-found" class="flex-columns">
    <div class="flex-rows mt-large">
        <h1>404 - PAGE NOT FOUND</h1>
        <p>We were unable to find what you were looking for.</p>    
        <p>If you believe you reached this page in error, please <a class="text text-green" [routerLink]="[ '/contact-us']">Contact Us</a>.</p>
        <h2>Or, maybe try these helpful links:</h2>
        <div class="helpful-links flex-columns" *ngIf="!this.user">
            <a href="/dashboard">Login</a>
            <a href="/faqs">Frequently Asked Questions</a>
            <a href="/request-demo">Request a Demo</a>
        </div>
        <div class="helpful-links flex-columns" *ngIf="this.user">
            <a href="/dashboard">Dashboard</a>
            <a href="/my-profile/resume">My Resumes</a>
            <a href="/my-profile">My Profile</a>
            <a href="/faqs">Frequently Asked Questions</a>
        </div>
    </div>
</div>