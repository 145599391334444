import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { FilterOption, School, Staff } from 'src/app/shared/models';
import { ArraySortPipe } from 'src/app/shared/pipes';
import { SchoolService, UserService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrl: './staff-list.component.scss'
})
export class StaffListComponent implements OnInit {


  user: User;
  school: School;
  urlName: string;
  working = 0;
  staffPageNumber: number = 1;

  allStaffList?: Staff[];
  modifiedStaffList?: Staff[];


  formGroup: FormGroup;

  staffFilterDropDownOptions: FilterOption[] = [];
  staff: any[];
  staffSearchOptions: any[] = [];
  constructor(
    public cdn: CDNService,
    private schools: SchoolService,
    private route: ActivatedRoute,
    private schoolService: SchoolService,
    private snackbar: ToastService,
    userService: UserService,
    private router: Router, 
  ) {
    this.user = userService.authenticatedUser;
    this.route.params.subscribe(params => {
      // Retrieve the ID from the route parameters
      this.urlName = params['urlName'];
    });
      
  }
  async ngOnInit(): Promise<void> {
    this.school = await this.schoolService.get(this.urlName);
    this.allStaffList = await this.schoolService.getFacultyList(this.urlName);
    this.modifiedStaffList = this.allStaffList;

    this.createStaffFilterOptions();
    this.createStaffFilterObjects();
  }

  createStaffFilterOptions(){
    const departments = this.school.departments
      .filter(a => a.departmentName)
      .map(a => a.departmentName);  

    const positions = this.school.positions
      .filter(a => a.name)
      .map(a => a.name);
    if(departments.length){
      this.staffFilterDropDownOptions.push({ id: 2, label: 'Department', active: false, name: 'department', options: departments });
    }
    if(this.school.locations.length){
      this.staffFilterDropDownOptions.push({ id: 3, label: 'Campus', active: false, name: 'campus', options: this.school.locations });
    }
    if(positions.length){
      this.staffFilterDropDownOptions.push( { id: 4, label: 'Position', active: false, name: 'position', options: positions });
    }

    this.staffSearchOptions = [
      { id: 1, name: 'name' },
    ];
  }

  createStaffFilterObjects(){
    this.staff = [];
    this.allStaffList.forEach(e => {
      const s = {
        id: e.id,
        name: e.firstName + " " + e.lastName,
        campus: e.schoolLocation,
        department: e.departmentName,
        position: e.positionName
      }
      this.staff.push(s);
    });
  }

  updateStaffList(data: { list: any[], sort: { value: string, asc: boolean }, search: string, reset: boolean }) {
    this.modifiedStaffList = [];
    if (data.list.length) {
      this.modifiedStaffList = new ArraySortPipe().transform(
        this.allStaffList.filter(a => data.list.some(i => i.id === a.id)),
        data.sort.value,
        data.sort.asc
      );
    }

    if (data.search.length) {
      const searchTermLower = data.search.toLowerCase();

      this.modifiedStaffList = this.modifiedStaffList.filter(e => {

        const nameMatches = (e.firstName + " " + e.lastName) && (e.firstName + " " + e.lastName).toLowerCase().includes(searchTermLower);
        return nameMatches;
      });
    }
  }

}
