import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { register as registerSwiperElements } from 'swiper/element/bundle';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

// const providers = [
//   { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
// ];

if (environment.production) {
  enableProdMode();
}
registerSwiperElements();
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
