<div class="tile" [ngClass]="{ 'monthly': monthly, 'yearly': !monthly }">
    <div class="text plan-name text-center mb-small" 
        [ngClass]="{'text-black': monthly, 'text-white': !monthly }">
        {{ pricing?.name }}
    </div>
    <div class="pricing-wrapper">
        <span class="heading-3 text price"
            [ngClass]="{'text-green': monthly, 'text-white': !monthly }">
            {{ ((monthly ? pricing?.monthly : pricing?.yearly) ?? pricing?.price) | currency }}
        </span>
        <span class="heading-1 text text-center text-roboto period"
                [ngClass]="{'text-green': monthly, 'text-white': !monthly }">
            {{ monthly ? '/mo' : '/yr' }}
        </span>
    </div>
</div>
