<div class="resume-container flex-rows">
    <div class="text text-center heading-1">
        {{ resume?.fullName }}
    </div>
    <div class="text text-center mb-medium heading-4">
        {{ subheading.join(' | ') }}
    </div>

    <ng-template ngFor let-s [ngForOf]="resume.sections | sort:'displayOrder':true">        
        <ng-container  *ngIf="s.sectionType === sectionType.Language; else skills">
            <div class="text heading-2" *ngIf="s.resumeSections?.length && s.resumeSections[0]?.languages?.length">
                {{ s?.overrideSectionName ?? s.name }}
            </div>
        </ng-container>
        <ng-template #skills>
            <ng-container *ngIf="s.sectionType === sectionType.Skills; else generic">
                <div class="text heading-2" *ngIf="s.resumeSections?.length && s.resumeSections[0]?.skills?.length">
                    {{ s?.overrideSectionName ?? s.name }}
                </div>
            </ng-container>
            <ng-template #generic>
                <div class="text heading-2" *ngIf="s.resumeSections?.length && !(summary === '' && s.name === 'Summary')">
                    {{ s?.overrideSectionName ?? s.name }}
                </div>
            </ng-template>
    
        </ng-template>

        <div  *ngIf="s.sectionType === sectionType.General" class="text mb-small" [innerHTML]="summary">
        </div>
        <!-- education -->
        <div *ngIf="s.sectionType === sectionType.Education">
            <ng-template ngFor let-edu [ngForOf]="s.resumeSections | doublesort:'startDate':false:'resumeSectionId':false" let-isLast="last">
                <div class="text text-600 mb-small flex-columns justify-between">
                    <span *ngIf="edu.location?.length">
                        {{ [edu.schoolName].join(' - ') }} | {{ edu.location }}
                    </span> 
                    <span>
                        {{edu.startDate | date:'MMM y' }} <span *ngIf="edu.endDate"> - {{ edu.endDate | date:'MMM y'}}</span>
                        <span *ngIf="!edu.endDate"> - Present</span>
                    </span>                       
                </div>
                <div class="text text-700">
                    <span class="mb-small" *ngFor="let m of edu.major">
                        {{ m }}<span *ngIf="edu.degree"> {{degreeService.getDegree(edu.degree).name}}</span>
                    </span>
                </div>
                <div class="text text-400" [innerHTML]="edu.description" [ngClass]="{ 'mb-large': isLast, 'mb-medium': !isLast }">
                </div>
            </ng-template>
        </div>

        <!-- experience, volunteer -->
        <div *ngIf="s.sectionType === sectionType.Experience">
            <ng-template ngFor let-ex [ngForOf]="s.resumeSections | doublesort:'startDate':false:'resumeSectionId':false" let-isLast="last">
                <div class="flex-columns justify-between">
                    <span class="text">
                        {{ ex.title }} 
                        <span class="text text-500">
                            | {{ ex.employerName}}
                        </span>
                        <span *ngIf="ex.location" class="text text-500">
                            | {{ ex.location}}
                        </span>
                    </span>
                    <span class="text text-500">
                        {{ex.startDate | date:'MMMM y' }} <span *ngIf="ex.endDate"> - {{ ex.endDate | date:'MMM y' }}</span>
                        <span *ngIf="!ex.endDate"> - Present</span>
                    </span>
                </div>
                <div class="text text-400" [innerHTML]="ex.description" [ngClass]="{ 'mb-large': isLast, 'mb-medium': !isLast }">
                </div>
            </ng-template>
        </div>

        <!-- skills -->
        <div *ngIf="s.sectionType === sectionType.Skills">
            <ng-template ngFor let-sk [ngForOf]="s.resumeSections" let-isFirst="first"
                let-isLast="last">
                <ul *ngIf="isFirst" class="skills">
                    <li class="text text-500" *ngFor="let skill of sk.skills" style="text-transform: capitalize;">
                        {{skill}}
                    </li>
                </ul>
            </ng-template>
        </div>

        <!-- certification, awards -->
        <div *ngIf="s.sectionType === sectionType.Award">
            <ng-template ngFor let-aw [ngForOf]="s.resumeSections | doublesort:'startDate':false:'resumeSectionId':false" let-isLast="last">

                <div class="flex-columns justify-between">
                    <span class="text text-500">
                        {{ aw.awardName }} 
                        <span class="text text-500" *ngIf="aw.awarder">
                            | {{aw.awarder}}
                        </span>
                    </span>
                    <span class="text text-500">
                        {{aw.startDate | date:'MMMM y' }} <span *ngIf="aw.endDate"> - {{ aw.endDate | date:'MMM y' }}</span>
                        <span *ngIf="!aw.endDate"> - Present</span>
                    </span>
                </div>
                <div class="text text-400" [innerHTML]="aw.description" [ngClass]="{ 'mb-large': isLast, 'mb-medium': !isLast }">
                </div>
            </ng-template>
        </div>

        <!-- clubs, extra -->
        <div *ngIf="s.sectionType === sectionType.Organization">
            <ng-template ngFor let-org [ngForOf]="s.resumeSections | doublesort:'startDate':false:'resumeSectionId':false" let-isLast="last">
                <div class="flex-columns justify-between">
                    <span class="text text-500">
                        {{ org.organizationName }} 
                        <span class="text text-500" *ngIf="org.schoolName">
                        | {{org.schoolName}}
                        </span>
                    </span>
                    <span class="text text-500">
                        {{org.startDate | date:'MMMM y' }} <span *ngIf="org.endDate"> - {{ org.endDate | date:'MMM y' }}</span>
                        <span *ngIf="!org.endDate"> - Present</span>
                    </span>
                </div>
                <div class="text text-400" [innerHTML]="org.description" [ngClass]="{ 'mb-large': isLast, 'mb-medium': !isLast }">
                </div>
            </ng-template>
        </div>

        <div *ngIf="s.sectionType === sectionType.Language">
            <ng-template ngFor let-sk [ngForOf]="s.resumeSections | sort:'id':true" let-isFirst="first">
                <ul *ngIf="isFirst" class="languages">
                    <li class="text text-400" *ngFor="let lang of sk.languages">
                        {{lang}}
                    </li>
                </ul>
            </ng-template>
        </div>

    </ng-template>
</div>