<div class="tile opportunity">
    <div class="summary" (click)="navigate($event)">
        <img *ngIf="opportunity?.thumbnailUrl ?? opportunity?.employerLogoUrl"
             src="{{ cdn.toCDN(opportunity?.thumbnailUrl ?? opportunity?.employerLogoUrl, true) }}" 
             alt="{{opportunity?.title}}" />
        <span class="score text" hidden>
            {{ opportunity.score }}
        </span>
        <img class="favorite" *ngIf="opportunity?.favorite" (click)="toggleFavorite($event)"
            [src]="cdn.toCDN('images/icons/favorite.png')"
            [srcset]="cdn.toCDN('images/icons/favorite@2x.png, 2x') + ', ' + cdn.toCDN('images/icons/favorite@3x.png 3x')" />
        <img class="not favorite" *ngIf="!opportunity?.favorite" (click)="toggleFavorite($event)"
            [src]="cdn.toCDN('images/icons/not-favorite.png')"
            [srcset]="cdn.toCDN('images/icons/not-favorite@2x.png, 2x') + ', ' + cdn.toCDN('images/icons/not-favorite@3x.png 3x')" />
        <a class="title text text-white"
            [routerLink]="[destinationLink]">
            {{ opportunity.title }}
        </a>
        <div class="tags">
            <span class="chip mr-small chip-{{ chipColor() }}">
                {{ opportunity?.opportunityType }}
            </span>
            <span *ngIf="opportunity?.applied" class="chip chip-yellow mr-small">
                Applied
            </span>
            <span *ngIf="opportunity?.allowRemote" class="chip chip-green-muted mr-small">
                Remote
            </span>
        </div>
    </div>
    <div class="details" (click)="navigate($event)">
        <span class="employer text">
            {{ opportunity?.employerName }}
        </span>
        <div class="badges">
            <div *ngIf="daysLeft >= 0 && daysPosted < 10">
                New
                <img [src]="cdn.toCDN('images/icons/new-icon.png')"
                [srcset]="cdn.toCDN('images/icons/new-icon@2x.png 2x') + ', ' + cdn.toCDN('images/icons/new-icon@3x.png 3x')"
                        alt="view details" />
            </div>

            <div *ngIf="opportunity?.numberApplicants > 20">
                Popular
                <img [src]="cdn.toCDN('images/icons/fire-icon.png')"
                [srcset]="cdn.toCDN('images/icons/fire-icon@2x.png 2x') + ', ' + cdn.toCDN('images/icons/fire-icon@3x.png 3x')"
                        alt="view details" />
            </div>
        </div>
        <div class="where" *ngIf="opportunity?.locations?.length">
            <span class="text mr-auto">
                <ng-template ngFor let-l [ngForOf]="opportunity.locations" let-i="index" let-isFirst="first" let-isLast="last" >
                    {{ i > 0 ? '' :  l }}
                    {{ i > 0 && isLast ? '& more' : '' }}
                </ng-template>
            </span>
        </div>
        <div class="dots" (click)="openDetails($event)">
            <svg viewBox="0 -5 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="showOptions">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z"
                  fill="currentColor"
                />
              </svg>
        </div>
        <div class="when">
            <div class="posted text text-blue-accent mr-small" *ngIf="opportunity?.active && !pubishedYet(opportunity)">
                Posts in {{ daysPosted }} days
            </div>
            <div class="posted text text-purple mr-small" [ngClass]="{ 'closing-soon': daysLeft < 10 }" *ngIf="!opportunity?.active">
                Not Posted
            </div>
            <div class="posted text mr-small" [ngClass]="{ 'closing-soon': daysLeft < 10 }" *ngIf="opportunity?.active && pubishedYet(opportunity)">
                Posted {{ daysPosted }} day{{ daysPosted > 1 ? 's' : '' }} ago
            </div>
            <div class="days-left" *ngIf="daysLeft >= 0 && daysLeft < 10">
                {{ daysLeft }} day{{ daysLeft > 1 ? 's' : '' }} left
            </div>
            <div class="days-left" *ngIf="daysLeft < 0">
                Expired
            </div>
        </div>
    </div>
    <div (click)="navigate($event)"
        class="button text text-green flex-columns">
        <div>
            View Details
        </div> 
        <img class="ml-small mr-auto" [src]="cdn.toCDN('images/icons/go-rounded.png')"
        [srcset]="cdn.toCDN('images/icons/go-rounded@2x.png 2x') + ', ' + cdn.toCDN('images/icons/go-rounded@3x.png 3x')"
                alt="view details" />
    </div>

     <div *ngIf="internal" (click)="openApplicationModal(opportunity)"
        class="button text text-green flex-columns">
        <div>
            Apply Now
        </div> 
        <img class="ml-small mr-auto" [src]="cdn.toCDN('images/icons/go-rounded.png')"
        [srcset]="cdn.toCDN('images/icons/go-rounded@2x.png 2x') + ', ' + cdn.toCDN('images/icons/go-rounded@3x.png 3x')"
                alt="view details" />
    </div>
    <div  *ngIf="external" (click)="redirectToExternalLink()" class="button text text-green flex-columns">
        <div>
            Apply Now
        </div> 
        <img class="ml-small mr-auto" [src]="cdn.toCDN('images/icons/go-rounded.png')"
        [srcset]="cdn.toCDN('images/icons/go-rounded@2x.png 2x') + ', ' + cdn.toCDN('images/icons/go-rounded@3x.png 3x')"
        alt="view details" />
    </div>
</div>
<app-modal #appModal [id]="'opportunity-' + opportunity?.id" size="small">
    <div class="details-modal-body">
        <app-progress-bar
            *ngIf="working" color="green" [value]="working">
        </app-progress-bar>
        <a [routerLink]="['/employers', employer?.urlName, 'opportunity', opportunity?.urlName, 'edit']" class="button btn-blue ml-auto mr-auto mb-small">Edit</a>
        <div class="details-modal-button">
            <button class="button orange outline ml-auto mr-auto mb-small" (click)="duplicateOpportunity()" [disabled]="working">Duplicate Opportunity</button>
            <app-info-modal [audience]="'Employers'" [section]="'Opportunities'" [item]="'Create New Opportunity'"></app-info-modal>
        </div>   
        <button class="button orange outline ml-auto mr-auto mb-small" (click)="copyToClipboard()" [disabled]="working">Copy to Clipboard</button>
        <button class="button orange outline ml-auto mr-auto mb-small" (click)="stopOpportunity()" [disabled]="working">Stop Opportunity</button>
    </div>
</app-modal>

<!-- <app-modal id="applicationModal">
    <app-student-opportunity-application [opportunity]="opportunity"></app-student-opportunity-application>
</app-modal> -->
