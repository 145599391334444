import { Injectable } from "@angular/core";
import { DropDownOptions } from "../components/dropdown/dropdown.component";
import { IdName } from "../models";

@Injectable()
export class DegreeService {

    getDegree(id: number): IdName | undefined {
        return this.getDegrees().find(x => x.id === id);
    }

    getDegrees(): IdName[] {
        return [{
             id: 1, 
             name: 'Associate\'s Degree' 
            }, {
                id: 2,
                name: 'Bachelor\'s Degree'
            }, {
                id: 3,
                name : 'Master\'s Degree',
            } , {
                id: 4,
                name: 'PhD'
            }];
    }

    getDegreeDropdownValues(): DropDownOptions[] {
        return this.getDegrees().map(d => { return { label: d.name, value: d.id }});
    }   
}