import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { City, EntityType, School, SchoolDepartment, Staff, Student } from 'src/app/shared/models';
import { ArrayMatchSortPipe } from 'src/app/shared/pipes';
import { ModalService, LocationService, SchoolService, SizeService, UserService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { QuillConfiguration } from 'src/app/static/quill/quilll.config';
import { SchoolAddress } from 'src/app/shared/models/address/school.address.model';
import { Subscription } from 'rxjs';
import { StepperComponent } from 'src/app/shared/components/stepper/stepper.component';
type stepId = 'info' | 'content' | 'team' | 'overview';
@Component({
  selector: 'app-my-school',
  templateUrl: './edit-school-profile.component.html',
  styleUrl: './edit-school-profile.component.scss'
})
export class EditSchoolProfileComponent implements OnInit, OnDestroy  {


  formGroup: FormGroup;
  departmentFormGroup: FormGroup;
  locationFormGroup: FormGroup;
  cityFormGroup: FormGroup;
  working = 0;
  // user: User;
  // staff: Staff;
  urlName: string;
  school: School;
  allLocations: string[] = [];
  
  allStates: any[] = [];
  allCities: any[] = [];
  cityList: any;
  contactTypes: any;
  selectedState = '';
  newCity = '';

  entityType = EntityType;
  staffList: Staff[];
  studentList: Student[];
  staffPageNumber: number = 1;
  studentPageNumber: number = 1;
  departments: DropDownOptions[] = [];
  sizes: DropDownOptions[] = [];
  selectedSize: DropDownOptions | undefined;
  quillConfiguration = QuillConfiguration;
  subscriptions: Subscription[] = [];

  @ViewChild('stepper') stepper: StepperComponent;

  
  activeStep = 0;
  steps: { id: stepId, title: string, split: boolean, valid: () => boolean, next: () => void }[] = [{
    id: 'info',
    title: 'Main Information',
    split: true,
    valid: () => {
      return true
    },
    next: () => {
      this.saveAndNext()
    }
    }, {
      id: 'content',
      title: 'Content',
      split: false,
      valid: () => {
        return true
      },
      next: () => this.saveAndNext()
  }, {
    id: 'team',
    title: 'Resources & Team',
    split: true,
    valid: () => {
      return true
    },
    next: () => {
      this.stepper.next()
    }
  }, {
    id: 'overview',
    title: 'Overview',
    split: true,
    valid: () => {
      return true
    },
    next: () => {
      this.router.navigate(['/school', this.school.urlName, 'profile' ])
    }
  }, ]

  displayDepartmentForm: boolean = true;
  displayLocationForm: boolean = false;
  displayDepartments: boolean = false;
  displayLocations: boolean = false;

  constructor(
    private modals: ModalService,
    private snackbar: ToastService,
    private locationService: LocationService,
    private schoolService: SchoolService,
    private fb: FormBuilder,
    public cdn: CDNService,
    private sizeService: SizeService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService

  ) {
    // this.user = this.users.authenticatedUser;

    this.urlName = this.route.snapshot.params.urlName;
    this.createDepartmentForm();
    this.createLocationForm();
    this.createCityForm();

  }

  displayDepartment(){
    this.displayDepartmentForm = true;
    this.displayLocationForm = false;
  }

  displayLocation(){
    this.displayDepartmentForm = false;
    this.displayLocationForm = true;
  }

  displayDepartmentList(){
    this.displayDepartments = !this.displayDepartments;
  }

  displayLocationList(){
    this.displayLocations = !this.displayLocations;
  }

  async saveAndNext() {
    const saveSuccessful = await this.saveSchool()
    if (saveSuccessful) {
      this.stepper.next()
    }
  }

  checkProfileProgress(): void {
    this.router.navigate(['/school', this.school.urlName, 'profile' ])
  }

  currentStepValid() {
    return this.steps[this.activeStep].valid();
  }

  async ngOnInit(): Promise<void> {
    this.working = 10;
    try {
      this.school = await this.schoolService.get(this.urlName);
      if (!this.school) {
        this.router.navigate(['/page-not-found']);
      }
      this.working += 10;

      this.createDepartmentForm();
     
      this.allLocations = await this.locationService.getCities();
      this.sizes = (await this.sizeService.get()).map(ss => { return { label: ss.value, value: ss.value } });
      // this.staff = await this.schoolService.getStaff(this.user.id);
      this.working += 10;
      //this.staffList = await this.schoolService.getFacultyList(this.urlName);
      //this.working += 10;
      //this.studentList = await this.schoolService.getStudentList(this.urlName);
      //this.working += 10;
      this.departments = (await this.schoolService.getSchoolDepartments(this.school.id)).map(x => { return { value: x.id, label: x.departmentName } });

      this.working += 10;
      this.allStates = (await this.locationService.getStateList()).map(x => { return { value: x.id, label: x.name } });
      this.allCities = (await this.locationService.getCityList());
      this.cityList = this.allCities.map(x => { return { value: x.id, label: x.name + ', ' + x.state?.name } });
      this.contactTypes = (await this.userService.getContactTypes()).map(x => { return { value: x.id, label: x.contactType } });

      this.working += 10;
      this.formGroup = this.createForm();
     
    } catch (error) {
      this.snackbar.error({
        message: 'There was a problem loading your school information',
      });
    } finally {
      this.working = 0;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  goToStudentList(): void {
      this.router.navigate(
        ['/school', this.school.urlName, 'students'],
        { state: { fromSpecificPage: true } }
      );
    }
  

  createForm(): FormGroup {
    // const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
    const fg = this.fb.group({
      SchoolName: new FormControl(this.school?.name, [Validators.required]),
      Domain: new FormControl(this.school?.domain, [Validators.required]),
      EmailDomain: new FormControl(this.school?.emailDomain, [Validators.required]),
      Phone: new FormControl(this.school?.phone, [Validators.required]),
      ContactEmail: new FormControl(this.school?.contactEmail, [Validators.required, Validators.email]),
      Size: new FormControl(this.school?.size, [Validators.required]),
      LogoUrl: new FormControl(this.school?.logoUrl),
      YoutubeUrl: new FormControl(this.school?.youtubeUrl),
      Description: new FormControl(this.school?.description, [Validators.required]),
      Benefits: new FormControl(this.school?.benefits),
      Culture: new FormControl(this.school?.culture),
      MeetingUrl: new FormControl(this.school?.meetingUrl)
    });

    this.selectedSize = fg.get('Size').value as any;

    return fg;
  }

  async saveSchool(): Promise<boolean> {
    this.working = 50;
    try {
      this.school.name = this.formGroup.get('SchoolName').value;
      this.school.size = this.formGroup.get('Size').value.value;
      this.school.domain = this.formGroup.get('Domain').value;
      this.school.emailDomain = this.formGroup.get('EmailDomain').value;
      this.school.logoUrl = this.formGroup.get('LogoUrl').value;
      this.school.description = this.formGroup.get('Description').value;
      this.school.benefits = this.formGroup.get('Benefits').value;
      this.school.culture = this.formGroup.get('Culture').value;
      this.school.youtubeUrl = this.formGroup.get('YoutubeUrl').value;
      this.school.phone = this.formGroup.get('Phone').value;
      this.school.contactEmail = this.formGroup.get('ContactEmail').value;
      this.school.meetingUrl = this.formGroup.get('MeetingUrl').value;
      this.school = await this.schoolService.updateSchool(this.urlName, this.school);
      return true;

    } catch (error) {
      this.snackbar.error({
        title: 'Error Savving',
        message: 'There was an error saving...'
      });
      return false;

    } finally {
      this.working = 0;
    }
  }

  imageUploaded(e: string[]): void {
    if (e?.length) {
      this.modals.close('uploadImage');
    }
  }
  async imageSelected(e: File[]): Promise<void> {
    this.working = e?.length ?? 10;
    try {
      this.working = 50;
      this.modals.close('uploadImage');
    } catch (error) {
      this.snackbar.error({
        message: 'There was a problem loading your school information',
      });
    } finally {
      this.working = 0;
    }
  }
  async logoSelected(e: File[]): Promise<void> {
    this.working = e?.length ?? 10;
    try {
      this.working = 50;
      this.modals.close('uploadLogo');
    } catch (error) {
      this.snackbar.error({
        message: 'There was a problem selecting your logo',
      });
    } finally {
      this.working = 0;
    }
  }

  logoUploaded(e: string[]): void {
    if (e?.length) {
      this.school.logoUrl = e[0];
      this.modals.close('uploadlogo');
    }
  }

  async bannerSelected(e: File[]): Promise<void> {
    this.working = e?.length ?? 10;
    try {
      this.working = 50;
      this.modals.close('uploadBanner');
    } catch (error) {
      this.snackbar.error({
        message: 'There was a problem selecting your banner',
      });
    } finally {
      this.working = 0;
    }
  }

  bannerUploaded(e: string[]): void {
    if (e?.length) {
      this.school.bannerUrl = e[0];
      this.modals.close('uploadBanner');
    }
  }

  // async switchUserType(e: Student){
  //   this.working = 50;
  //   try {
  //     const staff = await this.schoolService.switchToStaff(e.id);
  //     this.working = 90;
  //     const i = this.studentList.indexOf(e);
  //     this.studentList.splice(i, 1);
  //     this.staffList.push(staff);
  //     this.snackbar.success({
  //       title: 'Switched',
  //       message: `${e?.user?.firstName} was switched to staff`
  //     });
  //   } catch (error) { 
  //     this.snackbar.error({
  //       title: 'Error flipping to staff',
  //       message: 'There was a problem flipping this user to staff'
  //     });
  //   } finally {
  //     this.working = 0;
  //   }
  // }

  getFormControl(name: string): FormControl {
    return this.formGroup.controls[name] as FormControl;
  }
  schoolName(): FormControl {
    return this.getFormControl('SchoolName');
  }
  logoUrl(): FormControl {
    return this.getFormControl('LogoUrl');
  }

  UrlName(): FormControl {
    return this.getFormControl('UrlName');
  }

  enrollmentCapacity(): FormControl {
    return this.getFormControl('EnrollmentCapacity');
  }

  domain(): FormControl {
    return this.getFormControl('Domain');
  }
  emailDomain(): FormControl {
    return this.getFormControl('EmailDomain');
  }
  schoolSize(): FormControl {
    return this.getFormControl('SchoolSize');
  }

 
  openModal(id: string): void {
    this.modals.open(id);
  }

  close(id: string): void {
    this.modals.close(id);
  }


  selectSize(value: DropDownOptions): void {
    this.formGroup.get('Size').setValue(value.label);
  }

  // SCHOOL DEPARTMENTS

  createDepartmentForm(){
    this.departmentFormGroup = this.fb.group({
      PreferredContact: new FormControl('', [Validators.required]),
      Department: new FormControl('', [Validators.required]),
      ContactType: new FormControl('')
    });

  }

  updateDepartment: boolean;
  schoolDepartmentId: number;
  editSchoolDepartment(d: SchoolDepartment){
    this.departmentFormGroup.get('PreferredContact').setValue(d.preferredContact);
    this.departmentFormGroup.get('Department').setValue(d.departmentName);
    const contactType = d.contactTypeId ? this.contactTypes.find(c => c.value === d.contactTypeId)  : null;
    this.departmentFormGroup.get('ContactType').setValue(contactType ? contactType.label : null);
    this.updateDepartment = true;
    this.schoolDepartmentId = d.id;
    this.openModal('addDepartment');
  }
  getDepartmentFormControl(name: string): FormControl {
    return this.departmentFormGroup.controls[name] as FormControl;
  }

  async removeSchoolDepartment(d: SchoolDepartment): Promise<void>{
    this.working = 50;
    this.school.departments = await this.schoolService.removeDepartment(this.school.urlName, d.id);
    this.working = 0;
  }

  addDepartmentForm(){
    this.departmentFormGroup.get('PreferredContact').setValue('');
    this.departmentFormGroup.get('Department').setValue('');
    this.departmentFormGroup.get('ContactType').setValue('');
    
    this.schoolDepartmentId = 0
    this.updateDepartment = false;
    this.openModal('addDepartment');
  }

 
  async addDepartment(): Promise<void>{
    const d: SchoolDepartment = {
      schoolId: this.school.id,
      departmentName: this.departmentFormGroup.get('Department').value,
      preferredContact: this.departmentFormGroup.get('PreferredContact').value,
      contactTypeId: this.departmentFormGroup.get('ContactType').value.value,
      id: this.schoolDepartmentId
    };
  
    try {
      this.working = 50;
        this.school.departments = await this.schoolService.addDepartment(this.urlName, d);
        this.snackbar.success({
          title: 'Added',
          message: 'The new department has been added.'
        });
    } catch (error) {
      this.snackbar.error({
        title: 'Error',
        message: 'There was a problem adding the department'
      });
    } finally {
      this.working = 0;
    }
  }

  // SCHOOL LOCATION
  createLocationForm(){
    this.locationFormGroup = this.fb.group({
      Name: new FormControl('', [Validators.required]),
      Address1: new FormControl('', [Validators.required]),
      Address2: new FormControl(''),
      City: new FormControl('', [Validators.required]),
      State: new FormControl('', [Validators.required]),
      ZipCode: new FormControl('', [Validators.required]),
    });

  }

  createCityForm(){
    this.cityFormGroup = this.fb.group({
      City: new FormControl('', [Validators.required]),
      State: new FormControl('', [Validators.required]),
    });

  }

 
  onSearch(value: any, array: any): any {
    // Create a set from this.allMajors to ensure uniqueness
    const orgSet = new Set(array);
    // Transform value.items and convert it to a set to ensure uniqueness
    const diffSet = new Set(new ArrayMatchSortPipe().transform(value.items, value.term));
    // Combine sets without duplicates
    const combinedSet = new Set([...diffSet, ...orgSet ]);
    // Convert the set back to an array
    return Array.from(combinedSet);
  }

  async addLocation(){
    const s: SchoolAddress = {
      id: 0,
      primary: false,
      schoolId: this.school.id,
      name: this.locationFormGroup.get('Name').value,
      address: {
        address1: this.locationFormGroup.get('Address1').value,
        address2: this.locationFormGroup.get('Address2').value,
        zipCode: this.locationFormGroup.get('ZipCode').value,
        cityId: this.locationFormGroup.get('City').value.value,
        id: 0,
      }
    };
    try {
      this.working = 50;
      const success = await this.schoolService.createLocation(this.school.id, s);
      if(success){
        this.modals.close('addLocation');
        this.school.addresses.push(s);
      }
    } catch (error) {
      this.snackbar.error({
        title: 'Error',
        message: 'There was a problem adding this location'
      });
    } finally {
      this.working = 0;
    }
  }

  async addCity(): Promise<boolean>{
    const city: City = {
      id: 0,
      name: this.cityFormGroup.get('City').value,
      stateId: this.cityFormGroup.get('State').value.value,
    };
  
    await this.locationService.createCity(city);
    return true;
  }
}

