<div class="list-view">
    <div class="bg-teal pl-medium pr-medium">
        <div class="bg-lime pl-medium pr-medium py-large">
            <app-filter-menu [vertical]="true" (outputListEvent)="updateStaffList($event)"
                [inputList]="staff" [filterDropDownOptions]="staffFilterDropDownOptions">
            </app-filter-menu>
        </div>
    </div>


    <div *ngIf="modifiedStaffList" class="bg-cream pl-medium pr-medium py-large">
        <div class="flex-columns justify-between mb-medium">
            <div class="flex-columns">
                <div class="text text-title mb-small mr-small">
                    Advisors
                </div>
                <div class="text text-900 text-no-wrap" *ngIf="user?.isStaff && user?.staff?.school?.id === school?.id">
                    {{modifiedStaffList.length}} results
                </div>
            </div>
            <button class="btn lime-green mr-small" [routerLink]="['/school', this.school.urlName, 'staff', 'new']"
                *ngIf="user?.isStaff && user?.staff?.school?.id === school?.id">
                Invite New Staff
            </button>
        </div>

        <div class="flex-columns justify-between grid-quarters">

            <ng-template ngFor let-s
                [ngForOf]="modifiedStaffList | paginate: {id: 'staff-pager', itemsPerPage: 12, currentPage: staffPageNumber }">
                <div class="mb-small profile-card bg-white pl-small pr-small py-small">
                    <div class="text text-title">
                        {{ s.firstName }} {{ s.lastName }}
                    </div>
                    <div class="flex-columns">
                        <div class="flex-rows">
                            <div class="text text-rounded-mplus text-400">
                                {{ s.positionName }}
                            </div>
                            <div class="text text-rounded-mplus text-400">
                                {{ s.departmentName }}
                            </div>
                        </div>
                        <div class="avatar ml-auto">
                            <img [src]="cdn.toCDN(s?.user.avatar, true)" alt="profile pic" />
                        </div>
                    </div>
                    <a class="profile btn btn-white mr-small border-teal"
                        [routerLink]="['/school', this.school.urlName, 'staff', s.urlName]">View Profile</a>
                </div>
            </ng-template>
        </div>
        <div class="mt-small flex-columns justify-around">
            <pagination-controls id="staff-pager" (pageChange)="staffPageNumber = $event"></pagination-controls>
        </div>
    </div>

</div>