import { environment } from '../../../environments/environment';

export class UserApiConfig {
    static path: string = environment.api.user.path;
    static version: string | number = environment.api.user.version;

    public static setApiVersion(version: string | undefined = 'api'): void {
        UserApiConfig.version = version;
    }

    public static getApiVersion(): string | number {
        return UserApiConfig.version;
    }

    public static getPath(): string {
        return UserApiConfig.path;
    }

    public static getFullUrl(path): string {
        if (UserApiConfig.getApiVersion()) {
            return [UserApiConfig.getPath(), UserApiConfig.getApiVersion(), path].join('/');
        } else {
            return [UserApiConfig.getPath(), path].join('/');
        }
    }
}
