import { Component } from '@angular/core';

@Component({
  selector: 'app-connect-whats-next',
  templateUrl: './connect-whats-next.component.html',
  styleUrl: './connect-whats-next.component.scss'
})
export class ConnectWhatsNextComponent {

}
