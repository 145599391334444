import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class SubscriptionApiConfig {
    static path: string = environment.api.subscription.path;
    static version: string | number = environment.api.subscription.version;

    public static setApiVersion(version: string | undefined = 'api'): void {
        SubscriptionApiConfig.version = version;
    }

    public static getApiVersion(): string | number {
        return SubscriptionApiConfig.version;
    }

    public static getPath(): string {
        return SubscriptionApiConfig.path;
    }

    public static getFullUrl(path): string {
        if (SubscriptionApiConfig.getApiVersion()) {
            return [SubscriptionApiConfig.getPath(), SubscriptionApiConfig.getApiVersion(), path].join('/');
        } else {
            return [SubscriptionApiConfig.getPath(), path].join('/');
        }
    }
}
