import { Injectable } from '@angular/core';
import {warning, success, error} from 'toastr';

type toastOptions = {
  message: string,
  title?: string,
  closeDuration?: number
};

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private ERROR_CLASS = 'toast-bottom-center';
  private SUCCESS_CLASS = 'toast-top-center';

  public success(options: toastOptions) {
    success(options.message, options.title ?? 'Success!', {
      positionClass: this.SUCCESS_CLASS,
      closeDuration: options.closeDuration
    });
  }

  public warning(options: toastOptions) {
    warning(options.message, options.title ?? 'Warning!', {
      positionClass: this.ERROR_CLASS,
      closeDuration: options.closeDuration
    });
  }

  public error(options: toastOptions) {
    error(options.message, options.title ?? 'Error!', {
      positionClass: this.ERROR_CLASS,
      closeDuration: options.closeDuration
    });
  }
}
