import { Component, Input } from "@angular/core";

@Component({
  selector: "app-right-image",
  templateUrl: "./right-image.component.html",
  styleUrl: "./right-image.component.scss",
})
export class RightImageComponent {
  @Input()
  image: string;
  @Input()
  imageAlt: string;
  @Input()
  stickerText: string = null;
  @Input()
  stickerColor: string = "white";
}
