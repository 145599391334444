
<h3 *ngIf="heading">{{heading}}</h3>

<div class="marquee {{direction}}">
    <div class="marquee-content">
        <div class="collection collection-1">
            <ng-container *ngFor="let element of marqueeElements;">
                <div class="flex-rows">
                    <img class="mb-small" *ngIf="element.imageUrl?.length" [src]="element.imageUrl" [alt]="element.text" />
                    <span>{{element.text}}</span>
                </div>
                <span ngClass="{{element.color}}"> - </span>
            </ng-container>
            <ng-container *ngFor="let element of marqueeElements; last as isLast">
                <div class="flex-rows">
                    <img class="mb-small" *ngIf="element.imageUrl?.length" [src]="element.imageUrl" [alt]="element.text" />
                    <span>{{element.text}}</span>
                </div>
                <span *ngIf="!isLast" ngClass="{{element.color}}"> - </span>
            </ng-container>
        </div>
        <!-- <div class="collection collection-2">
            <ng-container *ngFor="let element of marqueeElements; last as isLast">
                <div class="flex-rows">
                    <img class="mb-small" *ngIf="element.imageUrl?.length" [src]="element.imageUrl" [alt]="element.text" />
                    <span>{{element.text}}</span>
                </div>
                <span *ngIf="!isLast" ngClass="{{element.color}}"> - </span>
            </ng-container>
        </div> -->
    </div>
</div>
