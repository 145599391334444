import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";

@Injectable({ providedIn: 'root' })
export class PromptUpdateService {

  constructor(swUpdate: SwUpdate) {
    if (swUpdate.isEnabled) {
      swUpdate.versionUpdates.subscribe((evt) => {
        if (evt.type === 'VERSION_READY') {
          document.location.reload();
        }        
      });
    }
  }

}