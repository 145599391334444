import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { StudentOpportunity } from 'src/app/shared/models';
import { ModalService, OpportunityService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';

@Component({
  selector: 'app-employer-prospect-card',
  templateUrl: 'employer-prospect-card.component.html',
  styleUrls: ['employer-prospect-card.component.scss']
})
export class EmployerProspectCardComponent {
  @Input() 
  get prospect(): StudentOpportunity {
    return this._prospect;
  }
  set prospect(value: StudentOpportunity) {
    this._prospect = value;
    if (value) {
      this.acceptId = `accept-prospect-${value.id}`;
      this.rejectId = `reject-prospect-${value.id}`;
    }
  }

  private _prospect: StudentOpportunity;
  working = false;
  acceptId = '';
  rejectId = '';

  constructor(
    private opportunities: OpportunityService,
    public cdn: CDNService,
    private modals: ModalService,
    private router: Router) {}

  viewProfile(ev: Event) {
    this.stopProp(ev)
    this.router.navigate(['/students', this.prospect.student.urlName])
  }

  stopProp(ev: Event) {
    ev.stopPropagation()
  }

  async acceptProspect(id: string, e: Event): Promise<void> {
    e.stopPropagation();
    try {
      this.working = true;
      this.modals.close(id);
      await this.opportunities.accept(this.prospect.id).toPromise();
      this.prospect.accepted = true;
    } catch (error) {
      console.error(error);
    } finally {
      this.working = false;
    } 
  }

  async rejectProspect(id: string, e: Event): Promise<void> {
    e.stopPropagation();
    try {
      this.working = true;
      this.modals.close(id);
      await this.opportunities.reject(this.prospect.id).toPromise();
      this.prospect.rejected = true;
    } catch (error) {
      console.error(error);
    } finally {
      this.working = false;
    }
  }

  openModal(id: string, e: Event): void {
    e.stopPropagation();
    this.modals.open(id);
  }
  closeModal(id: string, e: Event): void {
    e.stopPropagation();
    this.modals.close(id);
  }

  openDocument(url: string, e: Event): void {
    e.stopPropagation();
    window.open(url, '_blank');
  }
}