import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { IndustryApiConfig } from '../api';
import { skipAuthHeaders } from '../auth/auth-interceptor';

@Injectable()
export class IndustryService {

    constructor(private http: HttpClient) { }

    public get(): Promise<string[]> {
        return lastValueFrom(this.http.get<string[]>(IndustryApiConfig.getFullUrl('names'), { headers: skipAuthHeaders }));
    }
}
