    <div class="text mb-small" *ngIf="isEdit">
        Edit Schedule
    </div>
    <p class="text mb-small" *ngIf="isEdit">
        Try to provide a general idea to employers about your availability by using Unext’s
        schedule tool. This can help employers understand which days you’re able to commit
        to an opportunity.
    </p>
    <div class="schedule" [ngClass]="{ 'rounded-corners': includeBorder }">
        <div class="text heading-3 mb-small" *ngIf="includeTitle">
            Available Days
        </div>
        <app-progress-bar *ngIf="isWorking" [value]="33"></app-progress-bar>
        <div *ngIf="!isWorking" class="availability mb-small">
            <div class="label text" *ngIf="isEdit || availability?.monday">
                Monday
            </div>
            <div class="toggle" *ngIf="isEdit">
                <app-toggle-bar size="medium" [isOn]="availability?.monday" (toggled)="toggle('monday', $event)" [disabled]="!isEdit"></app-toggle-bar>
            </div>
            <div class="label text" *ngIf="isEdit || availability?.tuesday">
                Tuesday
            </div>
            <div class="toggle" *ngIf="isEdit">
                <app-toggle-bar size="medium" [isOn]="availability?.tuesday" (toggled)="toggle('tuesday', $event)" [disabled]="!isEdit"></app-toggle-bar>
            </div>
            <div class="label text" *ngIf="isEdit || availability?.wednesday">
                Wednesday
            </div>
            <div class="toggle" *ngIf="isEdit">
                <app-toggle-bar size="medium" [isOn]="availability?.wednesday" (toggled)="toggle('wednesday', $event)" [disabled]="!isEdit"></app-toggle-bar>
            </div>
            <div class="label text" *ngIf="isEdit || availability?.thursday">
                Thursday
            </div>
            <div class="toggle" *ngIf="isEdit">
                <app-toggle-bar size="medium" [isOn]="availability?.thursday" (toggled)="toggle('thursday', $event)" [disabled]="!isEdit"></app-toggle-bar>
            </div>
            <div class="label text" *ngIf="isEdit || availability?.friday">
                Friday
            </div>
            <div class="toggle" *ngIf="isEdit">
                <app-toggle-bar size="medium" [isOn]="availability?.friday" (toggled)="toggle('friday', $event)" [disabled]="!isEdit"></app-toggle-bar>
            </div>
            <div class="label text" *ngIf="isEdit || availability?.saturday">
                Saturday
            </div>
            <div class="toggle" *ngIf="isEdit">
                <app-toggle-bar size="medium" [isOn]="availability?.saturday" (toggled)="toggle('saturday', $event)" [disabled]="!isEdit"></app-toggle-bar>
            </div>
            <div class="label text" *ngIf="isEdit || availability?.sunday">
                Sunday
            </div>
            <div class="toggle" *ngIf="isEdit">
                <app-toggle-bar size="medium" [isOn]="availability?.sunday" (toggled)="toggle('sunday', $event)" [disabled]="!isEdit"></app-toggle-bar>
            </div>
        </div>
        <!-- <button *ngIf="!isWorking && isEdit" type="button" class="button btn-blue mb-small mr-small" (click)="discard()">Discard Changes</button>
        <button *ngIf="!isWorking && isEdit" type="button" class="button orange outline" (click)="updateSchedule()">Save Changes</button> -->
    </div>

