import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export function IsDateValidator(): any {
    return (control: FormControl): { [key: string]: any } => {
        if (!control.value?.length) {
            return null;
        }
        const val = moment(control.value, 'MM/DD/YYYY', true);

        if (!val.isValid()) {

            const val2 = moment(control.value, 'YYYY-MM-DD', true);
            if (!val2.isValid()) {
                return { invalidDate: true };
            }
        }

        return null;
    };  
}

export function DateRelativeValidator(date: Date, before: boolean): any {
    return (control: FormControl): { [key: string]: any } => {
        const val = moment(control.value, 'MM/DD/YYYY', true);
        const base = moment(date, 'MM/DD/YYYY', true);

        if (before) {
            if (base < val) {
                return { invalidFutureDate: true };
            }
        } else {
            if (val < base) {
                return { invalidPastDate: true };
            }
        }

        return null;
    };
}

// export function ValidatorAfterDateFormValue(controlName: string, afterControlName: string): any {
//     return (formGroup: FormGroup) => {
//         if (formGroup && formGroup.controls) {
//             const control = formGroup.controls[controlName];
//             const aftercontrol = formGroup.controls[afterControlName];

//             if (aftercontrol.errors && aftercontrol.errors.mustMatch) {
//                 return;
//             }
//             if (control.value === '' || aftercontrol.value === '') {
//                 return;
//             }

//             if (moment(control.value, 'MM/DD/YYYY, true') <= moment(aftercontrol.value, 'MM/DD/YYYY', true)) {
//                 aftercontrol.setErrors({ afterDate: true });
//             } else {
//                 aftercontrol.setErrors(null);
//             }
//         }
//         return;
//     };
// }

// export function IsTimeValidator(): any {
//     return (control: FormControl): { [key: string]: any } => {
//         const val = control.value;

//         const reg = RegExp('^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$');
//         if (reg.test(control.value)) {
//             return null;
//         } else {
//             return { invalidPastDate: true };
//         }
//     };
// }

// export function TimeRelativeValidator(time: string, before: boolean): any {
//     return (control: FormControl): { [key: string]: any } => {
//         const now = moment(moment().format()).set('hour', control.value.subString(0, 2))
//             .set('minute', control.value.subString(3, 2))
//             .set('gg', control.value.subString(5, 2));
//         const val = moment(control.value, 'MM/DD/YYYY', true);
//         const base = moment(now, 'MM/DD/YYYY', true);

//         if (before) {
//             if (base < val) {
//                 return { invalidTime: true };
//             }
//         }

//         return null;
//     };
// }

export function ValidatorAfterDateFormValue(compare: string): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {

        const value = control.value;

        if (!value) {
            return null;
        }

        const c = control.parent.get(compare);
        if (!c) {
            return null;
        }

        const compareValue = c.value;

        return compareValue <= value ? null : { afterDate: true };
    }
}