<app-progress-bar [splashScreen]="true" [value]="loading"></app-progress-bar>
<div class="list-view">
    <div class="bg-teal pl-medium pr-medium">
        <div class="bg-lime pl-medium pr-medium py-large">
            <app-filter-menu (outputListEvent)="updateEmployerList($event)" [inputList]="employers"
                [filterCheckBoxOptions]="employerFilterCheckBoxOptions" [searchOptions]="employerSearchOptions" [filterDropDownOptions]="employerFilterDropDownOptions"
                [sortOptions]="employerSortOptions">
            </app-filter-menu>
        </div>
    </div>

    <div *ngIf="employerList?.length" class="bg-cream pl-medium pr-medium py-large">
        <div class="flex-columns mb-medium">
            <div class="text text-title mb-small mr-small">
                Employers
            </div>
            <div class="text text-900">
                {{employerList.length}} results
            </div>
        </div>
       
        <div class="flex-columns justify-between grid-quarters">
            <div  *ngFor="let em of employerList | paginate: {id: 'employer-pager', itemsPerPage: 12, currentPage: employerPageNumber };">
                <div class="profile-card mb-small bg-white pl-small pr-small py-small">
                    <div class="text text-title">
                        {{ em.name }}
                    </div>
                    <div class="flex-columns">
                        <div class="avatar ml-auto">
                            <img [src]="em.smallLogoUrl" alt="profile pic" />
                        </div>
                    </div>
                    <button class="profile btn btn-white mr-small border-teal"
                        [routerLink]="['/employers', this.em.urlName]">View Profile
                    </button>
                </div>
            </div>
        </div>

       

        <div *ngIf="employerList" class="mt-small flex-columns justify-around">
            <pagination-controls id="employer-pager" (pageChange)="employerPageNumber = $event"></pagination-controls>
        </div>
    </div>
    <div *ngIf="!employerList?.length && !loading" class="bg-cream pl-medium pr-medium py-large">
        <label for="">No employers Found</label>
    </div>
    
</div>