
<div class="text heading-4 mb-small">
    Existing Opportunities
</div>

<form [formGroup]="formGroup" class="form filters mb-medium" (ngSubmit)="search()">
    <input type="text" class="form-input form-input-rounded form-input-green" (change)="updateFilter()"
        formControlName="filterText" placeholder="Job Title or Keywords" />
    <app-dropdown color="white" #ddIndustry
        label="Industry"
        [persist]="true"
        [fill]="true"
        [value]="selectedIndustry"
        (selected)="selectIndustry($event)"
        [actions]="industries | sort:'label':true"></app-dropdown>
    <app-dropdown color="white" #ddEmployment
        label="Employment"
        [persist]="true"
        [fill]="true"
        [value]="selectedEmployment"
        (selected)="selectEmployment($event)"
        [actions]="employmentTypes"></app-dropdown>
    <app-dropdown color="white" #ddLocation
        label="Locations"
        [persist]="true"
        [fill]="true"
        [value]="selectedLocation"
        (selected)="selectLocation($event)"
        [actions]="locations | sort:'label':true"></app-dropdown>
    <button type="submit" class="button dark-green">Search</button>
    <a *ngIf="searching" class="button button-link clear-search" (click)="clearSearch()">reset filters</a>
</form>

<app-progress-bar *ngIf="loading" [value]="loading" ></app-progress-bar>

<div class="text heading-4 mb-small" *ngIf="(showInactive || showUnpublished) && searchResultsActive?.length">
    <div>
        Published & Active ({{ searchResultsActive?.length }})
    </div>
    <button type="button" class="button orange outline button-slim ml-auto" (click)="showActive = !showActive">
        {{ (showActive ? 'hide' : 'show' ) }}
    </button>
</div>
<div class="opportunities mt-medium" *ngIf="searchResultsActive?.length && showActive">
    <ng-template ngFor let-o [ngForOf]="searchResultsActive | sort:'title':true" >
        <app-opportunity-card [opportunity]="o" [employer]="employer" class="mr-small mb-small"></app-opportunity-card>
    </ng-template>
</div>
<div class="opportunities mt-medium" *ngIf="!searchResultsActive?.length && !loading">
    <div class="text text-500 heading-4 mb-small">
        There are no active opportunities.
    </div>
</div>

<div class="text heading-4 mb-small" *ngIf="(showInactive || showUnpublished) && searchResultsPending?.length">
    <div>
        Ready to Go, Pending Publish Date ({{ searchResultsPending?.length }})
    </div>
    <button type="button" class="button btn-blue button-slim ml-auto" (click)="showPending = !showPending">
        {{ (showPending ? 'hide' : 'show' ) }}
    </button>
</div>
<div class="opportunities mt-medium" *ngIf="searchResultsPending?.length && showPending">
    <ng-template ngFor let-o [ngForOf]="searchResultsPending | sort:'title':true" >
        <app-opportunity-card [opportunity]="o" [employer]="employer" class="mr-small mb-small"></app-opportunity-card>
    </ng-template>
</div>

<div class="text heading-4 mb-small" *ngIf="(showInactive || showUnpublished) && searchResultsInProgress?.length">
    <div>
        In the works...  Not Published  ({{ searchResultsInProgress?.length }})
    </div>
    <button type="button" class="button btn-purple button-slim ml-auto" (click)="showInProgress = !showInProgress">
        {{ (showInProgress ? 'hide' : 'show' ) }}
    </button>
</div>
<div class="opportunities mt-medium mb-small" *ngIf="searchResultsInProgress?.length && showInProgress">
    <ng-template ngFor let-o [ngForOf]="searchResultsInProgress | sort:'id':false" >
        <app-opportunity-card [opportunity]="o" [employer]="employer" class="mr-small mb-small"></app-opportunity-card>
    </ng-template>
</div>

<div class="text heading-4 mb-small flex-columns" *ngIf="(showInactive || showUnpublished) && searchResultsExpired?.length">
    <div>
        Expired ({{ searchResultsExpired?.length }})
    </div>
    <button type="button" class="button btn-teal button-slim ml-auto" (click)="showExpired = !showExpired">
        {{ (showExpired ? 'hide' : 'show' ) }}
    </button>
</div>
<div class="opportunities mt-medium" *ngIf="searchResultsExpired?.length && showExpired">
    <ng-template ngFor let-o [ngForOf]="searchResultsExpired | sort:'title':true" >
        <app-opportunity-card [opportunity]="o" [employer]="employer" class="mr-small mb-small"></app-opportunity-card>
    </ng-template>
</div>