export enum EntityType
{
  Unknown = 0,
  AvatarImage = 1,
  EmployerImage = 2,
  EmployerMiniImage = 3,
  SchoolImage = 4,
  StudentImage= 5,
  Resume = 6,
  Video = 7,
  OpportunityImage = 8,
  OpportunityBanner = 9,
  CoverLetter = 10,
  Transcript = 11,
  ApplicationCoverletter = 12,
  SchoolAttachment = 13,
  SchoolLogo = 14,
  SchoolBanner = 15, 
  Portfolio = 16
}