<section>
    <h3>Why Unext?</h3>
    <div class="row">
        <div class="col">
            <span class="metric">3X</span>
            <span class="label">
                Faster resume creation
            </span>
        </div>
        <div class="col">
            <span class="metric">2X</span>
            <span class="label">
                Lower Recruiting Costs
            </span>
        </div>
        <div class="col">
            <span class="metric">
                <svg width="46" height="51" viewBox="0 0 46 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.8787 50.1213C22.0503 51.2929 23.9497 51.2929 25.1213 50.1213L44.2132 31.0294C45.3848 29.8579 45.3848 27.9584 44.2132 26.7868C43.0416 25.6152 41.1421 25.6152 39.9706 26.7868L23 43.7574L6.02944 26.7868C4.85786 25.6152 2.95837 25.6152 1.7868 26.7868C0.615223 27.9584 0.615223 29.8579 1.7868 31.0294L20.8787 50.1213ZM20 0L20 48H26L26 0L20 0Z" fill="white"/>
                </svg>                    
            </span>
            <span class="label">
                Reducing Turnover Rate
            </span>
        </div>
        <div class="col">
            <span class="metric">
                <svg width="46" height="51" viewBox="0 0 46 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.1213 0.878681C23.9497 -0.292892 22.0503 -0.292892 20.8787 0.878681L1.7868 19.9706C0.615223 21.1421 0.615223 23.0416 1.7868 24.2132C2.95837 25.3848 4.85786 25.3848 6.02944 24.2132L23 7.24264L39.9706 24.2132C41.1421 25.3848 43.0416 25.3848 44.2132 24.2132C45.3848 23.0416 45.3848 21.1421 44.2132 19.9706L25.1213 0.878681ZM26 51L26 3H20L20 51H26Z" fill="white"/>
                </svg>                    
            </span>
            <span class="label">
                Increasing Career Understanding
            </span>
        </div>
    </div>
</section>