<app-progress-bar *ngIf="loading" [value]="loading" >

</app-progress-bar>

<div class="opportunities mt-medium">
    <app-opportunity-list [employer]="employer" [opportunities]="all" [student]="user?.student">
    </app-opportunity-list>

    <div class="mb-small">
        <a *ngIf="showMore"
            class="tile green no-flex" [routerLink]="showMoreLink">
            <img class="image-right" [src]="cdn.toCDN('images/icons/go.png')" />
            <p class="text text-normal text-white">
                {{ showMoreText }}
            </p>
        </a>
    </div>

</div>