<app-banner>
    <h1 heading>
        We're Here to <span class="accent">Help.</span> Get in Touch with <span class="accent">Us.</span>
    </h1>
    <p paragraph>We're here to help with any questions or inquiries you may have. 
        Whether you're interested in our services or need support, reach out to us, and we’ll get back to you as soon as possible.</p>
    <img image alt="woman at job interview" src="/assets/images/woman-at-job-interview.png" />
</app-banner>
<section class="bg-cream" id="terms-and-conditions">
    <div class="d-flex flex-column">
        <div>
            <p class="text">
                Have a question? Try our <a [routerLink]="['/faqs']">Frequently Asked Questions page.</a>
                You can also send a message to Unext below and we’ll get back to you
                as soon as we can.
            </p>
        </div>
        <form class="form" [formGroup]="formGroup" (submit)="onSave()">
            <div class="row">
                <div class="col">
                    <label class="form-label text label-1">Full Name:</label>
                    <input class="form-input input-1 text" formControlName="name" placeholder="e.g. Jimmy Johnson" />
                    <label class="form-error text message-1" *ngIf="formGroup.touched && name.errors?.required">
                        Name is <strong>required</strong>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="form-label text label-2">Email Address:</label>
                    <input class="form-input input-2 text" formControlName="email" placeholder="e.g. jjohnson@email.com" />
                    <label class="form-error text message-2" *ngIf="formGroup.touched && email.errors?.email && !email.errors?.required">
                        Please enter a valid email address
                    </label>
                    <label class="form-error text message-2" *ngIf="formGroup.touched && email.errors?.required">
                        Email is <strong>required</strong>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="form-label text label-3">Message to Send:</label>
                    <textarea class="form-input input-3 text" formControlName="message" placeholder="What would you like to tell us?" rows="6"></textarea>
                    <label class="form-error text message-3" *ngIf="formGroup.touched && message.errors?.required">
                        A message of at least {{ minMsgLength }} characters is <strong>required</strong>
                    </label>
                </div>
            </div>
            <button type="submit" class="button dark-green outline" [disabled]="formGroup.invalid">Send Message</button>
        </form>
    </div>
</section>