import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { YoutubeService } from '../../services/youtube.service';

@Component({
  selector: 'app-youtube-video',
  templateUrl: 'youtube-video.component.html',
  styleUrls: ['youtube-video.component.scss']
})
export class YoutubeVideoComponent implements OnChanges {
  @ViewChild('wrapper')
  wrapper: ElementRef<HTMLElement>

  @Input()
  url: string; // https://www.youtube.com/watch?v=dQw4w9WgXcQ
  @Input()
  set width(value: number) {
    this._width = value;
    this._height = (value / 16) * 9; // youtube aspect ratio
  }
  get width(): number {
    return this._width;
  }

  _width = 560;
  _height = 315;

  html: SafeHtml;

  constructor(private youtube: YoutubeService, private sanitizer: DomSanitizer) {
    if (this.url) {
      this.loadVideo();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.url) {
      this.loadVideo();
    }
    if (changes.height || changes.width) {
      this.adjustSize();
    }
  }

  async loadVideo() {
    const tempWrapper = document.createElement('div');
    tempWrapper.innerHTML = await this.youtube.getEmbedHtml(this.url, `${this._height}px`, `${this._width}px`);
    const iframe = tempWrapper.getElementsByTagName('iframe')[0];
    iframe.style.height = `${this._height}px`;
    iframe.style.width = `${this._width}px`;
    this.html = this.sanitizer.bypassSecurityTrustHtml(tempWrapper.innerHTML);
  }

  async adjustSize() {
    const tempWrapper = document.createElement('div');
    tempWrapper.innerHTML = this.wrapper?.nativeElement?.innerHTML ?? '';
    const iframe = tempWrapper.getElementsByTagName('iframe')[0];
    if (iframe) {
      iframe.style.height = '100%';
      iframe.style.width = '100%';
    }
    this.html = this.sanitizer.bypassSecurityTrustHtml(tempWrapper.innerHTML);
  }

}