import { Component, ViewChild } from '@angular/core';
import { BaseSectionComponent } from '../base-section/base-section.component';
import { CustomQuillComponent } from 'src/app/shared/components/custom-quill/custom-quill.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalService, ResumeService, SkillService } from 'src/app/shared/services';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SmartWordService } from 'src/app/shared/services/smartwords.service';
import { UNextExperience } from 'src/app/shared/models';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrl: './experience.component.scss'
})
export class ExperienceComponent extends BaseSectionComponent {
  @ViewChild(CustomQuillComponent) quillDescription!: CustomQuillComponent;

  constructor(
    route: ActivatedRoute,
    fb: FormBuilder,
    modals: ModalService,
    resumes: ResumeService,
    skills: SkillService,
    snackbar: ToastService,
    router: Router,
    public smartwords: SmartWordService) {

    super(route, fb, modals, resumes, skills, snackbar, router);

    this.forcePresentTense = undefined;
    this.validateStartDate = true;
    this.validateEndDate = true;
    this.validateDescription = false;
    this.maxDescriptionLength = 1000;
  
    this.setupForm();
  }

  protected override setupForm(): void {
    super.setupForm();
    this.sectionForm.addControl('title', new FormControl('', Validators.required));
    this.sectionForm.addControl('employerName', new FormControl('', Validators.required));
    this.sectionForm.addControl('location', new FormControl('', [Validators.minLength(4)]));
    this.sectionForm.addControl('remote', new FormControl(false));
    this.sectionForm.addControl('experienceTypeId', new FormControl(1, [Validators.minLength(4)]));
    this.formSetup = true;
  }

  async save(): Promise<any> {

    if (!this.sectionForm.valid) {
      this.snackbar.warning({
        message: 'There is a problem with the information you are trying to submit, please your entry',
        title: 'Invalid information'
      });
    } else {
      if (this.sectionForm.get('description')) {
        this.sectionForm.get('description').setValue(this.quillDescription.textValue);
      }
  
      let returnData: any = {};
      if (this.sectionForm) {
        Object.keys(this.sectionForm.controls).forEach((key) => {
          returnData[key] = this.sectionForm.get(key).value;
        });
      } else {
        returnData = { ...this.section };
      }
      returnData.resumeSectionId = this.currentResumeSection?.resumeSectionId ?? 0;
      returnData = await this.saveSection(returnData);
      this.quillDescription.initialValue = returnData.description;

      this.snackbar.success({
        message: `${this.section?.name ?? 'section'} updated`
      });

    }
    this.sectionForm.updateValueAndValidity();
  }

  override async updateForm(): Promise<void> {
    super.updateForm();
    if (this.sectionForm && this.formSetup) {
      const x = this.currentResumeSection as UNextExperience;
      this.sectionForm.get('title').setValue(x?.title);
      this.sectionForm.get('employerName').setValue(x?.employerName);
      this.sectionForm.get('location').setValue(x?.location);
      this.sectionForm.get('remote').setValue(x?.remote);

      if (this.quillDescription) {
        this.quillDescription.initialValue = '';  //forces reset
        this.quillDescription.initialValue = this.sectionForm.get('description').value;
      }
    }
  }

}
