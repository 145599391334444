import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Employer } from 'src/app/shared/models/employer.model';
import { FilterOption, OpportunityType, SortOption, Student, StudentOpportunity, User } from 'src/app/shared/models';
import { EmployerService, StudentService, UserService } from 'src/app/shared/services';
import { ArraySortPipe } from 'src/app/shared/pipes';

@Component({
  selector: 'app-all-employer-list',
  templateUrl: './all-employer-list.component.html',
  styleUrls: ['./all-employer-list.component.scss']
})
export class AllEmployerListComponent implements OnInit {

  loading = false;
  allEmployers: Employer[];
  employerList: Employer[];
  employerPageNumber: number;
  employerFilterCheckBoxOptions: FilterOption[] = [];
  employerSortOptions: SortOption[] = [];
  employerSearchOptions: any[] = [];
  employers: any[] = []
  student: Student;
  currentUser: User;
  studentOpportunities: StudentOpportunity[] = [];
  employerFilterDropDownOptions: FilterOption[] = [];

  constructor(
    private users: UserService,
    private employerService: EmployerService,
    private studentService: StudentService,
    private changeDetection: ChangeDetectorRef
  ) {
    this.loading = true;

    this.currentUser = this.users.authenticatedUser;
  }

  async ngOnInit() {
    this.loading = true;
    if(this.currentUser?.student){

    this.studentOpportunities = await this.studentService.getOpportunities(
      this.currentUser.student.urlName,
      null,
      null,
      null,
      null,
      null);
    }

    await this.employerService.getEmployers(false, false, false, false).then((ems: Employer[]) => {
      this.allEmployers = ems;
      this.loading = false;
    });
    this.employerList = new ArraySortPipe().transform(this.allEmployers, 'name', true);


    this.createEmployerFilterOptions();
    this.createEmployerFilterObjects();
    this.loading = false;
  }

  createEmployerFilterOptions() {
    const locations = [...new Set(this.allEmployers
      .flatMap(e => e.addresses))]
      .sort();

    const industries = [...new Set(this.allEmployers
      .flatMap(e => e.industries))]
      .sort();

    const interests = [...new Set(this.allEmployers
      .flatMap(e => e.employerFieldOfInterests.map(interest => interest.name)))] // Extract name from each interest
      .sort();

    // check box filter options
    this.employerFilterCheckBoxOptions = [
      { id: 1, label: 'Unext Recommended', active: false, name: 'unextSuggested' },
      { id: 2, label: 'Near You', active: false, name: 'nearYou' },
       ];

    if(this.studentOpportunities?.length){
      this.employerFilterCheckBoxOptions.push(   { id: 3, label: 'Offers Internship', active: false, name: 'offersIntereship' },
        { id: 4, label: 'Offers Employment', active: false, name: 'offersEmployment' },
        { id: 5, label: 'Offers Volunteering', active: false, name: 'offersVolunteering' },
        { id: 6, label: 'Offers Co Op', active: false, name: 'offersCoOp' },
        { id: 7, label: 'Offers Job Shadowing', active: false, name: 'offersJobShadow' })
    }

    // drop down filter options
    if (locations.length) {
      this.employerFilterDropDownOptions.push({ id: 1, label: 'Location', active: false, name: 'location', options: locations });
    }

    if (industries.length) {
      this.employerFilterDropDownOptions.push({ id: 2, label: 'Industry', active: false, name: 'industry', options: industries });
    }

    if (interests.length) {
      this.employerFilterDropDownOptions.push({ id: 2, label: 'Field Offered', active: false, name: 'interest', options: interests });
    }

    // sort options
    this.employerSortOptions = [
      { value: 'name', label: 'A -> Z', asc: true },
      { value: 'name', label: 'Z -> A', asc: false },
      { value: 'created', label: 'New Employers', asc: false },
      { value: 'bestMatch', label: 'Best Match', asc: false },
    ];

    // search options
    this.employerSearchOptions = [
      { id: 1, name: 'employerName' },
      { id: 2, name: 'employerInterests' }
    ];
  }

  createEmployerFilterObjects() {
    this.employers = [];
    this.allEmployers.forEach(e => {
      const opportunities = this.studentOpportunities.filter(s => s.opportunity?.employerId == e.id);
      // Find the opportunity with the highest score (or null if none exist)
      const highestScoreOpportunity = opportunities.reduce((highest, current) => {
        return (current.score && (!highest || current.score > highest.score)) ? current : highest;
      }, null);

      // Extract the score value or use 0 if there's no highest score
      const highestScore = highestScoreOpportunity ? highestScoreOpportunity.score : 0;

      // add bestMatch score to employer object in allEmployers list so we can sort by it later. sort options must match property name in order to work.
      Object.assign(e, { bestMatch: highestScore });

      const employer = {
        id: e.id,
        nearYou: e?.cities.find(c => this.student?.cities.some(ci => ci == c)) ? true : false,
        offersJobShadow: opportunities?.find(o => o?.opportunity?.opportunityTypeId == OpportunityType.JobShadowing) ? true : false,
        offersIntereship: opportunities?.find(o => o?.opportunity?.opportunityTypeId == OpportunityType.Internship) ? true : false,
        offersEmployment: opportunities?.find(o => o?.opportunity?.opportunityTypeId == OpportunityType.Employment) ? true : false,
        offersVolunteering: opportunities?.find(o => o?.opportunity?.opportunityTypeId == OpportunityType.Volunteering) ? true : false,
        offersCoOp: opportunities?.find(o => o?.opportunity?.opportunityTypeId == OpportunityType.CoOp) ? true : false,
        bestMatch: highestScore,
        created: e.created,
        name: e.name,
        industry: e.industries,
        unextSuggested: e.subscribed ? true : false,
        location: e.addresses,
        interest: e.employerFieldOfInterests.map(s => s.name)
      }
      this.employers.push(employer);

    });

  }

  updateEmployerList(data: { list: any[], sort: { value: string, asc: boolean }, search: string, reset: boolean }) {
    this.employerList = [];
    if (data.list.length) {

      this.employerList = new ArraySortPipe().transform(
        this.allEmployers.filter(a => data.list.some(i => i.id === a.id)),
        data.sort.value,
        data.sort.asc
      );

      if (data.search.length) {
        const searchTermLower = data.search.toLowerCase();

        this.employerList = this.employerList.filter(e => {
          // check if search term matches by name or industry
          const nameMatches = e.name && e.name.toLowerCase().includes(searchTermLower);
          const industryMatches = (e.industries || []).some(industry =>
            industry.toLowerCase().includes(searchTermLower)
          );
          return nameMatches || industryMatches;
        });
      }
    }
  }

}
