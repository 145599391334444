import { Component, Input } from '@angular/core';
import { FAQ } from 'src/app/shared/models/faq.model';

@Component({
  selector: 'app-faq-item',
  templateUrl: './faq-item.component.html',
  styleUrls: ['./faq-item.component.scss']
})
export class FaqItemComponent {
  @Input()
  faq: FAQ;
  @Input()
  isEmployer: boolean;

  isExpanded = false;
}
