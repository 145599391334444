import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { InfoModalData } from '../models/infoModalData.model';

@Injectable({
  providedIn: 'root'
})
export class InfoModalService {

  private http: HttpClient;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
   }

   public get(): Promise<InfoModalData[]> {
     return lastValueFrom(this.http.get<InfoModalData[]>('assets/json/infoModalData.json'));
   }
}
