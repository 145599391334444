<section>
    <div class="row">
        <div class="col p-4">
            <ng-content></ng-content>
        </div>
        <app-sticker *ngIf="stickerText !== null" class="{{stickerColor}} shadow tilt-right" stickerText="{{stickerText}}"></app-sticker>
        <div class="col">
            <div class="right-clip">
                <img src="{{image}}" alt="{{imageAlt}}" />
            </div>
        </div>
    </div>
</section>