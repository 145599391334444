<div class="list-view">
    <div class="bg-teal pl-medium pr-medium">
        <div class="bg-lime pl-medium pr-medium py-large">
            <app-filter-menu [vertical]="true" (outputListEvent)="updateOpportunityList($event)"
                [inputList]="opportunityList" [filterDropDownOptions]="opportunityFilterDropDownOptions"
                [searchOptions]="opportunitySearchOptions">
            </app-filter-menu>
        </div>
    </div>
    <div class="bg-cream pl-medium pr-medium py-large">
        <div class="flex-columns mb-medium">
            <div class="text text-title mb-small mr-small">
                My Opportunities
            </div>
        </div>

        <div *ngIf="filterActive">
            <div class="flex-columns justify-between grid-thirds">
                <div *ngFor="let o of modifiedOpportunitiesList | paginate:{id: 'opportunity-pager', itemsPerPage: 12, currentPage: opportunityPageNumber };">
                    <app-student-opportunity-card [studentOpportunity]="o"></app-student-opportunity-card>
                </div>
            </div>
            <div *ngIf="modifiedOpportunitiesList" class="mt-small flex-columns justify-around">
                <pagination-controls id="opportunity-pager"
                    (pageChange)="opportunityPageNumber = $event"></pagination-controls>
            </div>
        </div>
        <div *ngIf="!filterActive">

            <div *ngIf="opportunities.length">
                <div class="text large text-500 mb-small mr-small">
                    Pending Participation
                </div>
                <app-list-scroll>
                    <ng-template ngFor let-o [ngForOf]="opportunities">

                        <app-student-opportunity-card [studentOpportunity]="o"></app-student-opportunity-card>

                    </ng-template>
                </app-list-scroll>
            </div>

            <div *ngIf="approvedOpportunitiesList.length">
                <div class="text large text-500 mb-small mr-small">
                    Approved
                </div>
                <app-list-scroll>
                    <ng-template ngFor let-o [ngForOf]="approvedOpportunitiesList">

                        <app-student-opportunity-card [studentOpportunity]="o"></app-student-opportunity-card>

                    </ng-template>
                </app-list-scroll>
            </div>

            <div *ngIf="completedOpportunitiesList.length">
                <div class="text large text-500 mb-small mr-small">
                    Completed
                </div>
                <app-list-scroll>
                    <ng-template ngFor let-o [ngForOf]="completedOpportunitiesList">

                        <app-student-opportunity-card [studentOpportunity]="o"></app-student-opportunity-card>

                    </ng-template>
                </app-list-scroll>
            </div>
        </div>


    </div>

</div>


<!-- <div class="minimal-padding">
    <div *ngIf="!isFavorites" class="text heading-2 mb-small">
        {{ HeadingText }}
    </div>

    <form [formGroup]="formGroup" class="form filters mb-medium" *ngIf="showFilters" (ngSubmit)="search()">
        <input type="text" class="form-input border border-teal" (change)="updateFilter()"
            formControlName="filterText" placeholder="Job Title or Keywords" />
        <app-dropdown color="white" #ddIndustry label="Industry" [persist]="true" [fill]="true"
            [value]="selectedIndustry" (selected)="selectIndustry($event)" [actions]="industries"></app-dropdown>
        <app-dropdown color="white" #ddEmployment label="Employment" [persist]="true" [fill]="true"
            [value]="selectedEmployment" (selected)="selectEmployment($event)"
            [actions]="employmentTypes"></app-dropdown>
        <app-dropdown color="white" #ddLocation label="Locations" [persist]="true" [fill]="true"
            [value]="selectedLocation" (selected)="selectLocation($event)" [actions]="locations"></app-dropdown>
        <button type="submit" class="button dark-green button-slim">Search</button>
        <a *ngIf="searching" class="button button-link clear-search" (click)="clearSearch()">reset filters</a>
    </form>

    <ng-template [ngIf]="isFavorites">

        <div class="text heading-2 mt-small">
            Favorites
        </div>
        <app-list-scroll>
            <div class="opportunities mt-medium">
                <app-student-opportunities [user]="user" [opportunities]="opportunities" [selfLoading]="false">
                </app-student-opportunities>
            </div>
        </app-list-scroll>
    </ng-template>

    <app-progress-bar *ngIf="loading" [value]="loading"></app-progress-bar>

    <ng-template [ngIf]="!isFavorites && !searchResults">
        <div class="text heading-4 mb-small">
            Featured Employers
        </div>
        <app-partners [showTitle]="false" [showSignup]="false" [onlyFeatured]="true"></app-partners>

        <ng-template [ngIf]="recommended.length">
            <div class="text heading-4 mb-small">
                Recommended Opportunities
            </div>
            <app-list-scroll>
                <div class="opportunities mb-medium">
                    <app-opportunity-list [opportunities]="recommended" [student]="user?.student" selfLoading="false">
                    </app-opportunity-list>
                </div>
            </app-list-scroll>
        </ng-template>
        <ng-template [ngIf]="near.length">
            <div class="text heading-4 mb-small">
                Near Me
            </div>
            <app-list-scroll>
                <div class="opportunities mb-medium">
                    <app-opportunity-list [opportunities]="near" [student]="user?.student" selfLoading="false">
                    </app-opportunity-list>
                </div>
            </app-list-scroll>
        </ng-template>
        <ng-template [ngIf]="newest.length">
            <div class="text heading-4 mb-small">
                New Opportunities
            </div>
            <app-list-scroll>
                <div class="opportunities mb-medium">
                    <app-opportunity-list [opportunities]="newest" [student]="user?.student" selfLoading="false">
                    </app-opportunity-list>
                </div>
            </app-list-scroll>
        </ng-template>
        <ng-template [ngIf]="fulltime.length && student?.employment">
            <div class="text heading-4 mb-small">
                Fulltime Opportunities
            </div>
            <app-list-scroll>
                <div class="opportunities mb-medium">
                    <app-opportunity-list [opportunities]="fulltime" [student]="user?.student" selfLoading="false">
                    </app-opportunity-list>
                </div>
            </app-list-scroll>
        </ng-template>
        <ng-template [ngIf]="internships.length && student?.internship">
            <div class="text heading-4 mb-small">
                Internships
            </div>
            <app-list-scroll>
                <div class="opportunities mb-medium">
                    <app-opportunity-list [opportunities]="internships" [student]="user?.student" selfLoading="false">
                    </app-opportunity-list>
                </div>
            </app-list-scroll>
        </ng-template>
        <ng-template [ngIf]="volunteers.length && student?.volunteering">
            <div class="text heading-4 mb-small">
                Volunteering Opportunities
            </div>
            <app-list-scroll>
                <div class="opportunities mb-medium">
                    <app-opportunity-list [opportunities]="volunteers" [student]="user?.student" selfLoading="false">
                    </app-opportunity-list>
                </div>
            </app-list-scroll>
        </ng-template>
        <ng-template [ngIf]="shadows.length && student?.shadowing">
            <div class="text heading-4 mb-small">
                Shadowing
            </div>
            <app-list-scroll>
                <div class="opportunities mb-medium">
                    <app-opportunity-list [opportunities]="shadows" [student]="user?.student" selfLoading="false">
                    </app-opportunity-list>
                </div>
            </app-list-scroll>
        </ng-template>
        <ng-template [ngIf]="coOps.length && student?.coOp">
            <div class="text heading-4 mb-small">
                Co Ops
            </div>
            <app-list-scroll>
                <div class="opportunities mb-medium">
                    <app-opportunity-list [opportunities]="coOps" [student]="user?.student" selfLoading="false">
                    </app-opportunity-list>
                </div>
            </app-list-scroll>
        </ng-template>
        <ng-template [ngIf]="others.length && student?.other">
            <div class="text heading-4 mb-small">
                Other Opportunities
            </div>
            <app-list-scroll>
                <div class="opportunities mb-medium">
                    <app-opportunity-list [opportunities]="others" [student]="user?.student" selfLoading="false">
                    </app-opportunity-list>
                </div>
            </app-list-scroll>
        </ng-template>


        <ng-template ngFor let-ind [ngForOf]="industryFocus" let-i="index">
            <div class="text heading-4 mb-small">
                {{ industryFocusName[i] }} Opportunities
            </div>
            <app-list-scroll>
                <div class="opportunities mb-medium">
                    <app-opportunity-list [opportunities]="ind" [student]="user?.student" selfLoading="false">
                    </app-opportunity-list>
                </div>
            </app-list-scroll>
        </ng-template>

    </ng-template>

    <ng-template [ngIf]="!isFavorites && searchResults">
        <div class="text heading-2 mb-small">
            {{ searchResults?.length ? '' : 'No ' }}Search Results
        </div>
        <div class="opportunities mb-medium">
            <app-opportunity-list [opportunities]="searchResults" [student]="user?.student" selfLoading="false"
                [grid]="true">
            </app-opportunity-list>
        </div>
    </ng-template>

</div> -->