import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { FieldOfInterestApiConfig } from '../api';
import { skipAuthHeaders } from '../auth/auth-interceptor';

@Injectable()
export class FieldOfInterestService {

    constructor(private http: HttpClient) { }

    public get(): Promise<Array<{ name: string, id: number }>> {
        return lastValueFrom(this.http.get<Array<{ name: string, id: number }>>(FieldOfInterestApiConfig.getFullUrl('names'), { headers: skipAuthHeaders }));
    }
}
