import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LocationApiConfig } from '../api';
import { skipAuthHeaders } from '../auth/auth-interceptor';
import { City, State } from '../models';

@Injectable()
export class LocationService {
    constructor(private http: HttpClient) { }

    public getCities(): Promise<string[]> {
        return lastValueFrom(this.http.get<string[]>(LocationApiConfig.getFullUrl('city'), { headers: skipAuthHeaders }));
    }
    public getStates(): Promise<string[]> {
        return lastValueFrom(this.http.get<string[]>(LocationApiConfig.getFullUrl('state'), { headers: skipAuthHeaders }));
    }

    public getCityList(): Promise<City[]> {
        return lastValueFrom(this.http.get<City[]>(LocationApiConfig.getFullUrl('cities'), { headers: skipAuthHeaders }));
    }
    public getStateList(): Promise<State[]> {
        return lastValueFrom(this.http.get<State[]>(LocationApiConfig.getFullUrl('states'), { headers: skipAuthHeaders }));
    }

    public createCity(city: City): Promise<boolean> {
        return lastValueFrom(this.http.post<boolean>(LocationApiConfig.getFullUrl('city'),city));
    }
}
