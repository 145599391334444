import { Component, ViewChild } from '@angular/core';
import { BaseSectionComponent } from '../base-section/base-section.component';
import { CustomQuillComponent } from 'src/app/shared/components/custom-quill/custom-quill.component';
import { SmartWordService } from 'src/app/shared/services/smartwords.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ModalService, ResumeService, SkillService } from 'src/app/shared/services';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UNextOrganization } from 'src/app/shared/models';

@Component({
  selector: 'app-clubsandorganizations',
  templateUrl: './clubsandorganizations.component.html',
  styleUrl: './clubsandorganizations.component.scss'
})
export class ClubsandOrganizationsComponent extends BaseSectionComponent {
  @ViewChild(CustomQuillComponent) quillDescription!: CustomQuillComponent;

  constructor(    
    route: ActivatedRoute,
    fb: FormBuilder,
    modals: ModalService,
    resumes: ResumeService,
    snackbar: ToastService,
    skills: SkillService,
    router: Router,
    public smartwords: SmartWordService
  ) {
    super(route, fb, modals, resumes, skills, snackbar, router);


    this.forcePresentTense = undefined;
    this.validateStartDate = true;
    this.validateEndDate = true;
    this.validateDescription = true;
    this.maxDescriptionLength = 200;

    this.setupForm();
  }

  protected override setupForm(): void {
    super.setupForm();
    this.sectionForm.addControl('organizationName', new FormControl(undefined, Validators.required));
    this.sectionForm.addControl('schoolName', new FormControl(undefined, Validators.required));
    this.sectionForm.addControl('role', new FormControl(undefined));
    this.formSetup = true;
  }

  async save(): Promise<any> {
    if (!this.sectionForm.valid) {
      this.snackbar.warning({
        message: 'There is a problem with the information you are trying to submit, please your entry',
        title: 'Invalid information'
      });
    } else {
      if (this.sectionForm.get('description')) {
        this.sectionForm.get('description').setValue(this.quillDescription.textValue);
      }
    
      let returnData: any = {};
      if (this.sectionForm) {
        Object.keys(this.sectionForm.controls).forEach((key) => {
          returnData[key] = this.sectionForm.get(key).value;
        });
      } else {
        returnData = { ...this.section };
      }
      returnData.resumeSectionId = this.currentResumeSection?.resumeSectionId ?? 0;
      returnData = await this.saveSection(returnData);
      this.quillDescription.initialValue = returnData.description;

      this.snackbar.success({
        message: `${this.section?.name ?? 'section'} updated`
      });

    }
    this.sectionForm.updateValueAndValidity();
  }

  override async updateForm(): Promise<void> {
    super.updateForm();
    if (this.sectionForm && this.formSetup) {
      const x = this.currentResumeSection as UNextOrganization;
      this.sectionForm.get('organizationName').setValue(x?.organizationName);
      this.sectionForm.get('schoolName').setValue(x?.schoolName);
      this.sectionForm.get('role').setValue(x?.role);

      if (this.quillDescription) {
        this.quillDescription.initialValue = '';  //forces reset
        this.quillDescription.initialValue = this.sectionForm.get('description').value;
      }
    }
  }

}
