import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { UserService } from '../shared/services';
import { lastValueFrom } from 'rxjs';
import { ToastService } from '../shared/services/toast.service';

@Component({
  selector: 'app-handle-login',
  templateUrl: './handle-login.component.html',
  styleUrls: ['./handle-login.component.scss']
})
export class HandleLoginComponent implements AfterViewInit {

  message: string | undefined;

  constructor(
    private auth0: AuthService,
    private activatedRoute: ActivatedRoute,
    private users: UserService,
    private router: Router,
    private snackbar: ToastService
  ) {
  }

  async ngAfterViewInit(): Promise<void> {
    // this.users.clearProfile();
    // this.handleLoginRequest().then(async () => {
      // setTimeout(async() => {
        try {
        await lastValueFrom(this.auth0.handleRedirectCallback());
        await lastValueFrom(await this.auth0.getAccessTokenSilently());
        await this.users.getProfile();
        this.router.navigate(['/dashboard']);    
        } catch {
          // eat the error, fallbacks should take care of it
        }
      // }, 1000);
    // });
    // this.auth0.handleRedirectCallback()..then(async () => {
    //   await this.loginService.syncronizeToken();
    //   componentState.agentService.authenticate().then((agent) => {
    //     if (agent?.active == true) {
    //       this.agentService.trackLogin();
    //       this.appRouter?.navigate(['/dashboard']);
    //     } else {
    //       console.error('Agent is not active');
    //     }
    //   });
    // });
    // this.activatedRoute.queryParams.subscribe(async p => {
    //   if (p.code) { //} === 'success') {
    //     await this.handleLoginRequest();
    //     var t = await this.auth0.getAccessTokenSilently();
    //     this.auth0.isAuthenticated$.subscribe(async (b) => {
    //       await this.users.getProfile();
    //       this.router.navigate(['/dashboard']);
    //     });
    //   } else {
    //     this.message = decodeURIComponent(p.message);
    //     this.router.navigate(['/']);
    //   }
    // });
  }

}
