<section id="partners">
    <div *ngIf="showTitle" class="text text-center mb-medium">
        {{ title }}
    </div>
    <div class="partners">
        <app-progress-bar *ngIf="working" [value]="40"></app-progress-bar>
        <a *ngFor="let p of partners" class="tile" [routerLink]="[p.route]" (click)="handle('CTA', 'home', 'click', 'partner ' + p.name )" >
            <div class="image-container mb-small">
                <img [src]="p.url | cdn" [alt]="p.name" />
            </div>
            <div class="text text-normal text-center">
                {{ p.name }}
            </div>
            <div class="text text-small text-center">
                {{ p.city }}    
            </div>
        </a>
        <a *ngIf="showSignup" class="tile green no-flex" [routerLink]="['/dashboard']" (click)="handle('signup', 'home', 'click', 'sign up and get started today')" >
            <img class="image-right" [src]="cdn.toCDN('images/icons/go.png')" />
            <p class="text text-normal text-white">
                Sign up and get started today
            </p>
        </a>
    </div>
</section>