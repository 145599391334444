import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-entity-list',
  templateUrl: './entity-list.component.html',
  styleUrl: './entity-list.component.scss'
})
export class EntityListComponent<T> implements OnInit, OnDestroy {

  @Input()
  columns: EntityColumn[] = [];

  @Input()
  entities: T[] = [];

  @Input()
  actions: EntityAction[] = [];

  @Input()
  filters: EntityFilter[] = [];

  @Output()
  rowClick: EventEmitter<T> = new EventEmitter<T>();

  @Output()
  search: EventEmitter<EntitySearch> = new EventEmitter<EntitySearch>();

  currentFilter: EntitySearch = {
    direction: 'asc',
    filter: [],
    sort: ''
  };

  formFilter: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    fb: FormBuilder,
    private snackbar: ToastService
  ) {
    this.formFilter = fb.group({});
    this.subscriptions.push(this.formFilter.valueChanges.subscribe((value: any) => {

      this.filters.forEach(f => {
        const x = this.currentFilter.filter.find(x => x.name === f.value);
        if (!x) {
          this.currentFilter.filter.push({ name: f.value, value: value[f.value] });
        } else {
          x.value = value[f.value];
        }
      });
      this.search.next(this.currentFilter);
    }));
  }

  ngOnInit(): void {
    this.filters.forEach((f) => {
      this.formFilter.addControl(f.value, new FormControl());
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  selectRow(e) {
    if (e) {
      this.rowClick.next(e);
    }
  }
  actionClick(e: Event, action: EntityAction, value: T) {
    e.preventDefault();
    this.currentFilter.direction = 'asc';
    this.currentFilter.sort = '';

    if (action?.action) {
      action.action(value);
    }
  }



  sort(e: Event, column: EntityColumn) {
    if (column.sortable) {
      if (this.currentFilter.sort === column.value) {
        this.currentFilter.direction = this.currentFilter.direction === 'asc' ? 'desc' : 'asc';
      } else {
        this.currentFilter.direction = 'asc';
        this.currentFilter.sort = column.value;
      }
      this.search.next(this.currentFilter);
    }
  }

  getProperty(e: any, property: string): any {
    const path: string[] = property.split('.');
    return this.getSubProperty(e, path);
  }

  getSubProperty(e: any, path: string[]): any {
    if (!path.length) {
      return e;
    } else if (path.length === 1) {
      return e[path[0]];
    } else {
      const root = path.splice(0, 1);
      if (!root) {
        return undefined;
      } else {
        return this.getSubProperty(e[root[0]], path);
      }
    }
  }
}

export class EntityColumn {
  public label: string;
  public value: string;
  public displayType: 'text' | 'toggle';
  public width: number | undefined;
  public display: boolean;
  public sortable: boolean;
}

export class EntityAction {
  public label: string;
  public action: any;
}

export class EntityFilter {
  public label: string;
  public displayType: 'text' | 'toggle';
  public value: string;
}

export class EntitySearch {
  sort: string;
  direction: 'asc' | 'desc';
  filter: EntitySearchFilter[] = [];
}

export class EntitySearchFilter {
  public name: string;
  public value: any;
}