import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { Employer, EntityType, Opportunity, ResumeType, SectionWord, Student, StudentOpportunity, StudentOpportunityApplication, User } from 'src/app/shared/models';
import { CustomQuillComponent } from 'src/app/shared/components/custom-quill/custom-quill.component';
import { ModalService, StudentService } from 'src/app/shared/services';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-student-apply-documents',
  templateUrl: './student-apply-documents.component.html',
  styleUrls: ['./student-apply-documents.component.scss']
})
export class StudentApplyDocumentsComponent {

  @ViewChild(CustomQuillComponent) quillDescription!: CustomQuillComponent;

  @Input()
  opportunity: Opportunity;
  @Input()
  user: User;

  private _student: Student;
  @Input()
  get student(): Student {
    return this._student;
  }
  set student(value: Student) {
    this._student = value;
    this.loadResumes().then();
  }

  @Input()
  studentOpportunity: StudentOpportunity;
  @Input()
  employer: Employer;

  @Output()
  applied: EventEmitter<StudentOpportunityApplication> = new EventEmitter<StudentOpportunityApplication>();
  @Output()
  cancelled: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading = 0;
  words: SectionWord[] = [];

  resumes: DropDownOptions[] = [];

  resumeId: number | undefined;
  hasTranscript = false;
  includeTranscript = false;
  coverletter: string | undefined = undefined;

  entityType = EntityType;

  constructor(
    private students: StudentService,
    public modals: ModalService,
    private snackbar: ToastService
  ) { }

  async loadResumes(): Promise<void> {
    if (this.student) {
      try {
        this.loading = 75;
        if (this.student.resumes.find(r => r.resumeType === ResumeType.Transcript)) {
          this.hasTranscript = true;
        }
        this.resumes = [];
        this.student = await this.students.getStudentResume(this.user.student.urlName);
        this.user.unextResumes.map(value => {
          this.resumes.push({ value: value.id, label: `Unext Resume:  ${value.name}` });
        });
        if (this.student.resumes.find(r => r.resumeType === ResumeType.Resume)) {
          this.resumes.push({ value: 0, label: 'Resume attached to profile' });
        }
        this.loading = 90;
      } catch {
        this.snackbar.error({
          message: 'Error loading resumes'
        });
        } finally {
        this.loading = 0;
      }
    }
  }

  selectResume(e: DropDownOptions) {
    this.resumeId = e?.value;
  }
  coverletterUploaded(e: string[], modal: string): void {
    this.coverletter = e?.length ? e[0] : undefined;
    this.modals.close(modal);
  }

  async apply(): Promise<void> {
    try {
      this.loading = 25;

      const resume = this.user.unextResumes.find(r => r.id === this.resumeId);
      this.applied.emit({
        id: this.studentOpportunity.id,
        coverLetter: this.coverletter,
        unextResume: !!resume,
        resume: resume ? `${this.getOrigin()}/${this.student.urlName}/resume-preview/${encodeURIComponent(resume.id)}/base` : '',
        transcript: this.includeTranscript,
        resumeId: 0,
        opportunityInterests: []
      });
    } catch {      
      this.snackbar.error({
        message: 'Error applying'
      });
    } finally {
      this.loading = 0;
    }
  }

  getOrigin(): string {
    return window.location.origin.toLowerCase().indexOf('localhost') > 0 ? 'https://dev.unext.online' : window.location.origin;
  }

  cancel(): void {
    this.cancelled.emit(true);
  }
}
