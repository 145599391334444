import { environment } from '../../../environments/environment';
/**
 * Base API configuration file
 */
export class ReportingApiConfig {
    static path: string = environment.api.reporting.path;
    static version: string | number = environment.api.reporting.version;
    public static setApiVersion(version: string | undefined = 'api'): void {
        ReportingApiConfig.version = version;
    }
    public static getApiVersion(): string | number {
        return ReportingApiConfig.version;
    }
    public static getPath(): string {
        return ReportingApiConfig.path;
    }
    public static getFullUrl(path): string {
        if (ReportingApiConfig.getApiVersion()) {
            return [ReportingApiConfig.getPath(), ReportingApiConfig.getApiVersion(), path].join('/');
        } else {
            return [ReportingApiConfig.getPath(), path].join('/');
        }
    }
}

