<app-banner>
  <h1 heading>GAIN <span class="accent">career</span>
    EXPERIENCES 
    AND MAJOR
    <span class="accent">confidence</span>
  </h1>
  <p paragraph>
    Many students choose a major without fully understanding what it leads to. 
    Unext helps by connecting students with real-world experiences like job shadows and internships, 
    giving them the insight they need to explore careers, understand company cultures, and confidently pursue fulfilling paths in their chosen fields.
  </p>
  <img image alt="student at job interview" src="/assets/images/student-2.jpeg" />
</app-banner>

<section class="bg-blue text-center">
  <h2 class="sans-serif-bold">SO YOU’VE SELECTED YOUR MAJOR.<br/>WHAT’S NEXT?</h2>
  <p>Students often pick a major without knowing what it entails, they struggle to get connected to
    employers, they overlook the importance of culture and how it affects their fulfillment of jobs. Our
    mission is to help college students learn about careers that relate to their major. We connect them to
    first-hand experiences such as job shadows, internships, jobs, and other opportunities.</p>

      <h3>Sign up for an account.</h3>

      <div class="arrow down">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512">
              <path fill="currentColor" d="M390.624 150.625L256 16L121.376 150.625l22.628 22.627l95.997-95.998v417.982h32V77.257l95.995 95.995z" />
          </svg>
      </div>

  <button type="button" class="button light-blue" (click)="signup()">Create Account</button>
</section>

<section class="services bg-cream text-center">
  <h2 class="sans-serif-bold">Our Services</h2>
  <div class="row">
    <div class="col text-left">
      <svg id="internship-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
        <path d="M487.65,147.34H112.35c-24.81,0-44.99,20.18-44.99,44.99v215.34c0,24.81,20.18,44.99,44.99,44.99h375.31c24.81,0,44.99-20.18,44.99-44.99v-215.34c0-24.81-20.18-44.99-44.99-44.99ZM510.34,407.67c0,12.51-10.18,22.69-22.69,22.69H112.35c-12.51,0-22.69-10.18-22.69-22.69v-215.34c0-12.51,10.18-22.69,22.69-22.69h375.31c12.51,0,22.69,10.18,22.69,22.69v215.34Z"/>
        <rect x="148.07" y="243.47" width="141.51" height="22.3"/>
        <rect x="148.07" y="298.84" width="83.06" height="22.3"/>
        <path d="M440.74,313.16c-27.35-12.19-55.09-12.26-82.45-.23-22.8,10.03-34.28,28.58-33.2,53.64.57,13.33,8.16,20.56,21.33,20.28,12.27-.24,24.82-.19,36.95-.15,5.01.02,10.01.04,15.02.04,5.19.02,10.39-.03,15.58-.06,12.25-.07,24.93-.14,37.29.2.35,0,.79.02,1.32.02,3.33,0,9.84-.49,14.84-5.28,5.25-5.03,5.97-11.98,6.05-16.77.41-24.1-10.91-41.97-32.72-51.69ZM451.16,364.57c-7.28-.19-14.61-.25-21.87-.25-5.19,0-10.36.03-15.45.06-5.15.03-10.3.04-15.46.06h0c-4.98,0-9.96-.02-14.93-.04-11.82-.04-24.03-.09-36.12.13-.34-15.13,6.01-25.05,19.94-31.18,21.36-9.4,43.02-9.33,64.39.19,13.56,6.04,19.75,15.87,19.5,30.94,0,.03,0,.06,0,.09Z"/>
        <path d="M398.21,298.71c.45.01.89.02,1.34.02,11.7,0,23.39-4.84,32.25-13.39,9.53-9.2,15.03-21.84,15.08-34.69.05-12.92-4.7-24.92-13.38-33.77-8.7-8.87-20.59-13.83-33.47-13.95-13.19-.16-25.65,4.89-34.96,14.11-9,8.92-13.96,20.71-13.96,33.22,0,25.46,21.57,47.65,47.1,48.46ZM380.77,232.88c4.99-4.94,11.65-7.65,18.79-7.65.09,0,.18,0,.26,0,14.41.14,24.82,10.79,24.76,25.33-.03,6.86-3.04,13.69-8.26,18.73-4.9,4.73-11.25,7.33-17.4,7.13-13.35-.42-25.51-12.89-25.51-26.16,0-6.51,2.61-12.68,7.35-17.38Z"/>
      </svg>
      <h3>Resume Builder</h3>
      <p>We use AI to give feedback like. We
        enable students 3x faster resume
        completion, we enable students to get
        ahead and create strong documents, we
        leverage data, machine learning to do so,
        we use ATS friendly templates to make
        sure the students resume fits
        requirements from employers.</p>
    </div>
    <div class="col text-left">
      <svg id="job-shadow-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
        <g>
            <path class="cls-1" d="M458.1,118.49c-100.61-.08-203.77-.08-315.39,0-27.17.02-49.32,22.13-49.38,49.3-.13,61.15-.13,121.51,0,179.4.06,25.45,19.33,46.95,44.15,49.65v49.35c0,3.91,0,7.82-.02,11.73-.02,10.09,6.1,18.81,15.59,22.24,2.68.97,5.43,1.44,8.13,1.44,6.87,0,13.47-3.03,18.08-8.61l59.62-72.21.52-.69c1.83-2.6,2.59-3.13,6.45-3.14,72.01.27,145.48.2,210.3.13,30.61-.03,51.19-20.44,51.22-50.78.06-60.5.1-119.69-.03-178.65-.06-27.08-22.15-49.14-49.24-49.16ZM485.07,346.27c-.02,18.1-10.56,28.48-28.94,28.5-64.8.06-138.24.13-210.2-.13h-.16c-10.73,0-18.26,3.78-24.34,12.23l-59.36,71.9h0c-.22.26-.58.71-1.44.39-.85-.31-.85-.85-.85-1.21,0-3.92.02-7.85.02-11.77v-70.48l-10.6-.52c-1.14-.06-2.17-.1-3.13-.15-2.05-.09-3.66-.17-5.28-.29-14.07-1.05-25.11-13.18-25.14-27.61-.13-57.85-.13-118.18,0-179.3.03-14.9,12.19-27.03,27.09-27.04,56.37-.04,110.57-.06,163.39-.06s102.18.02,151.97.06c14.83.01,26.93,12.08,26.96,26.9.12,58.93.08,118.1.03,178.58Z"/>
            <path class="cls-1" d="M418.88,219.29c-20.6.32-37.48,17.44-37.62,38.16-.08,11.17,4.7,21.76,13.1,29.06,7.01,6.09,15.99,9.36,25.29,9.36,1.94,0,3.9-.14,5.86-.43,15.05-2.24,27.59-13.69,31.21-28.49,2.85-11.67.19-23.74-7.28-33.1-7.53-9.43-18.66-14.73-30.55-14.55ZM435.05,261.67c-1.44,5.9-6.83,10.83-12.82,11.72-4.94.73-9.64-.58-13.25-3.71-3.48-3.02-5.45-7.42-5.42-12.07.06-8.7,7.09-15.88,15.66-16.01.08,0,.16,0,.24,0,4.84,0,9.4,2.24,12.53,6.16,1.84,2.31,4.69,7.18,3.05,13.91Z"/>
            <path class="cls-1" d="M181.53,219.28c-10.02-.07-19.96,4.06-27.24,11.31-7.37,7.34-11.42,16.97-11.39,27.12.06,20.67,16.92,37.79,37.58,38.15.24,0,.48,0,.72,0,10.16,0,19.67-3.82,26.84-10.81,7.27-7.08,11.33-16.64,11.41-26.91.09-10.37-3.86-20.14-11.11-27.5-7.15-7.26-16.68-11.3-26.81-11.37ZM192.48,269.08c-3.04,2.96-7.15,4.56-11.6,4.48-8.62-.15-15.65-7.29-15.67-15.92-.01-4.15,1.7-8.14,4.82-11.25,3.07-3.06,7.17-4.81,11.26-4.81.03,0,.06,0,.09,0,4.17.03,8.11,1.7,11.07,4.71,3.07,3.11,4.73,7.25,4.7,11.66-.04,4.27-1.7,8.22-4.67,11.12Z"/>
            <path class="cls-1" d="M300.06,219.29c-10.17.1-19.77,4.25-27.04,11.71-7.25,7.44-11.16,17.13-10.99,27.27.33,20.65,17.44,37.52,38.14,37.6.05,0,.1,0,.16,0,10.31,0,19.95-3.96,27.17-11.16,7.21-7.19,11.17-16.81,11.15-27.09-.02-10.28-4.09-19.97-11.47-27.26-7.32-7.24-16.96-11.17-27.11-11.08ZM311.74,268.93c-3,2.99-7.05,4.64-11.41,4.64h-.07c-8.65-.03-15.79-7.06-15.93-15.66-.07-4.16,1.59-8.19,4.67-11.35,3.09-3.17,7.09-4.93,11.28-4.97.05,0,.1,0,.15,0,4.12,0,8.04,1.64,11.07,4.64,3.12,3.08,4.84,7.15,4.85,11.44,0,4.29-1.63,8.29-4.6,11.26Z"/>
        </g>
        <path d="M458.1,118.49c-100.61-.08-203.77-.08-315.39,0-27.17.02-49.32,22.13-49.38,49.3-.13,61.15-.13,121.51,0,179.4.06,25.45,19.33,46.95,44.15,49.65v49.35c0,3.91,0,7.82-.02,11.73-.02,10.09,6.1,18.81,15.59,22.24,2.68.97,5.43,1.44,8.13,1.44,6.87,0,13.47-3.03,18.08-8.61l59.62-72.21.52-.69c1.83-2.6,2.59-3.13,6.45-3.14,72.01.27,145.48.2,210.3.13,30.61-.03,51.19-20.44,51.22-50.78.06-60.5.1-119.69-.03-178.65-.06-27.08-22.15-49.14-49.24-49.16ZM485.07,346.27c-.02,18.1-10.56,28.48-28.94,28.5-64.8.06-138.24.13-210.2-.13h-.16c-10.73,0-18.26,3.78-24.34,12.23l-59.36,71.9h0c-.22.26-.58.71-1.44.39-.85-.31-.85-.85-.85-1.21,0-3.92.02-7.85.02-11.77v-70.48l-10.6-.52c-1.14-.06-2.17-.1-3.13-.15-2.05-.09-3.66-.17-5.28-.29-14.07-1.05-25.11-13.18-25.14-27.61-.13-57.85-.13-118.18,0-179.3.03-14.9,12.19-27.03,27.09-27.04,56.37-.04,110.57-.06,163.39-.06s102.18.02,151.97.06c14.83.01,26.93,12.08,26.96,26.9.12,58.93.08,118.1.03,178.58Z"/>
        <path d="M418.88,219.29c-20.6.32-37.48,17.44-37.62,38.16-.08,11.17,4.7,21.76,13.1,29.06,7.01,6.09,15.99,9.36,25.29,9.36,1.94,0,3.9-.14,5.86-.43,15.05-2.24,27.59-13.69,31.21-28.49,2.85-11.67.19-23.74-7.28-33.1-7.53-9.43-18.66-14.73-30.55-14.55ZM435.05,261.67c-1.44,5.9-6.83,10.83-12.82,11.72-4.94.73-9.64-.58-13.25-3.71-3.48-3.02-5.45-7.42-5.42-12.07.06-8.7,7.09-15.88,15.66-16.01.08,0,.16,0,.24,0,4.84,0,9.4,2.24,12.53,6.16,1.84,2.31,4.69,7.18,3.05,13.91Z"/>
        <path d="M181.53,219.28c-10.02-.07-19.96,4.06-27.24,11.31-7.37,7.34-11.42,16.97-11.39,27.12.06,20.67,16.92,37.79,37.58,38.15.24,0,.48,0,.72,0,10.16,0,19.67-3.82,26.84-10.81,7.27-7.08,11.33-16.64,11.41-26.91.09-10.37-3.86-20.14-11.11-27.5-7.15-7.26-16.68-11.3-26.81-11.37ZM192.48,269.08c-3.04,2.96-7.15,4.56-11.6,4.48-8.62-.15-15.65-7.29-15.67-15.92-.01-4.15,1.7-8.14,4.82-11.25,3.07-3.06,7.17-4.81,11.26-4.81.03,0,.06,0,.09,0,4.17.03,8.11,1.7,11.07,4.71,3.07,3.11,4.73,7.25,4.7,11.66-.04,4.27-1.7,8.22-4.67,11.12Z"/>
        <path d="M300.06,219.29c-10.17.1-19.77,4.25-27.04,11.71-7.25,7.44-11.16,17.13-10.99,27.27.33,20.65,17.44,37.52,38.14,37.6.05,0,.1,0,.16,0,10.31,0,19.95-3.96,27.17-11.16,7.21-7.19,11.17-16.81,11.15-27.09-.02-10.28-4.09-19.97-11.47-27.26-7.32-7.24-16.96-11.17-27.11-11.08ZM311.74,268.93c-3,2.99-7.05,4.64-11.41,4.64h-.07c-8.65-.03-15.79-7.06-15.93-15.66-.07-4.16,1.59-8.19,4.67-11.35,3.09-3.17,7.09-4.93,11.28-4.97.05,0,.1,0,.15,0,4.12,0,8.04,1.64,11.07,4.64,3.12,3.08,4.84,7.15,4.85,11.44,0,4.29-1.63,8.29-4.6,11.26Z"/>
      </svg>
      <h3>Job Shadowing</h3>
      <p>We facilitate finding job shadowing, give
        stats on why job shadowing is beneficial.
        Helps you expand your network, have
        more connections, have more to put on
        your resume, increases qualification,
        understand different avenues your major
        could take you.</p>
    </div>
    <div class="col text-left">
      <svg id="employment-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
        <path class="cls-1" d="M461.89,161.18h-39.61v-13.46c0-24.81-20.18-44.99-44.99-44.99h-150.74c-24.81,0-44.99,20.18-44.99,44.99v13.46h-39.61c-28.2,0-51.14,22.94-51.14,51.14v233.8c0,28.2,22.94,51.14,51.14,51.14h319.94c28.2,0,51.14-22.94,51.14-51.14v-233.8c0-28.2-22.94-51.14-51.14-51.14ZM203.87,147.72c0-12.51,10.18-22.69,22.69-22.69h150.74c12.51,0,22.69,10.18,22.69,22.69v13.46h-196.12v-13.46ZM141.95,183.48h319.94c15.9,0,28.84,12.94,28.84,28.84v118.05h-91.01v-36.57h-22.3v36.57h-146.89v-36.57h-22.3v36.57h-95.11v-118.05c0-15.9,12.94-28.84,28.84-28.84ZM461.89,474.96H141.95c-15.9,0-28.84-12.94-28.84-28.84v-93.44h95.11v34.99h22.3v-34.99h146.89v34.99h22.3v-34.99h91.01v93.44c0,15.9-12.94,28.84-28.84,28.84Z"/>
      </svg>
      <h3>Finding Opportunities</h3>
      <p>If employers offer other internship or
        employment opportunities, students can
        see what they are, and make sure they are
        a good match.</p>
    </div>
  </div>
</section>

<app-left-image stickerColor="green" stickerText="Career Launchpad" image="/assets/images/student-1.jpeg">
  <h2>The next step to creating
    career readiness.</h2>
    <p>Unext is a tool to help students make the best
      out of their careers. Students that use Unext
      are motivated, they are the ones that take
      their education and future seriously. We are a
      facilitator for the ones that are hungry for
      success.</p>
        <button type="button" class="text-button icon-right">
            <p class="learn-more large serif">Create Account</p>
            <div class="arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M390.624 150.625L256 16L121.376 150.625l22.628 22.627l95.997-95.998v417.982h32V77.257l95.995 95.995z" />
                </svg>
            </div>
        </button>
</app-left-image>

<app-right-image stickerColor="pink" stickerText="Better Results" image="/assets/images/lady.webp">
  <h2>We help find the 
    perfect match!</h2>
    <p>Make more informed recruiting decisions,
        understand where students are looking for
        work, where their strengths are, weakness,
        good match, where and how to recruit,
        transferable skills, data will save you
        money on recruiting, reach more students,
        use the data provided on Unext to target
        more students on other platforms.</p>
        <button type="button" class="text-button icon-right">
            <p class="learn-more large serif">Learn More</p>
            <div class="arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M390.624 150.625L256 16L121.376 150.625l22.628 22.627l95.997-95.998v417.982h32V77.257l95.995 95.995z" />
                </svg>
            </div>
        </button>
</app-right-image>

<app-contact-section class="bg-orange">
  <h2 class="sans-serif-bold" title>HAVE ANY QUESTIONS? CONTACT US.</h2>
  <p paragraph>Unext is dedicated to providing a platform for career exploration and preparation by providing students with comprehensive insights, practical experiences.</p>
  <h3 subheading>Sign up for an account</h3>
  <button class="orange outline" buttons>FAQs</button>
  <button class="orange outline" buttons>Contact Us</button>
</app-contact-section>