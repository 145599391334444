<div class="admin form">

    <form [formGroup]="formFilter" class="mb-medium flex-columns justify-around">
        <ng-template ngFor let-filter let-first="first" [ngForOf]="filters">
            <div class="flex-rows mr-large">
                <label class="text">
                    {{ filter.label }}
                </label>
                <input *ngIf="filter.displayType === 'text'" type="text" [formControlName]="filter.value" class="form form-input form-input-rounded mr-small mt-auto mb-auto" />    
                <input *ngIf="filter.displayType === 'toggle'" type="checkbox" [formControlName]="filter.value" class="mt-auto mb-auto" />
            </div>
        </ng-template>

    </form>

    <table class="table grid mt-medium">
        <thead>
            <tr>
                <th>
                </th>
                <ng-template ngFor let-c [ngForOf]="columns">
                    <th class="text text-500" [ngClass]="{ 'sortable': c.sortable, 'current': c.value === currentFilter.sort, 'asc': c.value === currentFilter.sort && currentFilter.direction === 'asc', 'desc': c.value === currentFilter.sort && currentFilter.direction === 'desc' }"
                        [width]="c.width ? c.width.toString() + 'px' : 'auto'"
                        (click)="sort($event, c)">{{ c.label }}</th>
                </ng-template>
                <th class="text text-500">
                    Actions
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-e [ngForOf]="entities">
                <tr>
                    <td (click)="selectRow(e)" style="cursor: pointer; width: 40px; padding-left: 12px;">                    
                        <img src="/assets/images/icons/edit.svg"/>
                    </td>
                    <ng-template ngFor let-column [ngForOf]="columns">
                        <td *ngIf="column.display">
                            <input class="form-input form-input-rounded form-input-100" *ngIf="column.displayType === 'text'"
                                readonly [value]="getProperty(e, column.value)" />
                            <app-toggle-bar *ngIf="column.displayType === 'toggle'" [isOn]="getProperty(e, column.value)" size="small"
                                [disabled]="true"></app-toggle-bar>
                        </td>
                    </ng-template>

                    <td>
                        <div class="flex-columns justify-between">
                            <ng-template ngFor let-action [ngForOf]="actions" let-i="index">
                                <button type="button" class="button button-slim mr-small"
                                    [ngClass]="{ 'btn-green': i % 3 === 0, 'btn-blue': i % 3 === 1, 'btn-orange': i % 3 === 2 }"
                                     (click)="actionClick($event, action, e)" >{{ action.label }}</button>                                
                            </ng-template>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </tbody>
        <tfoot>
            <tr>
                <td></td>
                <ng-template ngFor let-c [ngForOf]="columns">
                    <td>
                        &nbsp;
                    </td>
                </ng-template>
                <td>
                    &nbsp;
                </td>
            </tr>
        </tfoot>
    </table>
</div>