import { Component, Input } from '@angular/core';
import { StudentOpportunity, OpportunityType, Opportunity } from 'src/app/shared/models';
import { CDNService } from 'src/app/shared/services/cdn.service';

@Component({
  selector: 'app-student-opportunity-card',
  templateUrl: './student-opportunity-card.component.html',
  styleUrl: './student-opportunity-card.component.scss'
})
export class StudentOpportunityCardComponent {

  constructor(
    public cdn: CDNService,
  ){

  }

@Input()
studentOpportunity: StudentOpportunity;

@Input()
opportunity: Opportunity;



  chipColor(o: Opportunity): string {
    let color: string;

    switch (o.opportunityTypeId) {
      case OpportunityType.Internship:
        color = 'orange';
        break;
      case OpportunityType.Employment:
        color = 'purple';
        break;
      case OpportunityType.Volunteering:
        color = 'green';
        break;
      case OpportunityType.Other:
        color = 'gray';
        break;
      case OpportunityType.CoOp:
        color = 'green-muted';
        break;
      case OpportunityType.JobShadowing:
      default:
        color = 'blue';
        break;  
    }

    return color;
  }
}
