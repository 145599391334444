<div class="menu" (click)="stateChange()">
    <div class="menu-bar bar-top" [ngClass]="{ 'active': open }">

    </div>
    <div class="menu-bar bar-middle" [ngClass]="{ 'active': open }">
        
    </div>
    <div class="menu-bar bar-bottom" [ngClass]="{ 'active': open }">
        
    </div>
</div>