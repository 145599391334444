import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SectionType, Student, UNextResume } from 'src/app/shared/models';

@Component({
  selector: 'app-section-mapper',
  templateUrl: './section-mapper.component.html',
  styleUrl: './section-mapper.component.scss'
})
export class SectionMapperComponent implements OnInit, OnDestroy {

  student: Student;
  selectedResume: UNextResume;

  sectionName: string;

  private _selectedSection: any;
  get selectedSection(): any {
    return this._selectedSection;
  }
  set selectedSection(value: any) {
    this._selectedSection = value;
    this.sectionName = this._selectedSection?.name;
    this.map();
  }
  subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router) {

  }

  ngOnInit(): void {

    this.subscriptions.push(this.activatedRoute.params.subscribe((params) => {
      this.sectionName = params.type
    }));

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  map(): void {
    if (this.selectedSection) {
      const section = this.selectedResume.sections.find(s => s.name === this.sectionName);

      const path = [];

      if (section) {
        switch (section.sectionType) {
          case SectionType.Award:
            path.push(section.name);
            break;
          case SectionType.Education:
            path.push(section.name);
            break;
          case SectionType.Experience:
            path.push(section.name);
            break;
          case SectionType.General:
            path.push(section.name);
            break;
          case SectionType.Language:
            path.push(section.name);
            break;
          case SectionType.Organization:
            path.push(section.name);
            break;
          case SectionType.Skills:
            path.push(section.name);
            break;
          default:
            break;
        }
      }
      this.router.navigate(path);
    }
  }
}
