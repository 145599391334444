import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResumeService } from 'src/app/shared/services';
import { UNextResume } from 'src/app/shared/models';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-student-resume-preview',
  templateUrl: './student-resume-preview.component.html',
  styleUrls: ['./student-resume-preview.component.scss']
})
export class StudentResumePreviewComponent implements OnInit {
  @Input()
  selectedResume: UNextResume;

  selectedTemplate: string;
  urlName: string | undefined;
  @Input()
  chrome = true;
  working = 0;
  @Input()
  padding = true;
  @Input()
  showProgress = true;

  constructor(
    private resumes: ResumeService,
    private route: ActivatedRoute,
    private router: Router,
    // private modals: ModalService,
    private snackbar: ToastService
  ){
    this.selectedTemplate = this.route.snapshot.params.template;
    this.urlName = this.route.snapshot.params.studentUrl;
    if (this.chrome) {
      this.chrome = this.route.snapshot.params.nochrome !== 'true';
    }
    
    route.queryParams.subscribe(params => {
      if (params.nochrome === 'true') {
        this.chrome = false;
      }
    });

  }

  async ngOnInit() {
      await this.loadResume();  
  }

  private async loadResume() {
    try{
      if (this.urlName?.length) {
        this.working = 50;
        if (parseInt(this.route.snapshot.params.resumeName, 10)) {
          this.selectedResume = await this.resumes.getResumeById(parseInt(this.route.snapshot.params.resumeName, 10));
        } else {
          this.selectedResume = await this.resumes.getResumeByName(this.route.snapshot.params.resumeName);
        }
      }
    }
    catch(e){
      console.error(e);
      this.snackbar.error({
        message: 'There was an error loading your resume'
      });
    } finally {
      this.working = 0;
    }
    
  }

  backToEdit(){
    this.router.navigate(['/students', this.urlName, 'resume-builder', this.selectedResume.name]);
  }

  // async downloadResume(modalName: string): Promise<void> {
  //   try {
  //     this.working = 25;
  //     const bits = await this.resumes.downloadResume(this.selectedResume.id);
  //     this.working = 50;
  //     const download = new Blob([bits], { type: 'application/pdf'});
  //     const url = window.URL.createObjectURL(download);
  //     if (!window.open(url, '_blank')) {
  //       this.modals.open(modalName);
  //       window.open(url, '_self');
  //     }
  //     setTimeout(() => URL.revokeObjectURL(url), 300000);
  //   } catch (error) {
  //     this.snackbar.error({
  //       'title': 'Error downloading resume',
  //       message: 'There was a problem downloading your resume'
  //     });
  //   } finally {
  //     this.working = 0;
  //   }
  // }
  
}
