import { Component } from '@angular/core';
import { User } from '@auth0/auth0-angular';
import { UserService } from '../shared/services';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
  user: User;

  constructor(
    public users: UserService
  ) { 
    this.user = this.users.authenticatedUser;
  }
}
