import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Employer, User } from 'src/app/shared/models';
import { EmployerService, UserService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';

@Component({
  selector: 'app-employer-employee-list',
  templateUrl: 'employer-employee-list.component.html',
  styleUrls: ['employer-employee-list.component.scss']
})
export class EmployerEmployeeListComponent implements OnInit {
  employer: Employer;
  employees: User[] = [];
  loadProgress = 0;
  urlName: string;
  user: User;

  constructor(
    private employerService: EmployerService,
    users: UserService,
    private route: ActivatedRoute,
    public cdn: CDNService,

  ) { 
    this.user = users.authenticatedUser;
    this.route.params.subscribe(params => {
      // Retrieve the ID from the route parameters
      this.urlName = params['urlName'];

    });

  }

  async ngOnInit() {
    try {
      if (this.urlName != null) {
        this.loadProgress = 40;
        this.employer = await this.employerService.getEmployer(this.urlName);
        this.loadProgress = 60;
        this.employees = await this.employerService.getEmployees(this.urlName);
      }  
    } catch (err) {
      console.log(err);
    } finally {
      this.loadProgress = 0      
    }
  }
}