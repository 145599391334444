import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class FieldOfInterestApiConfig {
    static path: string = environment.api.fieldOfInterest.path;
    static version: string | number = environment.api.fieldOfInterest.version;

    public static setApiVersion(version: string | undefined = 'api'): void {
        FieldOfInterestApiConfig.version = version;
    }

    public static getApiVersion(): string | number {
        return FieldOfInterestApiConfig.version;
    }

    public static getPath(): string {
        return FieldOfInterestApiConfig.path;
    }

    public static getFullUrl(path): string {
        if (FieldOfInterestApiConfig.getApiVersion()) {
            return [FieldOfInterestApiConfig.getPath(), FieldOfInterestApiConfig.getApiVersion(), path].join('/');
        } else {
            return [FieldOfInterestApiConfig.getPath(), path].join('/');
        }
    }
}
