import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class JsonDateInterceptor implements HttpInterceptor {

    private isIsoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // if (req.body) {
        //     req = req.clone( {
        //         body: this.toUTC(req.body)
        //     });
        // }
        return next.handle(req).pipe(map( (val: HttpEvent<any>) => {
          if (val instanceof HttpResponse){
            const body = val.body;
            this.fromUTC(body);
          }
          return val;
        }));
    }

    isIsoDateString(value: any): boolean {
        if (value === null || value === undefined) {
          return false;
        }
        if (typeof value === 'string'){
          return this.isIsoDateFormat.test(value);
        }
        return false;
      }

    // toUTC(body: any){
    //     if (body === null || body === undefined ) {
    //         return body;
    //     }
    //     if (typeof body !== 'object' ){
    //         return body;
    //     }
    //     for (const key of Object.keys(body)) {
    //         let value = body[key];
    //         if (this.isIsoDateString(value)) {
    //             value = moment(new Date(value))
    //                     .add(-(moment().utcOffset()), 'm')
    //                     .toDate();
    //         }
    //         if (value instanceof Date) {
    //             body[key] = value.toISOString();
    //         } else if (typeof value === 'object') {
    //             this.toUTC(value);
    //         }
    //     }
    // }

    fromUTC(body: any){
        if (body === null || body === undefined ) {
            return body;
        }
        if (typeof body !== 'object' ){
            return body;
        }
        for (const key of Object.keys(body)) {
            const value = body[key];
            if (this.isIsoDateString(value)) {
                body[key] = moment(new Date(value))
//                                .add((moment().utcOffset()), 'm')
                                .format('yyyy-MM-DD');
            } else if (typeof value === 'object') {
                this.fromUTC(value);
            }
        }
    }
}

