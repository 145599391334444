<app-progress-bar [value]="working" [splashScreen]="true">
    <div class="standard-padding">
        <form class="form {{ activeOpportunityType }}" [formGroup]="formGroup" (submit)="onSave($event)">
            <div *ngIf="urlName === 'new'" class="text heading-2 mb-small create-label">
                Create Opportunity
            </div>
            <div *ngIf="urlName !== 'new'" class="text heading-2 mb-small ml-auto mr-auto">
                Edit Opportunity
            </div>
            <a *ngIf="urlName !== 'new'" class="text heading-4 ml-auto mr-auto mt-auto mb-medium"
                [routerLink]="['/employers', employerUrlName, 'opportunity', urlName ]">
                View Opportunity
            </a>
            <app-progress-bar class="col-2" *ngIf="working" [value]="working"></app-progress-bar>
            <app-stepper #stepper [steps]="steps" [(activeStep)]="activeStep"></app-stepper>

            <div class="rounded-corners mb-large stepper-content" *ngIf="!steps[activeStep].split">
                <ng-container *ngIf="steps[activeStep].id === 'opportunity'">
                    <div class="mb-small general-information">
                        <div class="text heading-4 mb-small">
                            What opportunity are you posting? <app-info-modal [audience]="'Employers'"
                                [section]="'Opportunities'" [item]="'Type'"></app-info-modal>
                        </div>
                        <div id="opportunity-grid">
                            <div *ngFor="let opportunity of opportunityTypes">
                                <div class="rounded-corners mb-small text"
                                    [ngClass]="{active: opportunityType.value === opportunity.value}"
                                    (click)="selectOpportunityType(opportunity)">
                                    <div>
                                        {{opportunity.label}}
                                    </div>
                                    <app-info-modal class="mt-small" *ngIf="opportunity.label === 'Job Shadowing'"
                                        [audience]="'Employers'" [section]="'Opportunities'" [item]="'Job Shadowing'">
                                    </app-info-modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="steps[activeStep].id === 'audience'">
                    <div class="load-audience">
                        <div class="text heading-4 mb-small">
                            Load Audience
                        </div>
                        <app-dropdown color="white" label="Choose an Audience" [persist]="true" [fill]="true"
                            (selected)="selectAudience($event)" [actions]="selectAudiences | sort:'name':true">
                        </app-dropdown>
                    </div>

                    <div class="audience">
                        <div class="text heading-4 mb-small">
                            Audiences <app-info-modal [audience]="'Employers'" [section]="'Opportunities'"
                                [item]="'Audience'"></app-info-modal>
                        </div>
                        <div class="mb-small">
                            <label for="AudienceDescription" class="form-label text text-black">
                               Description
                            </label>
                            <div class="form-input-quill readonly">
                                <quill-editor id="AudienceDescription" formControlName="AudienceDescription"
                                    placeholder="Enter description here" [modules]="quillConfiguration"
                                    [sanitize]="true"></quill-editor>
                            </div>
                        </div>
                        <div class=" col-3">
                            <label for="CompanyHistory" class="form-label text text-black">
                                Details
                            </label>
                            <div class="form-input-quill readonly">
                                <quill-editor id="Details" formControlName="Details"
                                    placeholder="Enter additional details here. EX: Any documentation the student needs?" [modules]="quillConfiguration"
                                    [sanitize]="true"></quill-editor>
                            </div>
                        </div>
                        <div class="mb-small">
                            <label for="TargetSchools" class="form-label">
                                Targeted Schools
                            </label>
                            <div>
                                <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                    [availableValues]="availableSchools" attribute="school" [showOnFocus]="true"
                                    placeholder="Search for Schools" buttonColor="green" (added)="selectSchool($event)">
                                </app-opportunity-add-attribute>
                            </div>
                            <div class="flex-columns flex-wrap" *ngIf="schools.value?.length">
                                <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                    *ngFor="let s of schools.value" (click)="removeSelection(s, schools, 'school')">
                                    {{ s }}
                                </span>
                            </div>
                        </div>
                        <div class="mb-small">
                            <label for="TargetEducation" class="form-label">
                                Targeted Education Level
                            </label>
                            <div>
                                <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                    [availableValues]="educationLevels" attribute="degree" [showOnFocus]="true"
                                    placeholder="Search for Education Level" buttonColor="green"
                                    (added)="selectDegree($event)">
                                </app-opportunity-add-attribute>
                            </div>
                            <div class="flex-columns flex-wrap" *ngIf="degrees.value?.length">
                                <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                    *ngFor="let d of degrees.value" (click)="removeSelection(d, degrees, 'degree')">
                                    {{ d }}
                                </span>
                            </div>
                        </div>
                        <div class="mb-small">
                            <label for="TargetMajors" class="form-label">
                                Targeted Majors
                            </label>
                            <div>
                                <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                    [availableValues]="availableMajors" attribute="major"
                                    placeholder="Search for majors" buttonColor="green" (added)="selectMajor($event)">
                                </app-opportunity-add-attribute>
                            </div>
                            <div class="flex-columns flex-wrap" *ngIf="majors.value?.length">
                                <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                    *ngFor="let m of majors.value" (click)="removeSelection(m, majors, 'major')">
                                    {{ m }}
                                </span>
                            </div>
                        </div>
                        <div class="mb-small">
                            <label for="TargetMinors" class="form-label">
                                Targeted Minors
                            </label>
                            <div>
                                <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                    [availableValues]="availableMinors" attribute="minor"
                                    placeholder="Search for minors" buttonColor="green" (added)="selectMinor($event)">
                                </app-opportunity-add-attribute>
                            </div>
                            <div class="flex-columns flex-wrap" *ngIf="minors.value?.length">
                                <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                    *ngFor="let m of minors.value" (click)="removeSelection(m, minors, 'minor')">
                                    {{ m }}
                                </span>
                            </div>
                        </div>
                        <div class="mb-small">
                            <label for="TargetSkills" class="form-label">
                                Targeted Skills & Interests
                            </label>
                            <div>
                                <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                    [availableValues]="availableSkills" attribute="skill"
                                    placeholder="Search for skills" buttonColor="green" (added)="selectSkill($event)">
                                </app-opportunity-add-attribute>
                            </div>

                            <div class="flex-columns flex-wrap" *ngIf="skills.value?.length">
                                <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                    *ngFor="let s of skills.value" (click)="removeSelection(s, skills, 'skill')">
                                    {{ s }}
                                </span>
                            </div>
                        </div>
                        <div class="mb-small">
                            <label for="TargetSkills" class="form-label">
                                Targeted Interests <app-info-modal [audience]="'Employers'" [section]="'Opportunities'"
                                    [item]="'Targeted Interests'"></app-info-modal>
                            </label>
                            <div>
                                <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                    [availableValues]="availableInterests" attribute="fieldofinterest"
                                    placeholder="Search for interests" buttonColor="green"
                                    (added)="selectInterest($event)">
                                </app-opportunity-add-attribute>
                            </div>
                            <div class="flex-columns flex-wrap" *ngIf="interests.value?.length">
                                <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                    *ngFor="let i of interests.value"
                                    (click)="removeSelection(i, interests, 'fieldofinterest')">
                                    {{ i }}
                                </span>
                            </div>
                        </div>
                        <div class="mb-small">
                            <label for="TargetSkills" class="form-label">
                                Targeted Certifications 
                            </label>
                            <div>
                                <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                    [availableValues]="availableCertifications" attribute="certifications"
                                    placeholder="Search for certifications" buttonColor="green"
                                    (added)="selectCertification($event)">
                                </app-opportunity-add-attribute>
                            </div>
                            <div class="flex-columns flex-wrap" *ngIf="interests.value?.length">
                                <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                    *ngFor="let i of interests.value"
                                    (click)="removeSelection(i, certifications, 'certifications')">
                                    {{ i }}
                                </span>
                            </div>
                        </div>

                        <div class="flex-columns justify-between">
                        
                            <div style="width: 50%" class="preferred-opportunity checkbox-section gray mb-small">
                                <div class="text checkbox-section-title">
                                    Opportunity Type
                                </div>
                                <div class="flex-columns mb-xsmall">
                                    <input hidden type="checkbox" formControlName="Shadowing"
                                        class="form-input" />
                                    <div (click)="toggleProp('Shadowing')" class="checkbox-display mr-small"
                                        [ngClass]="{ 'checked': formGroup.get('Shadowing').value }">
                                        <div class="checkbox-fill"></div>
                                    </div>
                                    <label for="Shadowing" class="form-label" (click)="toggleProp('Shadowing')">
                                        Shadowing
                                    </label>
                                </div>
                                <div class="flex-columns mb-xsmall">
                                    <input hidden type="checkbox" formControlName="Volunteering"
                                        class="form-input" />
                                    <div (click)="toggleProp('Volunteering')" class="checkbox-display mr-small"
                                        [ngClass]="{ 'checked': formGroup.get('Volunteering').value }">
                                        <div class="checkbox-fill"></div>
                                    </div>
                                    <label for="Volunteering" class="form-label" (click)="toggleProp('Volunteering')">
                                        Volunteering
                                    </label>
                                </div>
                                <div class="flex-columns mb-xsmall">
                                    <input hidden type="checkbox" formControlName="Internship"
                                        class="form-input" />
                                    <div (click)="toggleProp('Internship')" class="checkbox-display mr-small"
                                        [ngClass]="{ 'checked': formGroup.get('Internship').value }">
                                        <div class="checkbox-fill"></div>
                                    </div>
                                    <label for="Internship" class="form-label" (click)="toggleProp('Internship')">
                                        Internship
                                    </label>
                                </div>
                                <div class="flex-columns mb-xsmall">
                                    <input hidden type="checkbox" formControlName="Employment"
                                        class="form-input" />
                                    <div (click)="toggleProp('Employment')" class="checkbox-display mr-small"
                                        [ngClass]="{ 'checked': formGroup.get('Employment').value }">
                                        <div class="checkbox-fill"></div>
                                    </div>
                                    <label for="Employment" class="form-label" (click)="toggleProp('Employment')">
                                        Employment
                                    </label>
                                </div>
                                <div class="flex-columns mb-xsmall">
                                    <input hidden type="checkbox" formControlName="Tour"
                                        class="form-input" />
                                    <div (click)="toggleProp('Tour')" class="checkbox-display mr-small"
                                        [ngClass]="{ 'checked': formGroup.get('Tour').value }">
                                        <div class="checkbox-fill"></div>
                                    </div>
                                    <label for="Tour" class="form-label" (click)="toggleProp('Tour')">
                                        Tour
                                    </label>
                                </div>
                                <div class="flex-columns mb-xsmall">
                                    <input hidden type="checkbox" formControlName="CoOp"
                                        class="form-input" />
                                    <div (click)="toggleProp('CoOp')" class="checkbox-display mr-small"
                                        [ngClass]="{ 'checked': formGroup.get('CoOp').value }">
                                        <div class="checkbox-fill"></div>
                                    </div>
                                    <label for="CoOp" class="form-label" (click)="toggleProp('CoOp')">
                                        Co-Op
                                    </label>
                                </div>
                            </div>

                            <div style="width: 50%" class="preferred-opportunity checkbox-section gray mb-small">
                                <div class="text checkbox-section-title">
                                    Candidate Type
                                </div>
                                <div class="flex-columns mb-xsmall">
                                    <input hidden type="checkbox" formControlName="UnderGraduate" [value]="true"
                                        class="form-input" />
                                    <div (click)="toggleProp('UnderGraduate')" class="checkbox-display mr-small"
                                        [ngClass]="{ 'checked': formGroup.get('UnderGraduate').value }">
                                        <div class="checkbox-fill"></div>
                                    </div>
                                    <label for="UnderGraduate" class="form-label" (click)="toggleProp('UnderGraduate')">
                                        Under Graduate
                                    </label>
                                </div>
                                <div class="flex-columns mb-xsmall">
                                    <input hidden type="checkbox" formControlName="Graduate" [value]="true"
                                        class="form-input" />
                                    <div (click)="toggleProp('Graduate')" class="checkbox-display mr-small"
                                        [ngClass]="{ 'checked': formGroup.get('Graduate').value }">
                                        <div class="checkbox-fill"></div>
                                    </div>
                                    <label for="Graduate" class="form-label" (click)="toggleProp('Graduate')">
                                        Graduate
                                    </label>
                                </div>
                                <div class="flex-columns mb-xsmall">
                                    <input hidden type="checkbox" formControlName="Doctorate" [value]="true"
                                        class="form-input" />
                                    <div (click)="toggleProp('Doctorate')" class="checkbox-display mr-small"
                                        [ngClass]="{ 'checked': formGroup.get('Doctorate').value }">
                                        <div class="checkbox-fill"></div>
                                    </div>
                                    <label for="Doctorate" class="form-label" (click)="toggleProp('Doctorate')">
                                        Doctorate
                                    </label>
                                </div>
                                <div class="flex-columns mb-xsmall">
                                    <input hidden type="checkbox" formControlName="TechnicalDiploma" [value]="true"
                                        class="form-input" />
                                    <div (click)="toggleProp('TechnicalDiploma')" class="checkbox-display mr-small"
                                        [ngClass]="{ 'checked': formGroup.get('TechnicalDiploma').value }">
                                        <div class="checkbox-fill"></div>
                                    </div>
                                    <label for="TechnicalDiploma" class="form-label" (click)="toggleProp('TechnicalDiploma')">
                                        Technical Diploma
                                    </label>
                                </div>
                                <div class="flex-columns mb-xsmall">
                                    <input hidden type="checkbox" formControlName="HighSchool" [value]="true"
                                        class="form-input" />
                                    <div (click)="toggleProp('HighSchool')" class="checkbox-display mr-small"
                                        [ngClass]="{ 'checked': formGroup.get('HighSchool').value }">
                                        <div class="checkbox-fill"></div>
                                    </div>
                                    <label for="HighSchool" class="form-label" (click)="toggleProp('HighSchool')">
                                        High School
                                    </label>
                                </div>
                                <div class="flex-columns mb-xsmall">
                                    <input hidden type="checkbox" formControlName="PostGraduate" [value]="true"
                                        class="form-input" />
                                    <div (click)="toggleProp('PostGraduate')" class="checkbox-display mr-small"
                                        [ngClass]="{ 'checked': formGroup.get('PostGraduate').value }">
                                        <div class="checkbox-fill"></div>
                                    </div>
                                    <label for="PostGraduate" class="form-label" (click)="toggleProp('PostGraduate')">
                                        Post Graduate
                                    </label>
                                </div>
                                <div class="flex-columns mb-xsmall">
                                    <input hidden type="checkbox" formControlName="JobCenters" [value]="true"
                                        class="form-input" />
                                    <div (click)="toggleProp('JobCenters')" class="checkbox-display mr-small"
                                        [ngClass]="{ 'checked': formGroup.get('JobCenters').value }">
                                        <div class="checkbox-fill"></div>
                                    </div>
                                    <label for="JobCenters" class="form-label" (click)="toggleProp('JobCenters')">
                                        Job Centers
                                    </label>
                                </div>
                            </div>
                        </div>

                        <button type="button" class="button orange outline text"
                            (click)="openModal('saveAudience')">
                            Save as Audience Preset
                        </button>
                    </div>
                </ng-container>
            </div>

            <div *ngIf="steps[activeStep].split" class="stepper-content">

                <ng-container *ngIf="steps[activeStep].id === 'details'">
                    <div class="rounded-corners mb-large">
                        <div class="text heading-4 mb-small">
                            Details
                        </div>
                        <div class="row">
                            <label for="Title" class="form-label">
                                Opportunity Name <app-info-modal [audience]="'Employers'"
                                    [section]="'Opportunities'" [item]="'Name'"></app-info-modal>
                            </label>
                            <input id="Title" type="text" placeholder="Job Title" required
                                class="form-input form-input-rounded form-input-green" formControlName="Title" />
                            <label *ngIf="title?.errors?.required" class="text form-error">
                                Required
                            </label>
                            <label *ngIf="title?.errors?.minlength" class="text form-error">
                                5 character minimum
                            </label>
                            <label *ngIf="title?.errors?.maxlength" class="text form-error">
                                50 character maximum
                            </label>
                        </div>

                        <div>
                            <label for="Locations" class="form-label">
                                Position Location(s) <app-info-modal [audience]="'Employers'"
                                    [section]="'Opportunities'" [item]="'Location'"></app-info-modal>
                            </label>
                            <app-dropdown color="white" #ddLocation [required]="true"
                                label="Available Locations" [persist]="true" [fill]="true"
                                (selected)="selectLocation($event)" [actions]="selectLocations"></app-dropdown>
                            <div class="flex-columns flex-wrap">
                                <span *ngFor="let l of locations.value" name="Locations" type="text"
                                    (click)="deselectLocation(l)"
                                    class="chip chip-remove chip-green-border chip-sm mb-small mr-small">
                                    {{ l }}
                                </span>
                            </div>
                            <label *ngIf="locations?.errors?.required"
                                class="text form-error mb-small">
                                At least one location is required
                            </label>
                        </div>
                        <div class="flex-rows">
                            <div class="flex-columns">
                                <label for="Description" class="form-label mr-large">
                                    Description
                                </label>
                            </div>
                            <div class="form-input-quill mb-small">
                                <quill-editor id="Description" formControlName="Description"
                                    placeholder="Enter job description here" [modules]="quillConfiguration"
                                    [sanitize]="true"></quill-editor>
                            </div>
                            <label *ngIf="description?.errors?.required" class="text form-error">
                                Required
                            </label>
                            <label *ngIf="description?.errors?.minlength" class="text form-error">
                                Please be more descriptive
                            </label>
                            <label *ngIf="description?.errors?.maxlength" class="text form-error">
                                Your description is too long
                            </label>
                        </div>

                        <div class="mb-small">
                            <div class="row">
                                <label for="PublishStartDate" class="form-label">
                                    Publish From
                                    <app-info-modal [audience]="'Employers'" [section]="'Opportunities'"
                                        [item]="'Publish From'"></app-info-modal>
                                </label>
                                <input id="PublishStartDate" type="date"
                                    class="form-input form-input-rounded form-input-green"
                                    formControlName="PublishStartDate" />
                                <label *ngIf="publishStartDate?.errors?.required"
                                    class="text form-error">
                                    Required
                                </label>
                                <label *ngIf="publishStartDate?.errors?.afterDate"
                                    class="text form-error">
                                    Has to be before Publish To
                                </label>
                            </div>

                            <div class="row">
                                <label for="PublishEndDate" class="form-label">
                                    Publish To
                                    <app-info-modal [audience]="'Employers'" [section]="'Opportunities'"
                                        [item]="'Publish To'"></app-info-modal>
                                </label>
                                <input id="PublishEndDate" type="date"
                                    class="form-input form-input-rounded form-input-green"
                                    formControlName="PublishEndDate" />
                                <label *ngIf="publishEndDate?.errors?.required"
                                    class="text form-error">
                                    Required
                                </label>
                                <label *ngIf="publishEndDate?.errors?.afterDate"
                                    class="text form-error">
                                    Has to be after Publish From
                                </label>
                            </div>
                        </div>

                        <!-- <div *ngIf="opportunityType.value == opportunityTypeEnum.Volunteering || opportunityType.value ==  opportunityTypeEnum.JobShadowing"> -->
                        <div *ngIf="opportunityType.value === opportunityTypeEnum.Volunteering || opportunityType.value ===  opportunityTypeEnum.JobShadowing ||
                            opportunityType.value === opportunityTypeEnum.CoOp || opportunityType.value ===  opportunityTypeEnum.Employment || opportunityType.value ===  opportunityTypeEnum.Internship">
                            <div *ngIf="opportunityType.value === opportunityTypeEnum.Volunteering || opportunityType.value ===  opportunityTypeEnum.JobShadowing">   
                                <div  class="form-label">
                                    How would you like to receive applications?
                                </div>
                                <input type="hidden" formControlName="NeedsApplicationUrl"
                                    class="form-input form-input-rounded form-input-green mr-small" />
                                <div class="flex mb-medium">
                                    <div (click)="toggle(needsApplicationUrl, false)" class="checkbox-display mr-small"
                                        [ngClass]="{ 'checked': !needsApplicationUrl.value }">
                                        <div class="checkbox-fill"></div>
                                    </div>
                                    <label for="NeedsApplicationUrl" class="text">
                                        Application through Unext (Recommended)<br>
                                        Apply with Resume & Profile (Better tracking)
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div class="flex mb-small">
                                    <div *ngIf="opportunityType.value === opportunityTypeEnum.Volunteering || opportunityType.value === opportunityTypeEnum.JobShadowing"
                                        (click)="toggle(needsApplicationUrl, true)" class="checkbox-display mr-small"
                                        [ngClass]="{ 'checked': needsApplicationUrl.value }">
                                        <div class="checkbox-fill"></div>
                                    </div>
                                    <label for="NeedsApplicationUrl" class="text">
                                        Direct Applicants to an external site to apply
                                    </label>
                                 </div>
                                <div>
                                    <input style="width:100%" type="url" formControlName="ApplicationUrl" placeholder="External Site Url"
                                        class="form-input form-input-rounded form-input-green" />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="steps[activeStep].id === 'overview'">
                    <div class="rounded-corners flex-columns col-2 mb-medium">
                        <div class="text heading-4 mb-small details-preview">
                            Preview
                        </div>

                        <div class="flex-rows ml-auto">
                            <button class="mr-small mb-small button dark-green button-slim text"
                                style="grid-column: 1 / span 2;" type="button" (click)="publish($event)"
                                *ngIf="!opportunity?.active" [disabled]="publishRequirements.length">
                                Publish
                            </button>
                            <div *ngIf="publishRequirements().length"
                                class="text text-500 ml-auto mr-auto flex-rows">
                                <span *ngFor="let e of publishRequirements()">
                                    {{ e }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="mr-medium">
                        <app-employer-opportunity class="details-preview mb-large" [opportunity]="getOpportunity()"
                            [nested]="true"></app-employer-opportunity>
                    </div>

                    <div class="rounded-corners mb-large">
                        <div class="text heading-4 mb-small">
                            Audience
                        </div>

                        <div class="mb-small">
                            <label for="TargetSchools" class="form-label">
                                Targeted Schools
                            </label><br>
                            <input readonly class="form-input form-input-rounded form-input-green mb-small"
                                *ngFor="let s of schools.value" [value]="s" />
                        </div>
                        <div class="mb-small">
                            <label for="TargetEducation" class="form-label">
                                Targeted Education Level
                            </label><br>
                            <input readonly class="form-input form-input-rounded form-input-green mb-small"
                                *ngFor="let d of degrees.value" [value]="d" />
                        </div>
                        <div class="mb-small">
                            <label for="TargetMajors" class="form-label">
                                Targeted Majors
                            </label><br>
                            <input readonly class="form-input form-input-rounded form-input-green mb-small"
                                *ngFor="let m of majors.value" [value]="m" />
                        </div>
                        <div class="mb-small">
                            <label for="TargetMinors" class="form-label">
                                Targeted Minors
                            </label><br>
                            <input readonly class="form-input form-input-rounded form-input-green mb-small"
                                *ngFor="let m of minors.value" [value]="m" />
                        </div>
                        <div class="mb-small">
                            <label for="TargetSkills" class="form-label">
                                Targeted Skills & Interests
                            </label><br>
                            <input readonly class="form-input form-input-rounded form-input-green mb-small"
                                *ngFor="let s of skills.value" [value]="s" />
                        </div>
                        <div class="mb-large">
                            <label for="TargetSkills" class="form-label">
                                Targeted Interests
                            </label><br>
                            <input readonly class="form-input form-input-rounded form-input-green mb-small"
                                *ngFor="let i of interests.value" [value]="i" />
                        </div>
                        <div class="rounded-corners mt-auto">
                            <div class="text mb-small">
                                Card Preview
                            </div>
                            <app-opportunity-card [opportunity]="getOpportunity()"></app-opportunity-card>
                        </div>

                    </div>

                    <div class="rounded-corners flex-columns col-2 mb-medium">
                        <div class="text heading-4 mb-small details-preview">
                            Preview

                        </div>

                        <button class="ml-auto mr-small mb-small button dark-green button-slim text"
                            style="grid-column: 1 / span 2;" type="button" (click)="publish($event)"
                            *ngIf="!opportunity?.active" [disabled]="publishRequirements.length">
                            Publish
                        </button>

                    </div>

                </ng-container>
            </div>
            <div class="stepper-actions-split">
                <div class="rounded-corners actions ml-auto mr-auto">
                    <button type="button" class="button btn-blue text mb-small mr-small back" [disabled]="activeStep === 0"
                        (click)="stepper.previous()">
                        Back
                    </button>
                    <button type="button" class="button orange outline text mb-small mr-small next"
                        [disabled]="!currentStepValid() || activeStep === steps.length - 1"
                        (click)="onSave($event)">
                        Next
                    </button>
                    <!-- <button *ngIf="steps[activeStep].showSave" type="button"
                        class="button btn-blue text mb-small mr-small discard" (click)="cancel()">
                        Discard Changes
                    </button>
                    <button *ngIf="steps[activeStep].showSave" type="button" class="button orange outline text mb-small save"
                        [disabled]="!currentStepValid()" (click)="onSave($event)">
                        Save Changes
                    </button> -->
                </div>
            </div>
            <!-- POTENTIAL FEATURE
            <div class="mb-small">
                <div class="text" style="text-transform: uppercase;">
                    Promoting your opportunity
                </div>
                <p class="text text-400">
                    By promoting your opportunity, you can reach more relevant prospects.
                    Plus, our matching technology will help you improve your prospects over time to
                    get more of the results that matter to your business.
                </p>
            </div>

            <button class="button orange outline btn-fill" [disabled]="!formGroup.valid">Promote Opportunity</button>

        END POTENTIAL FEATURE -->
        </form>
    </div>
</app-progress-bar>

<app-modal id="saveAudience">
    <div class="form create-audience">
        <input type="text" placeholder="New Audience Name" [(ngModel)]="newAudienceName"
            class="form-input form-input-rounded text" />
        <button type="button" class="button btn-purple text" [disabled]="!newAudienceName?.length"
            (click)="createAudience()">
            Create
        </button>

        <ng-template ngFor let-a [ngForOf]="allaudiences | sort:'name':true">
            <div class="text text-400">
                {{ a.name }}
            </div>
            <button type="button" class="button btn-purple text" (click)="updateAudience(a.id)">
                Overwrite
            </button>
        </ng-template>
        <app-progress-bar *ngIf="working" [value]="working"></app-progress-bar>
    </div>
</app-modal>
