import { CanComponentDeactivate, CanDeactivateType } from "../../guard/canDeactivate.guard";

export abstract class GenericComponent implements CanComponentDeactivate  {
 
    constructor( ) {
    }

    canDeactivate(): CanDeactivateType { 
        if (this.hasUnsavedChanges) {
            return window.confirm('Do you want to leave and lose your changes?');
          } else {
            return true;
          }
    }

    public working = 0;
    protected startWorking(): void {
        this.working = 1;
    }
    protected setWorking(value: number): void {
        this.working = value;
    }
    protected endWorking(): void {
        this.working = 0;
    }

    hasUnsavedChanges = false;
    public hasChanges(): boolean { 
        return this.hasUnsavedChanges; 
    }

    protected postExecute(): void { } 

    protected async execute(callback: () => Promise<boolean>, errorMessage: string) {
        try {
            this.startWorking();
            if (await callback()) {
                this.postExecute(); 
            }
        } catch (error: any) {
            window.alert(errorMessage);
        } finally {
            this.endWorking();
        }
    }

}