<section id="faq-header" class="standard-padding mb-large">
    <div class="text text-black text-center mb-large">
        Frequently Asked Questions
    </div>
    <div class="text text-black text-center">
        Choose the role that best fits you to learn about what Unext cn do for you.
    </div>
</section>

<section id="roles" class="standard-padding mb-medium">
    <div class="tile employer" [ngClass]="{ 'active': selectedGroup === 'Employers' }"
        (click)="selectedGroup = 'Employers'">
        <img [src]="cdn.toCDN('images/icons/person.png')"
            [srcset]="cdn.toCDN('images/icons/person@2x.png 2x') + ', ' + cdn.toCDN('images/icons/person@3x.png 3x')" alt="employer" />
        <div class="text text-normal mb-small text-center">
            Employers
        </div>
    </div>
    <div class="tile student" [ngClass]="{ 'active': selectedGroup === 'Students' }"
        (click)="selectedGroup = 'Students'">
        <img [src]="cdn.toCDN('images/icons/hat.png')"
            [srcset]="cdn.toCDN('images/icons/hat@2x.png 2x') + ',' + cdn.toCDN('images/icons/hat@3x.png 3x')" alt="student" />
        <div class="text text-normal mb-small text-center">
            Students
        </div>
    </div>
</section>

<section id="faqs" class="standard-padding">
    <ng-template ngFor let-g [ngForOf]="groups">
        <div class="faq-group standard-padding {{ g.Name }}" [ngClass]="{ 'active': selectedGroup === g.Name }">
            <app-faq-item *ngFor="let f of g.FAQs" [faq]="f" [isEmployer]="g.Name === 'Employers'"></app-faq-item>
        </div>
    </ng-template>
</section>


