import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class StudentApiConfig {
    static path: string = environment.api.student.path;
    static version: string | number = environment.api.student.version;

    public static setApiVersion(version: string | undefined = 'api'): void {
        StudentApiConfig.version = version;
    }

    public static getApiVersion(): string | number {
        return StudentApiConfig.version;
    }

    public static getPath(): string {
        return StudentApiConfig.path;
    }

    public static getFullUrl(path): string {
        if (StudentApiConfig.getApiVersion()) {
            return [StudentApiConfig.getPath(), StudentApiConfig.getApiVersion(), path].join('/');
        } else {
            return [StudentApiConfig.getPath(), path].join('/');
        }
    }
}
