import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PageModeService {
    private editMode = false;

    public openEditMode(): void {
        this.editMode = true;
        document.body.classList.add('edit-mode');
    }

    public closeEditMode(): void {
        this.editMode = false;
        document.body.classList.remove('edit-mode');
    }
}
