import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Opportunity } from 'src/app/shared/models/opportunity.model';
import { EmployerExistingOpportunitiesComponent } from '../employer-existing-opportunities/employer-existing-opportunities.component';

@Component({
  selector: 'app-employer-opportunities',
  templateUrl: './employer-opportunities.component.html',
  styleUrls: ['./employer-opportunities.component.scss']
})
export class EmployerOpportunitiesComponent {
  @ViewChild('existingOpportunities') existingOpportunities: EmployerExistingOpportunitiesComponent

  urlName: string

  constructor(
    route: ActivatedRoute,
  ) {
    if (route.snapshot.params.urlName !== null && route.snapshot.params.urlName !== undefined) {
      this.urlName = route.snapshot.params.urlName;
    }
  }

  opportunityAdded(event: Opportunity): void {
    this.existingOpportunities.opportunityAdded(event)
  }
}
