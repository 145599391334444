import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { UploadApiConfig } from '../api';

@Injectable()
export class UploadService {

    constructor(private http: HttpClient) { }

    public push(files: File[], entityId: string, entityType: number): Promise<string[]> {
        const formData = new FormData();

        if (files && files.length) {
            for (let i = 0; i < files.length; ++i) {
                formData.append(`file-${i}`, files[i], files[i].name);
            }
        }
        return lastValueFrom(this.http.post<string[]>(UploadApiConfig.getFullUrl(`${entityId}/${entityType}`), formData));
    }
}
