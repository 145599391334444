import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { NEVER, Observable, timer } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

export const authRetryCount = 0;
export const retryWaitMilliSeconds = 1000;

export const InterceptorSkipHeader = 'X-Skip-Interceptor';
export const skipAuthHeaders = new HttpHeaders().set(InterceptorSkipHeader, '');

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const token = localStorage.getItem('a0at');
        const uid = localStorage.getItem('uid');

        if (req.url.indexOf('/api/') < 0) {
            return next.handle(req);
        }

        if (uid?.length && !req.params.has('uid')) {
            const params = req.params.append('uid', uid);
            return next.handle(req.clone({ params }));
        }

        if (!token?.length && req.headers.has(InterceptorSkipHeader)) {
            const headers = req.headers.delete(InterceptorSkipHeader);
            return next.handle(req.clone({ headers }));
        }

        if (token) {
            return next.handle(this.addAuthToken(req))
            .pipe(
                map((event: HttpEvent<any>) => {
                    return event;
                }),
            );
        } else {
            return timer(1000).pipe(
                delay(1000),
                switchMap(() => localStorage.getItem('a0at') ? 
                    next.handle(this.addAuthToken(req)).pipe(
                        map ((event: HttpEvent<any>) => {
                            return event;
                        }),
                    ) : 
                    NEVER));
        }
    }

    private addAuthToken(request: HttpRequest<any>): HttpRequest<any> {
        const token = localStorage.getItem('a0at');

        if (token) {
            request = request.clone({
                headers: request.headers.set('Authorization', `bearer ${token}`),
            });
        }

        return request;
    }

}
