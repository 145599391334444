<form class="form" id="upload-file">
    <ng-template ngFor let-f [ngForOf]="files" let-i="index">
        <label class="label mb-small">
            <input class="form-input form-input-100" type="text" readonly [value]="f.name" [disabled]="working" [accept]="getMetaType()" />
            <img class="delete ml-auto" [src]="cdn.toCDN('images/icons/delete.png')" alt="remove" (click)="remove(i)" />
        </label>
    </ng-template>

    <label [attr.for]="'file-select-' + uniqueId" id="file-select-label">
        <a class="button">
            Select File
        </a>
    </label>
    <input [attr.name]="'file-select-' + uniqueId" [attr.id]="'file-select-' + uniqueId" 
            [ngModelOptions]="{ standalone: true }" [(ngModel)]="fileUpload" 
            type="file" (change)="onChange($event)" [disabled]="working" [accept]="getMetaType()" />

    <button (click)="onUpload()" class="button dark-green mb-2"
        [disabled]="!files.length || files.length > maxFiles || working">
        Upload
    </button>

    <app-progress-bar [value]="40" *ngIf="working" [splashScreen]="true"></app-progress-bar>
</form>
