<section id="newsletter">
  <form class="form" [formGroup]="formGroup" (submit)="onSave($event)">
    <label class="sr-only form-label text label-1">Full Name</label>
    <label class="sr-only form-label text label-2">Email Address</label>
    <div class="row">
      <div class="col col-input-1">
        <input class="form-input transparent input-1" formControlName="name" placeholder="Name" />
        <label class="form-error text" *ngIf="formGroup.touched && name.errors?.required">
          Name is <strong>required</strong>
        </label>
      </div>
      <div class="col col-input-2">
        <input class="form-input transparent input-2" formControlName="email" placeholder="Email" />
        <label class="form-error text"
          *ngIf="formGroup.touched && email.errors?.email && !email.errors?.required">
          Please enter a valid email address
        </label>
        <label class="form-error text" *ngIf="formGroup.touched && email.errors?.required">
          Email is <strong>required</strong>
        </label>
      </div>
      <button type="submit" class="btn" [disabled]="formGroup.invalid"
        (click)="handle('newsletter', 'home', 'click', 'sign up')">Subscribe</button>  
    </div>
  </form>
</section>