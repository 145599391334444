import { Component } from '@angular/core';
import { GTMComponentComponent } from 'src/app/shared/components/gtmcomponent/gtmcomponent.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-for-institutions',
  templateUrl: './for-institutions.component.html',
  styleUrl: './for-institutions.component.scss'
})
export class ForInstitutionsComponent extends GTMComponentComponent{

  constructor(
    gtm: GoogleTagManagerService
  ) {
      super(gtm);
  }

}
