<div class="toggle text {{ size }}" (click)="toggleButton($event)">
    <span *ngIf="offText && offText.length" class="text text-black ml-auto" [ngClass]="{ 'text-no-wrap': textNowrap }">
        {{ offText }}
    </span>
    <div class="toggle-wrapper">
        <div class="toggle-background" [ngClass]="{ 'off': !isOn, 'on': isOn }">
        </div>
        <div class="toggle-box" [ngClass]="{ 'off': !isOn, 'on': isOn }">
            <div class="toggle-box-inner"></div>
        </div>
    </div>
    <span *ngIf="onText && onText.length" class="text text-black mr-auto" [ngClass]="{ 'text-no-wrap': textNowrap }">
        {{ onText }}
    </span>
</div>
