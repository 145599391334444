import { environment } from '../../../environments/environment';

/**
 * Base API configuration file
 */
export class SkillApiConfig {
    static path: string = environment.api.skill.path;
    static version: string | number = environment.api.skill.version;

    public static setApiVersion(version: string | undefined = 'api'): void {
        SkillApiConfig.version = version;
    }

    public static getApiVersion(): string | number {
        return SkillApiConfig.version;
    }

    public static getPath(): string {
        return SkillApiConfig.path;
    }

    public static getFullUrl(path): string {
        if (SkillApiConfig.getApiVersion()) {
            return [SkillApiConfig.getPath(), SkillApiConfig.getApiVersion(), path].join('/');
        } else {
            return [SkillApiConfig.getPath(), path].join('/');
        }
    }
}
