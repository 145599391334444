import { Component } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-gtmcomponent',
  templateUrl: './gtmcomponent.component.html',
  styleUrls: ['./gtmcomponent.component.scss']
})
export class GTMComponentComponent {

  constructor (
    private gtm: GoogleTagManagerService
  ) { }

  public handle(
    event: string | undefined = undefined, 
    category: string | undefined = undefined, 
    action: string | undefined = undefined, 
    label: string | undefined = undefined): void {
    const gtmTag = {
      'event': `'${event ?? ''}'`,
      'action': `'${action ?? ''}'`,
      'label': `'${label ?? ''}'`,
      'category': `'${category ?? ''}'`,
      'pageName': window.location.pathname
    };
    this.gtm.pushTag(gtmTag);
  }

}
